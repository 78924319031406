import React from "react";
import Row from 'react-bootstrap/Row';
import Header from "../components/UI/Header";
import SideBar from "../components/UI/Sidebar";
import CloudStatusArea from "../components/CloudStatus/CloudStatusArea";

function CloudStatusPage() {

  return (
    <div>
      <Header />
      <Row>
          <SideBar />
          <CloudStatusArea />
      </Row>
    </div>
  );
}

export default CloudStatusPage;