import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";
import ApiGatewayService from "../../API/ApiGatewayService";
import MultiSelect from "./MultiSelect/MultiSelect";
function AddServiceTokenForm({ addToken, setAddToken, setSuccessAdd, successAdd }) {

    const [issuedDate, setIssuedDate] = useState(null);
    const [issuedTime, setIssuedTime] = useState('06:00'); 
    const [allAudience, setAllAudience] = useState([])
    const [allScope, setAllScope] = useState([])
    const [errors, setErrors] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [tokenDescription, setTokenDescription] = useState('');
    const [name, setName] = useState('');
    const [audience, setAudience] = useState(null);
    const [selectedScopes, setSelectedScopes] = useState([])

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {

        (async () => {

            await ApiGatewayService.getAudienceList().then((res) => { setAllAudience(res) }).catch((err) => changeStatus())
           // await ApiGatewayService.getScopeList().then((res) => { setAllScope(res) }).catch((err) => changeStatus())
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') saveToken();

            })
            .catch(() => changeStatus())
    }


    const saveToken = async event => {
        setIsPreloader(true);
        if (event) event.preventDefault();
        const token = {};
        token.name = name;
        token.issued_at = issuedDate + 'T' + issuedTime;
        token.audience = audience.id;
        token.scope = selectedScopes;
        token.description = tokenDescription;

        await ApiGatewayService.newServiceToken(token)
            .then(() => { setAddToken(false); setIsPreloader(false); setSuccessAdd(!successAdd) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken('save');
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })

    }
    const getExpDate = () => {
        const startDate = new Date(issuedDate);
        const dateWithToken = startDate.getTime() + 31536000*1000;//Год
        const expDate = new Date(dateWithToken)
        return expDate.toISOString().split('T')[0]
    }
    return (
        <Form id="form" onSubmit={saveToken} className="was-validated" style={{ position: "relative" }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key={"danger" + error} variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}
         
            <Row>
                <Col className=" col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Название</Form.Label>
                        <Form.Control size="sm" type='text' value={name} onChange={(e) => setName(e.target.value)} required={true}>

                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col sm={2}>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Дата активации </Form.Label>
                        <Form.Control
                            value={issuedDate || ""}
                            onChange={(e) => setIssuedDate(e.target.value)}
                            size="sm" type="date"
                            required={true} />

                    </Form.Group>
                </Col>
                <Col sm={2}>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >время активации</Form.Label>
                        <Form.Control
                            value={issuedTime}
                            onChange={(e) => setIssuedTime(e.target.value)}
                            size="sm" type="time"
                            required={true} />

                    </Form.Group>
                </Col>
            </Row>
            <Row>
              
                <Col sm={4}>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Сервис</Form.Label>

                        <Dropdown style={!audience ? { border: "1px solid red", height: 31, borderRadius: 6, paddingLeft: '.5rem' } : { border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-audience" >
                                {audience?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'audience'}
                                // filterQa={true}
                            >
                                {allAudience?.map(item =>
                                    <Dropdown.Item eventKey={"au" + item.id} key={"au" + item.name} onClick={() => { setAudience(item);setSelectedScopes([]) }}>{item.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Ресурсы и доступы</Form.Label>

                        <MultiSelect data={audience?.scopes} selectedScopes={selectedScopes} setSelectedScopes={setSelectedScopes} disabled={!audience}/>

                    </Form.Group>
                </Col>
            </Row>
            <Row>

                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Описание</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={tokenDescription || ""}
                            size="sm"
                            onChange={(e) => setTokenDescription(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                {issuedDate && <Col sm={2}>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Дата окончания</Form.Label>
                        <Form.Control
                  
                            value={getExpDate()}
                            // onChange={(e) => setExpiresDate(e.target.value)}
                            disabled={true}
                            size="sm" type="date"
                        />

                    </Form.Group>
                </Col>}
                {issuedDate && <Col sm={2}>
                <Form.Group className="mb-3" required={true} >
                    <Form.Label style={{ color: "white" }}>Time</Form.Label>
                    <Form.Control
                        value={issuedTime}
                                disabled={true}
                        size="sm" type="time"
                        required={true} />

                </Form.Group>
            </Col>}
            </Row>
            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            setAddToken(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Добавить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>


            <hr />
        </Form >
    );
}
export default AddServiceTokenForm;
