import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import StatsList from "./StatsList";
import Graph from "./Graph";
import { Button } from "react-bootstrap";
function DetailAssetArea({sn, item, setVisible, visible}) {

    const [key, setKey] = useState('table');
    return (
        <Col style={{minWidth: '800px', maxHeight: '100px'}}>
           <div style={{display: "flex", justifyContent: "space-between"}}><h3>Устройство {sn}</h3><Button variant="outline-primary" onClick={()=> {setKey('table');setVisible(false)}}>Закрыть</Button></div> 
           {visible &&  <Tabs
                id="asset-tabs"
                activeKey={key}
                onSelect={(key) => setKey(key)}
                className="mb-3"
            >
                <Tab eventKey="table" title="Таблица" >
                    < StatsList sn={sn} asset={true}/>
                </Tab>
                <Tab eventKey="graph" title="График">
                    {key === 'graph' && <Graph sn={sn} visible={visible}/>}
                </Tab>
            </Tabs>}
        </Col>
    )
}

export default DetailAssetArea;