import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import UserCreateForm from "../User/UserCreateForm";
import UserList from "../User/UserList";
import OrgService from "../../API/OrgService";
import QuotaArea from "../Quotator/QuotaArea";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ContractsArea from "../Contracts/ContractArea";
import { AuthContext, UserContext } from "../../context";
import AssetArea from "../Assets/AssetArea";
import { useNavigate, useParams } from 'react-router-dom';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import OrgCreateForm from "./OrgCreateForm";
import OrgPartner from "./OrgPartner";

const OrgDetailComp = (props) => {
    //Контект
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const navigate = useNavigate();
    const { idOrg } = useParams();
    const { userinfo } = useContext(UserContext);
    const [userChanged, setUserChanged] = useState(false);
    const [isUserAdding, setIsUserAdding] = useState(false);
    const [orgDetails, setOrgDetails] = useState();

    const [key, setKey] = useState('assets');
    const [isOrgAdding, setIsOrgAdding] = useState(false);

    useEffect(() => {

        (async () => {
            await OrgService.getItem(idOrg).then((res) => setOrgDetails(res)).catch(() => changeStatus())

            setKey('assets')
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idOrg, isUserAdding, isOrgAdding]);

    return (
        <Col>
            <Row>
                <Col sm={2} colSpan={1}>
                    <Form.Group className="mb-4">
                        <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => { navigate(-1) }}
                        >Вернуться</Button>

                    </Form.Group>
                </Col>
            </Row>
            {isOrgAdding && <OrgCreateForm org={orgDetails} setIsOrgAdding={setIsOrgAdding} title='Изменить организацию'>
            </OrgCreateForm>}
            {
                isUserAdding
                &&
                <Row>
                    <Col className="col-4">
                        <UserCreateForm
                            setIsUserAdding={setIsUserAdding}
                            setUserChanged={setUserChanged}
                            orgId={orgDetails.id}
                        />
                    </Col>
                </Row>
            }
            {orgDetails &&
                <>
                    <Row>
                        <Col >
                            <b>Наименование</b> {orgDetails.name}
                            <br />
                            <b>Тип</b> {orgDetails.type}
                            <br />
                            <b>ИНН</b> {orgDetails.vat}
                            <br />
                            <b>Создана</b> {orgDetails.date_created}
                            <br />
                            <b>Количество квот</b> {orgDetails.quotes}
                            <br />
                            <b>Количество контрактов</b> {orgDetails.contracts}
                            <br />
                            <b>Количество устройств</b> {orgDetails.devices}
                            <br />
                            <b>Количество пользователей</b> {orgDetails.users_amount}
                            <br />
                            <b>SLUG</b> {orgDetails.slug}
                            <br />
                            <b>Веб-сайт</b> {orgDetails.website}
                            <br />
                            <br />
                            <br />


                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-md-auto" >
                            <Form.Group className="mb-4">
                                <Button variant="outline-primary" size="sm" onClick={() => setIsUserAdding(true)}>Добавить пользователя</Button>

                            </Form.Group>
                        </Col>
                        <Col className="col-md-auto"  >
                            <Form.Group className="mb-4">
                                <Button variant="outline-primary" size="sm" onClick={() => setIsOrgAdding(true)}>Изменить организацию</Button>

                            </Form.Group>
                        </Col>

                    </Row>
                </>

            }
            {orgDetails &&
                <Row>
                    <Col>
                        <Tabs
                            id="OrgDetail-tabs"
                            activeKey={key}
                            onSelect={(key) => setKey(key)}
                            className="mb-3"
                        >
                            <Tab eventKey="assets" title="Устройства" >
                                {key === 'assets' && <AssetArea orgId={orgDetails.id} close={props.setViewOrg} />}
                            </Tab>
                            <Tab eventKey="contracts" title="Договора" disabled={userinfo.organizationType !== "VENDOR"}>
                                {key === 'contracts' && <ContractsArea orgId={orgDetails.id} />}
                            </Tab>
                            <Tab eventKey="quotas" title="Квоты">
                                {key === 'quotas' && <QuotaArea org={orgDetails} orgId={orgDetails.id} />}
                            </Tab>
                            <Tab eventKey="partners" title="Партнеры" disabled={orgDetails.type === "ENDUSER" ? false : true}>
                                {key === 'partners' && <OrgPartner partners={orgDetails.partners} />}
                            </Tab>
                            <Tab eventKey="users" title="Пользователи">
                                {key === 'users' && <UserList orgId={orgDetails.id} userChanged={userChanged} />}
                            </Tab>

                        </Tabs>
                    </Col>
                </Row>}
        </Col>
    );
}

export default OrgDetailComp;