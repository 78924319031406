import React, { useContext, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import User from "../../API/User";
import Alert from 'react-bootstrap/Alert';
import { UserContext } from "../../context";


function MyProfileForm({ setVisible }) {
    const { userinfo, setUserinfo } = useContext(UserContext);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [phone, setPhone] = useState('');

    const [error, setError] = useState([]);
    const [message, setMessage] = useState(false);
    const [profileChanging, setProfileChanging] = useState(true);

    useEffect(() => {
        setFirstName(userinfo.first_name);
        setLastName(userinfo.last_name);
        setJobTitle(userinfo.job_title);
        setPhone(userinfo.phone)
    }, [userinfo])
    const changeProfile = async event => {
        event.preventDefault();
        try {
            const data = {
                "first_name": firstName,
                "last_name": lastName,
                "job_title": jobTitle,
                "phone": phone
            };
            await User.updateMe(data)
                .then((res) => {
                    if (res.status === 200) {
                        setError([]);
                        setUserinfo(userinfo,
                            userinfo.first_name = res.data.first_name,
                            userinfo.last_name = res.data.last_name,
                            userinfo.job_title = res.data.job_title,
                            userinfo.phone = res.data.phone);
                        setMessage("Ваш профиль был успешно изменен");
                        setVisible();
                    } else {
                        setError([`Ошибка статус ${res.status}`]);
                    }
                })
                .catch((err) => setError(err));
            // const userinfo = props.userinfo;
            // userinfo['first_name'] = props.firstName;
            // userinfo['last_name'] = props.lastName;
            // userinfo['phone'] = props.phone;
            // userinfo['job_title'] = props.jobTitle;
            // props.setUserinfo(userinfo);
            // localStorage.setItem('first_name', userinfo['first_name']);
            // localStorage.setItem('last_name', userinfo['last_name']);
            // localStorage.setItem('phone', userinfo['phone']);
            // localStorage.setItem('job_title', userinfo['job_title']);
        } catch (e) {
            setError(e.message);
        };

    }


    return (
        <Form onSubmit={changeProfile} >
            {error.length > 0 &&
                error.map(item =>
                    <Alert key="danger" variant="danger">
                        {item}
                    </Alert>
                )
            }
            {message &&
                <Alert key="primary" variant="primary">
                    {message}
                </Alert>
            }
            <Form.Group className="mb-4">
                <Form.Label>Имя</Form.Label>
                <Form.Control value={firstName || ''} onChange={(e) => setFirstName(e.target.value)} size="sm" type="text" name='name'/>
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Фамилия</Form.Label>
                <Form.Control value={lastName || ''} onChange={(e) => setLastName(e.target.value)} size="sm" type="text" name='surname'/>
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Должность</Form.Label>
                <Form.Control value={jobTitle || ''} onChange={(e) => setJobTitle(e.target.value)} size="sm" type="text" name='dolzh'/>
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Телефон</Form.Label>
                <Form.Control value={phone || ''} onChange={(e) => setPhone(e.target.value)} size="sm" type="text" placeholder="Укажите Ваш контактный телефон.." name='phone'/>
            </Form.Group>
            {
                profileChanging &&
                <Button variant="outline-primary" size="sm" type="submit">
                    Сохранить изменения
                </Button>
            }

            <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setVisible(); setError([]); setMessage(false); setProfileChanging(true); }}>
                Закрыть
            </Button>
        </Form>
    );
}
export default MyProfileForm;