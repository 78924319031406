import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import { useRef } from "react";
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import { Captcha } from "../User/Captcha";
import Preloader from "../UI/Preloader/Preloader";
import { useParams } from 'react-router-dom';
const Login = function () {
    const { error } = useParams();
    // eslint-disable-next-line no-unused-vars
    const { isAuth, setIsAuth } = useContext(AuthContext);
    const [isPreloader, setIsPreloader] = useState(false);
    //КАПТЧА
    const [viewCaptcha, setViewCaptcha] = useState(false);
    const [token, setToken] = useState('');
    const url = process.env.REACT_APP_API_SERVER;

    const emailInputRef = useRef();
    const passInputRef = useRef();
    const [errors, setErrors] = useState([]);

    const login = async event => {
        event.preventDefault();
       
        setIsPreloader(true)
        const email = emailInputRef.current.value
        const pass = passInputRef.current.value
        var token = null;
        var accessToken = null;
        var freshToken = null;
        try {
          
            token = await Auth.getToken(email, pass);
            accessToken = token.access;
            freshToken = token.refresh;
            localStorage.setItem('token', accessToken);
            localStorage.setItem('freshToken', freshToken);
            setTimeout(() => {
                setIsPreloader(false); setErrors([]);
                setIsAuth(true);
                window.location.reload()
            }, 2000);

        } catch (e) {
      
            setTimeout(() => {setIsPreloader(false);
                setErrors(e.message);
            if (e.message[0].includes('Запрос')) {   setViewCaptcha(true);  }}, 3000) 

        };

    }

    useEffect(() => {
        if (token !== "") { setIsPreloader(true); setTimeout(()=> {setViewCaptcha(false); setToken(''); setErrors([]); setIsPreloader(false) }, 2000) };
    }, [token])

    useEffect(()=> {
if (error) {
    setErrors(["У Вас нет прав для логина"])
}
    },[error])
    return (
        <div className="Auth-form-container">
            <form onSubmit={login} className="Auth-form" style={{position:"relative"}}>
               {isPreloader &&  <Preloader></Preloader>}
                <div className="Auth-form-content">
                    <h3 className="Auth-form-title">Вход на портал</h3>
                    {errors.length > 0 &&
                        errors?.map(error =>
                            <Alert key="danger" variant="danger">
                                {error}
                            </Alert>
                        )
                    }
                    <div className="form-group mt-3">
                        <label>Электронный адрес</label>
                        <input
                            ref={emailInputRef}
                            type="email"
                            className="form-control mt-1"
                            placeholder="Введите email"
                        />
                    </div>
                    <div className="form-group mt-3 mb-2">
                        <label>Пароль</label>
                        <input
                            ref={passInputRef}
                            type="password"
                            className="form-control mt-1"
                            placeholder="Введите пароль"
                        />
                    </div>
                    {viewCaptcha && !url.includes('local') && <Captcha setToken={setToken} />}
                    <div className="d-grid gap-2 mt-3">
                        <button type="submit" className="btn btn-primary" disabled={viewCaptcha || isPreloader===true === true ? true : false}>
                            Войти
                        </button>
                    </div><hr />
                    <Link className="active" to="/passrecover">Забыли пароль?</Link>
                </div>
            </form>

        </div>
    );
}
export default Login;