/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ServerSoftwareList from "./ServerSoftwareList";
import PimDocumentationList from "./PimDocumentationList";
import PimCompatibleList from "./PimCompatibleList";

function PimArea() {

    return (
        <>
            <Col>
                <h3 className="mb-3">PIM</h3>
                <div style={{display: "flex", justifyContent: "flex-end", gap: 10}}>
<a href='#compatiblePimId' style={{color: "blue", fontSize: 14}}>Совместимость</a>
<a href='#docsPimId'style={{color: "blue", fontSize: 14}}>Документация</a>

                </div>
                <Row>
                    <Col style={{ position: "relative" }}>
                        <ServerSoftwareList />
                    </Col>
                </Row>
                <Row id='compatiblePimId'>
                    <Col>
                    <PimCompatibleList/>
                    </Col>
                </Row>
                <Row id='docsPimId' style={{marginBottom: 100}}>
                    <Col>
                    <PimDocumentationList/>
                    </Col>
                </Row>
               
            </Col>
        </>
    );
}
export default PimArea;