import Api from '.';

export default class ApiGatewayService {

    static async getAudienceList() {
        const url = 'api/v1/gateway/audience/'
        const method = 'GET';
        const params = {};
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async getScopeList() {
        const url = 'api/v1/gateway/scope/'
        const method = 'GET';
        const params = {};
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async newAudience(item) {
        const url = 'api/v1/gateway/audience/';
        const method = 'POST';
        const data = item;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async patchAudience(item, id) {
        const url = `api/v1/gateway/audience/${id}/`;
        const data = item;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }
    static async deleteAudience(id) {
        const url = `api/v1/gateway/audience/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }

    static async newToken(item) {
        const url = 'api/v1/gateway/refresh-token/';
        const method = 'POST';
        const data = item;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async newScope(item) {
        const url = 'api/v1/gateway/scope/';
        const method = 'POST';
        const data = item;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async patchScope(item, id) {
        const url = `api/v1/gateway/scope/${id}/`;
        const data = item;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }
    static async deleteScope(id) {
        const url = `api/v1/gateway/scope/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }

    static async getItem(id) {

        const url = `api/v1/edl/access/${id}/`;
        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }

    static async patchToken(item, subject) {
        const url = `api/v1/gateway/refresh-token/${subject}/`;
        const data = item;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }
    static async deleteToken(subject) {
        const url = `api/v1/gateway/refresh-token/${subject}/`;
    
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }

    static async getTokenList(filters) {
        const url = 'api/v1/gateway/refresh-token/'
        const method = 'GET';
        const params = {};
        params.order_by = '-date_end'
        if (filters) {
            for (let key in filters) {
                params[key] = filters[key];
            }
        };
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async newServiceToken(item) {
        const url = 'api/v1/gateway/service-token/';
        const method = 'POST';
        const data = item;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async getServiceTokenList(filters) {
        const url = 'api/v1/gateway/service-token/'
        const method = 'GET';
        const params = {order_by: 'date_end'};
        
        if (filters) {
            for (let key in filters) {
                params[key] = filters[key];
            }
        };
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async patchServiceToken(item, subject) {
  
        const url = `api/v1/gateway/service-token/${subject}/`;
        const data = item;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }
    static async deleteServiceToken(subject) {
        const url = `api/v1/gateway/service-token/${subject}/`;
    
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }

}