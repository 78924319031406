import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import { organisationRating } from "../../utils/constants";

const ContractItem = (props) => {
    const navigate = useNavigate();
    const [scroll, setScroll] = useState(false);

    const dateStyles = {
        "fontSize": 'x-small',
    }


    useEffect(() => {
        if (scroll === true)
            window.scrollTo(0, 0);
    }, [scroll]);

    const paymentMethodRu = (method) => {
        let methodRu = null;
        // eslint-disable-next-line default-case
        switch (method) {
            case 'monthly':
                methodRu = 'по-месячно';
                break;

            case 'quarterly':
                methodRu = 'по-квартально';
                break;
            case 'prepaid':
                methodRu = 'оплата 100%';
                break;
        }
        return methodRu;
    }

    const getStatusRu = (status, date) => {
        let statusRu = null;
        // eslint-disable-next-line default-case
        switch (status) {
            case 'paid':
                statusRu = 'оплачено';
                break;

            case 'pay_before':
                statusRu = <span style={{ color: 'red' }}>оплата до {date}</span>;
                break;
            case 'patch_needed':
                statusRu = <span style={{ color: 'red' }}>нужен патч</span>;
                break;
            case 'keys_needed':
                statusRu = <span style={{ color: 'red' }}>нужны ключи</span>;
                break;
            case 'all_done':
                statusRu = 'все сделано';
                break;
            case 'awaits_delivery':
                statusRu = <span style={{ color: 'red' }}>ожидание поставки</span>;
                break;
        }
        return statusRu;
    }

    const handleClickDetail = (e) => {
        navigate(`/contract/${props.item.id}`)
    }

    return (
        <tr
            style={dateStyles}>
            <td onClick={() => handleClickDetail()}>{getStatusRu(props.item.tech_status)}</td>
            <td onClick={() => handleClickDetail()}>{props.item.contract_date}</td>
            <td onClick={() => handleClickDetail()}>{props.item.contract_enddate}</td>
            <td onClick={() => handleClickDetail()}>{props.item.contract_nr}</td>
            <td onClick={() => handleClickDetail()}>{paymentMethodRu(props.item.payment_method)}</td>


            <td  onClick={() => { props.item.enduser_org ? navigate(`/orglist/${props.item.organisation_info[1].id}`) : navigate(`/orglist/${props.item.organisation_info[0].id}`) }}>{props.item.enduser_org ? organisationRating(props.item.organisation_info[1].rating, props.item.contract_partner_org) : organisationRating(props.item.organisation_info[0].rating, props.item.contract_partner_org)}

            </td>
            <td onClick={(e) => { props.item.enduser_org ? navigate(`/orglist/${props.item.organisation_info[0].id}`) : e.preventDefault() }}>{props.item.enduser_org ? organisationRating(props.item.organisation_info[0].rating, props.item.enduser_org) : ''}

            </td>
            <td>  <div style={{ display: "flex", justifyContent: "center", height: '100%' }}>

                {props.item.quota !== null ? <Button
                    onClick={(e) => {
                        setScroll(false)
                        props.setViewQuota(true); props.setCurrentQuota(props.item.quota); setScroll(true)
                    }} variant="outline-primary" style={{ padding: "2px 6px", fontSize: "10px" }}>{props.item.quota}</Button> : ""
                }  </div></td>
            <td onClick={() => handleClickDetail()}>{props.item.contract_price_sale_rub}</td>
            <td onClick={() => handleClickDetail()}>{props.item.comment}</td>

        </tr >
    );
}

export default ContractItem;