import Api from '.';

export default class GeoService {

  
    static async getAllCountries(filters) {

        var url = 'api/v1/countries/?page=all'
        const method = 'GET';
        const params = {};

        if (filters) {
            params.region__region_ru = filters;
        };
 
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async getAllCities(filters) {
     
        var url = 'api/v1/cities/?page=all'
        const method = 'GET';
        const params = {};

        if (filters) {
            params.country__country_ru = filters;
        };
 
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    

}