import React, { useState, useContext } from "react";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/esm/Button";
import UrlService from "../../API/UrlService";
import Alert from 'react-bootstrap/Alert';
import Preloader from "../UI/Preloader/Preloader";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
function CheckSn() {
    const columnStyle = {
        'padding': '0', 'position': "relative", 'boxSizing': "border-box",
        //'boxShadow': '10px 0 10px -10px rgba(0, 0, 0, .5)'
    }

    const subtitleStyle = {
        padding: '0 5px 0',
        "fontSize": 'x-small',
        marginBottom: '5px'
    }

    const [inputSn, setInputSn] = useState('')
    const [errors, setErrors] = useState([]);
    const [device, setdevice] = useState(null);

    const [isPreloader, setIsPreloader] = useState(false);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                handleCheckSn();
            })
            .catch(() => changeStatus())
    }
    const handleCheckSn = async event => {

        if (event) event.preventDefault();
        setIsPreloader(true);

        await UrlService.checkDevice(inputSn).then((res) => {
            setErrors([])
            setdevice(res)
        })

            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })
            .finally(() => setIsPreloader(false))
    }
    return (
        <>

            <Form onSubmit={handleCheckSn} style={{ position: 'relative', display: 'flex', padding: '0 5px', gap: '10px', justifyContent: 'space-between' }}>
                {isPreloader && <Preloader leftCoord={true} />}
                <Col className="col-8" style={columnStyle}>
                    <Form.Group className="mb-2">
                        <Form.Control size="sm" type="text" id="checkSn" required={true} style={{ 'border': '1px solid #0d6efd', 'borderRadius': '10px', }}
                            value={inputSn}

                            placeholder={'013201028175'}
                            pattern={'[0-9]+'}
                            onChange={(e) => { setInputSn(e.target.value); }}
                        />
                    </Form.Group></Col>
                <Col className="col-3" style={columnStyle}>
                    <Button

                        variant="outline-success" size="sm" type="submit " style={{ width: '100%', paddingRight: 0, paddingLeft: '0' }}>
                        Проверить
                    </Button></Col></Form>

            <hr style={{ marginTop: 0 }} />
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Row style={{ maxWidth: '100%', padding: 0, margin: 0 }} className='align-items-center justify-content-between'>

            </Row>
            {errors.length === 0 &&

                <>
                    <p style={subtitleStyle}><b>Время последнего обращения:</b> {device?.last_active_at || 'Нет данных'}</p>
                    <p style={subtitleStyle}><b>Организация:</b> {device?.organisation__name || 'Нет данных'}</p>
                    <p style={subtitleStyle}><b>Платформа:</b> {device?.platform__name}</p>
                    <p style={subtitleStyle}><b>Кол-во запросов за сегодня:</b> {device?.curr_day_req_cnt}</p>
                    <p style={subtitleStyle}><b>Кол-во запросов в kfeed за сегодня:</b> {device?.kfeed_hits_total}</p>
                    {/* <p style={subtitleStyle}><b>Середнее кол-во запросов за сегодня от таких же платформ:</b> {device?.external_db.platform_curr_day_req_avg}</p> */}

                </>}
        </>
    )
}
export default CheckSn;