import React from "react";
import Row from 'react-bootstrap/Row';
import Header from "../components/UI/Header";
import SideBar from "../components/UI/Sidebar";
import ApiGatewayArea from "../components/ApiGateway/ApiGatewayArea";

function ApiGatewayPage() {

  return (
    <div>
      <Header />
      <Row>
          <SideBar />
          <ApiGatewayArea />
      </Row>
    </div>
  );
}

export default ApiGatewayPage;