import React from "react";

const MyToggleAsset = React.forwardRef(({ children, onClick,size }, ref) => {
const styleToggle ={
    paddingLeft: "8px", width: "100%", overflow: "hidden"
};
if (size==='min') {styleToggle.fontSize = '10px'; styleToggle.height='38px'; styleToggle.whiteSpace ='pre-line'}
    return (

        <button className="btn dropdown-toggle btn-outline-secondary" 
        style={styleToggle} type="button" data-bs-toggle="dropdown"  aria-expanded="false"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}>

            {children}
        </button>


    )
});

export default MyToggleAsset;