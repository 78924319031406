import React from "react";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

function DashboardWarehouse(props) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );
    let summAssets = 0;
    let summAssetsOutWH = 0;
    const options = {
        indexAxis: 'y',
        interaction: {
            mode: "index"
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                ticks: {
                    font: {
                        size: 8,
                    }
                }
            }
        },
        elements: {

            bar: {
                borderWidth: 1,
            },
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: false,
                text: `Устройств  ${summAssets + summAssetsOutWH}`,
            },
            tooltips: {
                enabled: false

            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: function (context) {
                        if (context.datasetIndex > 0)
                            return false
                        return "На складе  " + data.datasets[0].data[context.dataIndex] + " / " + data.datasets[1].data[context.dataIndex] + "  Не на складе"
                    }
                },
            }
        }
    }
    const labels = props.assetsStats !== undefined ? props?.assetsStats.map((item, index) => { summAssets += item.count; summAssetsOutWH += item.not_on_warehouse_count; return item.platform__name }) : ''

    const data = props.assetsStats !== undefined ? {
        labels,
        datasets: [
            {
                label: `На складе: ${summAssets - summAssetsOutWH} шт.`,
                data: labels.map((item, index) => props?.assetsStats[index].count - props?.assetsStats[index].not_on_warehouse_count),
                borderColor: 'rgb(60, 179, 113, 0.8)',
                backgroundColor: 'rgba(60, 179, 113, 0.5)',
                barPercentage: .2,
                maxBarThickness: 8,
                datalabels: {
                    opacity: 0
                },
            },
            {
                label: `Не на складе:  ${summAssetsOutWH} шт.`,
                data: labels.map((item, index) => props?.assetsStats[index].not_on_warehouse_count),
                borderColor: 'rgb(198, 108, 108, 0.8)',
                backgroundColor: 'rgba(198, 108, 108, 0.5)',
                barPercentage: .2,
                maxBarThickness: 8,
                datalabels: {
                    opacity: 0
                },
            },
        ],
    } : {}

    return (

        <div style={{ position: 'relative', width: '100%', height: '100%' }}>

            <div style={{ minHeight: '500px' }}>
                {props.assetsStats !== undefined && <Bar options={options} data={data} />}
            </div>
        </div>
    );
}
export default DashboardWarehouse;