import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AssetService from "../../API/AssetService";
import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import HypervisorService from "../../API/HypervisorService";
import GeoService from "../../API/GeoService";
import DatePicker from "react-datepicker";
import parseISO from 'date-fns/parseISO';
import Preloader from "../UI/Preloader/Preloader";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function AssetChangeForm(props) {
    const [isPreloader, setIsPreloader] = useState(false);
    const refactDate = (date) => {
        if (date !== null) {
            const arr = date.split('.').reverse();

            return parseISO(arr.join('-') + 'T11:30:30')
        }
        else return null
    }
    // const [patchDate, setPatchDate] = useState(refactDate(props.asset.patched_date))
    const [assetDescription, setAssetDescription] = useState(props.asset.description);
    const [serialNumber, setSerialNumber] = useState(props.asset.serial_nr);
    const [city, setCity] = useState({ city_ru: props.asset.city });
    const [country, setCountry] = useState({ country_ru: props.asset.country });
    const [cityList, setCityList] = useState([])

    // eslint-disable-next-line no-unused-vars
    const [assetOrg, setAssetOrg] = useState(props.asset?.organisation_info[0].name);
    const [softVersion, setSoftVersion] = useState(props.asset.sw_version || null)
    const [addHypervisor, setAddHypervisor] = useState(false);
    const [hypervisor, setHypervisor] = useState(null);
    const [currentHypervisor, setCurrentHypervisor] = useState(props.asset.hyper_visor || null);
    registerLocale('ru', ru)
    const [errors, setErrors] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [assetPlatform, setAssetPlatform] = useState(props.asset.platform_name || null);
    // eslint-disable-next-line no-unused-vars
    const [assetPlatformType, setAssetPlatformType] = useState(props.asset.platform_type || null);

    const [countryFilter, setCountryFilter] = useState('Европа');
    const [countryList, setCountryList] = useState([]);
    const [cityFilter, setCityFilter] = useState(props.asset.country);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {

        (async () => {

            await HypervisorService.getList().then((res) => setHypervisor(res.results)).catch(() => changeStatus());



        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addHypervisor]);

    useEffect(() => {

        (async () => {

            await GeoService.getAllCountries(countryFilter).then((res) => setCountryList(res.results)).catch(() => changeStatus());;

        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryFilter]);

    useEffect(() => {

        (async () => {

            await GeoService.getAllCities(cityFilter).then((res) => setCityList(res.results)).catch(() => changeStatus());;

        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityFilter]);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                updateAsset();
            })
            .catch(() => changeStatus())
    }

    const updateAsset = async event => {
        if (event) event.preventDefault();
        setIsPreloader(true)
        const asset = {};
        asset.id = props.asset.id;
        asset.serial_nr = serialNumber;
        asset.country = country.country_ru;
        asset.description = assetDescription;
        asset.platform = assetPlatform;
        if (assetOrg !== props.asset?.organisation_info[0].name) asset.organisation = assetOrg;
        if (softVersion !== props.asset.sw_version) asset.sw_version = softVersion;
        // if (patchDate !== null) { asset.patched_date = patchDate.toISOString().split('T')[0] } else asset.patched_date = null;

        if (currentHypervisor !== null) {

            asset.hyper_visor = currentHypervisor.id
        }
        asset.city = city.city_ru;
        asset.lon = city.lon;
        asset.lat = city.lat;
        await AssetService.patchAsset(asset)
            .then(() => { setTimeout(() => { setIsPreloader(false); props.setIsAssetChanging(false) }, 1000) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })

    }
    return (
        <Form id="form" onSubmit={updateAsset} className="was-validated" >
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }

            {isPreloader && <Preloader />}
            <Row>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Серийный номер устройства</Form.Label>
                        <Form.Control size="sm" type="text" value={serialNumber.trim()} required={true} placeholder={'Введите серийный номер'}
                            onChange={(e) => setSerialNumber(e.target.value.trim())}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Организация</Form.Label>
                        <Form.Control size="sm" type="text" value={assetOrg} disabled

                        />

                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">

                        <Form.Label size="sm">Платформа</Form.Label>
                        <Form.Control size="sm" type="text" value={assetPlatform} disabled

                        />

                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={4} >
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Регион</Form.Label>
                        <Form.Select size="sm" id="geoReg" onChange={(e) => setCountryFilter(e.target.value)} >
                            <option value="Европа">Европа</option>
                            <option value="Азия">Азия</option>
                            <option value="Америка">Америка</option>
                            <option value="Африка">Африка</option>
                            <option value="Океания">Океания</option>

                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Страна</Form.Label>

                        <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-country" >
                                Страна: {country?.country_ru || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assCount'}
                                filterQa={true}
                            >
                                {countryList?.map(country =>
                                    <Dropdown.Item eventKey={"country" + country.id} key={"country" + country.id} onClick={() => { setCountry(country); setCityFilter(country.country_ru); setCity(null) }}>{country.country_ru}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Город</Form.Label>

                        <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-country" >
                                Город: {city?.city_ru || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assCity'}
                                filterQa={true}
                            >
                                {cityList?.map(city =>
                                    <Dropdown.Item eventKey={"city" + city.id} key={"city" + city.id} onClick={() => { setCity(city); }}>{city.city_ru}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Версия ПО (опционально)</Form.Label>
                        <Form.Control size="sm" type="text" value={softVersion || ""} placeholder={'Введите версию ПО'}
                            onChange={(e) => setSoftVersion(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>

            <Row>

                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Комментарий (опционально)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={assetDescription || ""}
                            size="sm"
                            onChange={(e) => setAssetDescription(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                {/* <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Дата патча</Form.Label>
                        <DatePicker
                            id="datePicker_assetChange"


                            dateFormat="dd.MM.yyyy"
                            selected={patchDate}
                            onChange={(date) => { if (date !== null) date.setHours(15, 0, 0, 0); setPatchDate(date) }}
                            locale="ru"
                        />
                    </Form.Group>
                </Col> */}
                {assetPlatformType === "VM" && <Col sm={4}>
                    <Form.Group className="mb-4" >
                        <Form.Label size="sm">Гипервизор</Form.Label>

                        <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }} >
                            <Dropdown.Toggle as={MyToggle} id="dropdown-orgs"  >
                                {currentHypervisor?.hv_type || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assTypeHyp'}
                                filterQa={true}
                                clickFunction={setAddHypervisor}
                            >
                                {hypervisor !== null && hypervisor?.map(visor =>
                                    <Dropdown.Item eventKey={"vis" + visor.id} key={"vis" + visor.id} onClick={() => { setCurrentHypervisor(visor) }}>{visor.hv_type}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>}
            </Row>

            <Row >
                <Col sm={1} style={{ width: 100 }}>
                    <Form.Group className="mb-4">
                        <Button variant="outline-danger" size="sm" onClick={() => {
                            props.setIsAssetChanging(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col >
                    <Form.Group className="mb-4">
                        <Button variant="outline-primary" size="sm" type="submit">
                            Сохранить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>

            <hr />

        </Form >
    );
}
export default AssetChangeForm;