/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef } from "react";
import Col from 'react-bootstrap/Col';
import Preloader from "../UI/Preloader/Preloader";
import User from "../../API/User";
import { AuthContext } from "../../context";
import UsersFullList from "./UsersFullList";
import Row from "react-bootstrap/esm/Row";
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import Button from "react-bootstrap/esm/Button";
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import { Alert } from "react-bootstrap";
import UserCopyForm from "./UserCopyForm";

function UsersArea() {

    const [nextPage, setNextPage] = useState(0);
    const [isPreloader, setIsPreloader] = useState(false);
    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [usersData, setUsersData] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [needToLoad, setNeedToLoad] = useState(false);
    const [errors, setErrors] = useState([]);
    const [cloneUser, setCloneUser] = useState(false);
    //фильтры
    const [activeFilter, setActiveFilter] = useState(false)
    const [searchText, setSearchText] = useState('');
    const [orgtypeFilter, setOrgtypeFilter] = useState(false);
    const [roleUserFilter, setRoleUserFilter] = useState(false);
    const [extAccessFilter, setExtAccessFilter] = useState(false);
    const [switchActive, setSwitchActive] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [successClone, setSuccessClone] = useState(false)
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const fetchData = async (add) => {
        let filters = false;
        if (searchText || activeFilter || orgtypeFilter || roleUserFilter || extAccessFilter) {
            filters = {
                search: searchText,
                is_active: activeFilter,
                organisation__type: orgtypeFilter,
                role: roleUserFilter,
                has_external_orgs_access: extAccessFilter
            };
        }
        setIsUsersLoading(true);

        await User.getList(filters, add ? nextPage : 1).then((res) => {
            if (add === 'add') { res.results?.map(item => setUsersData(arr => [...arr, item])); setNextPage(res.next ? nextPage + 1 : null); }
            else { setUsersData(res.results); setNextPage(res.next ? 2 : null); }
            setTotalCount(res.count);
            setIsUsersLoading(false);
        }).catch(() => changeStatus()).finally(() => setIsPreloader(false))

    }
    useEffect(() => {
        if (switchActive === false){
        setIsPreloader(true)
        const timerRefresh = setTimeout(fetchData, 500);
        return () => {

            clearTimeout(timerRefresh)
        }}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilter, orgtypeFilter, roleUserFilter, extAccessFilter, searchText, switchActive, successClone]);

    useEffect(() => {

        (async () => {

            if (needToLoad && nextPage) {

                await fetchData('add')
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    useEffect(() => {

        if (isUsersLoading) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage && usersData?.length>0) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

    }, [isUsersLoading]);


    const clearFilters = () => {
        setSearchText("");
        setActiveFilter(false);
        setExtAccessFilter(false);
        setOrgtypeFilter(false);
        setRoleUserFilter(false);
    }
    const switchActiveUser = async (item) => {

        setIsPreloader(true);
        let param = !item.is_active
        await User.switchActivate(item.id, param)
            .then(() => { setIsPreloader(false); setSwitchActive(false) })
            .catch((e) => {
  setIsPreloader(false); setErrors(e.message); }
            );
    }
    return (
        <Col>
         {switchActive && <MyConfirm visible={switchActive} setVisible={setSwitchActive} >
                <Row className="mb-4" style={{ position: "relative" }}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4">Вы точно хотите {currentItem.is_active === false ? 'активировать' : 'деактивировать'} {<br />}пользователя {currentItem.last_name}?</h3>
                </Row>
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); switchActiveUser(currentItem) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setSwitchActive(false); setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row>
            </MyConfirm>}
            {cloneUser && <MyConfirm visible={cloneUser} setVisible={setCloneUser} >
               <UserCopyForm user={currentItem} setCloneUser={setCloneUser} successClone={successClone} setSuccessClone={setSuccessClone} />
            </MyConfirm>}
            <Row className="mt-2 mb-4"><h3>Пользователи (отображено пользователей {totalCount})</h3></Row>
            <hr />
            {/* <b>Всего пользователей:</b> <span>{totalCount}</span> */}
            <Row style={{ position: 'relative' }}>
                {isPreloader && <Preloader />}
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Поиск</Form.Label>
                    <Form.Control onChange={(e) => { setSearchText(e.target.value) }}
                        placeholder="Начните вводить..."
                        size="sm"
                        style={{ fontSize: '1rem', height: 38 }}
                        value={searchText}

                    />
                </Col>

                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Статус</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-au" >
                            {activeFilter || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filter={setActiveFilter}
                            type='activeUser'
                        >
                            <Dropdown.Item eventKey={"au" + 1} key={"au" + 1} onClick={() => { setActiveFilter('Активен') }}>{'Активен'}</Dropdown.Item>
                            <Dropdown.Item eventKey={"au" + 2} key={"au" + 2} onClick={() => { setActiveFilter('Не активен') }}>{'Не активен'}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Тип организации</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-au" >
                            {orgtypeFilter || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filter={setOrgtypeFilter}
                            type='orgtype'
                        >
                            <Dropdown.Item eventKey={"ot" + 1} key={"ot" + 1} onClick={() => { setOrgtypeFilter('Partner') }}>{'Partner'}</Dropdown.Item>
                            <Dropdown.Item eventKey={"ot" + 2} key={"ot" + 2} onClick={() => { setOrgtypeFilter('Enduser') }}>{'Enduser'}</Dropdown.Item>
                            <Dropdown.Item eventKey={"ot" + 3} key={"ot" + 3} onClick={() => { setOrgtypeFilter('Vendor') }}>{'Vendor'}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Роль пользователя</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-ru" >
                            {roleUserFilter || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filter={setRoleUserFilter}
                            type='userrole'
                        >
                            <Dropdown.Item eventKey={"ru" + 1} key={"ru" + 1} onClick={() => { setRoleUserFilter('PRIMARY_USER') }}>{'PRIMARY_USER'}</Dropdown.Item>
                            <Dropdown.Item eventKey={"ru" + 2} key={"ru" + 2} onClick={() => { setRoleUserFilter('USER') }}>{'USER'}</Dropdown.Item>
                            <Dropdown.Item eventKey={"ru" + 3} key={"ru" + 3} onClick={() => { setRoleUserFilter('MANAGER') }}>{'MANAGER'}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-3" >
                    <Form.Label size="sm">Доступ к сторонним устройствам</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-ea" >
                            {extAccessFilter || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filter={setExtAccessFilter}
                            type='externalassets'
                        >
                            <Dropdown.Item eventKey={"ea" + 1} key={"ea" + 1} onClick={() => { setExtAccessFilter('Да') }}>{'Да'}</Dropdown.Item>
                            <Dropdown.Item eventKey={"ea" + 2} key={"ea" + 2} onClick={() => { setExtAccessFilter('Нет') }}>{'Нет'}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            <Row>
                {(searchText || orgtypeFilter || roleUserFilter || activeFilter || extAccessFilter) && <Col className="col-2">

                    <Button variant="outline-info" size="sm" style={{ width: "100%", height: '38px', margin: '10px 0' }} onClick={() => clearFilters()}>Сбросить</Button>
                </Col>}
            </Row>
            <UsersFullList userList={usersData} setCurrentItem={setCurrentItem} setSwitchActive={setSwitchActive} setCloneUser={setCloneUser}/>
            <div ref={lastItem} style={{ height: 20 }} />
        </Col>
    );
}
export default UsersArea;