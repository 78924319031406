import React from "react";
import Row from 'react-bootstrap/Row';
import Header from "../components/UI/Header";
import SideBar from "../components/UI/Sidebar";
import QuotaArea from "../components/Quotator/QuotaArea";

function QuotatorPage() {

  return (
    <div>
      <Header />
      <Row>
          <SideBar />
          <QuotaArea />
      </Row>
    </div>
  );
}

export default QuotatorPage;
