import React from "react";
import Table from 'react-bootstrap/Table';


function ThreatVaultCardCve({ data, getItemById }) {
  
    return (
        <>

            <Table bordered striped hover id='tableExcell' className="mb-5">

                <thead>
                    <tr>
                        <th >Signature</th>
                        <th >Severity</th>
                        <th >CVE</th>
                        <th >First Release</th>
                        <th >Last Update</th>
                    </tr>

                </thead>
                 <tbody>
{ data && data?.map((item, index)=>                <tr key={item.id+ index} >
                    <td style={{cursor: "pointer"}}onClick={(e)=> getItemById(item.id)}> <span style={{textDecoration: 'underline' }}>{item?.name? item?.name: 'Нет данных'}</span><br /><span style={{textDecoration: 'none'}}>{'Unique Threat ID: ' + item?.id}</span></td>
                    <td>{item?.severity}</td>
                    <td style={{width: '20%'}}>{item?.cve ?item?.cve.map((it, ind)=> <div key={it + ind}><span>{it}</span></div>): 'Нет данных'}</td>
                    <td style={{width: '15%'}}>{item?.ori_release_version? item?.ori_release_version + ' (' + item.ori_release_time + ')': 'Нет данных'}</td>
                    <td style={{width: '15%'}}>{item?.latest_release_version? item?.latest_release_version+' (' + item.latest_release_time + ')': 'Нет данных'}</td>
               </tr>)}
                </tbody> 

            </Table></>
    )
}

export default ThreatVaultCardCve;