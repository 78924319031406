import React from "react";
import Col from "react-bootstrap/esm/Col";
import { useNavigate } from "react-router-dom";
import './style.css';
import copyAsset from '../../icons/copy.svg';
import ImgRemove from '../../icons/remove.png';
import touchIcon from '../../icons/touch.svg';
import { organisationRating } from "../../utils/constants";

const AssetItem = (props) => {
    const navigate = useNavigate();

    const dateStyles = {
        "fontSize": 'x-small',

    }
    const linkStyle = {
        "cursor": 'pointer',
        "color": 'blue',
        'textDecoration': "underline",
    }

    const expStyle = {
        "color": 'red',
    }
    const getRmaFlag = (rma) => {
        if (rma === true) return <span style={{ color: "blue" }}>RMA</span>
    }

    return (

        <tr key={props.item.id + 'assetItem'}
            style={dateStyles}>
            <td>
                <img width="30" className="img__asset" src={touchIcon} alt="linkToAsset" onClick={() => navigate(`/asset/${props.item.id}`)
                } />
            </td>
            <td onClick={(e) => { e.stopPropagation(); navigate(`/orglist/${props.item.organisation_info[0].id}`) }} > {organisationRating(props.item.organisation_info[0].rating, props.item.organisation)} 

            </td>
            <td>{props.item.platform_name}</td>
            <td>{props.item.serial_nr}</td>
            <td>{props.item.os_version}</td>
            <td>{props.item.description}</td>
            <td>{props.item.city}</td>
            <td>{props.item.country}</td>
            <td> {props.item.subscriptions_info?.active.length > 0 && props.item.subscriptions_info?.active.map((item, index) => <Col key={item.id + index}>{item.slug} {item.time_left_verbose}</Col>)}
            </td>
            <td style={props.item.subscriptions_info?.expires_next?.status !== "ACTIVE" ? expStyle : { color: "black" }}>{props.item.subscriptions_info?.expires_next?.date_end}</td>
            <td>{props.item.service.length > 0 && props.item.service[0].status === "ACTIVE" ? (<span >{props.item.service[0].slug}-{props.item.service[0].duration} </span>) : (<span style={{ color: "red" }}>Нет сервиса</span>)}

                {props.item.service.length > 0 && props.item.service[0].status === "ACTIVE" ? getRmaFlag(props.item.service[0].rma) : ''}

            </td>
            <td>  <img width="30" src={copyAsset} alt="delete" onClick={
                (e) => { e.stopPropagation(); props.setIsCopyAsset(true); props.setCurrentAsset(props.item) }
            } /></td>
            <td>
                <img width="30" src={ImgRemove} alt="copy" onClick={
                    (e) => { e.stopPropagation(); props.removeAsset(true); props.setCurrentAsset(props.item) }
                } />
            </td>
        </tr>
    );
}

export default AssetItem;