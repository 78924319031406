import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import "./styles/App.css"
import AppRouter from "./components/AppRouter"
import Container from 'react-bootstrap/Container';
import { AuthContext, UserContext } from './context';
import User from "./API/User";
import WindowFocusHandler from "./utils/WindowActivity";


function App() {
  const [isAuth, setIsAuth] = useState(true);
  const [status, setStatus] = useState(false);
  const [userinfo, setUserinfo] = useState(null);

  useEffect(() => {
    const token =localStorage.getItem('token')
    if (token!==null) {
      User.getMe().then((res) => {
        
       
          setUserinfo(res)
        
      }).catch(()=> {setStatus(true)})
    }
  }, [isAuth, status]);

  const logout = () => {
    setIsAuth(false);
    setUserinfo({});
    localStorage.removeItem('auth');
    localStorage.removeItem('tokenReview');
    localStorage.removeItem('token');
    localStorage.removeItem('freshToken');
  }
  

  return (
    <AuthContext.Provider value={{
      isAuth,
      setIsAuth,
      logout,
      status, setStatus
    }}>
      <UserContext.Provider value={{
        userinfo,
        setUserinfo,
      }}>
        <WindowFocusHandler/>
        <Container fluid>
          <AppRouter isAuth={isAuth} />
        </Container>
      </UserContext.Provider>
    </AuthContext.Provider>

  );
}

export default App;