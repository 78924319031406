export const changeDate=(date)=> {
    if (date) {
    let arr = date.split('T')
let index = arr[1].indexOf('.');
let timeStr =''
if (index===-1) timeStr = arr[1]
else timeStr = arr[1].slice(0, index)

    let str = arr[0].split('-').reverse().join('.') +' ' +  timeStr

    return str
    } else return ''
}