import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OrgService from "../../API/OrgService";
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import EdlService from "../../API/EdlService";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";
import MyModal from "../UI/MyModal/MyModal";
import ApiGatewayService from "../../API/ApiGatewayService";

function AddEdlForm(props) {

    const [valid, setVaild] = useState(false);
    const [itemOrg, setItemOrg] = useState(null);
    const [expiresDate, setExpiresDate] = useState(null);
    const [isStatus, setIsStatus] = useState('true')
    const [allOrganisations, setAllOrganisations] = useState(null);
    const [errors, setErrors] = useState([]);
    const [errorsSlug, setErrorsSlug] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [patchOrgSlugModal, setPatchOrgSlugModal] = useState(false);
    const [subs, setSubs] = useState([]);
    const [itemSub, setItemSub] = useState({name: 'trial', id: 0})
    const [slug, setSlug] = useState('');
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const [tokenList, setTokenList] = useState(null);
    const [token, setToken] = useState(null);
    const [vendorError, setVendorError] = useState(null)

    useEffect(() => {

        (async () => {

            // await OrgService.getDropList().then((res) => setAllOrganisations(res)).catch(() => changeStatus());
            await OrgService.getDropList(false, false, false).then((res) => setAllOrganisations(res)).catch(() => changeStatus());
            await EdlService.getSubsListDropdown().then((res) => { setSubs(res) })
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
           
            if (itemOrg) {
                setIsPreloader(true)
                if (itemOrg?.type !== 'VENDOR') {
                    let filters = {}
                    filters.organisation = itemOrg?.id;
                    filters.type = 'unlimited-without-updating';
                    filters.is_active = true
                    await ApiGatewayService.getTokenList(filters).then((res) => setTokenList(res)).catch(() => changeStatus()).finally(()=> setIsPreloader(false));

                }
                else {
                    setIsPreloader(false)
                    setVendorError('Для управление сервисом может быть только один доступ типа VENDOR')
                    // let filters = {}
                    // // filters.type='unlimited-without-updating';
                    // filters.organisation = itemOrg.id;
                    // filters.is_active = true
                    // await ApiGatewayService.getServiceTokenList(filters).then((res) => setTokenList(res)).catch(() => changeStatus());
                }

            }
        })()
    }, [itemOrg])

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') saveInstance();
                else patchOrganisation();
            })
            .catch(() => changeStatus())
    }
    const patchOrganisation = async () => {
        setIsPreloader(true)
        await OrgService.patchOrg(false, itemOrg.id, false, slug).then((res) => {
            setIsPreloader(false); setPatchOrgSlugModal(false); itemOrg.slug = slug; saveInstance(); setErrorsSlug([])
        }).catch((err) => {
            if (err.message[0].includes('token')) refreshToken('patch');
            else { setErrorsSlug(err.message); setIsPreloader(false); }
        })

    }
    const saveInstance = async event => {

        if (event) event.preventDefault();
        const instance = {};
        instance.organisation = itemOrg?.id;
        if (expiresDate) instance.date_end = expiresDate;
        instance.is_active = isStatus;
        if (itemSub.name !=='trial') instance.subscription = itemSub?.id
        instance.gw_access_token = token?.access_token || null;
        if (itemOrg.slug !== null) {
            setIsPreloader(true);
            await EdlService.newInstance(instance)
                .then(() => { props.setIsAddingInstance(false); setIsPreloader(false) })
                .catch((err) => {
                    if (err.message[0].includes('token')) refreshToken('save');
                    else {
                        setErrors(err.message); setIsPreloader(false);
                    }
                })
        }
        else { setPatchOrgSlugModal(true); }
    }

    return (
        <Form id="form" onSubmit={saveInstance} className="was-validated" >
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key={"danger" + error} variant="danger">
                        {error}
                    </Alert>
                )
            }
            {vendorError &&

                <Alert key={"warning"} variant="warning">
                    {vendorError}
                </Alert>

            }
            {isPreloader && <Preloader />}

            {patchOrgSlugModal && <MyModal visible={patchOrgSlugModal} setVisible={setPatchOrgSlugModal} maxWidth={true}>

                <h3 className="mb-3 mt-3">{itemOrg.name}</h3>

                {errorsSlug.length > 0 &&
                    errorsSlug.map((error, index) =>

                        <Alert key={'danger' + index} variant="danger" >
                            {error}
                        </Alert>
                    )
                }
                <Col className="col-10">
                    <Form.Group>
                        <Form.Label size="sm">Введите SLUG </Form.Label>
                        <Form.Control size="sm" type="text" value={slug} required={true}
                            onChange={(e) => setSlug(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Row className="mt-3">
                    <Col className="mb-3 col-5" >
                        <Form.Group className="mb-4"  >
                            <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                                setPatchOrgSlugModal(false)

                            }}>
                                Отменить
                            </Button>
                        </Form.Group>
                    </Col>
                    <Col className="mb-3 col-5" >
                        <Form.Group className="mb-4">
                            <Button style={{ width: '100%' }} variant="outline-primary" size="sm" onClick={() => patchOrganisation()}>
                               Сохранить
                            </Button><br />
                        </Form.Group>
                    </Col>
                </Row>
            </MyModal>}

            <Row>

                <Col className="mb-4 col-2">
                    <Form.Group >
                        <Form.Label size="sm">Организация</Form.Label>

                        <Dropdown style={!valid ? { border: "1px solid red", height: 31, borderRadius: 6, paddingLeft: '.5rem' } : { border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-orgs" >
                                {itemOrg?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assOrg'}
                            // filterQa={true}
                            >
                                {allOrganisations?.map(org =>
                                    <Dropdown.Item eventKey={"e" + org.id} key={"e" + org.id} onClick={() => { setItemOrg(org); setVaild(true); setVendorError(null) }}>{org.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2">
                    <Form.Group >
                        <Form.Label size="sm">Статус</Form.Label>
                        <Form.Select size="sm" id="isActive" onChange={(e) => setIsStatus(e.target.value)} disabled={itemOrg?.type === 'VENDOR' ? true : false}>
                            <option value={true}>Активен</option>
                            <option value={false}>Не активен</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

                {itemOrg?.type !== 'VENDOR' && <Col className="mb-4 col-2">
                    <Form.Group >
                        <Form.Label size="sm">Подписки</Form.Label>

                        <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-subs" >
                                {itemSub?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'subsEdl'}
                            // filterQa={true}
                            >
                                {subs?.map(sub =>
                                    <Dropdown.Item eventKey={"es" + sub.id} key={"es" + sub.id} onClick={() => { setItemSub(sub);setVaild(true) }}>{sub.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>}
               {itemSub?.name!=='trial' &&  <Col className="mb-3 col-2">
                    <Form.Group  >
                        <Form.Label >Дата окончания</Form.Label>
                        <Form.Control
                            disabled={itemOrg?.type === 'VENDOR' ? true : false}
                            value={expiresDate || ""}
                            onChange={(e) => setExpiresDate(e.target.value)}
                            size="sm" type="date"
                            required={true} />

                    </Form.Group>
                </Col>}
                {itemOrg && itemOrg?.type !== 'VENDOR' && <Col className="mb-4 col-2">
                    <Form.Group >
                        <Form.Label size="sm">Токен API GW</Form.Label>

                        <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-tokens" >
                                {token?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'tokenEdl'}
                            // filterQa={true}
                            >
                                <Dropdown.Item eventKey={"tok"} key={"tok"} onClick={() => { setToken(null); }}>Без токена</Dropdown.Item>
                                {tokenList?.map(token =>
                                    <Dropdown.Item eventKey={"tok" + token.subject} key={"es" + token.subject} onClick={() => { setToken(token); }}>{token.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>}
            </Row>
            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            props.setIsAddingInstance(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit" disabled={itemOrg?.type === 'VENDOR'? true: false}>
                            Добавить доступ
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>


        </Form >
    );
}
export default AddEdlForm;
