import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import DashboardService from "../../API/DashboardService";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Colors } from 'chart.js';

function DashboardCloudDoug() {

    const [cloudsStats, setCloudsStats] = useState(null);

    ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels, Colors);
    useEffect(() => {
        (async () => {
            await DashboardService.getCloudsStats().then((res) => setCloudsStats(res))

        })()
    }, [])

    const data = {
        labels: cloudsStats !== null ? cloudsStats.map((item, index) => { return item.slug }) : '',
        datasets: [
            {
                datalabels: {
                    color: 'black',
                    opacity: 0
                },
         
                data: cloudsStats !== null && cloudsStats.map((item, index) => { return item.equipment_cnt }),
                borderWidth: 1,
            },
        ],
    };
    const options = {
        plugins: {
            colors: {
                enabled: true,
                forceOverride: true
            },
            datalabels: {
                color: '#36A2EB'
            },
            legend: {
                position: 'top',
                labels: { boxWidth: 20 }

            },

            tooltip: {
                enabled: true
            },

        }
    }

    return (

        <div style={{ position: 'relative', width: '100%', height: '100%' }}>

            <Doughnut data={data} options={options} />
        </div>
    );
}
export default DashboardCloudDoug;