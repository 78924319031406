import React, { useState, useEffect, useRef, useContext } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import QuotaList from "./QuotaList";
import QuotaService from "../../API/QuotaService";
import OrgService from "../../API/OrgService";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import AddQuotaForm from "./AddQuotaForm";
import PlatformService from "../../API/PlatformService";
import OrgCreateForm from "../Organizations/OrgCreateForm";
import QuotaDetail from "./QuotaDetail";
import PriceService from "../../API/PriceService";
import EditQuotaForm from "./EditQuotaForm";
import { useLocation } from 'react-router-dom';
import Preloader from "../UI/Preloader/Preloader";
import Dropdown from 'react-bootstrap/Dropdown';
import MyMenu from "../UI/MyDropdown/MyMenu";
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import { AuthContext, UserContext } from "../../context";
import User from "../../API/User";
import ExportQuotaForm from "./ExportQuotaForm";
import MyConfirm from "../UI/MyConfirm/MyConfirm";

function QuotaArea(props) {

    const location = useLocation();
    const [isPreloader, setIsPreloader] = useState(false);
    const [viewOrg, setViewOrg] = useState(false);
    // перечень всех квот
    const [quotaitems, setQuotaitems] = useState();
    const [totalquotaitems, setTotalQuotaitems] = useState();
    const [syncQuota, setSyncQuota] = useState(false)

    //пагинация
    const lastItem = useRef();
    const [isQuotasLoading, setIsQuotasLoading] = useState(false);
    const [nextPage, setNextPage] = useState();
    const [needToLoad, setNeedToLoad] = useState(false);
    const observer = useRef();

    // просмотр квоты
    const [viewQuota, setViewQuota] = useState(false);
    const [quotaDetails, setQuotaDetails] = useState();
    const [quotaDetailsItems, setQuotaDetailsItems] = useState();

    // редактирование квоты
    const [isQuotaEdit, setIsQuotaEdit] = useState(false);
    const [quotaToEditID, setQuotaToEditID] = useState();

    // добавление квоты
    const [isAddingQuota, setIsAddingQuota] = useState(false);
    const [quotaAdded, setQuotaAdded] = useState(false);
    const [quotaSold, setQuotaSold] = useState(false);
    const [platforms, setPlatforms] = useState();
    const [categories, setCategories] = useState();
    // eslint-disable-next-line no-unused-vars
    const [currentPlatform, setCurrentPlatform] = useState();
    const [currentCategory, setCurrentCategory] = useState();
    const [isCloneQuota, setIsCloneQuota] = useState(false);

    // добавление организации
    const [isOrgAdding, setIsOrgAdding] = useState(false);
    const [orgAdded, setOrgAdded] = useState(false);
    const [hideOrgMenu, setHideOrgMenu] = useState(false);
    const [orgAddingType, setOrgAddingType] = useState("");
    const [users, setUsers] = useState([])

    // перечень параметров для селектов
    const [organizations, setOrganizations] = useState();
    const [partners, setPartners] = useState();
    const quotaStatuses = [
        { value: "ACTIVE", name: "Активна" },
        { value: "SOLD", name: "Продано" },
        { value: "EXPIRED", name: "Просрочена" }
    ];
    // eslint-disable-next-line no-unused-vars
    const [curQuotaStatusFilter, setCurQuotaStatusFilter] = useState("");
    const [curPartnerFilter, setCurPartnerFilter] = useState("");
    const [curEndUserFilter, setCurEndUserFilter] = useState("");
    const [exportQuota, setExportQuota] = useState(false)

    // фильтрация элементов
    const [searchText, setSearchText] = useState("");
    const [endUserFilter, setEndUserFilter] = useState(false);
    const [partnerFilter, setPartnerFilter] = useState(false);
    const [statusFilter, setStatusFilter] = useState(false);
    const [isMainFilter, setIsMainFilter] = useState(false);
    const [isSortId, setIsSortId] = useState(false)
    const [isSortSumm, setIsSortSumm] = useState(false);
    const [isSortDate, setIsSortDate] = useState(false);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const {userinfo} = useContext(UserContext);

    function clearFilters() {
        setEndUserFilter(false);
        setPartnerFilter(false);
        setStatusFilter(false);
        setCurQuotaStatusFilter("");
        setCurPartnerFilter("");
        setCurEndUserFilter("");
        setSearchText("");
        setIsMainFilter(false);

    }

    useEffect(() => {

        (async () => {

            let filters = false;
            const id = props.orgId ? props.orgId : false;

            if (endUserFilter || partnerFilter || statusFilter || searchText || id !== false || isMainFilter || isSortDate || isSortId || isSortSumm) {
                filters = {
                    partnerFilterAll: id,
                    endUserFilter: endUserFilter,
                    partnerFilter: partnerFilter,
                    statusFilter: statusFilter.value,
                    searchText: searchText,
                    isMainFilter: isMainFilter,
                    isSortDate: isSortDate,
                    isSortSumm: isSortSumm,
                    isSortId: isSortId,
                };
            };

            setIsPreloader(true)
            setIsQuotasLoading(true);
            await QuotaService.getList(filters, false).then((res) => {
                setQuotaitems(res.results);
                setTotalQuotaitems(res.count);
                setNextPage(res.next);
                setQuotaAdded(false);
                setQuotaSold(false)
                setIsQuotasLoading(false);
            }).catch(() => changeStatus()).finally(() => setIsPreloader(false))

        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endUserFilter, partnerFilter, statusFilter, searchText, quotaAdded, quotaSold, viewOrg, viewQuota, isMainFilter, isSortDate, isSortId, isSortSumm, isCloneQuota]);


    useEffect(() => {

        (async () => {
             await PlatformService.getDropList().then((res) => {
                 setPlatforms(res);
                 setCurrentPlatform(res[0].name)
             }).catch(() => changeStatus());

         })();

         (async () => {
             await PriceService.getCategoryDropList(1).then((res) => {
                 setCategories(res);
                 setCurrentCategory(res[0]?.name);
             });

         })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        (async () => {
            setIsPreloader(true);
            let filters = { 'type': 'PARTNER' };
            await OrgService.getDropList().then((res) => setOrganizations(res));

            await OrgService.getDropList(filters).then((res) => setPartners(res)).finally(() => setIsPreloader(false));
            await User.getDropList().then((res)=> setUsers(res))
            if (orgAdded) {
                // setHideOrgMenu(true);
            };
            setOrgAdded(false);
        })();

    }, [orgAdded]);

    useEffect(() => {

        (async () => {
            if (viewQuota) {
                setIsPreloader(true);
                await QuotaService.getItem(viewQuota).then((res) => {
                    setQuotaDetails(res);

                    if (res.items) {
                        setQuotaDetailsItems(res.items);
                    }
                }).finally(() => setIsPreloader(false));

            };
        })();

    }, [viewQuota, syncQuota]);

    useEffect(() => {

        (async () => {
            if (needToLoad) {
                setIsPreloader(true);
                setIsQuotasLoading(true);
                await QuotaService.getList(false, nextPage).then((res) => {
                    res.results?.map(item => setQuotaitems(arr => [...arr, item]));
                    setNeedToLoad(false);
                    setIsQuotasLoading(false);
                    setNextPage(res.next);
                }).finally(() => setIsPreloader(false));

            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad, viewQuota]);

    useEffect(() => {
        if (isQuotasLoading) return;
        if (isAddingQuota) return;
        if (viewQuota) return;
        if (isQuotaEdit) return;
        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isQuotasLoading, isAddingQuota, viewQuota, isQuotaEdit]);
    return (
        <>
         {exportQuota && <MyConfirm visible={exportQuota} setVisible={setExportQuota} >
               <ExportQuotaForm close={()=> setExportQuota(false)} />
            </MyConfirm>}
            {viewOrg === false && <Col>
                {location.pathname === '/quotator' ? !isAddingQuota
                    ? <div><h3>Квотатор (отображено {totalquotaitems} квот)</h3></div>
                    : <div><h3>Создание новой квоты</h3></div>
                    : ''
                }
                {
                    isOrgAdding
                    &&
                    <OrgCreateForm
                        setIsOrgAdding={setIsOrgAdding}
                        orgAddingType={orgAddingType}
                        setOrgAdded={setOrgAdded}
                        setCurEndUserFilter={setCurEndUserFilter}
                        setEndUserFilter={setEndUserFilter}
                        setCurPartnerFilter={setCurPartnerFilter}
                        setPartnerFilter={setPartnerFilter}
                    />
                }
                {location.pathname === '/quotator' && !props.orgId ? !isAddingQuota
                    && !isQuotaEdit && !viewQuota &&
                    <Row className="mt-4">
                        <Col className="col-2">
                            <Button variant="outline-success" style={{ width: "100%" }} size="sm" onClick={() => { setIsAddingQuota(true) }}>Новая квота</Button>
                        </Col >
                        {  userinfo.role!=='MANAGER' &&   <Col className="col-2">
                            <Button variant="outline-success" style={{ width: "100%" }} size="sm" onClick={() => { setExportQuota(true) }}>Экспорт в xslx</Button>
                        </Col >}
                    </Row>
                    : ''
                }
                {location.pathname === '/quotator' ? !isAddingQuota && !isQuotaEdit && !viewQuota &&
                    <>
                        <hr />
                       
                        <Row className="mb-2">

                            <Col className="mb-3 col-2 ">
                                <Form.Label size="sm">Партнер</Form.Label>
                                <Dropdown style={{ width: "100%" }}>
                                    <Dropdown.Toggle as={MyToggleAsset} id="dropdown-qPartners">
                                        {partnerFilter || "выбрать"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        as={MyMenu}
                                        filter={setPartnerFilter}
                                        type='qPartner'
                                    >
                                        {partners?.map(org =>
                                            <Dropdown.Item eventKey={"pqPartOrg" + org.id} key={"pqPartOrg" + org.id} onClick={() => { setPartnerFilter(org.name) }}>{org.name}</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>

                            </Col>
                            <Col className="mb-3 col-2 ">
                                <Form.Label size="sm">Конечный заказчик</Form.Label>
                                <Dropdown style={{ width: "100%" }}>
                                    <Dropdown.Toggle as={MyToggleAsset} id="dropdown-qEnduser">
                                        {endUserFilter || "выбрать"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        as={MyMenu}
                                        filter={setEndUserFilter}
                                        type='qEnd'
                                    >
                                        {organizations?.map(org =>
                                            <Dropdown.Item eventKey={"pq" + org.id} key={"pqendOrg" + org.id} onClick={() => { setEndUserFilter(org.name) }}>{org.name}</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>

                            </Col>
                            <Col className="mb-3 col-2 ">
                                <Form.Label size="sm">Статус</Form.Label>
                                <Dropdown style={{ width: "100%" }}>
                                    <Dropdown.Toggle as={MyToggleAsset} id="dropdown-qStatus">
                                        {statusFilter.name || "выбрать"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        as={MyMenu}
                                        filter={setStatusFilter}
                                        type='qSt'
                                    >
                                        {quotaStatuses?.map((status) =>
                                            <Dropdown.Item eventKey={"qstatus" + status} key={"qs" + status.name} onClick={() => { setStatusFilter(status) }}>{status.name}</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>

                            </Col>
                            <Col className="mb-3 col-2" >
                                <Form.Label size="sm">Main</Form.Label>
                                <Dropdown >
                                    <Dropdown.Toggle as={MyToggleAsset} id="dropdown-newWH" >
                                        {isMainFilter || "выбрать"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        as={MyMenu}
                                        filter={setIsMainFilter}
                                        type='isMainFilter'
                                    >
                                        <Dropdown.Item eventKey={"mf" + 1} key={"mf" + 1} onClick={() => { setIsMainFilter('Да') }}>{'Да'}</Dropdown.Item>
                                        <Dropdown.Item eventKey={"mf" + 2} key={"mf" + 2} onClick={() => { setIsMainFilter('Нет') }}>{'Нет'}</Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col className="mb-3 col-2" >
                                <Form.Label size="sm">Поиск</Form.Label>
                                <Form.Control onChange={(e) => {setSearchText(e.target.value)}}
                                    placeholder="Начните вводить..."
                                    size="sm"
                                    style={{ fontSize: '1rem', height: 38 }}
                                    value={searchText}
                                    // disabled={isQuotasLoading}
                                />
                            </Col>
                            {(endUserFilter || partnerFilter || statusFilter || searchText || isMainFilter) &&

                                <Col className="col-2">

                                    <Button variant="outline-info" size="sm" style={{ width: "100%", height: '38px', marginTop: 29 }} onClick={() => clearFilters()}>Сбросить</Button>
                                </Col>

                            }
                            <hr />

                        </Row></>
                    : ""
                }
                <Row>
                    <Col style={{ position: "relative" }}> 
                        {
                            isAddingQuota
                                ?
                                <AddQuotaForm
                                    orgAdded={orgAdded}
                                    users={users}
                                    partnerorganizations={partners}
                                    setPartnerFilter={setPartnerFilter}
                                    partnerValue={curPartnerFilter}
                                    setCurPartnerFilter={setCurPartnerFilter}
                                    organizations={organizations}
                                    setEndUserFilter={setEndUserFilter}
                                    enduserValue={curEndUserFilter}
                                    setCurEndUserFilter={setCurEndUserFilter}
                                    platforms={platforms}
                                    // setPlatforms={setPlatforms}
                                    setIsAddingQuota={setIsAddingQuota}
                                    clearFilters={clearFilters}
                                    setIsOrgAdding={setIsOrgAdding}
                                    isOrgAdding={isOrgAdding}
                                    setOrgAddingType={setOrgAddingType}
                                    categories={categories}
                                    currentCategory={currentCategory}
                                    hideOrgMenu={hideOrgMenu}
                                    setHideOrgMenu={setHideOrgMenu}
                                />
                                :
                                viewQuota
                                    ?
                                    <QuotaDetail
                                        setViewQuota={setViewQuota}
                                        quotaDetails={quotaDetails}
                                        setQuotaDetails={setQuotaDetails}
                                        quotaDetailsItems={quotaDetailsItems}
                                        setQuotaDetailsItems={setQuotaDetailsItems}
                                        setIsQuotaEdit={setIsQuotaEdit}
                                        setQuotaToEditID={setQuotaToEditID}
                                        setQuotaSold={setQuotaSold}
                                        syncQuota={syncQuota}
                                        setSyncQuota={setSyncQuota}
                                    />
                                    :
                                    isQuotaEdit
                                        ?
                                        <EditQuotaForm
                                        users={users}
                                            quotaId={quotaToEditID}
                                            setIsQuotaEdit={setIsQuotaEdit}
                                            setQuotaToEditID={setQuotaToEditID}
                                            setQuotaAdded={setQuotaAdded}
                                            platforms={platforms}
                                            clearFilters={clearFilters}
                                            categories={categories}
                                            currentCategory={currentCategory}
                                            partnerorganizations={partners}
                                        />
                                        :
                                        <QuotaList setViewOrg={setViewOrg} viewOrg={viewOrg} setIsSortId={setIsSortId} isSortId={isSortId}
                                            quotaitems={quotaitems} setViewQuota={setViewQuota} lastItem={lastItem}
                                            setIsSortSumm={setIsSortSumm} isSortSumm={isSortSumm} isCloneQuota={isCloneQuota} setIsCloneQuota={setIsCloneQuota}
                                            setIsSortDate={setIsSortDate} isSortDate={isSortDate}
                                            partnerorganizations={partners}
                                        />
                        }
                        {isPreloader && <Preloader />}
                    </Col>
                </Row>

            </Col>}
        </>
    );
}
export default QuotaArea;