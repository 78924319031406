import Api from '.';

export default class User {

    static async getList(filters, nextpage) {
        var url = 'api/v1/users/'
        const method = 'GET';
        const params = {};

        if (filters) {

            if (filters.search) {
                params.search = filters.search;
            };
            if (filters.is_active) {
                params.is_active = filters.is_active === 'Активен' ? 'true' : 'false';
            }
            if (filters.role) {
                params.role = filters.role;
            }
            if (filters.organisation__type) {
                params.organisation__type = filters.organisation__type.toUpperCase();
            }
            if (filters.has_external_orgs_access) {
                params.has_external_orgs_access = filters.has_external_orgs_access === 'Да' ? 'true' : 'false';
            }
        };

        if (nextpage) {
            params.page = nextpage
        }
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async getDropList() {
        var url = 'api/v1/users/dropdown/'
        const method = 'GET';
        
        const api = new Api({ url: url});
        const response = await api.request(method);
        return response.data;
    };
    static async getMe() {
        const url = 'api/v1/users/me/';
        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        const userdata = response.data;
        const userinfo = {
            'username': userdata.username,
            'first_name': userdata.first_name,
            'last_name': userdata.last_name,
            'job_title': userdata.job_title,
            'phone': userdata.phone,
            'email': userdata.email,
            'organization': userdata.organisation.name,
            'organizationType': userdata.organisation.type,
            'isActive': userdata.is_active,
            'role': userdata.role,
        };
        return userinfo;
    };

    static async deactivate(id) {
        const url = `api/v1/users/${id}/`;
        const method = 'PATCH';
        const data = { "is_active": false };

        const api = new Api({ url: url });
        const response = await api.request(method, data);

        return response.data;
    };

    static async activate(id) {
        const url = `api/v1/users/${id}/`;
        const method = 'PATCH';
        const data = { "is_active": true };

        const api = new Api({ url: url });
        const response = await api.request(method, data);

        return response.data;
    };

    static async switchActivate(id, active) {
        var url = `api/v1/users/${id}/`;
        const method = 'PATCH';
        const data = {};
        data.is_active = active
        const api = new Api({ url: url});
        const response = await api.request(method, data);
        return response.data;
    };

    static async makePrimary(id) {
        const url = `api/v1/users/${id}/`;
        const method = 'PATCH';
        const data = { "role": "PRIMARY_USER" };

        const api = new Api({ url: url });
        const response = await api.request(method, data);

        return response.data;
    };

    static async new(user, orgId) {
        var url = 'api/v1/users/';
        if (orgId === "me") {
            url = 'api/v1/users/colleague_create/';
        };
        const method = 'POST';
        const data = user;
        data.is_active = false;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    }

    static async updateMe(data) {
        const url = 'api/v1/users/me/';
        const method = 'PATCH';
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response;
    };
    static async getUser(id) {
        const url = `api/v1/users/${id}/`;
        const method = 'GET';
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };
    static async patchUser(data, id) {
        const url = `api/v1/users/${id}/`;
        const method = 'PATCH';
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response;
    };
    static async deleteUser(id) {
        const url = `api/v1/users/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };
    static async cloneUser(id, user) {
        var url = `api/v1/users/${id}/duplicate/`;
        const method = 'POST';
        const data = user;
        // data.is_active = false;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    }

}