/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AudienceList from "./AudienceList";
import TokenList from "./TokenList";
import ScopeList from "./ScopeList";
import ServiceTokenList from "./ServiceTokenList";

function ApiGatewayArea() {

    const [key, setKey] = useState('token');

    return (
        <>
            <Col>
                <h3 className="mb-3">API URL GATEWAY</h3>
                <Tabs
                    id="urlGW-tabs"
                    activeKey={key}
                    onSelect={(key) => setKey(key)}
                    className="mb-3"
                >
                    <Tab eventKey="token" title="Токены" >
                        <Row>
                        <Col style={{ position: "relative" }}>
                        <TokenList /></Col></Row></Tab>
                    <Tab eventKey="audience" title="Сервисы" >
                        {key === 'audience' && <AudienceList/>}
                    </Tab>
                    <Tab eventKey="scope" title="Ресурсы и доступы" >
                        {key === 'scope' && <ScopeList />}

                    </Tab>
                    <Tab eventKey="serviceToken" title="Сервис токены" >
                        {key === 'serviceToken' && <ServiceTokenList />}
                    </Tab>
                </Tabs>
            </Col>
        </>
    );
}
export default ApiGatewayArea;