import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate, useParams } from 'react-router-dom';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import ContractService from "../../API/ContractService";
import ContractChangeForm from "./ContractChangeForm";
import { AuthContext, UserContext } from "../../context";
import Alert from 'react-bootstrap/Alert';
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";
import MyConfirm from "../UI/MyConfirm/MyConfirm";
const ContractDetail = (props) => {
    const navigate = useNavigate();
    const { idCont } = useParams();
    const [contractDetails, setContractDetails] = useState();
    const [isPreloader, setIsPreloader] = useState(false);
    const [errors, setErrors] = useState([]);
    const [deletingContract, setDeletingContract] = useState(false)
    const [isContractChanging, setIsContractChanging] = useState(false);
    const {userinfo} = useContext(UserContext);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {

        (async () => {
            await ContractService.getItem(idCont).then((res) => setContractDetails(res)).catch(() => changeStatus());

        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idCont, isContractChanging]);

    const paymentMethodRu = (method) => {
        let methodRu = null;
        // eslint-disable-next-line default-case
        switch (method) {
            case 'monthly':
                methodRu = 'по-месячно';
                break;

            case 'quarterly':
                methodRu = 'по-квартально';
                break;
            case 'prepaid':
                methodRu = 'оплата 100%';
                break;
        }
        return methodRu;
    }
    const getStatusRu = (status, date) => {
        let statusRu = null;
        // eslint-disable-next-line default-case
        switch (status) {
            case 'paid':
                statusRu = 'оплачено';
                break;

            case 'pay_before':
                statusRu = <span style={{ color: 'red' }}>{`оплата до ${date}`}</span>;
                break;
            case 'patch_needed':
                statusRu = <span style={{ color: 'red' }}>нужен патч</span>;
                break;
            case 'keys_needed':
                statusRu = <span style={{ color: 'red' }}>нужны ключи</span>;
                break;
            case 'all_done':
                statusRu = 'все сделано';
                break;
            case 'awaits_delivery':
                statusRu = <span style={{ color: 'red' }}>ожидание поставки</span>;
                break;
        }
        return statusRu;
    }

    const refreshToken = async (id) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                removeContract(id);
            })
            .catch(() => changeStatus())
    }

    const removeContract = async (contractId) => {
        setIsPreloader(true)
        await ContractService.deleteContract(contractId).then(() => { setDeletingContract(false); setErrors([]); setIsPreloader(false);navigate(-1) })

            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken(contractId);
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })

    }
    return (
        <Col className="col-9">

            {deletingContract &&
                <MyConfirm visible={deletingContract} setVisible={setDeletingContract}>
                    
                    <Row style={{position: "relative"}}>
                    {isPreloader&&<Preloader/>}
                        <h5 className="mb-3">Удалить договор?</h5>
                    </Row>
                    <Row> {errors.length > 0 &&
                        errors.map(error =>
                            <Alert key="danger" variant="danger">
                                {error}
                            </Alert>
                        )
                    }</Row>
                    <Row>
                        <Col >
                            <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); removeContract(idCont) }}>
                                Да
                            </Button>

                        </Col>
                        <Col >
                            <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeletingContract(false); setErrors([]) }} style={{ width: '100%' }}>
                                нет
                            </Button>
                        </Col>
                    </Row>

                </MyConfirm>}
            
            <Row>
                <Col sm={2} colSpan={1}>
                    <Form.Group className="mb-4">
                        <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => { navigate(-1) }}
                        >Вернуться</Button>

                    </Form.Group>
                </Col>
            </Row>

            {contractDetails &&
                <>
              
                    <Row>
                        <Col className="col-md-auto">
                            <b>ID</b> <span> {contractDetails.id} </span> <b>Дата заключения: </b>{contractDetails.contract_date}
                            <br />
                            <b>Срок договора</b>  <span> {contractDetails.contract_duration} мес. </span> <b>Заканчивается: </b> {contractDetails.contract_enddate}
                            <br />
                            <b>Номер договора</b> {contractDetails.contract_nr}
                            <br />
                            <b>Создан</b> Дата: {contractDetails.date_created} Кем: {contractDetails.created_by}
                            <br />
                            <b>Покупатель</b> {contractDetails.contract_partner_org}
                            <br />
                            {contractDetails.enduser_org !== null && <><b>Конечник</b> <span> {contractDetails.enduser_org} </span>
                                <br /></>}
                            <b>Тип оплаты</b> {paymentMethodRu(contractDetails.payment_method)}
                            <br />
                            <b>Квота ID</b> {contractDetails.quota}
                            <br />
                            <b>Сумма продажи квоты</b> {contractDetails.quota_price_sale_rub}
                            <br />
                            <b>Сумма договора с НДС</b> {contractDetails.contract_price_sale_rub}
                            <br />
                            <b>Комментарий</b> {contractDetails.comment}
                            <br />
                            <b>Тех. статус</b> {getStatusRu(contractDetails.tech_status)}

                        </Col>
                    </Row>
                </>
            }
            {userinfo.role!=='MANAGER' && <Row className="mt-4">
                <Col className="col-md-auto"  >
                    <Form.Group className="mb-4">
                        <Button variant="outline-primary" size="sm" onClick={() => setIsContractChanging(true)}>Изменить договор</Button>

                    </Form.Group>
                </Col>
                <Col className="col-md-auto"  >
                    <Form.Group className="mb-4">
                        <Button variant="outline-danger" size="sm" onClick={() => setDeletingContract(true)}>Удалить договор</Button>

                    </Form.Group>
                </Col>

            </Row>}
            <hr />
            <Row>
                {isContractChanging && <ContractChangeForm setisContractChanging={setIsContractChanging} contract={contractDetails} />}
            </Row>

        </Col >
    );
}

export default ContractDetail;