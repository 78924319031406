import React, { useState, useEffect,useContext } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import QuotaService from "../../API/QuotaService";
import Alert from "react-bootstrap/Alert";
import Preloader from "../UI/Preloader/Preloader";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import { handleSetDateFilters } from "../../utils/constants";
import ContractService from "../../API/ContractService";

function ExportContractForm({ close }) {

    const [errors, setErrors] = useState([]);
    const [interval, setInterval] = useState("userInterval");
    const [dateAfter, setDateAfter] = useState('');
    const [dateBefore, setDateBefore] = useState('');
    const [isPreloader, setIsPreloader] = useState(false);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem("freshToken"))
            .then((res) => {
                localStorage.setItem("token", res.data.access);
                setErrors([]);
                exportContracts();
            })
            .catch(() => changeStatus());
    };

    const exportContracts = async (event) => {
        if (event) event.preventDefault();
            let fileType = 'xlsx';
            let filters = false;
            if (dateAfter|| dateBefore) {
                filters = {
                    date_created_after: dateAfter,
                    date_created_before: dateBefore,
                };
            }
            setIsPreloader(true)
            await ContractService.getExportContractsExls(filters).then((resp)=>{
                const date = (new Date()).toISOString().replace('.', '-').split('T')[0];
    
                const effectiveFileName = `contracts-${date}.${fileType}`;
                const url = window.URL.createObjectURL(new Blob([resp]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', effectiveFileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                close();
            }).catch((err) => { setErrors(err.message)}).finally(()=> setIsPreloader(false));
        }

    useEffect(()=> {
if (interval!=='userInterval') {setDateBefore(''); handleSetDateFilters(interval, setDateAfter)};
    },[interval]);

    return (
        <Form onSubmit={exportContracts} style={{ position: "relative" }}>
            {isPreloader && <Preloader />}

            {errors.length > 0 &&
                errors.map((error) => (
                    <Row>
                        <Col>
                            <Alert key="danger" variant="danger">
                                {error}
                            </Alert>
                        </Col>
                    </Row>
                ))}

            <Row className="mb-4">
                <h5 style={{ fontSize: "26px" }}>Экспорт списка контрактов в xlsx</h5>
            </Row>

            <Row className="mb-4">
                <Col>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Интервал</Form.Label>
                        <Form.Select
                            size="sm"
                            id="exportQuota"
                            onChange={(e) => {
                                setInterval(e.target.value);
                            }}
                        >
                            <option value={"userInterval"}>
                                Задать свой интервал
                            </option>
                            <option value={"today"}>За сегодня</option>
                            <option value={"last7"}>За последние 7 дней</option>
                            <option value={"last30"}>
                                За последний месяц
                            </option>
                            <option value={"last90"}>
                                За последние 3 месяца
                            </option>
                            <option value={"lastY"}>За последний год</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>После</Form.Label>
                        <Form.Control
                          value={dateAfter || ""}
                            onChange={(e) => setDateAfter(e.target.value)}
                            size="sm"
                            type="date"
                          
                            disabled={interval !== "userInterval"}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>До </Form.Label>
                        <Form.Control
                            value={dateBefore || ""}
                            onChange={(e) => setDateBefore(e.target.value)}
                            size="sm"
                            type="date"
                            disabled={interval !== "userInterval"}
                            
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col className="col-6">
                    <Form.Group className="mb-4">
                        <Button
                            variant="outline-danger"
                            style={{ width: "100%" }}
                            size="sm"
                            onClick={() => {
                                close();
                            }}
                        >
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="col-6">
                    <Form.Group className="mb-4">
                        <Button
                            variant="outline-success"
                            style={{ width: "100%" }}
                            size="sm"
                            type="submit"
                        >
                            Экспорт
                        </Button>
                        <br />
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
}
export default ExportContractForm;
