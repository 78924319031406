import React, {useState} from "react";
import Table from 'react-bootstrap/Table';
import ImgEdit from '../../icons/edit.png';
import MyModal from "../UI/MyModal/MyModal";
import CategoryEditForm from "./CategoryEditForm";

function CategoryList(props) {
    const [categoryEditing, setCategoryEditing] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(null);
    const basicStyles = {
        "fontSize": 'smaller',
    }

    return (<>
        {categoryEditing && 
            <MyModal visible={categoryEditing} setVisible={setCategoryEditing}>
                <CategoryEditForm
                    setVisible={setCategoryEditing}
                    category={currentCategory}
                    setCategoryEdited={props.setCategoryEdited}
                />
            </MyModal>}
        <Table>

            <thead style={basicStyles}>
                <tr>
                    <th sm={1}>ID</th>
                    <th sm={5}>Название</th>
                    <th sm={4}>Скидка</th>
                    <th sm={1}>Описание</th>
                    <th sm={1}>Изменить</th>

                </tr>
            </thead>
            <tbody style={basicStyles}>
                {
                    props.categories?.map(category =>
                        <tr key={category.id}>
                            <td>
                                {category.id}
                            </td>
                            <td>
                                {category.name}
                            </td>
                            <td>
                                {category.discount}
                            </td>
                            <td>
                                {category.description}
                            </td>
                            <td>
                                <img width="20" className="interactive_hover" alt="edit"src={ImgEdit} disabled onClick={
                                    () => {
                                        setCategoryEditing(true);
                                        props.setCategoryName(category.name);
                                        props.setCategoryDiscount(category.discount);
                                        props.setCategoryDescription(category.description);
                                        setCurrentCategory(category)
                                    }
                                } />
                            </td>
                        </tr>
                    )
                }

            </tbody>
        </Table>
        </>
    );
}
export default CategoryList;