import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import { useNavigate } from "react-router-dom";
import DashboardService from "../../API/DashboardService";
import touchIcon from '../../icons/touch.svg';

function DashboardSubEnded() {
    const navigate = useNavigate();
    const [assetsSubStat, setAssetsSubStat] = useState([])
    const [page, setPage] = useState(0)

    const basicStyles = {
        'fontSize': '10px'
    }

    const linkStyles = {
        'color': 'blue',
        'textDecoration': 'underline',
        'cursor': "pointer"
    }
    const contractStatus = (status) => {
        if (status.includes('дн') && (Number(status.split(' ')[0]) <= 14)) return <span style={{ marginRight: 5, width: 40, padding: '3px 0', fontSize: '10px' }} className="badge bg-danger">{status}</span>;
        else if (status.includes('дн') && (Number(status.split(' ')[0]) > 14)) return <span style={{ marginRight: 5, width: 40, padding: '3px 0', fontSize: '10px' }} className="badge bg-warning">{status}</span>;
        else return <span style={{ marginRight: 5, width: 40, padding: '3px 0', fontSize: '10px' }} className="badge bg-success">{status}</span>
    }
    useEffect(() => {
        (async () => {
            await DashboardService.getSubs(page + 1, false).then((res) => setAssetsSubStat(res))

        })()
    }, [page])

    const handleClick = (index) => {

        if (index === 0) setPage(0)
        else
            setPage(index)
    }
    const newPaginationString = (count) => {
        let paginationArr = []
        let arrPages = [];
        let allPages = Math.floor(count / 10);
        if (count % 10 === 0) allPages = Math.floor(count / 10) - 1;
        for (let i = 0; i <= allPages; i++) { arrPages.push(i) };
        if (count) {
            if (allPages <= 10)
                return arrPages.map((item, index) => <li key={'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => handleClick(index)} >{index + 1}</button></li>)
            else if (page < 7 && allPages > 10) {
                paginationArr = arrPages.slice(0, 8); paginationArr.push('...', arrPages.length - 1); return paginationArr.map((item, index) => <li key={'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => item !== '...' && handleClick(item)} >{item !== "..." ? item + 1 : item}</button></li>)
            }
            else if (page > arrPages.length - 7 && allPages > 10) {
                paginationArr = arrPages.slice(arrPages.length - 8, arrPages.length); paginationArr.unshift(0, '...'); return paginationArr.map((item, index) => <li key={item === '...' ? item + index : 'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => item !== '...' && handleClick(item)} >{item !== "..." ? item + 1 : item}</button></li>)
            }
            else { paginationArr = arrPages.length > 0 && arrPages.slice(page - 3, page + 4); paginationArr.unshift(0, '...'); paginationArr.push('...', arrPages.length - 1); return paginationArr.map((item, index) => <li key={item === '...' ? item + index : 'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => item !== '...' && handleClick(item)} >{item !== "..." ? item + 1 : item}</button></li>) }
        }
    }
    return (

        <>
            <Table className="mb-3" striped bordered hover>
                <thead style={basicStyles}>
                    <tr>

                        <th >Дата окончания</th>
                        <th >Организация</th>
                        <th >Серийник</th>
                        <th >SLUG</th>
                        <th></th>

                    </tr>
                </thead>
                <tbody style={basicStyles}>
                    {assetsSubStat?.results?.map((item, index) =>
                        <tr key={'assetsStats' + item.id + index}>
                            <td>{contractStatus(item.time_left_verbose)}{item.date_end}</td>
                            <td style={linkStyles} onClick={() => navigate(`/orglist/${item.equipment__organisation__id}`)}>{item.equipment__organisation__name.length > 25 ? item.equipment__organisation__name.substring(0, 25) + " ..." : item.equipment__organisation__name}</td>
                            <td>{item.equipment__serial_nr}</td>
                            <td>{item.equipment__platform__name}-{item.subscription__slug}</td>
                            <td> <img width="20" className="img__asset" src={touchIcon} alt="linkToAsset" onClick={() => navigate(`/asset/${item.equipment__id}`)
                            } /></td>
                        </tr>
                    )
                    }

                </tbody>

            </Table >
            <nav aria-label="..." >
                <ul className="pagination justify-content-center">
                    {newPaginationString(assetsSubStat.count)}
                </ul>
            </nav>
        </>
    );
}
export default DashboardSubEnded;