import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AssetService from "../../API/AssetService";

import Auth from "../../API/Auth";
import { AuthContext } from "../../context";
import Preloader from "../UI/Preloader/Preloader";
import UpdatesCloudService from "../../API/UpdatesCloudService";

function AddNewInstanceForm({setAddInstance}) {

    const [serialNumber, setSerialNumber] = useState('');
    const [type, setType] = useState('panorama');
    const [active, setActive] = useState(true)
    const [checkData, setCheckData] = useState(null)
    const [comment, setComment] = useState('')
    const [assetDescription, setAssetDescription] = useState('');

    const [isPreloader, setIsPreloader] = useState(false);
    const [checkErrors, setCheckErrors] = useState('');
    const [errors, setErrors] = useState([]);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);



    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                saveAsset();
            })
            .catch(() => changeStatus())
    }

    const saveAsset = async event => {
        if (event) event.preventDefault();
        const asset = {};
        setIsPreloader(true)
        asset.serial = serialNumber;
        asset.type = type;
        asset.active_flag = active===true? 1 : 0;
        asset.comment = comment;

        await UpdatesCloudService.addInstance(asset).then(() => {setAddInstance(false); setIsPreloader(false) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })
    }

    const checkDeviceInDb = async (serial) => {
        setIsPreloader(true);
        await UpdatesCloudService.checkAsets(serial)
        
        .then((res) => {
            if (res.count> 0) setCheckData(res.results[0])
            else { setCheckErrors('Устройство в базе не найдено')}
        }).catch((err)=> changeStatus()).finally(()=> setIsPreloader(false))
    }
    return (
        <Form id="form" onSubmit={saveAsset} className="was-validated" >
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}

            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            setAddInstance(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Добавить запись
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="mb-4 col-2 ">
                    <div className="form-check" style={{ alignItems: "center", display: "flex", }}>
                        <input onChange={(evt) => setActive(evt.target.checked)} className="form-check-input" type="checkbox" value='' checked={active} id="isActive" style={{ height: '25px', width: '25px' }}></input>
                        <label className="form-check-label" htmlFor="isMain" style={{ fontSize: '14px', fontWeight: 600, marginLeft: '10px', color: "black" }}>
                            Active
                        </label>
                    </div></Col>

            </Row>
          {checkErrors  &&     <Row>
            <Col sm={4}>
         
            
            <Alert key="danger" variant="danger">
                {checkErrors}
            </Alert>
</Col>
    </Row>}
  
            <Row>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Серийный номер устройства</Form.Label>
                        <Form.Control size="sm" type="text" value={serialNumber} required={true} placeholder={'Введите серийный номер'}
                            onChange={(e) => {setCheckErrors('');setCheckData(null); setSerialNumber(e.target.value)}}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col className=" col-2" >
                    <Form.Group className="mb-4">
                        <Form.Label size="sm" style={{ color: "white" }}>Check device</Form.Label>
                        <Button style={{ width: '100%' }} variant="success" size="sm" onClick={() => checkDeviceInDb(serialNumber)}>
                            Проверить в базе
                        </Button>
                    </Form.Group>
                </Col>

            </Row>
            {checkData && <Row className="mb-3">
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Организация</Form.Label>
                        <Form.Control size="sm" type="text" value={checkData?.organisation} disabled
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Платформа</Form.Label>
                        <Form.Control size="sm" type="text" value={checkData?.platform_name} disabled
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
               
            </Row>}
            <Row>
                <Col sm={4} >
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Тип</Form.Label>
                        <Form.Select size="sm" id="type" onChange={(e) => setType(e.target.value)} >
                            <option value="panorama">panorama</option>
                            <option value="hw_fw">hw_fw</option>
                            <option value="vm_fw">vm_fw</option>
                            <option value="only_apps_fw">only_apps_fw</option>

                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Комментарий (опционально)</Form.Label>
                        <Form.Control size="sm" type="text" value={comment} onChange={((e)=>setComment(e.target.value))} 
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>

            </Row>
           
          

            <hr />
        </Form >
    );
}
export default AddNewInstanceForm;
