import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EdlService from "../../API/EdlService";
import Preloader from "../UI/Preloader/Preloader";
import Dropdown from "react-bootstrap/Dropdown";
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import ApiGatewayService from "../../API/ApiGatewayService";
import { AuthContext } from "../../context";
import OrgService from "../../API/OrgService";
function PatchEdlTrialForm(props) {
    const [isPreloader, setIsPreloader] = useState(false);
    const [subs, setSubs] = useState(null);
    const [itemSub, setItemSub] = useState(props.item.subscription);
    const [orgSlug, setOrgSlug] = useState(null);
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const [expiresDate, setExpiresDate] = useState(null);

    // eslint-disable-next-line no-unused-vars
    const [itemOrg, setItemOrg] = useState(props?.item.org_name);
    const [errors, setErrors] = useState([]);

    const [validSlug, setValidSlug] = useState(true);

    useEffect(() => {
        (async () => {
            await EdlService.getSubsListDropdown().then((res) => {
                setSubs(res);
            });
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // useEffect(()=> {
    //     if (orgSlug) setValidSlug(true)
    //     else setValidSlug(false);
    // },[orgSlug])

    useEffect(() => {
        if (props?.item) {
            (async () => {
                await OrgService.getItem(props.item.org_id).then((res) => {
                    if (res.slug) setValidSlug(true);
                    else setValidSlug(false);
                }).catch((err)=> setErrors(err.message));
            })();
        }
    }, [props]);
    const patchInstance = async (event) => {
        event.preventDefault();
        setIsPreloader(true);
        const instance = {};
        instance.subscription = itemSub?.id;
        instance.date_end = expiresDate;
        await EdlService.patchItemSub(instance, props.item.id)
            .then(() => {
                props.setRenew(!props.renew);
                props.setPatchItem(false);
                setIsPreloader(false);
            })
            .catch((e) => {
                setIsPreloader(false);
                setErrors(e.message);
            });
    };
    const patchOrganisation = async () => {
        setIsPreloader(true);
        await OrgService.patchOrg(false, props.item.org_id, false, orgSlug)
            .then((res) => {
                setIsPreloader(false);
                setValidSlug(true);
                // setPatchOrgSlugModal(false); itemOrg.slug = slug; saveInstance(); setErrorsSlug([])
            })
            .catch((err) => {
                setErrors(err.message);
                setIsPreloader(false);
            });
    };

    return (
        <Form
            id="form"
            onSubmit={patchInstance}
            className="was-validated"
            style={{ position: "relative" }}
        >
            {errors.length > 0 &&
                errors.map((error) => (
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                ))}
            {isPreloader && <Preloader />}
            <h5 className="mt-2 mb-3">Активировать подписку</h5>

            <Row>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label>Организация</Form.Label>
                        <Form.Control
                            value={itemOrg}
                            disabled
                            size="sm"
                            type="text"
                        />
                    </Form.Group>
                </Col>
                {!validSlug && (
                    <Col sm={4}>
                        <Form.Group className="mb-4">
                            <Form.Label>SLUG</Form.Label>
                            <Form.Control
                                value={orgSlug || ''}
                                required={true}
                                placeholder="Введите SLUG"
                                size="sm"
                                type="text"
                                onChange={(e) => setOrgSlug(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                )}
                {!validSlug && (
                    <Col
                        className="mb-4 col-2"
                        style={{ display: "flex", alignItems: "flex-end" }}
                    >
                        <Form.Group>
                            <Button
                                style={{ width: "100%" }}
                                variant="outline-primary"
                                size="sm"
                                onClick={() => patchOrganisation()}
                            >
                                Подтвердить
                            </Button>
                        </Form.Group>
                    </Col>
                )}
            </Row>
            {validSlug && (
                <Row>
                    {props?.item?.org_type !== "VENDOR" && (
                        <Col className="mb-4 col-4">
                            <Form.Group>
                                <Form.Label size="sm">Подписки</Form.Label>

                                <Dropdown
                                    style={{
                                        border: "1px solid green",
                                        height: 31,
                                        borderRadius: 6,
                                        paddingLeft: ".5rem",
                                    }}
                                >
                                    <Dropdown.Toggle
                                        as={MyToggle}
                                        id="dropdown-subs"
                                    >
                                        {itemSub?.name || "выбрать"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        as={MyMenu}
                                        type={"subsEdl"}
                                        filterQa={true}
                                    >
                                        {subs?.map((sub) => (
                                            <Dropdown.Item
                                                eventKey={"es" + sub.id}
                                                key={"es" + sub.id}
                                                onClick={() => {
                                                    setItemSub(sub);
                                                }}
                                            >
                                                {sub.name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                        </Col>
                    )}
                    {itemSub?.name!=='trial' &&  <Col className="mb-3 col-2">
                    <Form.Group  >
                        <Form.Label >Дата окончания</Form.Label>
                        <Form.Control
                            disabled={itemOrg?.type === 'VENDOR' ? true : false}
                            value={expiresDate || ""}
                            onChange={(e) => setExpiresDate(e.target.value)}
                            size="sm" type="date"
                            required={true} />

                    </Form.Group>
                </Col>}
                </Row>
            )}

            <hr />
            <Row>
                <Col className="mb-3 col-2">
                    <Form.Group className="mb-4">
                        <Button
                            style={{ width: "100%" }}
                            variant="outline-danger"
                            size="sm"
                            onClick={() => {
                                props.setPatchItem(false);
                            }}
                        >
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                {validSlug && (
                    <Col className="mb-3 col-2">
                        <Form.Group className="mb-4">
                            <Button
                                style={{ width: "100%" }}
                                variant="outline-primary"
                                size="sm"
                                type="submit"
                            >
                                Сохранить
                            </Button>
                            <br />
                        </Form.Group>
                    </Col>
                )}
            </Row>
        </Form>
    );
}
export default PatchEdlTrialForm;
