import React from "react";
import Table from 'react-bootstrap/Table';


function ThreatVaultCard({ data }) {

    const basicStylesTitles = {
        "fontSize": 'smaller',
        width: '15%', textAlign: 'right' 
    }
  
    return (
        <>

            <Table bordered striped hover id='idT' >

                <tbody>
                    <tr>
                        <th style={basicStylesTitles}>Name:</th><td>{data?.name? data?.name: "Нет данных"}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Unique Threat ID</th><td>{data?.id}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Description</th><td>{data?.description? data?.description: 'Нет данных'}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Category</th><td>{data?.category? data?.category: 'Нет данных'}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Minimum Version</th><td>{data?.min_version?data?.min_version : 'Нет данных'}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>related sha256 hashes</th><td>{data?.related_sha256_hashes? data?.related_sha256_hashes.map((item, index)=><div key={item}><span>{item}</span></div> ): 'Нет данных'}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Subtype</th><td>{data?.subtype? data?.subtype: 'Нет данных'}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Severity</th><td>{data?.severity? data?.severity: 'Нет данных'}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Release</th><td>{data?.default_action?data?.default_action : 'Нет данных'}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Action</th><td>{data?.default_action?data?.default_action : 'Нет данных'}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>CVE</th><td>{data?.cve? data?.cve.map((item, index)=> <div key={item + index}><span>{item}</span></div>): 'Нет данных'}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Vendor ID</th><td>{data?.vendor?data?.vendor.map((item, index)=> <div key={item + index}><span>{item}</span></div>): 'Нет данных'}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>First Release</th><td>{data?.ori_release_version? data?.ori_release_version + ' (' + data.ori_release_time + ')': 'Нет данных'}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Last Update</th><td>{data?.latest_release_version? data?.latest_release_version + ' (' + data.latest_release_time + ')': 'Нет данных'}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Reference</th><td>{data?.reference? data?.reference.map((item, index)=><div key={item + index}><span>{item}</span></div> ): 'Нет данных'}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Status</th><td>{data?.status}</td>
                    </tr>

                </tbody>

            </Table></>
    )
}

export default ThreatVaultCard;