import React from 'react'
import './Preloader.css'

const Preloader = ({leftCoord, centerTable}) => {
  
    return (
        <div className="preloader" style={leftCoord && {left: '40%', right: '60%', bottom: centerTable? '300px': null, top: centerTable? 0: '30%'}}>
            <div className="preloader__container">
                <span className="preloader__round"></span>
            </div>
        </div>
    )
};

export default Preloader;
