import Api from '.';

export default class ThreatVaultService {

    static async getList(req, type) {

        let url = 'api/v1/check-cve/'

        const method = 'GET';
        const params = {};
        switch (type) {
            case ('cve'): params.cve = req;
                break;

            default: params.id = req;
        }

        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async getStats() {

        let url = 'api/v1/check-cve/internal_stats/'

        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };

    static async getListShaMd(req, type) {
        let url = 'api/v1/check-cve/sha_md/'

        const method = 'GET';
        const params = {};
        switch (type) {

            case ('sha256'): params.sha256 = req;
                break;

            default: params.md5 = req;
        }

        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async getItem(id) {
        let url = 'api/v1/check-cve/'
        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }


}