import React from "react";
import './Style.css';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

function SettingsPopup({ isOpen, logout, changePass, changeProfile }) {
    const navigate = useNavigate();

    return (
        <div className={isOpen ? "settingsPopup fadeInDown" : "settingsPopup"}>

            <Button variant="link" className="header__menu_link" onClick={() => navigate('/myorg')}>Моя организация</Button>
            <Button variant="link" className="header__menu_link" onClick={changeProfile}>Профиль</Button>
            <Button variant="link" className="header__menu_link" onClick={changePass}>Сменить пароль</Button>
            <Button variant="link" className="header__menu_link" onClick={()=> {logout(); navigate('/login');}}>Выйти</Button>

        </div>
    )
}
export default SettingsPopup;