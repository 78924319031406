import React, { useState, useContext, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";
import ApiGatewayService from "../../API/ApiGatewayService";
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";

function AddScopeForm({addScope, setAddScope, setSuccessAdd, successAdd}) {

    const [errors, setErrors] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [scopeDescription, setScopeDescription] = useState('');
    const [service, setService] = useState('');
    const [access, setAccess] = useState('');
    const [audience, setAudience] = useState({})
    const [audienceList, setAudienceList] = useState([])
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') saveScope();

            })
            .catch(() => changeStatus())
    }

    useEffect(() => {
        (async () => {


            await ApiGatewayService.getAudienceList().then((res) => { setAudienceList(res)})
        })()
    }, [])

    const saveScope = async event => {
        setIsPreloader(true);
        if (event) event.preventDefault();
        const scope = {};
        scope.resource = service;
        scope.audience = audience;
        scope.access = access;
        scope.description = scopeDescription;

        await ApiGatewayService.newScope(scope)
            .then(() => { setAddScope(false); setIsPreloader(false); setSuccessAdd(!successAdd) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken('save');
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })


    }

    return (
        <Form id="form" onSubmit={saveScope} className="was-validated" style={{position: "relative"}}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key={"danger" + error} variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}

            <Row>
                <Col className=" col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Ресурс</Form.Label>
                        <Form.Control size="sm" type='text' value={service} onChange={(e) => setService(e.target.value)} required={true}>

                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col className=" col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Доступ</Form.Label>
                        <Form.Control size="sm" type='text' value={access} onChange={(e) => setAccess(e.target.value)} >

                        </Form.Control>
                    </Form.Group>
                </Col></Row>
                <Row>
                {/* <Col sm={4}>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Сервис</Form.Label>

                        <Dropdown 
                        style={ { border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-orgs" >
                                {audience?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'aud'}
                                filterQa={true}
                            >
                                {audienceList?.map(aud =>
                                    <Dropdown.Item eventKey={"e" + aud.id} key={"e" + aud.id} onClick={() => { setAudience(aud) }}>{aud.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col> */}
                <Col sm={4}>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Сервис</Form.Label>

                        <Form.Select 
                        onChange={(e) => { setAudience(e.target.value) }}
                        style={ {  borderRadius: 6, paddingLeft: '.5rem' }} required={true}>
                            <option value="">Выберите сервис</option>
                           {audienceList?.map(aud =>
                                    <option key={"e" + aud.id}  value={aud.id} >{aud.name}</option>
                                )}
                            {/* <Dropdown.Toggle as={MyToggle} id="dropdown-orgs" >
                                {audience?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'aud'}
                                filterQa={true}
                            >
                                {audienceList?.map(aud =>
                                    <Dropdown.Item eventKey={"e" + aud.id} key={"e" + aud.id} onClick={() => { setAudience(aud) }}>{aud.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu> */}
                        </Form.Select>

                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Описание</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={scopeDescription || ""}
                            size="sm"
                            onChange={(e) => setScopeDescription(e.target.value)}
                        />
                    </Form.Group>
                </Col>

            </Row>
            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            setAddScope(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Добавить 
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>


            <hr />
        </Form >
    );
}
export default AddScopeForm;
