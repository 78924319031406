import React from "react";
import Row from 'react-bootstrap/Row';
import SandboxArea from "../components/Sandbox/SandboxArea";
import Header from "../components/UI/Header";
import SideBar from "../components/UI/Sidebar";

function SandboxPage() {

  return (
    <div>
      <Header />
      <Row>
          <SideBar />
          <SandboxArea />
      </Row>
    </div>
  );
}

export default SandboxPage;
