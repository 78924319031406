import React from "react";
import cl from './MyModal.module.css';

const MyModalFeed = ({children, visible, setVisible, top}) => {

    const rootClasses = !top ? [cl.myModalFeed]: [cl.myModalFeedTop]

    if (visible) {
        rootClasses.push(cl.myModalActiveFeed);
    }

    return (
        <div className={rootClasses.join(' ')} 
       // onClick={() => setVisible(false)}
        >
            <div className={cl.myModalContentFeed} onClick={(e) => e.stopPropagation()}>
                <div className={cl.myModalBlockFeed}>
                {children}
                </div>
            </div>
        </div>
    );
}
export default MyModalFeed;