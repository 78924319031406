import React from "react";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

function DashboardPlatform(props) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    )
    const getColorLabel=(index)=> {
        let color = 'black'
        if(props.platType==='HW' && !props?.assetsStats[index].is_on_warehouse) { color = 'red'
        }
      
      return color
    }
 
    let summAssets = 0;
    let summAssetsWithRma = 0;
    const options = {
        indexAxis: 'y',
        interaction: {
            mode: "index"
        },
        scales: {
            
         
            x: {
                stacked: true,
             
    },
            y: {
                stacked: true,
                ticks: {
                    font: {
                        size: 8,
                      
                    }, color: (c) => {return getColorLabel(c.index)}
                        
                      
                }
            }
        },
        elements: {

            bar: {
                borderWidth: 1,
            },
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: false,
                text: `Устройства по платформам ${props.platType}  ${summAssets + summAssetsWithRma}`,
                // text: `Устройства по платформам ${props.platType}  ${summAssets}`,
            },
            tooltips: {
                enabled: false

            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: function (context) {
                        if (context.datasetIndex > 0)
                            return false
                        return props.platType !== 'VM' ? "Без RMA  " + data.datasets[0].data[context.dataIndex] + " / " + data.datasets[1].data[context.dataIndex] + "  С RMA" : 'устройств ' + dataVM.datasets[0].data[context.dataIndex]
                    }
                },
            }
        }
    }
    const labels = props.assetsStats !== undefined ? props?.assetsStats.map((item) => { summAssets += item.count; summAssetsWithRma += item.count_with_rma; return item.platform__name }) : ''

    const data = props.assetsStats !== undefined ? {
        labels,

        datasets: [
            {
                label: `Устройств ${props.platType} без RMA:  ${summAssets - summAssetsWithRma} шт.`,
                data: labels.map((item, index) => { return props?.assetsStats[index].count - props?.assetsStats[index].count_with_rma }),
                borderColor: 'rgb(60, 179, 113, 0.8)',
                backgroundColor: 'rgba(60, 179, 113, 0.5)',
                barPercentage: .2,
                maxBarThickness: 8,
                datalabels: {
                    opacity: 0
                },
            },
            {
                label: `Устройств ${props.platType} с RMA:  ${summAssetsWithRma} шт.`,
                data: labels.map((item, index) => props?.assetsStats[index].count_with_rma),
                borderColor: 'rgb(198, 108, 108, 0.8)',
                backgroundColor: 'rgba(198, 108, 108, 0.5)',
                barPercentage: .2,
                maxBarThickness: 8,
                datalabels: {
                    opacity: 0
                },
            },
        ],
    } : {}
    const dataVM = props.assetsStats !== undefined ? {
        labels,

        datasets: [
            {
                label: `Устройств ${props.platType}   ${summAssets} шт.`,
                data: labels.map((item, index) => { return props?.assetsStats[index].count }),
                borderColor: 'rgb(60, 179, 113, 0.8)',
                backgroundColor: 'rgba(60, 179, 113, 0.5)',
                barPercentage: .2,
                maxBarThickness: 8,
                datalabels: {
                    opacity: 0
                },
            },

        ],
    } : {}


    return (

        <div style={{ position: 'relative', width: '100%', height: '100%' }}>

            <div style={{ minHeight: '500px' }}>
                {props.assetsStats !== undefined && <Bar options={options} data={props.platType !== 'VM' ? data : dataVM} />}
            </div>
        </div>
    );
}
export default DashboardPlatform;