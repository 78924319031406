import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";
import ServiceService from "../../API/ServiceService";
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import AssetService from "../../API/AssetService";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function AddSub(props) {
    const [isPreloader, setIsPreloader] = useState(false);
    const [dateSub, setDateSub] = useState('');
    const [durationSub, setDurationSub] = useState(12)
    const [errors, setErrors] = useState(null);
    const [subList, setSubList] = useState([]);
    const [currentSub, setCurrentSub] = useState(null);
    const [validSub, setValidSub] = useState(false);
    registerLocale('ru', ru);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {
        (async () => {
            setIsPreloader(true);
            let filters = false;
            if (filters) {
                filters.searchText = false;
            }
            await ServiceService.getSubList(false, true).then((res) => setSubList(res.results)).catch(() => changeStatus());

            setIsPreloader(false)
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                addSubscription();
            })
            .catch(() => changeStatus())
    }

    const addSubscription = async event => {
        if (event) event.preventDefault();
        setIsPreloader(true);
        const item = {};
        item.date_start = dateSub.toISOString().split('T')[0];
        item.duration = durationSub;
        item.subscription = currentSub.id;
        await AssetService.postSubAsset(props.id, item)
            .then(() => setTimeout(() => { props.setAddingSub(false); setIsPreloader(false); }, 1500))
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })
    }

    return (
        <Form onSubmit={addSubscription} className='was-validated' style={{ position: 'relative' }}>
            {isPreloader && <Preloader />}
            <hr />
            {errors !== null && <Alert key="danger" variant="danger">{errors}</Alert>}
            <Row >
                <h5 className="mb-3">Добавить подписку</h5>
                {/* <Col sm={4}>
                        <Form.Group className="mb-4">
                            <Form.Label size="sm">Дата начала подписки</Form.Label>
                            <Form.Control
                                value={dateSub}
                                onChange={(e) => setDateSub(e.target.value)}
                                size="sm" type="date"
                                required={true} />
                        </Form.Group>
                    </Col> */}
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Дата начала подписки</Form.Label>
                        <DatePicker
                            id="datePicker_subs"
                            dateFormat="dd.MM.yyyy"
                            selected={dateSub}
                            onChange={(date) => setDateSub(date)}
                            locale="ru"
                        />
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group controlId="catDiscountField" >
                        <Form.Label>Срок мес.</Form.Label>
                        <Form.Control required={true} size="sm" type="integer" value={durationSub} placeholder="Введите.." onChange={(e) => setDurationSub(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group>
                </Col>

                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Подписка</Form.Label>

                        <Dropdown style={!validSub ? { border: "1px solid red", height: 31, borderRadius: 6, paddingLeft: '.5rem' } : { border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-assetSub" >
                                {currentSub?.description || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assetSub'}
                                filterQa={true}
                            >
                                {subList?.map(sub =>
                                    <Dropdown.Item eventKey={"sub" + sub.id} key={"sub" + sub.id} onClick={() => { setCurrentSub(sub); setValidSub(true) }}>{sub.description}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
            </Row>
            <Row>

            </Row>
            <Row>
                <Col sm={2}>
                    <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }}>
                        Добавить
                    </Button>

                </Col>
                <Col sm={2}>
                    <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); props.setAddingSub(false) }} style={{ width: '100%' }}>
                        Отменить
                    </Button>
                </Col> </Row>
            <hr />
        </Form>

    );
}
export default AddSub;