import React from "react";
import Login from "../components/UI/Login";

function LoginPage() {
  return (

  <Login />
  );
}

export default LoginPage;
