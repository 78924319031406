import React, { useState, useEffect, useRef, useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

import { AuthContext } from "../../context";
import SandboxService from "../../API/SandboxService";
import Button from "react-bootstrap/esm/Button";
import Preloader from "../UI/Preloader/Preloader";
import MyModal from "../UI/MyModal/MyModal";
import CopyIcon from "../../icons/copy.svg";
import { Alert, Dropdown, Form } from "react-bootstrap";
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import VerdictDetailCard from "./VerdictDetailCard";
import MyModalFeed from "../UI/MyModalFeed/MyModalFeed";
import downloadIcon from "../../icons/download.svg";
import PreloaderTableCenter from "../UI/Preloader/PreloaderTableCenter";
function FilesList() {
  const [currentItem, setCurrentItem] = useState(null);
  const [typeFileFilter, setTypeFileFilter] = useState(false);
  const [sandboxData, setSandboxData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [verdictFilter, setVerdictFilter] = useState(false);
  const [verdictTVFilter, setVerdictTVFilter] = useState(false);
  const [verdictKasperFilter, setVerdictKasperFilter] = useState(false);
  const [verdictFortiFilter, setVerdictFortiFilter] = useState(false);
  const [isPreloader, setIsPreloader] = useState(false);
  const [isAssetLoading, setIsAssetLoading] = useState(false);
  const [nextPage, setNextPage] = useState(0);
  const [needToLoad, setNeedToLoad] = useState(false);
  const lastItem = useRef();
  const observer = useRef();
  const [errors, setErrors] = useState([]);
  const [copytext, setCopyText] = useState(false);
  const [serialFilter, setSerialFilter] = useState(false);
  const [viewVerdictDetail, setViewVerdictDetail] = useState(false);
  const [malwareOnly, setMalwareOnly] = useState(false);
  const [ordering, setOrdering] = useState("-last_seen");

  //контекст
  const { setStatus } = useContext(AuthContext);
  const changeStatus = () => setStatus(true);

  const fetchData = async (add) => {
    let filters = false;
    if (
      searchText ||
      verdictFilter ||
      typeFileFilter ||
      serialFilter ||
      verdictFortiFilter ||
      verdictKasperFilter ||
      verdictTVFilter ||
      malwareOnly ||
      ordering
    ) {
      filters = {
        malware_only: malwareOnly,
        search: searchText,
        verdict: verdictFilter,
        verdict_fortisandbox__in: verdictFortiFilter,
        file_type: typeFileFilter,
        firewall_id__in: serialFilter.serial,
        verdict_ktip_hash_lookup: verdictKasperFilter,
        verdict_threat_vault__in:
          verdictTVFilter === "Malware" ? "Malicious" : verdictTVFilter,
        ordering: ordering,
      };
    }

    setIsPreloader(true);
    setIsAssetLoading(true);

    await SandboxService.getFilesList(filters, add ? nextPage : 1)
      .then((res) => {
        if (add === "add") {
          res.items?.map((item) => setSandboxData((arr) => [...arr, item]));
          setNextPage(res.items?.length > 0 ? nextPage + 1 : null);
        } else {
          setSandboxData(res.items);
          setNextPage(res.items?.length > 0 ? 2 : null);
        }
        // setNextPage(res.next ? nextPage + 1 : null);
        setIsAssetLoading(false);
      })
      .catch(() => changeStatus())
      .finally(() => setIsPreloader(false));
  };
  useEffect(() => {
    setIsPreloader(true);
    const timerRefresh = setTimeout(fetchData, 500);
    return () => {
      clearTimeout(timerRefresh);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    verdictFilter,
    searchText,
    typeFileFilter,
    serialFilter,
    verdictFortiFilter,
    malwareOnly,
    verdictKasperFilter,
    verdictTVFilter,
    ordering,
  ]);

  useEffect(() => {
    (async () => {
      if (needToLoad && nextPage) {
        await fetchData("add");
        setNeedToLoad(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToLoad]);

  useEffect(() => {
    if (isAssetLoading) return;
    if (observer.current) observer.current.disconnect();
    var callback = function (entries, observer) {
      if (entries[0].isIntersecting && nextPage && sandboxData?.length > 0) {
        setNeedToLoad(true);
      }
    };
    observer.current = new IntersectionObserver(callback);
    observer.current.observe(lastItem.current);
  }, [isAssetLoading]);
  const dateStyles = {
    fontSize: "x-small",
  };
  const changeDate = (date) => {
    if (date) {
      const formatDate = date?.split("T");
      const reverseDate = (
        <>
          <span>{formatDate[0].split("-").reverse().join(".")}</span>{" "}
          <span>{formatDate[1].split(".")[0]}</span>
        </>
      );

      return reverseDate;
    } else return null;
  };

  // const refreshToken = async (type) => {
  //     await Auth.refreshToken(localStorage.getItem('freshToken'))
  //         .then((res) => {
  //             localStorage.setItem('token', res.data.access); setErrors([]);
  //             if (type === 'delete') deleteAsset(currentItem.id)

  //         })
  //         .catch(() => changeStatus())
  // }
  const getVerdictTVBadge = (verdict, item) => {
    if (verdict === "Benign")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "black" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge "
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
  };
  const getVerdictBadge = (verdict, item) => {
    if (verdict === "Benign")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "black" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unsupported")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Error")
      return (
        <span
          style={{
            marginRight: 10,
            fontSize: "x-small",
            background: "#9e1068",
          }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge "
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
  };
  const getVerdictBadgeForti = (verdict, item) => {
    if (verdict === "Clean")
      return (
        <span
          onClick={() => {
            setCurrentItem(item);
            setViewVerdictDetail(true);
          }}
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Low Risk")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          onClick={() => {
            setCurrentItem(item);
            setViewVerdictDetail(true);
          }}
          className="badge bg-warning"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else
      return (
        <span
          onClick={() => {
            setCurrentItem(item);
            setViewVerdictDetail(true);
          }}
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
  };
  const getVerdictBadgeKasper = (verdict, item) => {
    if (verdict === "Malware")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Not applicable")
      return (
        <span
          style={{
            marginRight: 10,
            fontSize: "x-small",
            background: "#ff7a45",
          }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Adware and other")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Not categorized")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Clean")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge"
        >
          {verdict}
        </span>
      );
  };
  const copyBufferText = ({ text, field }) => {
    navigator.clipboard
      .writeText(text)
      .then((res) => {
        setCopyText({ text: "Copy", field: field });
      })
      .catch((err) => {
        setCopyText({ text: "Error", field: field });
      });
  };
  const clearFilters = () => {
    setSearchText("");
    setVerdictFilter(false);
    setTypeFileFilter(false);
    setSerialFilter(false);
    setVerdictFortiFilter(false);
    setMalwareOnly(false);
    setVerdictKasperFilter(false);
    setVerdictTVFilter(false);
    setOrdering("-last_seen");
  };
  const downloadFile = async (item, type) => {
    setIsPreloader(true);
    await SandboxService.getExportSampleFileSandbox(item?.sha256)
      .then((resp) => {
        setErrors(null);
        const date = new Date().toISOString().replace(".", "-").split("T")[0];

        const effectiveFileName = `${item.sha256}-${date}`;
        const url = window.URL.createObjectURL(
          new Blob([resp.data], { type: resp.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", effectiveFileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        setErrors(err.message?.length > 0 ? err.message : ["Не найдено"]);
      })
      .finally(() => setIsPreloader(false));
  };
  return (
    <>
      {viewVerdictDetail && (
        <MyModalFeed
          visible={viewVerdictDetail}
          setVisible={setViewVerdictDetail}
        >
          <div
            style={{
              width: "50vw",
              margin: "0 auto",
              maxHeight: "80vh",
              overflowY: "scroll",
            }}
          >
            <Row style={{ margin: 0 }}>
              {
                <VerdictDetailCard
                  itemData={currentItem}
                  setViewVerdictDetail={setViewVerdictDetail}
                  viewVerdictDetail={viewVerdictDetail}
                />
              }
            </Row>
          </div>
        </MyModalFeed>
      )}

      <Row
        className="justify-content-between"
        style={{ margin: " 0 0 ", padding: "0 0" }}
      >
        {/* <Col className="col-3 mt-2 mb-2" style={{padding: 0}}>
                    <b>Всего {dataCount} </b>
                </Col> */}
      </Row>
      {errors?.length > 0 &&
        errors?.map((error, index) => (
          <Row key={"danger" + error + index}>
            <Col>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        ))}
      <Row className="mb-2" style={{ position: "relative" }}>
        <Col className="mb-3 col-2">
          <Form.Label size="sm">Поиск </Form.Label>
          <Form.Control
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            placeholder="Поиск по sha256"
            size="sm"
            style={{ fontSize: "1rem", height: 38 }}
            value={searchText}
          />
        </Col>
        {/* <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Firewall</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-firewall" >
                            {serialFilter?.serial|| "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filterAssets={setVerdictFilter}
                            type='verdictFilter'

                        >
                            {firewalls?.map((item, index)=> 
                            <Dropdown.Item eventKey={item.firewall.serial_nr + 'fre'} key={item.firewall.serial_nr + 'fre'} onClick={() => { setSerialFilter({deviceId: item.firewall.serial_nr , serial: item.firewall.serial_nr }) }}>{item.firewall.serial_nr }</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col> */}
        <Col className="mb-3 col-2">
          <Form.Label size="sm">Verdict Palo Alto</Form.Label>
          <Dropdown>
            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-verdict">
              {verdictFilter || "выбрать"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={MyMenu}
              filterAssets={setVerdictFilter}
              type="verdictFilter"
            >
              <Dropdown.Item
                eventKey={"v" + 1}
                key={"v" + 1}
                onClick={() => {
                  setVerdictFilter("Benign");
                }}
              >
                {"Benign"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"v" + 2}
                key={"v" + 2}
                onClick={() => {
                  setVerdictFilter("Malware");
                }}
              >
                {"Malware"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"v" + 3}
                key={"v" + 3}
                onClick={() => {
                  setVerdictFilter("Grayware");
                }}
              >
                {"Grayware"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"v" + 4}
                key={"v" + 4}
                onClick={() => {
                  setVerdictFilter("Phishing");
                }}
              >
                {"Phishing"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"v" + 6}
                key={"v" + 6}
                onClick={() => {
                  setVerdictFilter("Error");
                }}
              >
                {"Error"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"v" + 5}
                key={"v" + 5}
                onClick={() => {
                  setVerdictFilter("Unsupported");
                }}
              >
                {"Unsupported"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="mb-3 col-2">
          <Form.Label size="sm">Verdict Fortinet</Form.Label>
          <Dropdown>
            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-verdict">
              {verdictFortiFilter || "выбрать"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={MyMenu}
              filterAssets={setVerdictFortiFilter}
              type="verdictFilter"
            >
              <Dropdown.Item
                eventKey={"vf" + 1}
                key={"vf" + 1}
                onClick={() => {
                  setVerdictFortiFilter("Clean");
                }}
              >
                {"Clean"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"vf" + 2}
                key={"vf" + 2}
                onClick={() => {
                  setVerdictFortiFilter("Malicious");
                }}
              >
                {"Malicious"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"vf" + 3}
                key={"vf" + 3}
                onClick={() => {
                  setVerdictFortiFilter("Low Risk");
                }}
              >
                {"Low Risk"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"vf" + 4}
                key={"vf" + 4}
                onClick={() => {
                  setVerdictFortiFilter("Medium Risk");
                }}
              >
                {"Medium Risk"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"vf" + 5}
                key={"vf" + 5}
                onClick={() => {
                  setVerdictFortiFilter("High Risk");
                }}
              >
                {"High Risk"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"vf" + 6}
                key={"vf" + 6}
                onClick={() => {
                  setVerdictFortiFilter("Unknown");
                }}
              >
                {"Unknown"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="mb-3 col-2">
          <Form.Label size="sm">File Type</Form.Label>
          <Dropdown>
            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-type">
              {typeFileFilter || "выбрать"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={MyMenu}
              filterAssets={setTypeFileFilter}
              type="typeFilter"
            >
              <Dropdown.Item
                eventKey={"t" + 1}
                key={"t" + 1}
                onClick={() => {
                  setTypeFileFilter("pdf");
                }}
              >
                {"pdf"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 2}
                key={"t" + 2}
                onClick={() => {
                  setTypeFileFilter("ms-office");
                }}
              >
                {"ms-office"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 3}
                key={"t" + 3}
                onClick={() => {
                  setTypeFileFilter("pe");
                }}
              >
                {"pe"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 4}
                key={"t" + 4}
                onClick={() => {
                  setTypeFileFilter("jar");
                }}
              >
                {"jar"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 5}
                key={"t" + 5}
                onClick={() => {
                  setTypeFileFilter("flash");
                }}
              >
                {"flash"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 7}
                key={"t" + 7}
                onClick={() => {
                  setTypeFileFilter("archive");
                }}
              >
                {"archive"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 8}
                key={"t" + 8}
                onClick={() => {
                  setTypeFileFilter("script");
                }}
              >
                {"script"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 9}
                key={"t" + 9}
                onClick={() => {
                  setTypeFileFilter("apk");
                }}
              >
                {"apk"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 10}
                key={"t" + 10}
                onClick={() => {
                  setTypeFileFilter("linux");
                }}
              >
                {"linux"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>

        <Col className="mb-3 col-2">
          <Form.Label size="sm">Malware only</Form.Label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckCheckedWithKfeed"
              checked={malwareOnly}
              onChange={(e) => {
                setMalwareOnly(e.target.checked);
              }}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            ></label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3 col-2">
          <Form.Label size="sm">Kaspersky Lookup</Form.Label>
          <Dropdown>
            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-verdictK">
              {verdictKasperFilter || "выбрать"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={MyMenu}
              filterAssets={setVerdictKasperFilter}
              type="verdictFilterK"
            >
              <Dropdown.Item
                eventKey={"vfk" + 1}
                key={"vfk" + 1}
                onClick={() => {
                  setVerdictKasperFilter("Malware");
                }}
              >
                {"Malware"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"vfk" + 2}
                key={"vfk" + 2}
                onClick={() => {
                  setVerdictKasperFilter("Not applicable");
                }}
              >
                {"Not applicable"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"vfk" + 3}
                key={"vfk" + 3}
                onClick={() => {
                  setVerdictKasperFilter("Adware and other");
                }}
              >
                {"Adware and other"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"vfk" + 4}
                key={"vfk" + 4}
                onClick={() => {
                  setVerdictKasperFilter("Not categorized");
                }}
              >
                {"Not categorized"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"vfk" + 5}
                key={"vfk" + 5}
                onClick={() => {
                  setVerdictKasperFilter("Clean");
                }}
              >
                {"Clean"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"vfk" + 6}
                key={"vfk" + 6}
                onClick={() => {
                  setVerdictKasperFilter("Unknown");
                }}
              >
                {"Unknown"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="mb-3 col-2">
          <Form.Label size="sm"> Verdict Threat Vault</Form.Label>
          <Dropdown>
            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-verdictTV">
              {verdictTVFilter || "выбрать"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={MyMenu}
              filterAssets={setVerdictTVFilter}
              type="verdictTVFilter"
            >
              <Dropdown.Item
                eventKey={"tv" + 1}
                key={"tv" + 1}
                onClick={() => {
                  setVerdictTVFilter("Benign");
                }}
              >
                {"Benign"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"tv" + 2}
                key={"tv" + 2}
                onClick={() => {
                  setVerdictTVFilter("Malware");
                }}
              >
                {"Malware"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"tv" + 3}
                key={"tv" + 3}
                onClick={() => {
                  setVerdictTVFilter("Unknown");
                }}
              >
                {"Unknown"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        {(searchText ||
          verdictFilter ||
          typeFileFilter ||
          serialFilter ||
          verdictFortiFilter ||
          malwareOnly ||
          verdictKasperFilter ||
          verdictTVFilter ||
          ordering !== "-last_seen") && (
          <Col className="col-2">
            <Button
              variant="outline-info"
              size="sm"
              style={{ width: "100%", height: "38px", margin: "32px 0 10px" }}
              onClick={() => clearFilters()}
            >
              Сбросить
            </Button>
          </Col>
        )}
      </Row>

      {
        <div
          style={{
            position: "relative",
            minHeight: "375px",
            display: "flex",
            opacity: isPreloader ? 0.4 : 1,
            flexDirection: "column",
          }}
        >
          {isPreloader && <PreloaderTableCenter />}
          <div className="table-responsive">
            <table
              className="table align-middle table-striped table-bordered"
              id="checkUrlResp"
            >
              <thead>
                <tr style={dateStyles}>
                  <th style={{ width: "5%" }}>SHA256</th>
                  <th style={{ width: "2%" }}>
                    Hits
                    {
                      <span
                        style={{
                          color: ordering === "hits" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "hits"
                            ? setOrdering("hits")
                            : setOrdering(false)
                        }
                      >
                        ↑
                      </span>
                    }
                    {
                      <span
                        style={{
                          color: ordering === "-hits" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "-hits"
                            ? setOrdering("-hits")
                            : setOrdering(false)
                        }
                      >
                        ↓
                      </span>
                    }
                  </th>

                  <th style={{ width: "5%" }}>
                    First seen
                    {
                      <span
                        style={{
                          color: ordering === "created_at" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "created_at"
                            ? setOrdering("created_at")
                            : setOrdering(false)
                        }
                      >
                        ↑
                      </span>
                    }
                    {
                      <span
                        style={{
                          color: ordering === "-created_at" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "-created_at"
                            ? setOrdering("-created_at")
                            : setOrdering(false)
                        }
                      >
                        ↓
                      </span>
                    }
                  </th>
                  <th style={{ width: "5%" }}>
                    Last seen
                    {
                      <span
                        style={{
                          color: ordering === "last_seen" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "last_seen"
                            ? setOrdering("last_seen")
                            : setOrdering(false)
                        }
                      >
                        ↑
                      </span>
                    }
                    {
                      <span
                        style={{
                          color: ordering === "-last_seen" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "-last_seen"
                            ? setOrdering("-last_seen")
                            : setOrdering(false)
                        }
                      >
                        ↓
                      </span>
                    }
                  </th>
                  <th style={{ width: "5%" }}>
                    Verdict{<br />} Palo Alto
                    {
                      <span
                        style={{
                          color: ordering === "finished_at" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "finished_at"
                            ? setOrdering("finished_at")
                            : setOrdering(false)
                        }
                      >
                        ↑
                      </span>
                    }
                    {
                      <span
                        style={{
                          color: ordering === "-finished_at" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "-finished_at"
                            ? setOrdering("-finished_at")
                            : setOrdering(false)
                        }
                      >
                        ↓
                      </span>
                    }
                  </th>
                  <th style={{ width: "5%" }}>
                    Verdict {<br />} Fortinet
                    {
                      <span
                        style={{
                          color:
                            ordering === "finished_fortisandbox_at"
                              ? "blue"
                              : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "finished_fortisandbox_at"
                            ? setOrdering("finished_fortisandbox_at")
                            : setOrdering(false)
                        }
                      >
                        ↑
                      </span>
                    }
                    {
                      <span
                        style={{
                          color:
                            ordering === "-finished_fortisandbox_at"
                              ? "blue"
                              : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "-finished_fortisandbox_at"
                            ? setOrdering("-finished_fortisandbox_at")
                            : setOrdering(false)
                        }
                      >
                        ↓
                      </span>
                    }
                  </th>
                  <th style={{ width: "5%" }}>
                    Kaspersky {<br />} Lookup
                    {
                      <span
                        style={{
                          color:
                            ordering === "finished_ktip_hash_lookup_at"
                              ? "blue"
                              : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "finished_ktip_hash_lookup_at"
                            ? setOrdering("finished_ktip_hash_lookup_at")
                            : setOrdering(false)
                        }
                      >
                        ↑
                      </span>
                    }
                    {
                      <span
                        style={{
                          color:
                            ordering === "-finished_ktip_hash_lookup_at"
                              ? "blue"
                              : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "-finished_ktip_hash_lookup_at"
                            ? setOrdering("-finished_ktip_hash_lookup_at")
                            : setOrdering(false)
                        }
                      >
                        ↓
                      </span>
                    }
                  </th>
                  <th style={{ width: "5%" }}>
                    Verdict {<br />}Threat Vault
                    {
                      <span
                        style={{
                          color:
                            ordering === "finished_threat_vault_at"
                              ? "blue"
                              : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "finished_threat_vault_at"
                            ? setOrdering("finished_threat_vault_at")
                            : setOrdering(false)
                        }
                      >
                        ↑
                      </span>
                    }
                    {
                      <span
                        style={{
                          color:
                            ordering === "-finished_threat_vault_at"
                              ? "blue"
                              : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "-finished_threat_vault_at"
                            ? setOrdering("-finished_threat_vault_at")
                            : setOrdering(false)
                        }
                      >
                        ↓
                      </span>
                    }
                  </th>
                  <th style={{ width: "5%" }}>File type</th>
                  <th style={{ width: "5%" }}>
                    File size{<br />}(Mb){" "}
                    {
                      <span
                        style={{
                          color: ordering === "file_size" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "file_size"
                            ? setOrdering("file_size")
                            : setOrdering(false)
                        }
                      >
                        ↑
                      </span>
                    }
                    {
                      <span
                        style={{
                          color: ordering === "-file_size" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "-file_size"
                            ? setOrdering("-file_size")
                            : setOrdering(false)
                        }
                      >
                        ↓
                      </span>
                    }
                  </th>
                  <th style={{ width: "2%" }}>Download{<br />}sample (ZIP)</th>
                </tr>
              </thead>

              <tbody className="table-group-divider" id={"chDevExt"}>
                {sandboxData?.map((item, index) => (
                  <tr key={item.sha256 + "sandFiles"} style={dateStyles}>
                    <td title={item.sha256}>
                      <span>...{item.sha256?.slice(-10)} </span>
                      <img
                        width="16"
                        className="interactive_hover"
                        alt={"copy"}
                        src={CopyIcon}
                        disabled
                        onClick={() => {
                          setCurrentItem(item);
                          copyBufferText({ text: item.sha256, field: "hash" });
                        }}
                      />
                      {item.sha256 ===
                        currentItem?.sha256  &&
                      copytext?.field === "hash" ? (
                        <span
                          style={
                            copytext.text === "Ошибка"
                              ? { color: "red" }
                              : { color: "green" }
                          }
                        >
                          {copytext.text}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </td>
                    <td>{item.hits}</td>
                    <td>{changeDate(item.first_seen)}</td>
                    <td>{changeDate(item.last_seen)}</td>
                    <td style={{ cursor: "pointer" }}>
                      {getVerdictBadge(item.verdict_palo_alto, item)}
                      {<br />}
                      {changeDate(item.finished_at)}
                    </td>
                    <td style={{ cursor: "pointer" }}>
                      {getVerdictBadgeForti(item.verdict_fortisandbox, item)}
                      {<br />}
                      {changeDate(item.finished_fortisandbox_at)}
                    </td>
                    <td style={{ cursor: "pointer" }}>
                      {getVerdictBadgeKasper(
                        item.verdict_ktip_hash_lookup,
                        item
                      )}
                      {<br />}
                      {changeDate(item.finished_ktip_hash_lookup_at)}
                    </td>
                    <td style={{ cursor: "pointer" }}>
                      {getVerdictTVBadge(item.verdict_threat_vault, item)}
                      {<br />}
                      {changeDate(item.finished_threat_vault_at)}
                    </td>
                    <td>{item.file_type}</td>
                    <td>{item.file_size || "Нет данных"}</td>

                    <td>
                      {item.file_sample && (
                        <Button
                          variant="light"
                          style={{ fontSize: "10px", padding: 3 }}
                          onClick={() => downloadFile(item, "zip")}
                        >
                          <img
                            width="16"
                            className="interactive_hover"
                            alt={"download sample"}
                            src={downloadIcon}
                            disabled
                          />
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div ref={lastItem} style={{ height: 20 }} />
        </div>
      }
    </>
  );
}

export default FilesList;
