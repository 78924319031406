import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OrgService from "../../API/OrgService";
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import EdlService from "../../API/EdlService";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";

function AddEdlSubForm({ setAddSub, setSuccessAdd, successAdd }) {

    const [name, setName] = useState('');
    const [listsLimit, setListsLimit] = useState('');
    const [listsActiveLimit, setListsActiveLimit] = useState('');
    const [ipLimit, setIpLimit] = useState('');
    const [domainLimit, setDomainLimit] = useState('');
    const [urlLimit, setUrlLimit] = useState('');
    const [hashLimit, setHashLimit] = useState('');
    const [errors, setErrors] = useState([]);

    const [isPreloader, setIsPreloader] = useState(false);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') saveSub();
            })
            .catch(() => changeStatus())
    }

    const saveSub = async event => {

        if (event) event.preventDefault();
        const sub = {};
        sub.name = name;
        sub.lists_max_limit = listsLimit;
        sub.active_lists_max_limit = listsActiveLimit;
        sub.ip_addresses_max_limit = ipLimit;
        sub.domains_max_limit = domainLimit;
        sub.urls_max_limit = urlLimit
        sub.hashes_max_limit = hashLimit;
        setIsPreloader(true);
        await EdlService.newSub(sub)
            .then(() => { setAddSub(false); setSuccessAdd(!successAdd); setIsPreloader(false) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken('save');
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })

    }

    return (
        <Form id="form" onSubmit={saveSub} className="was-validated" style={{ position: "relative" }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key={"danger" + error} variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}

            <Row>

                <Col className="mb-3 col-3">
                    <Form.Group >
                        <Form.Label>Название</Form.Label>
                        <Form.Control required={true} size="sm" type="text" value={name} placeholder="Введите.." onChange={(e) => setName(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-3">
                    <Form.Group>
                        <Form.Label>Лимит списков</Form.Label>
                        <Form.Control required={true} size="sm" type="number" value={listsLimit} placeholder="Введите.." onChange={(e) => setListsLimit(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group>
                </Col>

                <Col className="mb-3 col-3">
                    <Form.Group  >
                        <Form.Label>Лимит активных списков</Form.Label>
                        <Form.Control required={true} size="sm" type="number" value={listsActiveLimit} placeholder="Введите.." onChange={(e) => setListsActiveLimit(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row >
                <Col className="mb-3 col-3">
                    <Form.Group  >
                        <Form.Label>Ip лимит</Form.Label>
                        <Form.Control required={true} size="sm" type="number" value={ipLimit} placeholder="Введите.." onChange={(e) => setIpLimit(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group>
                </Col>

                <Col className="mb-3 col-3">
                    <Form.Group  >
                        <Form.Label>domain лимит</Form.Label>
                        <Form.Control required={true} size="sm" type="number" value={domainLimit} placeholder="Введите.." onChange={(e) => setDomainLimit(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-3">
                    <Form.Group  >
                        <Form.Label>url лимит</Form.Label>
                        <Form.Control required={true} size="sm" type="number" value={urlLimit} placeholder="Введите.." onChange={(e) => setUrlLimit(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-4">
            <Col className="mb-3 col-3">
                    <Form.Group  >
                        <Form.Label>hash лимит</Form.Label>
                        <Form.Control required={true} size="sm" type="number" value={hashLimit} placeholder="Введите.." onChange={(e) => setHashLimit(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            setAddSub(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Добавить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>


        </Form >
    );
}
export default AddEdlSubForm;
