import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import HypervisorService from "../../API/HypervisorService";
import Preloader from "../UI/Preloader/Preloader";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function HyperTypeAddForm(props) {
    const [isPreloader, setIsPreloader] = useState(false);
    const [hypervisorType, setHypervisorType] = useState('');
    const [hypervisorVersion, setHypervisorVersion] = useState('');
    const [hypervisorDescription, setHypervisorDescription] = useState('');
    const [errors, setErrors] = useState('');
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                addHypervisor();
            })
            .catch(() => changeStatus())
    }
    const addHypervisor = async event => {
        if (event) event.preventDefault();
        setIsPreloader(true);
        const item = {};
        item.hv_type = hypervisorType;
        item.hv_version = hypervisorVersion;
        if (hypervisorDescription !== "") item.description = hypervisorDescription;
        await HypervisorService.newItem(item)
            .then((data) => { setTimeout(() => { props.setCurrentHypervisor ? props.setCurrentHypervisor(data) : console.log(); setIsPreloader(false); props.setHypervisorAdd(!props.hypervisorAdd); props.setAddHypervisor(false) }, 1000) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })

    }


    return (
        <Form onSubmit={addHypervisor} className='was-validated' style={{ position: 'relative' }}>
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}
            <hr />
            <Row >
                <h3 className="mb-3">Добавить гипервизор</h3>
                <Col className="mb-3 col-4">

                    <Form.Group controlId="catNameField" >
                        <Form.Label>Тип гипервизора</Form.Label>
                        <Form.Control size="sm" type="text"
                            required={true}
                            value={hypervisorType}
                            placeholder="Введите.." onChange={(e) => setHypervisorType(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group></Col>
                <Col className="mb-3 col-4">
                    <Form.Group controlId="catDiscountField" >
                        <Form.Label>Версия гипервизора</Form.Label>
                        <Form.Control required={true} size="sm" type="text" value={hypervisorVersion} placeholder="Введите.." onChange={(e) => setHypervisorVersion(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group>
                </Col></Row>
            <Row>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Описание (опционально)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={hypervisorDescription}
                            size="sm"
                            onChange={(e) => setHypervisorDescription(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    <Button variant="outline-primary" size="sm" type="submit " onClick={(e) => { e.preventDefault(); addHypervisor(e) }} style={{ width: '100%' }}>
                        Добавить
                    </Button>

                </Col>
                <Col sm={2}>
                    <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); props.setAddHypervisor(false) }} style={{ width: '100%' }}>
                        Отменить
                    </Button>
                </Col> </Row>
            <hr />
        </Form>

    );
}
export default HyperTypeAddForm;