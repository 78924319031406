import React from "react";
import { useState, useEffect } from "react";
import Auth from "../../API/Auth";
import { useRef } from "react";
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import Preloader from "../UI/Preloader/Preloader";
import { Captcha } from "../User/Captcha";

const PasswordRecover = function () {
    const [isPreloader, setIsPreloader] = useState(false);
    const emailInputRef = useRef();
    const [error, setError] = useState([]);
    const [message, setMessage] = useState(false);
    //КАПТЧА
    const [viewCaptcha, setViewCaptcha] = useState(false);
    const [token, setToken] = useState('');
    const url = process.env.REACT_APP_API_SERVER;

    const login = async event => {
        setIsPreloader(true);
        event.preventDefault();
        const email = emailInputRef.current.value
        try {
            await Auth.passRecover(email);
            setTimeout(()=> { setMessage(`Инструкции по изменению Вашего пароля были высланы на указанный адрес ${email}`);}, 3000)
            setError([]);
       
            // setTimeout (()=>setIsPreloader(false), 2000);
        } catch (e) {
            setTimeout(() => {
                setIsPreloader(false);
                setError(e.message);
                if (e.message[0].includes('Запрос')) { setViewCaptcha(true); }
            }, 3000)

        };
        setTimeout(() => setIsPreloader(false), 3000);
    }

    useEffect(() => {
        if (token !== "") { setIsPreloader(true); setTimeout(()=> {setViewCaptcha(false); setToken(''); setError([]); setIsPreloader(false) }, 2000) };
    }, [token])

    return (
        <div className="Auth-form-container">
            <form onSubmit={login} className="Auth-form" style={{ position: "relative" }}>
                {isPreloader && <Preloader style={{ top: 0, left: 0 }} />}
                <div className="Auth-form-content">
                    <h5>Форма восстановления пароля</h5>
                    {error.length > 0 &&
                        error.map(error =>
                            <Alert key="danger" variant="danger">
                                {error}
                            </Alert>
                        )
                    }
                    {message &&
                        <Alert key="primary" variant="primary">
                            {message}
                        </Alert>
                    }
                    <div className="form-group mt-3">
                        {/* <label>Адрес электронной почты</label> */}
                        <input
                            ref={emailInputRef}
                            type="email"
                            className="form-control mt-1"
                            placeholder="Введите email"
                        />
                    </div>
                    {viewCaptcha &&  !url.includes('local') && <Captcha setToken={setToken} />}
                    <div className="d-grid gap-2 mt-3">
                        <button type="submit" className="btn btn-primary" disabled={viewCaptcha || isPreloader? true: false}> 
                            Отправить
                        </button><hr />
                        <Link className="active" to="/login">Вход на портал</Link>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default PasswordRecover;