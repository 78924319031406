import React, { useState } from "react";
import ImgEdit from '../../icons/edit.png';
import CopyIcon from '../../icons/copy.svg';
import ImgRemove from '../../icons/remove.png';
import { Button, Col } from "react-bootstrap";
const EdlItem = (props) => {

    const dateStyles = {
        "fontSize": 'x-small',
        cursor: 'pointer'

    }
    const [copytext, setCopyText] = useState(false);

    const dateEndStatus = (date, status) => {

        if (status.includes('дн') && (Number(status.split(' ')[0]) === 0)) return <span style={{ marginRight: 10, fontSize: 'x-small' }} className="badge bg-danger">{date} ({status})</span>;
        else if (status.includes('дн') && (Number(status.split(' ')[0]) <= 31)) return <span style={{ marginRight: 10, fontSize: 'x-small' }} className="badge bg-warning">{date} ({status})</span>;
        else return <span style={{ marginRight: 10, fontSize: 'x-small' }} className="badge bg-success">{date} ({status})</span>
    }
    const copyBufferText = (text, field) => {
        navigator.clipboard.writeText(text)
            .then((res) => {

                setCopyText({ text: 'Copy', field: field })
            })
            .catch(err => {
                setCopyText({ text: 'Error', field: field });
            });
    }

    return (

        <tr key={props.item.id + 'edlItem'}
            style={dateStyles}
            onClick={() => { props.setViewDetail(true); props.setCurrentItem(props.item) }}
        >

            <td>{props.item.org_name}</td>
           {!props.trial &&  <td>{props.item.org_slug}</td>}
            <td>{props.item.date_created}</td>
           {!props.trial &&  <td>{props.item.org_type === 'USER' ? dateEndStatus(props.item.date_end, props.item.time_left_verbose) : 'не предусмотрено'}</td>}
            <td> {props.item.is_active ? <span style={{ color: "green" }}>Активен</span> : <span style={{ color: "red" }}>Не активен</span>}</td>
            <td>{props.item.org_type === 'USER' ? <><span style={{ color: 'green' }}>{props.item.list_stats.active_lists}</span>/<span style={{ color: 'red' }}>{props.item.list_stats.inactive_lists}</span></> : 'не предусмотрено'}</td>
           {!props.trial &&  <td>{props.item.subscription?.name || 'нет подписки'}</td>}
            {/* domain: {props.item.list_stats.domain}<br />
                ip: {props.item.list_stats.ip}<br />
                url: {props.item.list_stats.url}<br /> */}

            <td>{props.item.org_type === 'USER' ? props.item.list_stats.total_entries : 'не предусмотрено'}</td>
           {!props.trial &&  <td title={props.item.gw_access_token}>{props.item.gw_access_token ?<> <img width="20" className="interactive_hover" alt={'copy token'} src={CopyIcon} onClick={
                (evt) => {
                    evt.stopPropagation();
                    copyBufferText(props.item.gw_access_token, 'refresh');
                    props.setCurrentItem(props.item)

                }
            } />   {props.item.id === props.currentItem?.id && copytext ? <span style={copytext === 'Ошибка' ? { color: "red" } : { color: "green" }}>{copytext.field === "refresh" ? copytext.text : ''}</span> : <span></span>} </> : ''}
                {/* {item.subject === currentToken?.subject && copytext ? <span style={copytext === 'Ошибка' ? { color: "red" } : { color: "green" }}>{copytext.field === "refresh" ? copytext.text : ''}</span> : <span></span>} */}
            </td>}
            {!props.trial && <td onClick={(evt)=> evt.stopPropagation()}>
                <Col className="col-2" >

<div className="form-check form-switch" style={{ minHeight: 0 }}>
    <input className="form-check-input" type="checkbox" id="statusChecks" checked={props.item.subscription_extras?.kfeed || false} disabled={!props?.item?.is_subscription_active}
        onChange={(e) => {
            e.stopPropagation();
            props.setSwitchSub(true);
            props.setCurrentItem(props.item);
        }} />
    <label className="form-check-label" htmlFor="statusChecks" style={{fontSize: '10px'}}>kdns</label>

</div>
</Col>
                </td>}
          { !props.trial &&  <td><img width="20" className="interactive_hover" alt={'edit'} src={ImgEdit}  onClick={
                (evt) => {
                    evt.stopPropagation();
                    props.setCurrentItem(props.item)
                    props.setPatchItem(true)
                }
            } />
            </td>}
            {props.trial && <td ><Button type='primary' style={{fontSize: '10px', padding: '2px'}} onClick={(e)=> {e.stopPropagation(); props.setCurrentItem(props.item); props.setPatchItem(true)}}>Платная подписка</Button></td>}
            <td>{props.item.org_type !== 'VENDOR' && <img width="20" className="interactive_hover" alt={'delete'} src={ImgRemove}  onClick={
                (evt) => {
                    evt.stopPropagation();
                    props.setCurrentItem(props.item)
                    props.setDeleteItem(true)
                }
            } />}
            </td>


        </tr>
    );
}

export default EdlItem;