export const fileTypeSandboxCreate= [
    {title: 'pe', key: 'pe'},
    {title: 'pdf', key: 'pdf'},
    {title: 'ms-office', key: 'ms-office'},
    {title: 'jar', key: 'jar'},
    {title: 'flash', key: 'flash'},
    {title: 'archive', key: 'archive'},
    {title: 'script', key: 'script'},
    {title: 'apk', key: 'apk'},
    {title: 'linux', key: 'linux'},
]
export function changeHandler(current, arr, set) {

    if (arr?.some((item) => item === current) !== true) {
        set([...arr, current]);
        //setSlugFilter(true)
    } else { const index = arr?.findIndex((item) => current === item); set([...arr?.slice(0, index), ...arr?.slice(index + 1)]) }
}
