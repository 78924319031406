import React from "react";
import ImgRemove from '../../icons/remove.png';
import ImgEdit from '../../icons/edit.png';
import ImgCamera from '../../icons/camera.png';
import WarehouseService from "../../API/WarehouseService";
import ImgCopy from '../../icons/copy.svg'

const WarehouseItem = (props) => {

    const dateStyles = {
        "fontSize": 'x-small',

    }


    return (

        <tr key={props.item.id + 'assetItem'}
            style={dateStyles}>
            <td>{props.item.serial_nr}</td>
            <td>{props.item.platform}</td>

            <td>{props.item.is_documents === true ? 'Да' : "Нет"}</td>
            <td>{props.item.condition}</td>
            <td>{props.item.is_in_box === true ? 'Да' : 'Нет'}</td>
            <td>{props.item.equipment_location === 'warehouse' ? "Склад" : ` ${props.item.testing_organisation} - до ${props.item.testing_end_date}`}</td>
            <td>{props.item.comment}</td>
            <td>{props.item.purchase_price}</td>
            <td>{props.item.purchase_date}</td>
            <td>{props.item.supplier}</td>
            <td> {props.item.images.length>0 && <img width="25" src={ImgCamera} alt="фотография" onClick={
                (e) => { e.stopPropagation(); props.setShowPhoto(true); props.setCurrentAsset(props.item) }
            } />}</td>
             <td>
            <img width="25" src={ImgCopy} alt="copy" onClick={
                (e) => { e.stopPropagation(); props.setIsCopyAsset(true); props.setCurrentAsset(props.item) }
            } />
            </td>
            <td>  <img width="25" src={ImgEdit} alt="delete" onClick={
                (e) => { e.stopPropagation(); props.setEditAsset(true); props.setCurrentAsset(props.item) }
            } /></td>
            <td>
                <img width="25" src={ImgRemove} alt="copy" onClick={
                    (e) => { e.stopPropagation(); props.removeAsset(true); props.setCurrentAsset(props.item) }
                } />
            </td>
        </tr>
    );
}

export default WarehouseItem;