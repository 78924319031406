import React from "react";
import Row from 'react-bootstrap/Row';
import Header from "../components/UI/Header";
import SideBar from "../components/UI/Sidebar";
import EdlArea from "../components/Edl/EdlArea";

function EdlPage() {

  return (
    <div>
      <Header />
      <Row>
          <SideBar />
         <EdlArea />
      </Row>
    </div>
  );
}

export default EdlPage;