import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContractService from "../../API/ContractService";
import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import { paymentMethod, techStatus } from "../../utils/constants";
import Preloader from "../UI/Preloader/Preloader";
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";

function ContractChangeForm(props) {

    const [isPreloader, setIsPreloader] = useState(false);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const paymentMethodRu = (method) => {
        let methodRu = null;
        // eslint-disable-next-line default-case
        switch (method) {
            case 'monthly':
                methodRu = 'по-месячно';
                break;

            case 'quarterly':
                methodRu = 'по-квартально';
                break;
            case 'prepaid':
                methodRu = 'оплата 100%';
                break;
        }
        return methodRu;
    }

    const getStatusRu = (status) => {
        let statusRu = null;

        switch (status) {
            case 'paid':
                statusRu = 'оплачено';
                break;

            case 'pay_before':
                statusRu = `оплата до `;
                break;
            case 'patch_needed':
                statusRu = 'нужен патч';
                break;
            case 'keys_needed':
                statusRu = 'нужны ключи';
                break;
            case 'all_done':
                statusRu = 'все сделано';
                break;
            default: statusRu = ''
        }
        return statusRu;
    }

    const changeDate = (date) => {
        if (date) {
            const newDate = date.replace(/\./g, '-').split('-');

            newDate.reverse();

            return newDate.join('-');
        }
        else return ''
    }

    registerLocale('ru', ru)
    const [contractTechStatus, setContractTechStatus] = useState(props.contract.tech_status);

    const [contractPart] = useState(props.contract.contract_partner_org);

    const [contractEnd] = useState(props.contract.enduser_org || "");
    const [errors, setErrors] = useState([]);
    const [contractDate, setContractDate] = useState(changeDate(props.contract.contract_date));
    const [contractEndDate, setContractEndDate] = useState(changeDate(props.contract.contract_enddate));
    const [number, setNumber] = useState(props.contract.contract_nr);
    const [contractDescription, setContractDescription] = useState(props.contract.comment || "");
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState(props.contract.payment_method);
    const [contractSummary, setContractSummary] = useState(props.contract.contract_price_sale_rub);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                updateContract();
            })
            .catch(() => changeStatus())
    }

    const updateContract = async event => {
        if (event) event.preventDefault();
        const contract = {};
        setIsPreloader(true)
        if (contractDescription !== "") { contract.comment = contractDescription } else { contract.comment = null }
        contract.contract_date = contractDate;
        contract.contract_enddate = contractEndDate;
        contract.contract_nr = number;
        contract.payment_method = currentPaymentMethod;
        contract.tech_status = contractTechStatus;
        contract.id = props.contract.id;
        contract.contract_price_sale_rub = contractSummary.replace(/,/g, '.');
        await ContractService.patchContract(contract)
            .then(() => { setTimeout(() => { setIsPreloader(false); props.setisContractChanging(false) }, 1000) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })

    }

    return (
        <Form id="formCC" onSubmit={updateContract} className="was-validated" >
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}
            <Row>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Покупатель  </Form.Label>
                        <Form.Control size="sm" type="text" id="partner" disabled
                            value={contractPart}
                        />
                        <Form.Text className="text-muted">

                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Конечник  </Form.Label>
                        <Form.Control size="sm" type="text" id="enduser" disabled
                            value={contractEnd}
                        />
                        <Form.Text className="text-muted">

                        </Form.Text>
                    </Form.Group>
                </Col>

            </Row>
            <Row>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Тип оплаты</Form.Label>
                        <Form.Select size="sm" id="payMeth" required={true} onChange={(e) => setCurrentPaymentMethod(e.target.value)} >
                            <option value={paymentMethodRu(props.contract.payment_method)}>выбран: {paymentMethodRu(props.contract.payment_method)}</option>
                            {paymentMethod?.map((method, index) => <option key={"m" + method.type} value={method.type}>{method.title}</option>)}
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Номер договора</Form.Label>
                        <Form.Control size="sm" type="text" id="number" required={true}
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                        />
                        <Form.Text className="text-muted">

                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row>

                <Col sm={4}>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Дата договора</Form.Label>
                        <Form.Control
                            value={contractDate}
                            onChange={(e) => setContractDate(e.target.value)}
                            size="sm" type="date"
                            required={true} />
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Дата окончания договора</Form.Label>
                        <Form.Control
                            value={contractEndDate}
                            onChange={(e) => setContractEndDate(e.target.value)}
                            size="sm" type="date"
                            required={true} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Тех. статус</Form.Label>
                        <Form.Select size="sm" id="techSt" onChange={(e) => setContractTechStatus(e.target.value)} required={true} defaultValue={props.contract.tech_status || ''}>
                        <option value="">Выбрать</option>
                            {/* {<option >выбран: {getStatusRu(props.contract.tech_status)}</option>} */}
                            {techStatus?.map((method, index) => <option key={"ts" + method.type} value={method.type}>{method.title}</option>)}
                        </Form.Select>
                    </Form.Group>
                    {/* value={paymentMethodRu(props.contract.payment_method)} */}
                </Col>
               
            </Row>
            <Row >
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Комментарий (опционально)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={contractDescription}
                            size="sm"
                            onChange={(e) => setContractDescription(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-3">
                        <Form.Label size="sm">Сумма договора с НДС</Form.Label>
                        <Form.Control size="sm" type="text" id="contrSum"
                            value={contractSummary}
                            onChange={(e) => setContractSummary(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row >
                <Col sm={1} style={{ width: 100 }}>
                    <Form.Group className="mb-4">
                        <Button variant="outline-danger" size="sm" onClick={() => {
                            props.setisContractChanging(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col >
                    <Form.Group className="mb-4">
                        <Button variant="outline-primary" size="sm" type="submit">
                            Сохранить Договор
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>
            <hr />

        </Form>
    );
}
export default ContractChangeForm;