import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PriceService from "../../API/PriceService";
import Alert from 'react-bootstrap/Alert';
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function PriceItemEditForm(props) {

    const [description, setDescription] = useState(props.currentdescription);
    const [price, setPrice] = useState(props.currentprice);
    const [errors, setErrors] = useState([]);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                editItem();
            })
            .catch(() => changeStatus())
    }
    const editItem = async event => {
        if (event) event.preventDefault();
        const edititem = {
            'id': props.itemToEdit.id,
            'name': description,
            'price': price,
        };
        if (description === "") {
            edititem.name = props.itemToEdit.name;
        }
        if (price === "") {
            edititem.price = props.itemToEdit.price;
        }
        try {
            await PriceService.updateItem(edititem);
            props.PriceChanged(true);
            props.setIsItemEditing(false);
            props.setVisible(false);
            props.setItemToEdit({});
            setErrors([]);
        } catch (e) {
            if (e.message[0].includes('token')) refreshToken();
            setErrors(e.message);
        };

    }

    return (
        <Form onSubmit={editItem}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Form.Group className="mb-4">
                <Form.Label>Платформа</Form.Label>
                <Form.Control type="text" value={props.itemToEdit.platform_name} disabled />
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Категория</Form.Label>
                <Form.Control type="text" value={props.itemToEdit.category} disabled />
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Парт-номер</Form.Label>
                <Form.Control type="text" value={props.itemToEdit.part_number} disabled />
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Control as="textarea" rows={5} type="text" defaultValue={props.currentdescription} onChange={(e) => setDescription(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Цена за ед.</Form.Label>
                <Form.Control type="text" defaultValue={props.currentprice} onChange={(e) => setPrice(e.target.value)} />
            </Form.Group>
            <Button variant="primary" type="submit">
                Сохранить
            </Button>
        </Form>
    );
}
export default PriceItemEditForm;