import Api from '.';

export default class CloudStatusService {
    static async checkCloudStatus() {
    
        var url =  `api/v1/cloud-status/` ;
  
        const method = 'GET';
        const params = {};
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };


}