import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { downloadExcel } from "react-export-table-to-excel";
import Button from "react-bootstrap/esm/Button";
import Row from "react-bootstrap/esm/Row";

function PlatformStats({ platform, renewFunction }) {

    const dateStyles = {
        "fontSize": 'x-small',
        textAlign: 'center'

    }
    function handleDownloadExcel(arr, header) {
        var now = (new Date()).toISOString();
        now = now.replace('.', '-');

        downloadExcel({
            fileName: `assets-${now}`,
            sheet: "assets",
            tablePayload: {
                header,
                body: arr,
            },
        });
    }
    function exportData() {

        var table = document.getElementById("tableExcellPlat");

        var rows = [];
        var headRows = [];
        // eslint-disable-next-line no-cond-assign
        for (var i = 0, row; row = table.rows[i]; i++) {
            if (i === 0) {
                let column1 = row.cells[0].innerText;
                let column2 = row.cells[1].innerText;
                let column3 = row.cells[2].innerText;
                headRows.push(
                    column1,
                    column2,
                    column3
                )
            } else {
                let column1 = row.cells[0].innerText;
                let column2 = row.cells[1].innerText;
                let column3 = row.cells[2].innerText;

                rows.push(
                    [
                        column1,
                        column2,
                        column3
                    ])
            }
        };
        handleDownloadExcel(rows, headRows)
    }

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );
    // eslint-disable-next-line no-unused-vars
    let summAssets = 0;
    const options = {
        indexAxis: 'x',

        scales: {
            y: {
                ticks: {
                    font: {
                        size: 8,
                    }
                },

                position: 'left',
                grid: {
                    display: false,
                },


            },
            y1: {
                ticks: {
                    font: {
                        size: 8,
                    },
                    grid: {
                        display: false,
                    },
                },

                position: 'right'
            },
            x: {
                ticks: {
                    font: {
                        size: 10,
                    }
                },

            }
        },
        elements: {

            bar: {
                borderWidth: 1,
            },
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: (data) => { return `Платформа: ${data[0].label}` },
                    // label: (data) => { return `Платформа: ${data[0].label}` } ,

                    // afterLabel: (data) => { return `Платформа:` }
                },
                titleAlign: 'center',
                titleFont: {
                    weight: 'bold'
                },

            },
        }
    };
    const labels = platform !== undefined ? platform.map((item, index) => { summAssets += item.count; return item.platform__name !== null ? item.platform__name : 'Без платформы' }) : ''
    const data = platform !== undefined ? {
        labels,
        datasets: [
            {
                yAxisID: 'y',
                // label: `Устройств  ${summAssets} шт.`,
                label: `Устройств на платформе`,
                data: labels.map((item, index) => platform[index].count),
                borderColor: 'rgb(60, 179, 113, 0.8)',
                backgroundColor: 'rgba(60, 179, 113, 0.5)',
                // / barPercentage: 1.0,
                //  categoryPercentage: 1.0,
                //  barPercentage: .2,
                // maxBarThickness: 8,
                maxBarThickness: 20,
                datalabels: {
                    opacity: 1
                }
            },
            {
                yAxisID: 'y1',
                label: 'Максимум запросов за сегодня от таких же платформ',
                data: labels.map((item, index) => platform[index].platform_curr_day_req_max ),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                // barPercentage: .2,
                // categoryPercentage: 1.0,
                // maxBarThickness: 8,
                maxBarThickness: 20,
                datalabels: {
                    opacity: 0
                }
            },

        ],
    } : {}

    return (<>
    <Row style={{margin:' 0 0 10px'}}>
        <Button type="link" variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '20%' }} onClick={() => { exportData() }}>
            Скачать в Excel
        </Button>
        <Button  variant="outline-info" size="sm" style={{ fontSize: '1rem', width: '20%', marginLeft: '10px' }} onClick={() => { renewFunction() }}>
            Обновить
        </Button>
        </Row>
        <table id='tableExcellPlat'
         style={{ display: 'none' }}
         >
            <thead>
                <tr style={dateStyles}>
                    <td style={{width: '20%'}}>Платформа</td>
                    <td style={{width: '20%'}}>Количество шт.</td>
                    <td style={{width: '50%'}}>Максимум запросов за сегодня от таких же платформ</td>
                </tr>
            </thead>
            <tbody>
                {platform !== undefined && platform.map((item, index) =>
                    <tr key={item.platform__name + item.count + 'platformTable'} style={dateStyles}>

                        <td>{item.platform__name !== null ? item.platform__name : 'Без платформы'}</td>
                        <td >{item.count}</td>
                        <td >{item.platform_curr_day_req_max}</td>
                    </tr>)}</tbody></table>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>

            <div style={{ minHeight: '500px' }}>
                {platform !== undefined && <Bar options={options} data={data} />}
            </div>
        </div></>

    )
}

export default PlatformStats;