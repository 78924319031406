import React from "react";
import Row from 'react-bootstrap/Row';
import Header from "../components/UI/Header";
import SideBar from "../components/UI/Sidebar";
import PriceArea from "../components/Price/PriceArea";


function PricePage() {

  return (
    <div>
      <Header />
      <Row>
          <SideBar />
          <PriceArea />
      </Row>
    </div>
  );
}

export default PricePage;
