import React from "react";
import cl from './MyConfirm.module.css';

const MyConfirm = ({children, visible, setVisible, align, maxWidth}) => {

    const rootClasses = [cl.myModal]

    if (visible) {
        if (align) {rootClasses.push(cl.active_top)} else rootClasses.push(cl.active);
    }

    return (
        <div className={rootClasses.join(' ')} onClick={() => setVisible(false)}>
            <div className={maxWidth!==true? cl.myModalContent: cl.myModalContentWidth} onClick={(e) => e.stopPropagation()}>
                <div className={cl.myModalBlock}>
                {children}
                </div>
            </div>
        </div>
    );
}
export default MyConfirm;