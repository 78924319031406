import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import User from "../../API/User";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Table from "react-bootstrap/esm/Table";
import Preloader from "../UI/Preloader/Preloader";
function UserPatchForm({ user, allOrganisation, externalAccess, setExternalAccess, setPatchUser, orgFilter, setOrgFilter }) {


    const [isPreloader, setIsPreloader] = useState(false);
    const [email, setEmail] = useState(user.email || "");
    const [firstName, setFristName] = useState(user.first_name || "");
    const [lastName, setLastName] = useState(user.last_name || "");
    const [jobTitile, setJobTitile] = useState(user.job_title || "");
    const [phone, setPhone] = useState(user.phone || "");
    const [errors, setErrors] = useState([]);
    const [isActive, setIsActive] = useState(user.is_active === true ? 'Да' : 'Нет');
    const [addAccess, setAddAccess] = useState(false);
    // const [externalAccess, setExternalAccess] = useState(user?.external_orgs_access);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const dateStyles = {
        "fontSize": 'x-small',

    }

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                patchDataUser();
            })
            .catch(() => changeStatus())
    }

    const patchDataUser = async event => {
        if (event) event.preventDefault();
        const userData = {};
        setIsPreloader(true)
        userData.first_name = firstName;
        userData.last_name = lastName;
        userData.email = email;
        userData.phone = phone;
        userData.job_title = jobTitile;
        userData.is_active = isActive === 'Нет' ? false : true;
        let array = [];
        externalAccess.forEach((item) => array.push(item.id));
        userData.external_orgs_access = array
        try {
            await User.patchUser(userData, user.id);
            setPatchUser(false)
            setErrors([]);
            setIsPreloader(false)
        } catch (e) {

            if (e.message[0].includes('token')) refreshToken();
            else {
                setErrors(e.message);
            }
            setIsPreloader(false)

        };
    }
    const addExtAccess = (id, name) => {

        let newItem = {
            id: id,
            name: name
        }
        setExternalAccess([...externalAccess, newItem]);
    }

    const deleteExtAccess = (id) => {
        setExternalAccess(externalAccess.filter(item => item.id !== id))

    }
    const checkAccessView = (id, name) => {
        if (id !== user?.organisation_info[0]?.id) {
        if (!externalAccess.some((item) => item.id === id)) {
            return <Button variant="outline-primary" size="sm" style={{ width: '100%' }} onClick={() => addExtAccess(id, name)}>
                Добавить доступ
            </Button>
        }
        else {
            return <Button variant="outline-danger" size="sm" style={{ width: '100%' }} onClick={() => deleteExtAccess(id)}>
                Удалить доступ
            </Button>
        }}
    }
const clearFilters=()=> {
    setOrgFilter('')
}
    return (
        <Row>
            <Col className="mb-3">
                <Form onSubmit={patchDataUser} className='was-validated' style={{ position: "relative" }}>
                    <hr />
                    {errors.length > 0 &&
                        errors.map(error =>
                            <Alert key="danger" variant="danger">
                                {error}
                            </Alert>
                        )
                    }
                    {isPreloader && <Preloader />}
                    <h5>Редактировать пользователя</h5>
                    <hr />
                    <Row>
                        <Col className="col-4">
                            <Form.Group className="mb-4">
                                <Form.Label size="sm">Email (он же пароль)</Form.Label>
                                <Form.Control size="sm" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group></Col>
                        <Col className="col-4">
                            <Form.Group className="mb-4">
                                <Form.Label size="sm">Должность (опционально)</Form.Label>
                                <Form.Control size="sm" type="text" value={jobTitile} onChange={(e) => setJobTitile(e.target.value)} />
                            </Form.Group></Col>
                        <Col className="col-4"><Form.Group className="mb-4">
                            <Form.Label size="sm">Телефон (опционально)</Form.Label>
                            <Form.Control size="sm" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </Form.Group></Col>
                    </Row>
                    <Row>
                        <Col className="col-4">
                            <Form.Group className="mb-4">
                                <Form.Label size="sm">Фамилия</Form.Label>
                                <Form.Control size="sm" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </Form.Group></Col>
                        <Col className="col-4"><Form.Group className="mb-4">
                            <Form.Label size="sm">Имя</Form.Label>
                            <Form.Control size="sm" type="text" value={firstName} onChange={(e) => setFristName(e.target.value)} />
                        </Form.Group></Col>
                        <Col sm={4} >
                            <Form.Group className="mb-4">
                                <Form.Label size="sm">Активен</Form.Label>
                                <Form.Select size="sm" id="activeUser" onChange={(e) => setIsActive(e.target.value)} value={isActive}>
                                    <option value="Нет">Нет</option>
                                    <option value="Да">Да</option>

                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col className="col-4">
                            <Button variant={addAccess === false ? "outline-primary" : "outline-info"} size="sm" style={{ width: '100%' }} onClick={() => addAccess === false ? setAddAccess(true) : setAddAccess(false)}>
                                {addAccess === false ? 'Добавить доступ к устройствам' : 'Скрыть список'}
                            </Button></Col>
                    </Row>
                    </Form>
                    {addAccess && <Row>
                        <Col className="mb-3 col-3" >
                            <Form.Label size="sm">Поиск</Form.Label>
                            <Form.Control onChange={(e) => setOrgFilter(e.target.value)}
                                placeholder="Начните вводить..."
                                size="sm"
                                style={{ fontSize: '1rem', height: 38 }}
                                value={orgFilter}
                            />
                        </Col>
                       {orgFilter && <Col className="col-2">
                        <Form.Label size="sm" style={{color: "white"}}>Сбросить</Form.Label>
                            <Button variant="outline-info" size="sm" style={{ width: "100%", height: '38px'}} onClick={() => clearFilters()}>Сбросить</Button>
                        </Col>}
                    </Row>}
                    {addAccess && <Row>
                        <Table striped bordered hover >

                            <thead style={dateStyles}>
                                <tr >
                                    <th>Тип</th>
                                    <th>Название</th>
                                    <th >Устройства</th>
                                    <th >Slug</th>
                                    <th>Пользователей</th>
                                    <th></th>
                                </tr>

                            </thead>
                            <tbody style={dateStyles}>
                                {allOrganisation&& allOrganisation?.results?.map(org =>
                                    <tr key={org.id} >
                                        <td>
                                            {org.type}
                                        </td>
                                        <td>
                                            {org.name}
                                        </td>
                                        <td>
                                            {org.devices} шт
                                        </td>
                                        <td>
                                            {org.slug}
                                        </td>
                                        {org.users_amount === 0 ? <td>{0}</td> :
                                            <td>
                                                активных: {org.active_users}<br />
                                                не активных: {org.passive_users}
                                            </td>}

                                        <td>
                                            {checkAccessView(org.id, org.name)}
                                        </td>
                                    </tr>
                                )}
                            </tbody>

                        </Table>
                    </Row>}

                    <Row>

                        <Col className="col-2">
                            <Button variant="outline-danger" onClick={(e) => setPatchUser(false)} size="sm" style={{ width: '100%' }}>
                                Отменить
                            </Button></Col>
                        <Col className="col-2">
                            <Button variant="outline-success" onClick={(event)=> patchDataUser(event)} size="sm" style={{ width: '100%' }}>
                                Сохранить
                            </Button></Col>

                    </Row>


            </Col>
            <hr />
        </Row>
    );
}
export default UserPatchForm;