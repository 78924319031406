import React, { useState, useContext } from "react";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import UrlService from "../../API/UrlService";
import Alert from "react-bootstrap/Alert";
import Preloader from "../UI/Preloader/Preloader";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import { Tab, Tabs } from "react-bootstrap";
import MyModalFeed from "../UI/MyModalFeed/MyModalFeed";
import DetailFeed from "./DetailFeed";
import touchIcon from '../../icons/touch.svg';
function CheckUrl() {
    const columnStyle = {
        padding: "0",
        position: "relative",
        boxSizing: "border-box",
        //'boxShadow': '10px 0 10px -10px rgba(0, 0, 0, .5)'
    };

    const subtitleStyle = {
        padding: "0 5px 0",
        fontSize: "x-small",
        marginBottom: "5px",
    };
    const dateStyles = {
        fontSize: "x-small",
        cursor: "pointer"
    };
    const [inputUrl, setInputUrl] = useState("");
    const [errors, setErrors] = useState([]);
    const [responceUrl, setResponceUrl] = useState("");
    const [responceSource, setResponceSource] = useState("");
    const [responceCreated, setResponceCreated] = useState("");
    const [responceUpdated, setResponceUpdated] = useState("");
    const [responceSuccess, setResponceSuccess] = useState(false);
    const [responceCategories, setResponceCategories] = useState([]);
    const [updateStatus, setUpdateStatus] = useState("");
    const [isPreloader, setIsPreloader] = useState(false);
    const [key, setKey] = useState('tableUrl');
    const [dataFeed, setDataFeed] = useState([]);
    const [openDetailFeed, setOpenDetailFeed] = useState(false)
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem("freshToken"))
            .then((res) => {
                localStorage.setItem("token", res.data.access);
                setErrors([]);
                handleCheckUrl();
            })
            .catch(() => changeStatus());
    };
    const handleCheckUrl = async (event) => {
        setResponceCategories([]);
        setUpdateStatus("");
        if (event) event.preventDefault();
        setIsPreloader(true);

        await UrlService.checkUrl(inputUrl)
            .then((res) => {
                if (res?.message !== undefined) {
                    setErrors([res?.message]);
                    setResponceSuccess(false);
                } else {
                    setErrors([]);
                    setDataFeed(res.kfeed_results)
                    setResponceUrl(res?.url);
                    setResponceSource(res?.source);
                    setResponceCreated(res?.created_at);
                    setResponceUpdated(res?.updated_at);
                    setResponceCategories(res?.categories);
                    setResponceSuccess(true);
                }
            })
            .catch((err) => {
                if (err.message[0].includes("token")) refreshToken();
                else {
                    setErrors(err.message);
                    setIsPreloader(false);
                    setResponceSuccess(false);
                }
            })
            .finally(() => setIsPreloader(false));
    };

    return (
        <>
        {openDetailFeed && <MyModalFeed visible={openDetailFeed} setVisible={setOpenDetailFeed} >
            <DetailFeed dataTable={dataFeed[0]} mask={null} setVisible={setOpenDetailFeed} msk={null}/>
            </MyModalFeed>}
            <Form
                onSubmit={handleCheckUrl}
                style={{
                    position: "relative",
                    display: "flex",
                    padding: "0 5px",
                    gap: "10px",
                    justifyContent: "space-between",
                }}
            >
                {isPreloader && <Preloader leftCoord={true} />}
                <Col className="col-8" style={columnStyle}>
                    <Form.Group className="mb-2">
                        <Form.Control
                            size="sm"
                            type="text"
                            id="checkUrl"
                            required={true}
                            style={{
                                border: "1px solid #0d6efd",
                                borderRadius: "10px",
                            }}
                            value={inputUrl}
                            placeholder={"www.ya.ru"}
                            onChange={(e) => {
                                setInputUrl(e.target.value);
                                setResponceSuccess(false);
                                setResponceCategories([]);
                            }}
                        />
                    </Form.Group>
                </Col>
                <Col className="col-3" style={columnStyle}>
                    <Button
                        variant="outline-success"
                        size="sm"
                        type="submit "
                        style={{
                            width: "100%",
                            paddingRight: 0,
                            paddingLeft: "0",
                        }}
                    >
                        Проверить
                    </Button>
                </Col>
            </Form>

            <hr style={{ marginTop: 0, marginBottom: "5px" }} />
            {errors?.length > 0 &&
                errors?.map((error) => (
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                ))}
            <Row
                style={{ maxWidth: "100%", padding: 0, margin: 0 }}
                className="align-items-center justify-content-between"
            ></Row>
            {errors.length === 0 && responceSuccess === true && (
                <>
                    <p style={subtitleStyle}>
                        <b>URL:</b> {responceUrl}{" "}
                        <b> Обновлено: {responceUpdated}</b>
                    </p>
                    <Tabs
                        id="url-tabs"
                        activeKey={key}
                        onSelect={(key) => setKey(key)}
                        className="mb-3"
                    >
                        <Tab eventKey="tableUrl" title="Url Check">
                        {responceSuccess && (
                        <div className="table-responsive">
                            <table
                                className="table align-middle table-striped table-bordered"
                                id="checkUrlResp"
                            >
                                <thead>
                                    <tr style={dateStyles}>
                                        <th>id</th>
                                        <th>Имя</th>
                                        <th>Описание</th>
                                    </tr>
                                </thead>
                                {responceCategories?.map((item, index) => (
                                    <tbody
                                        className="table-group-divider"
                                        key={item.cat_id + "chUr"}
                                    >
                                        <tr style={dateStyles}>
                                            <td>{item.cat_id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.description}</td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    )}
                        </Tab>
                        <Tab eventKey="tableFeed" title="KFEED">
                            {key === "tableFeed" && <>
                            {responceSuccess && dataFeed.length >0 ? 
                        <div className="table-responsive">
                            <table
                                className="table align-middle table-striped table-bordered"
                                id="checkUrlResp"
                            >
                                <thead>
                                    <tr style={dateStyles}>
                                        <th></th>
                                        <th>id</th>
                                        <th>Имя</th>
                                        <th>Описание</th>
                                    </tr>
                                </thead>
                                {dataFeed[0]?.categories?.map((item, index) => (
                                    <tbody
                                        className="table-group-divider"
                                        key={item.cat_id + "chUr"}
                                    >
                                        <tr style={dateStyles}>
                                        <td>
                                                <img
                                                    width="20"
                                                    className="img__asset"
                                                    src={touchIcon}
                                                    alt="linkToAsset"
                                                    onClick={() =>
                                                        setOpenDetailFeed(true)
                                                    }
                                                />
                                            </td>
                                            <td>{item.cat_id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.description}</td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>
                        : <div>Нет данных</div>
                    }
                            
                            </>}
                        </Tab>
                    </Tabs>
                    {/* {responceSuccess && (
                        <div className="table-responsive">
                            <table
                                className="table align-middle table-striped table-bordered"
                                id="checkUrlResp"
                            >
                                <thead>
                                    <tr style={dateStyles}>
                                        <th>id</th>
                                        <th>Имя</th>
                                        <th>Описание</th>
                                    </tr>
                                </thead>
                                {responceCategories?.map((item, index) => (
                                    <tbody
                                        className="table-group-divider"
                                        key={item.cat_id + "chUr"}
                                    >
                                        <tr style={dateStyles}>
                                            <td>{item.cat_id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.description}</td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    )} */}
                </>
            )}
        </>
    );
}
export default CheckUrl;
