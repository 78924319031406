import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";
import ApiGatewayService from "../../API/ApiGatewayService";

function PatchScopeForm({ setPatchScope, setSuccessPatch, successPatch, currentScope }) {

    const [errors, setErrors] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [scopeDescription, setScopeDescription] = useState(currentScope.description || '');
    const [service, setService] = useState(currentScope.service);
    const [access, setAccess] = useState(currentScope.access || '');
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') saveScope();

            })
            .catch(() => changeStatus())
    }


    const saveScope = async event => {
        setIsPreloader(true);
        if (event) event.preventDefault();
        const scope = {};
        scope.service = service;
        scope.access = access;
        scope.description = scopeDescription;

        await ApiGatewayService.newScope(scope)
            .then(() => { setPatchScope(false); setIsPreloader(false); setSuccessPatch(!successPatch) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken('save');
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })


    }

    return (
        <Form id="form" onSubmit={saveScope} className="was-validated" style={{ position: "relative" }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key={"danger" + error} variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}

            <Row>
                <Col className=" col-8">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Сервис</Form.Label>
                        <Form.Control size="sm" type='text' value={service} onChange={(e) => setService(e.target.value)} required={true}>

                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col className=" col-8">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Доступ</Form.Label>
                        <Form.Control size="sm" type='text' value={access} onChange={(e) => setAccess(e.target.value)} >

                        </Form.Control>
                    </Form.Group>
                </Col>

            </Row>
            <Row>
                <Col sm={8}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Описание</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={scopeDescription || ""}
                            size="sm"
                            onChange={(e) => setScopeDescription(e.target.value)}
                        />
                    </Form.Group>
                </Col>

            </Row>
            <Row >
                <Col className="mb-3 col-4" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            setPatchScope(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Изменить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>


            <hr />
        </Form >
    );
}
export default PatchScopeForm;
