import React from "react";
import Col from "react-bootstrap/esm/Col";
import Table from 'react-bootstrap/Table';

const CloudList = ({ data }) => {

    const dateStyles = {
        "fontSize": 'xx-small',
    }

    const calculateDateDiff = (date) => {

        const now = new Date();
        let timeDate = new Date(date);
        let difference = now.getTime() - timeDate.getTime();
        let styleArr = {}
        if (difference > 900000) styleArr.color = 'red'
        else styleArr.color = 'black'
        return styleArr
    }

    const changeViewDate = (date) => {

        let row = date.split(' ');

        return row[1] + ' ' + row[0].split('-').reverse().join('.')
    }
    return (<>
        <Table striped bordered hover id='clstatus' >
            <thead style={dateStyles}>
                <tr>

                    <th >Последний SN</th>
                    <th>Последнее скачивание пакета</th>
                    <th>Последнее скачивание Release notes</th>
                    <th>Последнее обращение за списком</th>


                </tr>
            </thead>
            <tbody>

                <tr style={dateStyles}>
                    <th><Col style={calculateDateDiff(data.last_serial.datetime)}><b>Date: </b>{changeViewDate(data.last_serial.datetime.replace('T', ' '))}</Col>
                        <Col ><b>SN: </b>{data.last_serial.stats.serial}</Col>
                        <Col><b>IP: </b>{data.last_serial.stats.ip}</Col>
                        <Col><b>Client: </b>{data.last_serial.stats.organisation__name || <span style={{ color: "red" }}>Не найдено</span>}</Col>
                        <Col><b>Device: </b>{data.last_serial.stats.platform__name || <span style={{ color: "red" }}>Не найдено</span>}</Col>

                    </th>
                    <th><Col style={calculateDateDiff(data.last_get_update.datetime)}><b>Date: </b>{changeViewDate(data.last_get_update.datetime.replace('T', ' '))}</Col>
                        <Col ><b>File: </b>{data.last_get_update.stats.file}</Col>
                        <Col ><b>SN: </b>{data.last_get_update.stats.serial}</Col>
                        <Col><b>IP: </b>{data.last_get_update.stats.ip}</Col>
                        <Col><b>Client: </b>{data.last_get_update.stats.organisation__name || <span style={{ color: "red" }}>Не найдено</span>}</Col>
                        <Col><b>Device: </b>{data.last_get_update.stats.platform__name || <span style={{ color: "red" }}>Не найдено</span>}</Col>
                    </th>
                    <th><Col style={calculateDateDiff(data.last_get_rn.datetime)}><b>Date: </b>{changeViewDate(data.last_get_rn.datetime.replace('T', ' '))}</Col>
                        <Col><b>File: </b>{data.last_get_rn.stats.file}</Col>
                        <Col ><b>SN: </b>{data.last_get_rn.stats.serial}</Col>
                        <Col><b>IP: </b>{data.last_get_rn.stats.ip}</Col>
                        <Col><b>Client: </b>{data.last_get_rn.stats.organisation__name || <span style={{ color: "red" }}>Не найдено</span>}</Col>
                        <Col><b>Device: </b>{data.last_get_rn.stats.platform__name || <span style={{ color: "red" }}>Не найдено</span>}</Col>
                    </th>
                    <th><Col style={calculateDateDiff(data.last_post.datetime)}><b>Date: </b>{changeViewDate(data.last_post.datetime.replace('T', ' '))}</Col>
                        <Col><b>Type: </b>{data.last_post.stats.type}</Col>
                        <Col ><b>SN: </b>{data.last_post.stats.serial}</Col>
                        <Col><b>IP: </b>{data.last_post.stats.ip}</Col>
                        <Col><b>Client: </b>{data.last_post.stats.organisation__name || <span style={{ color: "red" }}>Не найдено</span>}</Col>
                        <Col><b>Device: </b>{data.last_post.stats.platform__name || <span style={{ color: "red" }}>Не найдено</span>}</Col>

                    </th>

                </tr>
            </tbody>
        </Table>
        <Table striped bordered hover id='clstatus' >
            <thead style={dateStyles}>
                <tr>
                    <th>Последняя ошибка</th>
                </tr>
            </thead>
            <tbody>
                <tr style={dateStyles}>
                    <th style={{ width: '100%' }}>
                        <Col style={calculateDateDiff(data.last_error.datetime)}><b>Date: </b>{changeViewDate(data.last_error.datetime.replace('T', ' '))}</Col>
                        <Col><b>Err: </b>{data.last_error.stats.error}</Col>
                        <Col ><b>SN: </b>{data.last_error.stats.serial}</Col>
                        <Col><b>IP: </b>{data.last_error.stats.ip}</Col>
                        <Col><b>Client: </b>{data.last_error.stats.organisation__name || <span style={{ color: "red" }}>Не найдено</span>}</Col>
                        <Col><b>Device: </b>{data.last_error.stats.platform__name || <span style={{ color: "red" }}>Не найдено</span>}</Col>

                    </th>
                </tr>
            </tbody>
        </Table></>
    )
}

export default CloudList;

