import React, { useEffect, useState, useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/esm/Button";
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import UpdatesCloudService from "../../API/UpdatesCloudService";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";
function ErrorList({ platforms, organisation }) {
    const [errors, setErrors] = useState([]);
    const [searchTextError, setSearchTextError] = useState('');
    const [searchTextSerial, setSearchTextSerial] = useState('');
    const [platformFilter, setPlatformFilter] = useState(null);
    const [orgFilter, setOrgFilter] = useState(null);
    const [withoutSerialFilter, setWithoutSerialFilter] = useState(false);
    const [isPreloader, setIsPreloader] = useState(false);
    const [errorsList, setErrorsList] = useState(null);
    const [renew, setRenew] = useState(false);
    const [search, setSearch] = useState(false)
    const [page, setPage] = useState(0)
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const dateStyles = {
        "fontSize": 'x-small',

    }
    useEffect(() => {

        (async () => {
            let filters = false;
            if (platformFilter || searchTextError || withoutSerialFilter || orgFilter || searchTextSerial) {
                filters = {
                    platformFilter: platformFilter?.id,
                    searchTextError: searchTextError,
                    serial: withoutSerialFilter,
                    orgFilter: orgFilter?.id,
                    searchTextSerial: searchTextSerial,
                };
            }
            setIsPreloader(true);

            await UpdatesCloudService.getErrorsList(page, filters).then((resp) => {

                setErrorsList(resp); setErrors([]);

            }).catch((e) => { changeStatus(); setErrors(e.message); }).finally(() => { setIsPreloader(false); window.scrollTo(0, 0); });

        })()
    }, [renew, search, page, platformFilter, withoutSerialFilter, orgFilter]);

    const resetFilter = () => {
        setSearchTextError('');
        setSearchTextSerial('')
        setPlatformFilter(false);
        setWithoutSerialFilter(false)
        setOrgFilter(false);
        setRenew(!renew)
    }

    return (
        <>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Row style={{ margin: ' 0 0 3px', padding: '0 0 0 5px', position: "relative" }} className='justify-content-between'>
                {isPreloader && <Preloader leftCoord={true} />}
                <Col className="col-3 mt-2 mb-2">
                    {/* <b>Всего ошибок: {errorsList?.count}шт.</b> */}
                </Col>
                <Col className="col-2 mt-2 mb-2">
                    <Button variant="success" size="sm" style={{ width: '100%' }} onClick={(e) => { setRenew(!renew) }}>Обновить</Button>
                </Col>
            </Row>

            <hr style={{ margin: '5px 0' }} />
            <Row style={{ margin: ' 0 0 3px', padding: '0 0 0 5px' }}>
                <Col className="mb-3 col-3" >

                    <label htmlFor="basic-url" className="form-label">Поиск (серийный)</label>
                    <div className="input-group">

                        <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3 basic-addon4" onChange={(e) => { setSearchTextSerial(e.target.value) }} value={searchTextSerial} onKeyDown={(event) => {
                            if (event.keyCode === 13)
                                setSearch(!search)
                        }} />
                        <span className="input-group-text" id="basic-addon3" style={{ cursor: "pointer" }} onClick={() => setSearch(!search)}>Искать</span>
                    </div>

                </Col>
                <Col className="mb-3 col-3" >

                    <label htmlFor="basic-url" className="form-label">Поиск (ошибка, IP)</label>
                    <div className="input-group">

                        <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3 basic-addon4" onChange={(e) => { setSearchTextError(e.target.value) }} value={searchTextError} onKeyDown={(event) => {
                            if (event.keyCode === 13)
                                setSearch(!search)
                        }} />
                        <span className="input-group-text" id="basic-addon3" style={{ cursor: "pointer" }} onClick={() => setSearch(!search)}>Искать</span>
                    </div>

                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Платформа</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-platform" >
                            {platformFilter?.name || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filter={setPlatformFilter}
                            type='platformfilter'
                        // filterActiveAssets={setFilteredArray}
                        // filterRequest={setWithoutOrg}
                        >

                            {platforms && platforms.map((item) => <Dropdown.Item eventKey={item.id + 'plat'} key={item.id + 'plat'} onClick={() => {
                                setPlatformFilter(item);
                                setWithoutSerialFilter(false)
                            }}>{item.name}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Организация</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-org" >
                            {orgFilter?.name || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filter={setOrgFilter}
                            type='orgfilter'
                        // filterActiveAssets={setFilteredArray}
                        // filterRequest={setWithoutOrg}
                        >

                            {organisation && organisation.map((item) => <Dropdown.Item eventKey={item.id + 'plat'} key={item.id + 'org'} onClick={() => {
                                setOrgFilter(item);
                                setWithoutSerialFilter(false)
                            }}>{item.name}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-3" >
                    <Form.Label size="sm">Нет серийного номера</Form.Label>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={withoutSerialFilter}
                            onChange={(e) => {
                                setWithoutSerialFilter(e.target.checked); setOrgFilter(false); setPlatformFilter(false)
                            }} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>

                    </div>
                </Col>


                {(searchTextError || platformFilter || withoutSerialFilter || orgFilter || searchTextSerial) && <Col className="col-2">

                    <Button variant="outline-info" size="sm" style={{ width: "100%", height: '38px', margin: '30px 0 0' }} onClick={() => resetFilter()}>Сбросить</Button>
                </Col>}

            </Row>

            {

                <div style={{
                    position: "relative", minHeight: '375px', display: 'flex',
                    flexDirection: 'column'
                }}>

                    <div className="table-responsive" >
                        <table className="table align-middle table-striped table-bordered" id='checkUrlResp'>
                            <thead>
                                <tr style={dateStyles}>
                                    <th>SN</th>
                                    <th>Орг-ция</th>
                                    <th>Платформа</th>
                                    <th>Подписки</th>
                                    <th>Время</th>
                                    <th>Ошибка</th>
                                    <th>Версия ОС</th>
                                    <th>ip-адрес</th>
                                </tr>
                            </thead>


                            <tbody className="table-group-divider" id={'chDevExt'}>
                                {errorsList?.data?.map((item, index) =>
                                    <tr key={item.id + 'errorsUC'} style={dateStyles}>
                                        <td>{item.serial}</td>

                                        <td >{item.organisation__name}</td>
                                        <td >{item.platform__name}</td>
                                        <td >{item.subscriptions?.length > 0 && item.subscriptions.map((dev, index) => <Col key={dev.id + 'sub' + dev.slug}>{dev.slug} {dev.time_left_verbose}</Col>)}</td>
                                        <td>{item.datetime.replace('T', ' ')}</td>
                                        <td>{item.error}</td>
                                        <td>{item.os_version}</td>
                                        <td>{item.ip}</td>

                                    </tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                    <nav aria-label="pagination" style={{ marginTop: 'auto' }} >
                        <ul className="pagination justify-content-center" id='pagination-menu' style={{ margin: "0, auto" }}>
                            {errorsList?.data && <li className="page-item">
                                <button disabled={page === 0 ? true : false} className="page-link" aria-label="Previous" onClick={() => setPage(page - 1)}>
                                    <span aria-hidden="true">&laquo;</span>

                                </button> </li>}
                            {/* {paginationString(errorsList?.count)} */}
                            {errorsList?.data && <li className="page-item">
                                <button
                                    // disabled={page + 1 > errorsList?.count / 50 ? true : false} 
                                    className="page-link" aria-label="Next" onClick={() => setPage(page + 1)}>
                                    <span aria-hidden="true">&raquo;</span>

                                </button> </li>}
                        </ul>
                    </nav>

                </div>}
        </>
    )
}

export default ErrorList;