import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PriceService from "../../API/PriceService";
import Alert from 'react-bootstrap/Alert';
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function PriceItemCreateForm(props) {

    const [platform, setPlatform] = useState("");
    const [partNumber, setPartNumber] = useState("");
    const [description, setDescription] = useState(props.currentdescription);
    const [price, setPrice] = useState(props.currentprice);
    const [category, setCategory] = useState("");
    const [errors, setErrors] = useState([]);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                createItem();
            })
            .catch(() => changeStatus())
    }
    const createItem = async event => {
        if (event) event.preventDefault();

        const item = {
            'part_number': partNumber,
            'name': description,
            'price': price.replace(/,/g, '.'),
        };
        if (platform === '') {
            item.platform = props.currentplatform;
        } else {
            item.platform = platform;
        };
        if (category === '') {
            item.category = props.currentcategory;
        } else {
            item.category = category;
        };
        try {
            await PriceService.newItem(item);
            props.PriceChanged(true);
            props.setVisible(false);
            setErrors([]);
        } catch (e) {
            if (e.message[0].includes('token')) refreshToken();
            setErrors(e.message);
        };

    }


    return (
        <Form onSubmit={createItem} style={{ display: 'flex', flexDirection: 'column', gap: '.7rem' }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Form.Group  >
                <Form.Label>Платформа</Form.Label>
                <Form.Select size="sm" onChange={(e) => setPlatform(e.target.value)}>
                    {props.platforms?.map(platform =>
                        <option key={platform.name} value={platform.name}>{platform.name}</option>
                    )}
                </Form.Select>
            </Form.Group>
            <Form.Group >
                <Form.Label>Категория</Form.Label>
                <Form.Select onChange={(e) => setCategory(e.target.value)}>
                    {props.categories?.map(category =>
                        <option key={category.name} value={category.name}>{category.name}</option>
                    )}
                </Form.Select>
            </Form.Group>
            <Form.Group >
                <Form.Label>Парт-номер</Form.Label>
                <Form.Control type="text" placeholder="Парт-номер" onChange={(e) => setPartNumber(e.target.value)} />
                <Form.Text className="text-muted">
                    пример: 3020-T-HA
                </Form.Text>
            </Form.Group>
            <Form.Group >
                <Form.Control as="textarea" rows={5} type="text" defaultValue="" placeholder="Описание" onChange={(e) => setDescription(e.target.value)} />
                <Form.Text className="text-muted">
                    пример: TP-HA subs year 1, 3020
                </Form.Text>
            </Form.Group>
            <Form.Group >
                <Form.Label>Цена за ед.</Form.Label>
                <Form.Control type="text" placeholder="Введите стоимость" onChange={(e) => setPrice(e.target.value)} />
                <Form.Text className="text-muted">
                    в долларах США (с НДС)
                </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit">
                Добавить
            </Button>
        </Form>
    );
}
export default PriceItemCreateForm;