import React from "react";
import Row from 'react-bootstrap/Row';
import Header from "../components/UI/Header";
import SideBar from "../components/UI/Sidebar";
import UsersArea from "../components/Users/UsersArea";


function UsersPage() {

  return (
    <div>
      <Header />
      <Row>
          <SideBar />
          <UsersArea />
      </Row>
    </div>
  );
}

export default UsersPage;
