import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";
import Preloader from "../UI/Preloader/Preloader";
import SandboxService from "../../API/SandboxService";
import PlatformService from "../../API/PlatformService";
import { Dropdown } from "react-bootstrap";
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import UrlService from "../../API/UrlService";

function AddAssetForm({ setAddAsset, successAdd, setSuccessAdd }) {
    const [serial, setSerial] = useState("");
    const [active, setActive] = useState(false);
    const [comment, setComment] = useState("");
    const [isPreloader, setIsPreloader] = useState(false);
    const [errors, setErrors] = useState([]);
    const [allPlatforms, setAllPlatforms] = useState([]);
    const [assetPlatform, setAssetPlatform] = useState(null);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {
        (async () => {
            await PlatformService.getList()
                .then((res) => setAllPlatforms(res))
                .catch(() => changeStatus());
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem("freshToken"))
            .then((res) => {
                localStorage.setItem("token", res.data.access);
                setErrors([]);
                saveAsset();
            })
            .catch(() => changeStatus());
    };

    const saveAsset = async (event) => {
        if (event) event.preventDefault();
        setIsPreloader(true);
        let data = {};
        data.subject = serial;
        if (comment) data.comment = comment;
        if (assetPlatform) data.platform = assetPlatform.id;
        data.is_active = active;
        await UrlService.addInstance(data)
            .then(() => {
                setAddAsset(false);
                setIsPreloader(false);
                setSuccessAdd(!successAdd);
            })
            .catch((err) => {
                if (err.message[0].includes("token")) refreshToken();
                else {
                    setErrors(err.message);
                    setIsPreloader(false);
                }
            });
    };

    return (
        <Form
            id="formUrlAdd"
            onSubmit={saveAsset}
            className="was-validated"
            style={{ margin: " 0 0 3px", padding: "0 12px 0 12px" }}
        >
            {errors?.length > 0 &&
                errors?.map((error) => (
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                ))}
            {isPreloader && <Preloader />}
            <Row className="mb-3">
                <Col className="col-2">
                    <div
                        className="form-check form-switch"
                        style={{
                            minHeight: 0,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Form.Label size="sm" style={{ marginLeft: "-40px" }}>
                            Активно
                        </Form.Label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="statusChecks"
                            checked={active}
                            style={{ height: "25px", width: "50px" }}
                            onChange={(evt) => setActive(evt.target.checked)}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Серийный номер</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            value={serial}
                            required={true}
                            placeholder={"Введите серийный"}
                            onChange={(e) => {
                                setSerial(e.target.value);
                            }}
                        />
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Платформа</Form.Label>

                        <Dropdown
                            style={{
                                border: "1px solid green",
                                height: 31,
                                borderRadius: 6,
                                paddingLeft: ".5rem",
                            }}
                        >
                            <Dropdown.Toggle as={MyToggle} id="dropdown-plat">
                                Платформа: {assetPlatform?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={"assPlat"}
                                filterQa={true}
                            >
                                {allPlatforms?.map((platform) => (
                                    <Dropdown.Item
                                        eventKey={"e" + platform.id}
                                        key={"e" + platform.id}
                                        onClick={() => {
                                            setAssetPlatform(platform);
                                        }}
                                    >
                                        {platform.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Описание</Form.Label>
                        <Form.Control
                            size="sm"
                            as="textarea"
                            rows={2}
                            type="text"
                            value={comment}
                            required={true}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder={"Введите описание"}
                        />
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col className="col-2 mt-3">
                    <Form.Group className="mb-3">
                        <Button
                            style={{ width: "100%" }}
                            variant="outline-danger"
                            size="sm"
                            onClick={() => {
                                setAddAsset(false);
                            }}
                        >
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="col-2 mt-3">
                    <Form.Group className="mb-3">
                        <Button
                            style={{ width: "100%" }}
                            variant="outline-primary"
                            size="sm"
                            type="submit"
                        >
                            Сохранить
                        </Button>
                        <br />
                    </Form.Group>
                </Col>
            </Row>

            <hr />
        </Form>
    );
}
export default AddAssetForm;
