import React from "react";
import Table from 'react-bootstrap/Table';


function ThreatVaultCardSha({ data, getItemById }) {

    const basicStylesTitles = {
        "fontSize": 'xx-small',
        width: '15%', textAlign: 'right'
    }
    const dateStylesTitles = {
        "fontSize": 'xx-small',
        textAlign: 'left'
    }

    return (
        <>

            <Table bordered striped hover id='idT' >

                <tbody >
                    <tr>
                        <th style={basicStylesTitles}>File Type</th><td style={dateStylesTitles}>{data[0].filetype}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>sha256</th><td style={dateStylesTitles}>{data[0].sha256}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>sha1</th><td style={dateStylesTitles}>{data[0].sha1}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>md5</th><td style={dateStylesTitles}>{data[0].md5}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Size</th><td style={dateStylesTitles}>{data[0].size}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Family</th><td style={dateStylesTitles}>{data[0].family}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Create time</th><td style={dateStylesTitles}>{data[0].create_time}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Wildware verdict</th><td style={dateStylesTitles}>{data[0].wildfire_verdict}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Type</th><td style={dateStylesTitles}>{data[0].type}</td>
                    </tr>

                </tbody>

            </Table>
            <Table bordered striped hover id='idT' >
                <thead style={basicStylesTitles}>
                    <tr>
                        <th >Signature</th>
                        <th >Severity</th>
                        <th >CVE</th>
                        <th>sha256 hashes</th>
                        <th>Subtype</th>

                        <th >First Release</th>
                        <th >Last Update</th>
                    </tr>

                </thead>
                <tbody style={basicStylesTitles}>
                    {data && data[0].signatures.map((item, index) => <tr key={item.id + index} >
                        <td style={{ cursor: "pointer", color: "blue" }} onClick={(e) => getItemById(item.id)}> <span style={{ textDecoration: 'underline' }}>{item?.name ? item?.name : 'Нет данных'}</span><br /><span style={{ textDecoration: 'none' }}>{'Unique Threat ID: ' + item?.id}</span></td>
                        <td>{item?.severity}</td>
                        <td style={{ width: '20%' }}>{item?.cve ? item?.cve.map((it, ind) => <div key={it + ind}><span>{it}</span></div>) : 'Нет данных'}</td>
                        <td>{item.related_sha256_hashes.map((it, ind) => <div key={it}><span>{it}</span></div>)}</td>
                        <td>{item?.subtype}</td>
                        <td style={{ width: '15%' }}>{item?.ori_release_version ? item?.ori_release_version + ' (' + item.ori_release_time + ')' : 'Нет данных'}</td>
                        <td style={{ width: '15%' }}>{item?.latest_release_version ? item?.latest_release_version + ' (' + item.latest_release_time + ')' : 'Нет данных'}</td>
                    </tr>)}
                </tbody>
            </Table>
        </>
    )
}

export default ThreatVaultCardSha;