import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import User from "../../API/User";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
function UserCreateForm(props) {

    const [email, setEmail] = useState("");
    const [firstName, setFristName] = useState("");
    const [lastName, setLastName] = useState("");
    const [jobTitile, setJobTitile] = useState("");
    const [phone, setPhone] = useState("");
    const [errors, setErrors] = useState([]);
    const [role, setRole] = useState('USER');
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    
    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                createItem();
            })
            .catch(() => changeStatus())
    }

    const createItem = async event => {
        if (event) event.preventDefault();
        const user = {};
        user.first_name = firstName;
        user.last_name = lastName;
        user.email = email;
        user.password = email;
        user.phone = phone;
        user.job_title = jobTitile;
        user.is_active = false;
        user.role = role;
        if (props.orgId) {
            user.organisation = props.orgId;
        };

        try {
            await User.new(user, props.orgId || "me");
            props.setUserChanged(true);
            props.setIsUserAdding(false);
            setErrors([]);
        } catch (e) {

            if (e.message[0].includes('token')) refreshToken();
            else {
                setErrors(e.message);
            }

        };
    }


    return (
        <Row>
            <Col className="mb-3">
                <Form onSubmit={createItem}>
                    <hr />
                    {errors.length > 0 &&
                        errors.map(error =>
                            <Alert key="danger" variant="danger">
                                {error}
                            </Alert>
                        )
                    }
                    <h5>Добавление нового пользователя</h5>
                    <hr />
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Email (он же пароль)</Form.Label>
                        <Form.Control size="sm" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Фамилия</Form.Label>
                        <Form.Control size="sm" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Имя</Form.Label>
                        <Form.Control size="sm" type="text" value={firstName} onChange={(e) => setFristName(e.target.value)} />
                    </Form.Group>
                   {props.type === 'VENDOR' &&  <Form.Group className="mb-4">
                        <Form.Label size="sm">Роль</Form.Label>
                        <Form.Select size="sm" id="userRole" onChange={(e) => setRole(e.target.value)} required={true} defaultValue={role}>
                        <option value="USER">USER</option>
                        <option value="MANAGER">MANAGER</option>
                         
                        </Form.Select>
                    </Form.Group>}
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Должность (опционально)</Form.Label>
                        <Form.Control size="sm" type="text" value={jobTitile} onChange={(e) => setJobTitile(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Телефон (опционально)</Form.Label>
                        <Form.Control size="sm" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </Form.Group>
                    <Row>
                        <Col className="col-4">
                            <Button variant="outline-success" type="submit" size="sm" style={{ width: '100%' }}>
                                Сохранить
                            </Button></Col>
                        <Col className="col-4">
                            <Button variant="outline-danger" onClick={(e) => props.setIsUserAdding(false)} size="sm" style={{ width: '100%' }}>
                                Отменить
                            </Button></Col>
                    </Row>

                </Form>
            </Col>
            <hr />
        </Row>
    );
}
export default UserCreateForm;