import React from "react";
import { useState, useRef } from "react";
import Auth from "../../API/Auth";
import Alert from 'react-bootstrap/Alert';
import {Link, useParams} from 'react-router-dom';
import Form from 'react-bootstrap/Form';


const PasswordRecoverConfirm = function() {

    const newPassInputRef = useRef();
    const confirmPassInputRef = useRef();
    const [error, setError] = useState([]);
    const [message, setMessage] = useState(false);
    const params = useParams();

    const setPass = async event => {
        event.preventDefault();
        if (newPassInputRef.current.value !== confirmPassInputRef.current.value) {
            setError(["Введенные новые пароли не совпадают"]);
            setMessage(false);
        } else {
            try {
                await Auth.passRecoverConfirm(params.uid, params.token, newPassInputRef.current.value, confirmPassInputRef.current.value);
                setError([]);
                setMessage('Ваш пароль был успешно изменен');
            } catch(e) {
               
                setError(e.message);
            };
        };
    };

    return (
        <div className="Auth-form-container">
            <form onSubmit={setPass} className="Auth-form">
                <div className="Auth-form-content">
                <h5>Придумайте новый пароль</h5>
                        {error.length > 0 &&
                            error?.map(error => 
                                <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>
                            )
                        }
                        {message &&
                                <Alert key="primary" variant="primary">
                                    {message}
                                </Alert>
                        }
                    <Form.Group className="mb-4">
                        <Form.Label>Новый пароль</Form.Label>
                        <Form.Control ref={newPassInputRef} size="sm" type="password" placeholder="Введите новый пароль.." />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Повторите новый пароль</Form.Label>
                        <Form.Control ref={confirmPassInputRef} size="sm" type="password" placeholder="Повторите новый пароль.." />
                    </Form.Group>
                    <div className="d-grid gap-2 mt-3">
                        <button type="submit" className="btn btn-primary">
                        Отправить
                        </button><hr />
                        <Link className="active" to="/login">Вход на портал</Link>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default PasswordRecoverConfirm;