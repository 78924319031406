import React, { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import QuotaService from "../../API/QuotaService";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const QuotaDescription = (props) => {

    const [quota, setQuota] = useState(null);

    useEffect(() => {
        (async () => {
            if (props?.id) {
                await QuotaService.getItem(props.id).then((res) => setQuota(res));

            }
        })();
    }, [props])
    return (
        <Table>

            <tbody>

                {quota !== null &&
                    <tr>
                        <td sm={6} colSpan={4}>
                            <b>Статус</b> {quota.status} {quota.status === "SOLD" && `дата договора: ${quota.sold_date}`}
                            <br />
                            <b>Создана</b> {quota.date_created}, {quota.created_by}
                            <br />
                            <b>Обновлено</b> {quota.date_modified}, {quota.updated_by}
                            <br />
                            <b>Истекает</b> {quota.date_expires}
                            <br />
                            <b>Комментарии</b> {quota.description ? quota.description : "нет"}
                            <br />
                            <b>Версия цен</b> {quota.active_priceversion_comment} от {quota.active_priceversion_date}
                            <br />
                        </td>
                        <td colSpan={3}>
                            <b>Заказчик</b> {quota.enduser_org}
                            <br />
                            <b>Партнер</b> {quota.partner_org}
                            <br />
                            <b>GPL USD</b> {quota.total_price}
                            <br />
                            <b>Скидка</b> {quota.discount}
                            <br />
                            <b>Курс</b> {quota.currency_rate}
                            <br />
                            <b>Сумма продажи</b> {quota.total_price_sale_rub} руб
                            <br />
                        </td>
                    </tr>
                }
                <hr style={{ borderTop: 0 }} />
                <tr>
                    <td>
                        <b>Парт-номер</b>
                    </td>
                    <td>
                        <b>Наименование</b>
                    </td>
                    <td>
                        <b>Прайс</b>
                    </td>
                    <td>
                        <b>Количество</b>
                    </td>
                    <td>
                        <b>Прайс Sum</b>
                    </td>
                    <td>
                        <b>Прайс, руб</b>
                    </td>
                    <td>
                        <b>Скидка, %</b>
                    </td>
                    <td>
                        <b>Продажа сумма, руб</b>
                    </td>
                </tr>
                {quota?.items.map(item =>
                    <tr key={item.id}>
                        <td>
                            {item.part_number}
                        </td>
                        <td>
                            {item.name}
                        </td>
                        <td>
                            {item.price} USD
                        </td>
                        <td>
                            {item.amount}
                        </td>
                        <td>
                            {item.total_price} USD
                        </td>
                        <td>
                            {item.total_price_rub} р
                        </td>
                        <td>
                            {item.discount} %
                        </td>
                        <td>
                            {item.total_price_sale_rub} р
                        </td>
                    </tr>
                )
                }
                <Row>
                    <Col >
                        <Button variant="outline-primary" style={{ width: 120 }} size="sm" onClick={() => { props.setViewQuota(false) }}>Закрыть</Button>
                    </Col>
                </Row>
            </tbody>
        </Table>
    );
}

export default QuotaDescription;