import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";
import ApiGatewayService from "../../API/ApiGatewayService";

function PatchAudienceForm({setPatchAudience, setSuccesspatch, successpatch, currentAudience}) {

    const [errors, setErrors] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [audienceDescription, setAudienceDescription] = useState(currentAudience?.description || '');
    const [name, setName] = useState(currentAudience?.name);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') saveAudience();

            })
            .catch(() => changeStatus())
    }


    const saveAudience = async event => {
        setIsPreloader(true);
        if (event) event.preventDefault();
        const audience = {};
        audience.name = name;
        audience.description = audienceDescription;

        await ApiGatewayService.patchAudience(audience, currentAudience.id)
            .then(() => { setPatchAudience(false); setIsPreloader(false); setSuccesspatch(!successpatch) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken('save');
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })


    }

    return (
        <Form id="form" onSubmit={saveAudience} className="was-validated" style={{position: "relative"}}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key={"danger" + error} variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}
            <h5 className="mb-4">Изменить аудиенцию</h5>
            <Row>
                <Col className=" col-8">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Название</Form.Label>
                        <Form.Control size="sm" type='text' value={name} onChange={(e) => setName(e.target.value)} required={true}>

                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
            <Col sm={8}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Описание</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={audienceDescription || ""}
                            size="sm"
                            onChange={(e) => setAudienceDescription(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>


            <Row >
                <Col className="mb-3 col-4" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            setPatchAudience(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Изменить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>

        </Form >
    );
}
export default PatchAudienceForm;
