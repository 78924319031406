import React, { useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import CopyIcon from '../../icons/copy.svg';
import SandboxService from "../../API/SandboxService";
function VerdictDetailCard({itemData,setViewVerdictDetail }) {
const [copyText, setCopyText] = useState(false);
const [currentItem, setCurrentItem]= useState(false);
const [data, setData] = useState(null)
useEffect(() => {

    (async () => {

       
            await SandboxService.getSandboxVerdictDetail(itemData?.file_hash).then((res)=> setData(res)).catch((err)=> console.log(err));
          
        }
    )();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [itemData]);

const copyBufferText = ({text, field}) => {
    navigator.clipboard.writeText(text)
        .then((res) => {

            setCopyText({text: 'Copy', field: field})
        })
        .catch(err => {
            setCopyText({text: 'Error', field: field});
        });
}

const changeDate = (date) => {
    if (date) {
        const formatDate = date?.split("T");
        const reverseDate =<>
            <span>{formatDate[0].split("-").reverse().join(".")}{' '}</span>
            <span>{formatDate[1].split(".")[0]}</span></>

        return reverseDate;
    } else return null;
};
const formatTstoDate=(ts)=> {
    var d = new Date(ts).toLocaleString('Ru-ru');

    return d
}

const basicStylesTitles = {
    fontSize: "smaller",
    width: "20%",
    textAlign: "right",
};

    return (
        <>
 <div style={{position: "relative", display: "flex", justifyContent: 'space-between', marginBottom: '5px'}}><h5>Verdict: {itemData?.verdict_fortisandbox || 'Analyzing'}</h5><Button variant="outline-primary" onClick={()=> setViewVerdictDetail(false)}>Закрыть</Button>
</div>
{data?.map((item)=> <Table bordered striped hover id="idT" style={{ marginBottom: 20}} key={itemData?.file_hash + item.checked_file_sha256}>
                
                <tbody>
                <tr>
                        <th style={basicStylesTitles}>Parent sha256</th>
                        <td title={item.checked_file_sha256 ||  ''}>
                           
                           <span> {'...'}{item?.checked_file_sha256.slice(-35)}{' '}
                           <img width="16" className="interactive_hover" alt={'copy'} src={CopyIcon} disabled onClick={
                                            () => {
                                                setCurrentItem(item);
                                                copyBufferText({text: item.checked_file_sha256, field: 'checked_file_sha256'});
                                                
                                           
                                            }
                                        } />{(copyText?.field==='checked_file_sha256') ?
                                         <span style={copyText.text === 'Ошибка' ? { color: "red" } : { color: "green" }}>{copyText.text}</span> 
                                         : <span></span>}
                           </span>
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>sha256</th>
                        <td title={item.sha256 ||  ''}>
                           
                        <span>  {'...'}{item?.sha256.slice(-35)}
                            {' '}
                           <img width="16" className="interactive_hover" alt={'copy'} src={CopyIcon} disabled onClick={
                                            () => {
                                                setCurrentItem(item);
                                                copyBufferText({text: item.sha256, field: 'sha256'});
                                                
                                           
                                            }
                                        } />{(copyText?.field==='sha256') ?
                                         <span style={copyText.text === 'Ошибка' ? { color: "red" } : { color: "green" }}>{copyText.text}</span> 
                                         : <span></span>}</span>  
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>sha1</th>
                        <td title={item.sha1 ||  ''}>
                           
                           <span>{'...'}{item?.sha1.slice(-35)}
                            {' '}
                           <img width="16" className="interactive_hover" alt={'copy'} src={CopyIcon} disabled onClick={
                                            () => {
                                                setCurrentItem(item);
                                                copyBufferText({text: item.sha1, field: 'sha1'});
                                                
                                           
                                            }
                                        } />{(copyText?.field==='sha1') ?
                                         <span style={copyText.text === 'Ошибка' ? { color: "red" } : { color: "green" }}>{copyText.text}</span> 
                                         : <span></span>}</span> 
                           
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>Start ts</th>
                        <td>
                            {formatTstoDate(item.start_ts)}   
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>Finish ts</th>
                        <td>
                        {formatTstoDate(item.finish_ts)} 
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>Rating</th>
                        <td>
                            {item.rating}
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>Score</th>
                        <td>
                            {item.score}
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>Category </th>
                        <td>
                           
                            {item.category}
                           
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>Rating source</th>
                        <td>
                                 {item?.rating_source}  
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>Malware name</th>
                        <td>
                           
                                 {item?.malware_name}
                            
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>Virus ID</th>
                        <td>
                           
                                 {item?.vid}
                            
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>Infected OS</th>
                        <td>
                           {item?.infected_os}
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>Detection OS</th>
                        <td>
                           {item.detection_os}
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>Untrusted</th>
                        <td>
                           {item.untrusted}
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>False positive / negative</th>
                        <td>
                           {item.false_positive_negative}
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>File name</th>
                        <td>
                           {item.file_name}
                        </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>Detail url</th>
                        <td title={item.detail_url ||  ''}>
                          <span> {'...'}{item.detail_url.slice(-35)}
                           {' '}
                           <img width="16" className="interactive_hover" alt={'copy'} src={CopyIcon} disabled onClick={
                                            () => {
                                                setCurrentItem(item);
                                                copyBufferText({text: item.detail_url, field: 'detail_url'});
                                                
                                           
                                            }
                                        } />{(copyText?.field==='detail_url') ?
                                         <span style={copyText.text === 'Ошибка' ? { color: "red" } : { color: "green" }}>{copyText.text}</span> 
                                         : <span></span>}</span>
                        </td>
                    </tr>
                  <tr>
                        <th style={basicStylesTitles}>Created</th>
                        <td>{changeDate(item.created_at)}
                                                   </td>
                    </tr>
                    <tr>
                        <th style={basicStylesTitles}>Updated</th>
                        <td>{changeDate(item.updated_at)}
                                                   </td>
                    </tr>
               
                </tbody>
            </Table>)}</>
    )
}

export default VerdictDetailCard;