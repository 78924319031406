import React, { useState, useEffect } from "react";
import WarehouseService from "../../API/WarehouseService";
import './Warehouse.css';
function WarehousePhoto({ images }) {

    const [indexPhoto, setIndexPhoto] = useState(0);
    const [imagesString, setImagesString] =useState('')
    const nextPhoto = () => {
        if (indexPhoto + 1 < images.length) {
            setIndexPhoto(indexPhoto + 1);
        } else setIndexPhoto(0)
    }
    const previusPhoto = () => {

        if (indexPhoto - 1 >= 0) {

            setIndexPhoto(indexPhoto - 1);
        } else setIndexPhoto(images.length - 1)
    }
    const getBase64Image = async res => {
        const blob = await res.blob();
    
        const reader = new FileReader();
    
        await new Promise((resolve, reject) => {
          reader.onload = resolve;
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
        return reader.result;
      };
    
      useEffect(() => {
          if (images)
{        fetch(`${images[indexPhoto].image}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
          .then(getBase64Image)
          .then(imgString => setImagesString(imgString));}
      }, [images, indexPhoto]);
  
            return (

                <div className='image__container'>
                    <p className="image__title">{indexPhoto + 1 + '/' + images.length}</p>
                    <div className="image__arrow_left" onClick={previusPhoto}></div>
                    <div className="image__wrapper"> <img className="image__photo" src={imagesString} alt="фото устройства" /> </div>
         
                    {/* {viewImage(images[indexPhoto].image)} */}
                    <div className="image__arrow_right" onClick={nextPhoto}></div>
                </div>

            )
        }
        export default WarehousePhoto;