import React, { useState, useEffect, useRef, useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import ImgRemove from "../../icons/remove.png";
import AddSandboxForm from "./AddSandboxForm";
import Auth from "../../API/Auth";
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import { AuthContext } from "../../context";
import Alert from "react-bootstrap/Alert";
import SandboxService from "../../API/SandboxService";
import Button from "react-bootstrap/esm/Button";
import Preloader from "../UI/Preloader/Preloader";
import PatchSandboxForm from "./PatchSandboxForm";
import ImgEdit from "../../icons/edit.png";
import MyModal from "../UI/MyModal/MyModal";
import help from "../../icons/help.svg";
import SandboxDetailCard from "./SandboxDetailCard";
import forti from '../../icons/fortinet.svg'
import palo from '../../icons/PANW.svg'
import kasper from '../../icons/kasper.svg'
function SandBoxList({}) {
    const [currentItem, setCurrentItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(false);
    const [switchActive, setSwitchActive] = useState(false);
    const [addSandbox, setAddSandbox] = useState(false);
    const [patchSandbox, setPatchSandbox] = useState(false);
    const [sandboxData, setSandboxData] = useState(null);
    const [dataCount, setDataCount] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [activeFilter, setActiveFilter] = useState(false);
    const [isPreloader, setIsPreloader] = useState(false);
    const [isAssetLoading, setIsAssetLoading] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [errors, setErrors] = useState([]);
    const [sandboxBalancerData, setSandboxBalancerData] = useState(null);
    const [sandboxBalancerDataQueue, setSandboxBalancerDataQueue] = useState(null);
    const [sandboxDataTable, setSandboxDataTable] = useState(null);
    const [renew, setRenew] = useState(false);
    const [viewDetail, setViewDetail] = useState(false);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const fetchData = async (add) => {
        let filters = false;
        if (searchText || activeFilter) {
            filters = {
                search: searchText,
                is_active: activeFilter,
            };
        }

        setIsPreloader(true);
        setIsAssetLoading(true);
        // await SandboxService.getSandboxesBalancer().then((resp)=> setSandboxBalancerData(resp));
        await SandboxService.getSandboxesList(filters, add ? nextPage : 1)
            .then((res) => {
                if (add === "add") {
                    res.results?.map((item) =>
                        setSandboxData((arr) => [...arr, item])
                    );
                    setNextPage(res.next ? nextPage + 1 : null);
                } else {
                    setSandboxData(res.results);
                    setNextPage(res.next ? 2 : null);
                    setDataCount(res.count);
                }
                // setNextPage(res.next ? nextPage + 1 : null);
                setIsAssetLoading(false);
            })
            .catch(() => changeStatus())
            .finally(() => setIsPreloader(false));
    };
    useEffect(() => {
        if (
            !deleteItem &&
            !switchActive &&
            !addSandbox &&
            !switchActive &&
            !patchSandbox
        ) {
            setIsPreloader(true);
            const timerRefresh = setTimeout(fetchData, 1000);
            return () => {
                clearTimeout(timerRefresh);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        activeFilter,
        searchText,
        deleteItem,
        addSandbox,
        switchActive,
        patchSandbox,
    ]);
    useEffect(() => {
        (async () => {
            setIsPreloader(true)
            await SandboxService.getSandboxesBalancer().then((resp) =>
               { setSandboxBalancerData(resp); }).catch((err)=> setErrors(err.message)).finally(()=> setIsPreloader(false))
            
            await SandboxService.getSandboxesBalancerQueue().then((resp) =>
            setSandboxBalancerDataQueue(resp)).catch((err)=> setErrors(err.message)).finally(()=> setIsPreloader(false))
        
        })();
    }, [renew]);
    useEffect(() => {
        if (sandboxData && sandboxBalancerData) {
            let arrFilesInQ = sandboxData?.map((item) => {
                return {
                    ...item,
                    files_in_queue: sandboxBalancerData.balancer_usage?.filter(
                        (it) => item.external_ip === it.wf500_addr
                    ),
                };
            });
            let arrFileProc = arrFilesInQ?.map((item) => {
                return {
                    ...item,
                    files_processed: sandboxBalancerData.files_processed?.filter(
                        (it) => item.external_ip === it.wf500_addr
                    ),
                };
            });
            let arrSandboxStatus = arrFileProc?.map((item) => {
                return {
                    ...item,
                    status: sandboxBalancerData.health_status?.filter(
                        (it) => item.external_ip === it.wf500_addr
                    ),
                };
            });
            let arrSandboxUnsupported = arrSandboxStatus?.map((item) => {
                return {
                    ...item,
                    unsupported: sandboxBalancerData.unsupported_files?.filter(
                        (it) => item.external_ip === it.wf500_addr
                    ),
                };
            });
            let arrResult = arrSandboxUnsupported?.map((item) => {
                return {
                    ...item,
                    files_in_queue:
                        item.files_in_queue?.length > 0
                            ? item.files_in_queue[0].files_in_queue
                            : false,
                    files_processed:
                        item.files_processed?.length > 0
                            ? item.files_processed[0].files_processed
                            : false,
                            unsupported:
                            item.unsupported?.length > 0
                                ? item.unsupported[0]
                                : false,
                    status: item.status?.length > 0 ? item.status[0] : false,

                };
            });
            setSandboxDataTable(arrResult);
        }
    }, [sandboxData, sandboxBalancerData]);
    const getStatus = (status, detail) => {
        let value = "";
        if (status === 200)
            value = (
                <div
                    style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        background: "lime",
                    }}
                ></div>
            );
        else if (status === "dead")
            value = (
                <div
                    style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        color: "red",
                    }}
                >
                    <div
                        style={{
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            background: "red",
                        }}
                    ></div>
                    <span>dead</span>{" "}
                    {detail ? (
                        <img
                            title={detail}
                            style={{ width: 20, height: 20, cursor: "pointer" }}
                            src={help}
                            alt="tooltip"
                        ></img>
                    ) : (
                        ""
                    )}
                </div>
            );
        else
            value = (
                <div
                    style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        color: "red",
                    }}
                >
                    <div
                        style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            background: "red",
                        }}
                    ></div>
                    <span>{status}</span>{" "}
                    {detail ? (
                        <img
                            title={detail}
                            style={{ width: 20, height: 20, cursor: "pointer" }}
                            src={help}
                            alt="tooltip"
                        ></img>
                    ) : (
                        ""
                    )}
                </div>
            );
        return value;
    };
    useEffect(() => {
        (async () => {
            if (needToLoad && nextPage) {
                await fetchData("add");
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    useEffect(() => {
        if (isAssetLoading) return;
        if (observer.current) observer.current.disconnect();
        var callback = function (entries, observer) {
            if (
                entries[0].isIntersecting &&
                nextPage &&
                sandboxData?.length > 0
            ) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);
    }, [isAssetLoading]);
    const dateStyles = {
        fontSize: "x-small",
    };
    // const tdStyle = {
    //     padding: '4px'
    // }

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem("freshToken"))
            .then((res) => {
                localStorage.setItem("token", res.data.access);
                setErrors([]);
                if (type === "delete") deleteAsset(currentItem.id);
            })
            .catch(() => changeStatus());
    };
    const deleteAsset = async (id) => {
        setIsPreloader(true);
        await SandboxService.deleteSandbox(id)
            .then(() => {
                setIsPreloader(false);
                setDeleteItem(false);
            })
            .catch((e) => {
                if (e.message[0].includes("token")) refreshToken("delete");
                else {
                    setIsPreloader(false);
                    setErrors(e.message);
                }
            });
    };
    const switchActivationAsset = async (item) => {
        const formData = new FormData();

        setIsPreloader(true);
        formData.append("is_active", !item.is_active);
        await SandboxService.switchActivateSandbox(item.id, formData)
            .then(() => {
                setIsPreloader(false);
                setSwitchActive(false);
            })
            .catch((e) => {
                if (e.message[0].includes("token")) refreshToken("put");
                else {
                    setIsPreloader(false);
                    setErrors(e.message);
                }
            });
    };

    return (
        <>
          {viewDetail && <MyModal visible={viewDetail} setVisible={setViewDetail}>
                <div style={{ width: '50vw', margin: '0 auto', maxHeight: '80vh', overflowY: 'scroll' }}>
                    <Row style={{ margin: 0 }}>

                        <h5 className="mb-3">Queue detail {currentItem.external_ip}</h5>
                    </Row>

                    <Row style={{ margin: 0 }}>
                        {<SandboxDetailCard data={sandboxBalancerDataQueue}
                            serial={currentItem.external_ip}
                        />}
                    </Row>

                </div>
            </MyModal>}
            {" "}
            {patchSandbox && (
                // <MyModal visible={patchSandbox} setVisible={setPatchSandbox}>
                    <PatchSandboxForm
                        item={currentItem}
                        setAddInstance={setPatchSandbox}
                    />
                // </MyModal>
            )}
            {switchActive && (
                <MyConfirm visible={switchActive} setVisible={setSwitchActive}>
                    <Row className="mb-4" style={{ position: "relative" }}>
                        {isPreloader && <Preloader leftCoord={true} />}
                        <h3 className="mb-4 mt-4">
                            Вы точно хотите{" "}
                            {currentItem.is_active === false
                                ? "активировать"
                                : "деактивировать"}{" "}
                            {<br />}устройство {currentItem.serial}?
                        </h3>
                    </Row>
                    <Row>
                        {" "}
                        {errors.length > 0 &&
                            errors.map((error) => (
                                <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>
                            ))}
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                type="submit "
                                style={{ width: "100%" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    switchActivationAsset(currentItem);
                                }}
                            >
                                Да
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSwitchActive(false);
                                    setErrors([]);
                                }}
                                style={{ width: "100%" }}
                            >
                                нет
                            </Button>
                        </Col>
                    </Row>
                </MyConfirm>
            )}
            {deleteItem && (
                <MyConfirm visible={deleteItem} setVisible={setDeleteItem}>
                    <Row className="mb-4" style={{ position: "relative" }}>
                        {isPreloader && <Preloader leftCoord={true} />}
                        <h3 className="mb-4 mt-4">
                            Вы точно хотите удалить доступ{<br />}{" "}
                            {currentItem.name}?
                        </h3>
                    </Row>
                    <Row>
                        {" "}
                        {errors?.length > 0 &&
                            errors?.map((error) => (
                                <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>
                            ))}
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                type="submit "
                                style={{ width: "100%" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    deleteAsset(currentItem.id);
                                }}
                            >
                                Да
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setDeleteItem(false);
                                    setErrors([]);
                                }}
                                style={{ width: "100%" }}
                            >
                                нет
                            </Button>
                        </Col>
                    </Row>
                </MyConfirm>
            )}
            <Row className="justify-content-between mt-3 mb-4">
                {!addSandbox && (
                    <Col className="col-2 mt-2 mb-2">
                        <Button
                            variant="outline-success"
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={(e) => {
                                setAddSandbox(true);
                            }}
                        >
                            Добавить
                        </Button>
                    </Col>
                )}
                {!addSandbox && (
                    <Col className="col-2 mt-2 mb-2">
                        <Button
                            variant="primary"
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={(e) => {
                                setRenew(!renew);
                            }}
                        >
                            Обновить
                        </Button>
                    </Col>
                )}
            </Row>
            {addSandbox && <AddSandboxForm setAddInstance={setAddSandbox} />}
            <Row
                style={{
                    margin: " 0 0 3px",
                    padding: "0 0 0 5px",
                    position: "relative",
                }}
                className="justify-content-between"
            >
                {isPreloader && <Preloader leftCoord={true} />}
                <Col className="col-3 mt-2 mb-2">
                    <b>Всего {dataCount} шт.</b>
                </Col>
            </Row>
            <hr style={{ margin: "5px 0" }} />
            <Row style={{ margin: " 0 0 3px", padding: "0 0 0 5px" }}></Row>
            {
                <div
                    style={{
                        position: "relative",
                        minHeight: "375px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div className="table-responsive">
                        <table
                            className="table align-middle table-striped table-bordered"
                            id="checkUrlResp"
                        >
                            <thead>
                                <tr style={dateStyles}>
                                    <th>Имя</th>
                                    <th>Внешний IP</th>
                                    <th>Внутренний IP</th>
                                    <th>Status</th>
                                    <th>Queue</th>
                                    <th>Files processed</th>
                                    <th>Поддерживаемые{<br/>}типы</th>
                                    <th>Unsupported<br/>today / total</th>
                                    <th>Описание</th>
                                    <th style={{ width: "10%" }}>
                                        Activate/ {<br />} Deactivate
                                    </th>
                                    <th style={{ width: "5%" }}>Изменить</th>
                                    <th style={{ width: "5%" }}>Удалить</th>
                                </tr>
                            </thead>

                            <tbody
                                className="table-group-divider"
                                id={"chDevExt"}
                            >
                                {sandboxDataTable?.map((item, index) => (
                                    <tr
                                        key={item.id + "sandUC"}
                                        style={dateStyles}
                                    >
                                        <td ><div style={{display: "flex", alignItems: "center", gap: 6}}> <img
                                                    width="16"
                                                    className="interactive_hover"
                                                    alt={"edit"}
                                                    src={item?.type==='palo_alto'? palo: item?.type==='forti'?forti: kasper}
                                                    disabled
                                                   
                                                />{item.name}</div></td>
                                        <td>{item.external_ip}</td>

                                        <td>{item.internal_ip}</td>
                                        <td>
                                           {(item.type!=='kaspersky'  && getStatus(
                                                item.status.status,
                                                item.status.detail
                                            )) || "-"}
                                        </td>
                                        <td>{item.files_in_queue==='0'?  item.files_in_queue
                                        : item.files_in_queue !== false && <div style={{display: "flex", gap: 5, alignItems: "center"}}><span style={{color: 'blue', cursor: 'pointer'}}>{item.files_in_queue}</span><Button onClick={()=> {setCurrentItem(item); setViewDetail(true)}}type="primary" style={{width: 30, height: 15, padding:0,  fontSize: 8}}>Detail</Button></div>}</td>
                                        <td>{item.files_processed || "-"}</td>
                                        <td>{item.supported_file_types?.split(',')?.map((type, ind)=> <span key={type}>{type}{ind===item.supported_file_types?.split(',')?.length-1?'': ', '}</span>) || "-"}</td>
                                        <td>{item.unsupported ?  item.unsupported.unsupported_today + ' / ' + item.unsupported.unsupported_total :  "-"}</td>
                                        <td>{item.description}</td>
                                        <td>
                                            {
                                                <Col className="col-2">
                                                    <div
                                                        className="form-check form-switch"
                                                        style={{ minHeight: 0 }}
                                                    >
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusChecks"
                                                            checked={
                                                                item.is_active ===
                                                                !true
                                                                    ? false
                                                                    : true
                                                            }
                                                            onChange={(e) => {
                                                                setSwitchActive(
                                                                    true
                                                                );
                                                                setCurrentItem(
                                                                    item
                                                                );
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexSwitchCheckChecked"
                                                        ></label>
                                                    </div>
                                                </Col>
                                            }{" "}
                                        </td>
                                        <td>
                                            <img
                                                width="20"
                                                className="interactive_hover"
                                                alt={"edit"}
                                                src={ImgEdit}
                                                disabled
                                                onClick={() => {
                                                    setCurrentItem(item);
                                                    setPatchSandbox(true);
                                                }}
                                            />
                                        </td>
                                        <td
                                            style={{
                                                cursor: "pointer",
                                                padding: "4px",
                                            }}
                                        >
                                            <img
                                                width="20"
                                                src={ImgRemove}
                                                alt="delete"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setDeleteItem(true);
                                                    setCurrentItem(item);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div ref={lastItem} style={{ height: 20 }} />
                </div>
            }
        </>
    );
}

export default SandBoxList;
