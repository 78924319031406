import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ThreatVaultCard from "./ThreatVaultCard";
import ThreatVaultCardCve from "./ThreatVaultCardCve";
import ThreatVaultService from "../../API/ThreatVaultService";
import MyModal from "../UI/MyModal/MyModal";
import ThreatVaultCardSha from "./ThreatVaultCardSha";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
function ThreatVaultArea() {

    const [errors, setErrors] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [name, setName] = useState('');
    const [type, setType] = useState('id');
    const [data, setData] = useState(null);
    const [dataView, setDataView] = useState(null)
    const [arrDataCve, setArrDataCve] = useState(null)
    const [arrDataSha, setArrDataSha] = useState(null)
    const [viewResponse, setViewresponse] = useState(false);
    const [perMinuteRemaining, setPerMinuteRemaining] = useState('');
    const [perDayRemaining, setPerDayRemaining] = useState('');
    const [stats, setStats] = useState(null)
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async (type, id) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'search') getSearchResult();
                if (type === 'item') getItemById(id);
            })
            .catch(() => changeStatus())
    }

    useEffect(() => {
        (async () => {
            setIsPreloader(true)
            await ThreatVaultService.getStats().then((res) => {
                setStats(res);
            }).catch((e) => {
                if (e.message[0].includes('token')) changeStatus()
                else setErrors(e.message)
            }).finally(() => setIsPreloader(false))
        })()
    }, [])

    const getSearchResult = async event => {
        setIsPreloader(true)
        if (event) event.preventDefault();
        if (type === 'id' || type === "cve") {
            await ThreatVaultService.getList(name, type).then((res) => {
                setErrors([]);
                setArrDataCve(res.result); setData(null);
                setArrDataSha(null)
                if (res.req_per_day_remaining !== null) setPerDayRemaining(res.req_per_day_remaining);
                if (res.req_per_minute_remaining !== null) setPerMinuteRemaining(res.req_per_minute_remaining);
            }).catch((e) => { setErrors(e.message); setArrDataCve(null); setArrDataSha(null); if (e.message[0].includes('token')) refreshToken('search') }).finally(() => setIsPreloader(false))
        }
        else {
            await ThreatVaultService.getListShaMd(name, type).then((res) => {
                setErrors([]);
                setArrDataSha(res.result); setData(null); setArrDataCve(null);
                if (res.req_per_day_remaining !== null) setPerDayRemaining(res.req_per_day_remaining);
                if (res.req_per_minute_remaining !== null) setPerMinuteRemaining(res.req_per_minute_remaining);
            }).catch((e) => { setArrDataCve(null); setErrors(e.message); setArrDataSha(null); if (e.message[0].includes('token')) refreshToken('search') }).finally(() => setIsPreloader(false))
        }
    }

    const getItemById = async (itemID) => {
        setIsPreloader(true)
        await ThreatVaultService.getList(itemID, 'id')
            .then((res) => {
                setDataView(res.result[0]); setErrors([]);
                if (res.req_per_day_remaining !== null) setPerDayRemaining(res.req_per_day_remaining);
                if (res.req_per_minute_remaining !== null) setPerMinuteRemaining(res.req_per_minute_remaining);
                setViewresponse(true)
            })
            .catch((e) => { setErrors(e.message); if (e.message[0].includes('token')) refreshToken('item', itemID) }).finally(() => setIsPreloader(false))
    }

    const changeSelectType = (value) => {
        const lowerCaseValue = value.toLowerCase().trim();

        if (!isNaN(lowerCaseValue)) setType('id');
        if (lowerCaseValue.includes('cve')) setType('cve');
        if (lowerCaseValue.length === 32) setType('md5');
        if (lowerCaseValue.length === 64) setType('sha256');

        setName(value)
    }

    return (
        <Col style={{ position: "relative" }}>
            {isPreloader && <Preloader />}
            {<MyModal visible={viewResponse} setVisible={setViewresponse}>
                <div style={{ width: '60vw', margin: '0 auto', maxHeight: '80vh', overflow: 'scroll' }}>
                    <Row >

                        <h5 className="mb-3">Детальная информация</h5>
                    </Row>

                    <Row>
                        {<ThreatVaultCard data={dataView}

                        />}
                    </Row>

                </div>
            </MyModal>}
            <Form id="form" onSubmit={getSearchResult} className="was-validated" >
                {isPreloader && <Preloader />}
                <Row className="mb-4 mt-2">
                    <Col className='col-6'><h3>THREAT VAULT</h3></Col>

                </Row>

                <hr />
                <Row>
                    {errors?.length > 0 && Array.isArray(errors) &&
                        errors.map(error =>
                            <Alert key="danger" variant="danger">
                                {error}
                            </Alert>
                        )
                    }
                    {errors?.length > 0 && !Array.isArray(errors) &&

                        <Alert key="danger" variant="danger">
                            {errors}
                        </Alert>

                    }
                </Row>
                <h5>Статистика по внутренней базе:</h5>
                <Row>
                    <Col className="col-2">
                        <b>Сигнатур:</b> {stats?.signature_count}
                    </Col>
                    <Col className="col-2">
                        <b>CVE:</b> {stats?.cve_count}
                    </Col>
                    <Col className="col-2">
                        <b>Hash:</b> {stats?.hash_count}
                    </Col>
                    <Col className="col-3">
                        <b>Обновлено:</b> {stats?.last_updated}
                    </Col>
                </Row>
                <hr />
                <Row className="mb-3 justify-content-start" style={{ margin: '0 auto' }}>
                    <Col className="col-3">

                        <Form.Group>
                            <Form.Label size="sm">Поле поиска</Form.Label>
                            <Form.Control size="sm" type="text" value={name || ""} placeholder={'38419 или CVE-2002-1310'} required
                                pattern={type === 'id' ? '[0-9]+' : '[0-9a-z-A-Z_-]+'}
                                onChange={(e) => { changeSelectType(e.target.value) }}
                            />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col className="col-2">
                        <Form.Group className="mb-4">
                            <Form.Label size="sm">Тип</Form.Label>
                            <Form.Select size="sm" id="type" value={type} onChange={(e) => setType(e.target.value)} >
                                <option value={'id'}>ID Сигнатуры</option>
                                <option value={'cve'}>Номер CVE</option>
                                <option value={'sha256'}>sha256</option>
                                <option value={'md5'}>md5</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col className="mb-3 col-2" >
                        <Form.Label size="sm"><br /></Form.Label>
                        <Form.Group className="mb-4">

                            <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit" >
                                Поиск
                            </Button><br />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <span>Осталось запросов в день: {perDayRemaining}</span>
                </Row>
                <Row>
                    <span>Осталось запросов в минуту: {perMinuteRemaining}</span>
                </Row>
            </Form>
            <hr />
            {arrDataCve && <ThreatVaultCardCve data={arrDataCve} getItemById={getItemById} />}
            {arrDataSha && <ThreatVaultCardSha data={arrDataSha} getItemById={getItemById} />}
            {data && <ThreatVaultCard data={data} />}


        </Col>

    )
}

export default ThreatVaultArea;