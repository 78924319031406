import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";
import PlatformService from "../../API/PlatformService";

function PatchPlatformLimit({currentPlatform, setPatchPlatform, platformPatch, setPlatformPatch}) {

    const [errors, setErrors] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [ipValue, setIpValue] = useState(currentPlatform?.edl_ip_limit);
    const [urlValue, setUrlValue] = useState(currentPlatform?.edl_url_limit);
    const [domValue, setDomValue] = useState(currentPlatform?.edl_domain_limit);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);


    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                patchPlatformsLimit()})
            .catch(() => changeStatus())
    }
    const patchPlatformsLimit = async (event) => {
        if (event) event.preventDefault();
        const platform={};
        setIsPreloader(true)
        platform.name = currentPlatform.name;
        platform.type = currentPlatform.type;
        platform.edl_ip_limit = ipValue;
        platform.edl_url_limit = urlValue;
        platform.edl_domain_limit = domValue;
        platform.id = currentPlatform.id
        await PlatformService.patchItem(platform).then(()=> {setPlatformPatch(!platformPatch);setPatchPlatform(false)}).catch((err)=> 
       { if (err.message[0].includes('token')) refreshToken();
        else { setErrors(err.message);setIsPreloader(false);}
        }).finally(()=> setIsPreloader(false))
    }

    return (
        <Form id="form" onSubmit={patchPlatformsLimit} className="was-validated" >
        {errors.length > 0 &&
            errors.map(error =>
                <Alert key="danger" variant="danger">
                    {error}
                </Alert>
            )
        }
        {isPreloader && <Preloader />}

        <h5 className="mb-4">{currentPlatform?.name}</h5>
        <Row  className="mb-4" >
            <Col sm={3}>
                <Form.Group>
                    <Form.Label size="sm">IP LIMIT</Form.Label>
                    <Form.Control size="sm" type="number" value={ipValue}
                        onChange={(e) => setIpValue(e.target.value)}
                    />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Form.Group>
            </Col>
            <Col sm={3}>
                <Form.Group>
                    <Form.Label size="sm">URL LIMIT</Form.Label>
                    <Form.Control size="sm" type="number" value={urlValue}
                        onChange={(e) => setUrlValue(e.target.value)}
                    />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Form.Group>
            </Col>
            <Col sm={3}>
                <Form.Group>
                    <Form.Label size="sm">DOMAIN LIMIT</Form.Label>
                    <Form.Control size="sm" type="number" value={domValue}
                        onChange={(e) => setDomValue(e.target.value)}
                    />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Form.Group>
            </Col>
        </Row>
        <Row >
            <Col className="mb-3 col-3" >
                <Form.Group className="mb-4"  >
                    <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                     
                        setPatchPlatform(false)
                    }}>
                        Отменить
                    </Button>
                </Form.Group>
            </Col>
            <Col className="mb-3 col-3" >
                <Form.Group className="mb-4">
                    <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                        Изменить
                    </Button><br />
                </Form.Group>
            </Col>
        </Row>
    </Form>
    );
}
export default PatchPlatformLimit;
