/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";
import ServiceService from "../../API/ServiceService";
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import AssetService from "../../API/AssetService";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function EditService(props) {

    const changeDate = (date) => {

        const newDate = date.replace(/\./g, '-').split('-');

        newDate.reverse();

        return newDate.join('-');
    }
    const [isPreloader, setIsPreloader] = useState(false);
    const [dateService, setDateService] = useState(changeDate(props?.currentService?.date_start) || '');
    const [durationService, setDurationService] = useState(props?.currentService?.duration || 12)
    const [errors, setErrors] = useState(null);
    const [serviceList, setServiceList] = useState([]);
    const [currentService, setCurrentService] = useState(props?.currentService || null);
    const [validService, setValidService] = useState(false);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {
        (async () => {
            setIsPreloader(true);
            let filters = false;
            if (filters) {
                filters.searchText = false;
            }
            await ServiceService.getServiceList(false, true).then((res) => setServiceList(res.results)).catch(() => changeStatus());

            setIsPreloader(false)
        })();
    }, []);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                patchService();
            })
            .catch(() => changeStatus())
    }

    const patchService = async event => {
        if (event) event.preventDefault();
        setIsPreloader(true);
        const item = {};
        item.date_start = dateService;
        item.duration = durationService;
        if (currentService.id !== props?.currentService.id) item.service = currentService.id;
        await AssetService.patchServiceAsset(props.id, props.currentService.id, item)
            .then(() => setTimeout(() => { props.setEditingService(false); setIsPreloader(false); }, 1500))
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })
    }

    return (
        <Form onSubmit={patchService} className='was-validated' style={{ position: 'relative' }}>
            {isPreloader && <Preloader />}
            <hr />
            {errors !== null && <Alert key="danger" variant="danger">{errors}</Alert>}
            <Row >
                <h5 className="mb-3">Изменить сервис</h5>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Дата начала сервиса</Form.Label>
                        <Form.Control
                            value={dateService}
                            onChange={(e) => setDateService(e.target.value)}
                            size="sm" type="date"
                            required={true} />
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group controlId="catDiscountField" >
                        <Form.Label>Срок мес.</Form.Label>
                        <Form.Control required={true} size="sm" type="integer" value={durationService} placeholder="Введите.." onChange={(e) => setDurationService(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group>
                </Col>

                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Сервис</Form.Label>

                        <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-assetService" >
                                Сервис: {currentService?.description || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assetEditService'}
                                filterQa={true}
                            >
                                {serviceList?.map(service =>
                                    <Dropdown.Item eventKey={"Service" + service.id} key={"Service" + service.id} onClick={() => { setCurrentService(service); setValidService(true) }}>{service.description}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
            </Row>
            <Row>

            </Row>
            <Row>
                <Col sm={2}>
                    <Button variant="outline-primary" size="sm" type="Servicemit " style={{ width: '100%' }}>
                        Изменить
                    </Button>

                </Col>
                <Col sm={2}>
                    <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); props.setEditingService(false) }} style={{ width: '100%' }}>
                        Отменить
                    </Button>
                </Col> </Row>
            <hr />
        </Form>

    );
}
export default EditService;