/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import PanSummaryDoughnut from "./PanSummaryDoughnut";
import Preloader from "../UI/Preloader/Preloader";
import KfeedSummaryDoughnut from "./KfeedSummaryDoughnut";
function KfeedSummary({ data, isLoading }) {
    const appletStyle = {
        border: "1px solid #0d6efd",
        borderRadius: "10px",
        position: "relative",
        boxSizing: "border-box",

        WebkitBoxSizing: "border-box",
        minHeight: "300px",
    };

    return (
        <>
            <Col style={appletStyle} className="col-6">
                {isLoading &&  <Preloader/>}
                <div style={{ display: "flex", opacity: isLoading? 0.1: 1 }}>
                   
                    {/* <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>Всего урлов: {data?.total_cnt}</span>
                        <span>Вредоносные: {data?.bad_cnt}</span>
                        <span>Потенциально опасные: {data?.pot_bad_cnt}</span>
                        <span>Остальные: {data?.other_cnt}</span>
                    </div> */}
                    {data && <KfeedSummaryDoughnut data={data}/>}
                </div>
            </Col>
        </>
    );
}
export default KfeedSummary;
