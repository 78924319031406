import React, { useState, useEffect, useRef, useContext } from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Table from 'react-bootstrap/Table';
import AddCloudForm from "./AddCloudForm";
import ServiceService from "../../API/ServiceService";
import ImgEdit from '../../icons/edit.png';
import Preloader from "../UI/Preloader/Preloader";
import MyModal from "../UI/MyModal/MyModal";
import EditCloudForm from "./EditCloudForm";
import ImgRemove from '../../icons/remove.png';
import Alert from 'react-bootstrap/Alert';
import { AuthContext } from "../../context";

const CloudList = (props) => {
    const [addCloud, setAddCloud] = useState(false);
    const [editCloud, setEditCloud] = useState(false);
    const [cloudList, setCloudList] = useState([]);
    const [currentCloud, setCurrentCloud] = useState({});
    const [cloudAmount, setCloudAmount] = useState(0)
    const [isPreloader, setIsPreloader] = useState(false);
    const [succesDelete, setSuccessDelete] = useState(false);
    const [errors, setErrors] = useState([]);

    //пагинация 
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [isCloudLoading, setIsCloudLoading] = useState(false);
    const [nextPage, setNextPage] = useState(null);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const basicStyles = {
        "fontSize": 'smaller',
    }

    useEffect(() => {
        (async () => {
            setNextPage(null)
            setIsCloudLoading(true)
            setIsPreloader(true);
            let filters = false;
            if (filters) {
                filters.searchText = false;
            }
            await ServiceService.getCloudList().then((res) => {
                setCloudList(res.results);
                setCloudAmount(res.count)
            }).catch(() => changeStatus());

            setIsCloudLoading(false)
            setIsPreloader(false)
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addCloud, editCloud, succesDelete]);
    useEffect(() => {

        if (isCloudLoading) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCloudLoading]);


    useEffect(() => {

        (async () => {

            if (needToLoad) {

                setIsPreloader(true);
                setIsCloudLoading(true);
                await ServiceService.getCloudList(nextPage).then((res) => {
                    res.results?.map(item => setCloudList(arr => [...arr, item]));
                    setNextPage(res.next);
                }).finally(() => setIsPreloader(false));

                setIsCloudLoading(false);
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    const removeCloud = async (cloudId) => {

        await ServiceService.deleteCloud(cloudId).then(() => { setSuccessDelete(!succesDelete); setErrors([]) }).catch((err) => setErrors(err.message))
    }

    return (
        <>                  {errors.length > 0 &&
            errors.map(error =>
                <Alert key="danger" variant="danger">
                    {error}
                </Alert>
            )
        }
            <Row className="mb-3">
                <h5>Облака (всего {cloudAmount})</h5>
            </Row>
            <Row>
                <Col className="mb-3 col-2">
                    <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                        onClick={() => setAddCloud(true)}

                    >Добавить облако</Button>
                </Col>
            </Row>

            {addCloud === true && <AddCloudForm setAddCloud={setAddCloud} addCloud={addCloud} />}
            {editCloud === true &&
                <MyModal visible={editCloud} setVisible={setEditCloud}>

                    <EditCloudForm setEditCloud={setEditCloud} editCloud={editCloud} currentCloud={currentCloud} />

                </MyModal>}
            <Table striped bordered hover style={{ position: 'relative' }}>


                <thead style={basicStyles}>
                    <tr>
                        <th >ID</th>
                        <th>Адрес</th>
                        <th >SLUG</th>
                        <th >Описание</th>
                        <th >Типы обновлений </th>
                        <th >Изменить </th>
                    </tr>
                </thead>
                <tbody>
                    {cloudList?.map(item => (
                        <tr style={basicStyles} key={'cloud' + item.id}>

                            <td >{item.id}

                            </td>
                            <td>{item.address}</td>
                            <td>{item.slug}</td>
                            <td>{item.description}</td>
                            <td>{item.update_type}</td>

                            <td>
                                <img width="20" className="interactive_hover" src={ImgEdit} alt={'edit'} disabled onClick={
                                    () => {
                                        setEditCloud(true);
                                        setCurrentCloud(item)
                                    }
                                } />
                            </td>
                            <td> <img width="20" className="interactive_hover" alt="change" src={ImgRemove} disabled onClick={
                                () => {

                                    removeCloud(item.id)

                                }
                            } /> </td>
                        </tr>))}
                    <tr ref={lastItem} style={{ height: 20 }} />
                </tbody>
            </Table>
            {isPreloader && <Preloader />}
        </>)
}

export default CloudList;