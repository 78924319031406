import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Table from 'react-bootstrap/Table';
import ImgDelete from '../../icons/delete.svg';
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import Preloader from "../UI/Preloader/Preloader";
import ImgNotes from '../../icons/notes.svg';
import IconEdit from '../../icons/edit.svg';
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";
import Alert from 'react-bootstrap/Alert';
import PimService from "../../API/PimService";
import AddPimDocForm from "./AddPimDocForm";
import PatchPimDocForm from "./PatchPimDocForm";
import AddPimCompatibleForm from "./AddPimCompatibleForm";
import PatchPimCompatibleForm from "./PatchPimCompatibleForm";


const PimCompatibleList = () => {
    const [patchDocument, setPatchDocument] = useState(false);
    const [data, setData] = useState(null)
    const [errors, setErrors] = useState([]);
    const [addDocument, setAddDocument] = useState(false);
    const [successAdd, setSuccessAdd] = useState(false);
    const [successPatch, setSuccessPatch] = useState(false);
    const [currentInstance, setCurrentInstance] = useState(null);
    const [isPreloader, setIsPreloader] = useState(false);
    const [deleteItem, setDeleteItem] = useState(false);
    const [successDelete, setSuccessDelete] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const basicStyles = {
        "fontSize": '14px',
        cursor: 'pointer'
    }
    const fetchData=async(add)=> {
        setIsPreloader(true)
            await PimService.getPimCompatibleList(add ? nextPage : 1).then((res) => { 
                if (add === 'add') {setNextPage(res.next ? nextPage + 1 : null);
                    res.results?.map(item => setData(arr => [...arr, item]));}

                    else {
                        setData(res.results);
                        setNextPage(res.next ? 2 : null);
                    } }).catch((err) => { setErrors(err.message) }).finally(() => { setIsPreloader(false); })
    }
    useEffect(() => {
fetchData()
    }, [successAdd, successDelete, successPatch])
    useEffect(() => {

        if (isPreloader) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPreloader]);
    useEffect(() => {

        ( () => {

            if (needToLoad && nextPage) {
               
                fetchData('add');
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);
    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') deleteInstance();

            })
            .catch(() => changeStatus())
    }

    const deleteInstance = async (id, type) => {
        setIsPreloader(true);
        await PimService.deletePimCompatible(id)
            .then(() => { setIsPreloader(false); setDeleteItem(false); setSuccessDelete(!successDelete) })
            .catch((e) => {
                if (e.message[0].includes('token')) refreshToken('delete');
                else { setIsPreloader(false); setErrors(e.message); }
            });
    }

    return (
        <>
            {deleteItem?.open && <MyConfirm visible={deleteItem?.open} setVisible={setDeleteItem} >
                <div style={{width: 700}}>
                <Row className="mb-4" style={{ position: "relative",}}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4" >Вы точно хотите удалить совместимость?</h3>
                </Row>
                <Row > {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row >
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); deleteInstance(deleteItem.id ) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeleteItem(false); setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row></div>
            </MyConfirm>}
            <Row style={{ position: "relative" }} className="mt-5">
                {isPreloader && <Preloader />}
               
            </Row>
            {addDocument && <AddPimCompatibleForm open={setAddDocument} setSuccessAdd={setSuccessAdd} successAdd={successAdd} />}
            {patchDocument && <PatchPimCompatibleForm open={setPatchDocument} setSuccessAdd={setSuccessAdd} successAdd={successAdd} itemData={currentInstance} />}
            <hr />
                <Col style={{boxSizing: "border-box"}}>
               <div style={{display: "flex", justifyContent: "space-between", marginBottom: 5}}> <span >Совместимость</span>
               <Button variant="outline-success" size="sm" style={{ fontSize: '1rem',  }}
                        onClick={() => { setAddDocument(true) }}
                    >Добавить</Button>
               </div>
               <div style={{maxHeight: 400, overflowY: "scroll"}}>
            <Table striped bordered hover id='tableTokens' >
                <thead style={basicStyles}>
                    <tr>
                        <th style={{width: '10%'}}>Сервер</th>
                        <th style={{width: '10%'}}>Агент</th>
                        <th>Описание</th>
                        <th style={{width: '10%'}}></th>
                     
                       

                    </tr>
                </thead>
                <tbody>

                    {data?.map((item, index) =>
                        <tr key={item.id + 'compability'}
                            style={basicStyles}
                        >
                        <td >{item.server_version}</td>
                        <td>{item.client_compatible_since}</td>
                        <td>{item.description.split('*')?.map((des, index)=> <div key={des + index}>{des}</div>)}</td> 
                        <td>
                        <div  style={{marginBottom: 5}}>
                            {/* <img width="14"  style={{marginRight: 5,}}className="interactive_hover" alt={'download'} src={ImgDownload} onClick={
                                (evt) => {

                                    evt.stopPropagation();
                                  
                                    downloadFile(item)
                                }
                            } /> */}
                            <img width="14" style={{marginRight: 5,}}className="interactive_hover" alt={'edit'} src={IconEdit} onClick={
                                (evt) => {

                                    evt.stopPropagation();
                                    setPatchDocument(true);
                                    setCurrentInstance(item)

                                }
                            } /><img width="14" className="interactive_hover" alt={'delete'} src={ImgDelete} onClick={
                                (evt) => {

                                    evt.stopPropagation();
                                   setDeleteItem({open: true,  id: item.id})

                                }
                            } /></div>

                        </td>

                           
                        </tr>
                    )
                    }
                </tbody>
            </Table>
            <tr ref={lastItem} style={{ height: 20 }} ></tr>
            </div>
            </Col>
        </>
    )
}

export default PimCompatibleList
