import React, { useState } from "react";

import Row from "react-bootstrap/esm/Row";
import Alert from 'react-bootstrap/Alert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PlatformStats from "./PlatformStats";
import AllDataFeedTable from "./allDataFeedTable";

function CheckPlatform({errors, platform, renewFunction}) {

    const titleStyles = {
        'textAlign': "center",
        'margin': '0 0 16px',
        'padding': '0',
        'fontSize': '20px',
        'backgroundColor': 'rgb(13, 110, 253, .2)'
    }
    const [firstKey, setFirstKey] = useState('active');
   
    return (
        <>
            <Row style={{margin:' 0 0 ', padding: 0}}>
                <h4 style={titleStyles}>STATISTIC</h4>
                <hr />
            </Row>
            {errors?.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }

            <Tabs
                style={{ fontSize: 15, borderColor: "black" }}
                id="subsId"
                activeKey={firstKey}
                onSelect={(key) => setFirstKey(key)}

                className="mb-3 nav nav-pills nav-fill"
            >

                <Tab eventKey="active" title="Активные" >
                    {firstKey === 'active' && <>
                        {platform?.active?.length > 0 ?  <PlatformStats  platform={platform?.active} renewFunction={renewFunction}/> : <p><b>Ничего не найдено</b></p>}
                    </>}
                </Tab>
                <Tab eventKey="inactive" title="Неактивные" >
                    {firstKey === 'inactive' && <>
                        {platform?.inactive?.length > 0 ?  <PlatformStats platform={platform?.inactive} renewFunction={renewFunction}/> : <p><b>Ничего не найдено</b></p>}
                    </>}
                </Tab>
                <Tab eventKey="kfeed" title="KFEED" >
                    {firstKey === 'kfeed' && <>
                        { <AllDataFeedTable renewFunction={renewFunction}/>}
                    </>}
                </Tab>
            </Tabs>
        </>
    )
}

export default CheckPlatform;