import React from "react";
import Row from "react-bootstrap/esm/Row";
import Table from 'react-bootstrap/Table';
import { useNavigate } from "react-router-dom";


const OrgPartner = ({partners}) => {
    const navigate = useNavigate();

    const basicStyles = {
        "fontSize": 'smaller',
    }

    return (
        <>         
            <Row className="mb-3">
                <h5>Партнеры (всего {partners?.length})</h5>
            </Row>
           
            <Table striped bordered hover style={{ position: 'relative' }}>


                <thead style={basicStyles}>
                    <tr>
                        <th >ID</th>
                        <th>Организация</th>
                        <th >Кол-во контрактов</th>
                    </tr>
                </thead>
                <tbody>
                    {partners?.map(item => (
                        <tr style={basicStyles} key={'orgPart' + item.contract_partner_org_id} onClick={()=>navigate(`/orglist/${item.contract_partner_org_id}`)}>

                            <td >{item.contract_partner_org_id}

                            </td>
                            <td>{item.contract_partner_org__name}</td>
                            <td>{item.contracts_amount}</td>

                        </tr>))}
                </tbody>
            </Table>
        </>)
}

export default OrgPartner;