import React from 'react'
import './Preloader.css'

const PreloaderTableCenter= () => {
  
    return (
        <div className="preloaderTable" >
            <div className="preloader__container">
                <span className="preloader__round"></span>
            </div>
        </div>
    )
};

export default PreloaderTableCenter;
