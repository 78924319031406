import Api from '.';

export default class OrgService {

        static async getList(filters, nextpage, all) {

        var url = `api/v1/organisation/`
        const method = 'GET';
        const params = {};
        params.limit = 20
        if (filters) {
            if (filters.rateFilter) {
                params.rating = filters.rateFilter;
            };
            if (filters.searchText) {
                params.search = filters.searchText;
            };
            if (filters.type) {
                params.type = filters.type;
            };
        };
        
        if (nextpage) {
        params.page = nextpage;
        }
        if (all) {
            params.page = 'all';
            params.limit = null
            }
        const api = new Api({url: url, headers: {}, params: params});
        const response = await api.request(method);
        return response.data;
    }
    static async getDropList(filters) {

        const url = 'api/v1/organisation/dropdown/';
        const method = 'GET';
const params={}
if (filters?.type) {
    params.type = filters.type;
};
        const api = new Api({url: url, headers: {}, params: params});
        const response = await api.request(method);
        return response.data;
    }
    static async getPermissions() {

        const url = 'api/v1/organisation/permission-sections/';
        const method = 'GET';
const params={}

        const api = new Api({url: url, headers: {}, params: params});
        const response = await api.request(method);
        return response.data;
    }

    static async my() {

        const url = 'api/v1/organisation/me/';
        const method = 'GET';

        const api = new Api({url: url});
        const response = await api.request(method);
        return response.data;
    }
    static async getStats() {

        const url = 'api/v1/organisation/stats/';
        const method = 'GET';

        const api = new Api({url: url});
        const response = await api.request(method);
        return response.data;
    }

    static async getItem(id) {

        const url = `api/v1/organisation/${id}/`;
        const method = 'GET';

        const api = new Api({url: url});
        const response = await api.request(method);
        return response.data;
    }

    static async newOrg(vat, id, org) {

        const url = 'api/v1/organisation/';
        const method = 'POST';
        const data = org;

        const api = new Api({url: url});
        const response = await api.request(method, data);
 
        return response.data;
    }

    static async delete(id) {

        const url = `api/v1/organisation/${id}/`;
        const method = 'DELETE';

        const api = new Api({url: url});
        const response = await api.request(method);
        return response.data;
    }
    static async patchOrg(vat, id, org, slug) {
        const url  = `api/v1/organisation/${id}/`;
        const data ={};
        if (vat!==false) data.vat = vat;
        if (org!==false) {
            data.name=org.name;
            data.vat=org.vat;
            data.slug=org.slug;
            data.website=org.website;
            data.permissions = org.permissions
        }
        if(slug) data.slug=slug;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
       
        return response.data;
    }

}