import React from "react";
import { useLocation } from "react-router-dom";

const MyToggle = React.forwardRef(({ children, onClick, disabled, size }, ref) => {

  const location= useLocation();

  return (
    <button
    id ={location.pathname.includes('/contract') || location.pathname.includes('asset') || location.pathname.includes('quota')? "contract" : ''}
      href="#none"
      style={{background: 'none', border: 'none', fontSize: size==='min'? '10px' : '16px'}}
      ref={ref}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      {/* &#x25bc; */}
    </button>
)});

export default MyToggle;