import React, { useContext } from "react";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { UserContext } from "../../context";

const SideBar = function () {
  const {userinfo} = useContext(UserContext);
  return (
    <Col className="col-2" style={{width: '13%'}}>
      <ul className="nav flex-column">
        {userinfo.role!== 'MANAGER'&& <li className="nav-item">
          <Link disabled className="nav-link" to="/dashboard">
            Главная
          </Link>
        </li>}
        <hr style={{width: '60%', opacity: '1'}}/>
        <li className="nav-item">
          <Link className="nav-link" to="/price">
            Прайс-лист
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/quotator">
            Квотатор
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contract">
            Договора
          </Link>
        </li>
       { userinfo.role!== 'MANAGER'&&  <li className="nav-item">
          <Link className="nav-link" to="/warehouse">
            Склад
          </Link>
        </li>}
        <hr style={{width: '60%', opacity: '1'}}/>
        {userinfo.role!== 'MANAGER'&& <li className="nav-item">
          <Link className="nav-link" to="/asset">
            Устройства
          </Link>
        </li>}
        {userinfo.role!== 'MANAGER'&& <li className="nav-item">
          <Link className="nav-link" to="/orglist">
            Организации
          </Link>
        </li>}
        {userinfo.role!== 'MANAGER'&& <li className="nav-item">
          <Link className="nav-link" to="/users">
            Пользователи
          </Link>
        </li>}
        {userinfo.role!== 'MANAGER'&&  <hr style={{width: '60%', opacity: '1'}}/>}
         {userinfo.role!== 'MANAGER'&& <li className="nav-item">
          <Link className="nav-link" to="/urlfilter">
            URL FILTERING
          </Link>
        </li>}
        {userinfo.role!== 'MANAGER'&& <li className="nav-item">
          <Link className="nav-link" to="/kfeed">
            KFEED
          </Link>
        </li>}
        {userinfo.role!== 'MANAGER'&&  <li className="nav-item">
          <Link className="nav-link" to="/edl">
           DTL
          </Link>
        </li>}
        {userinfo.role!== 'MANAGER'&&  <li className="nav-item">
          <Link className="nav-link" to="/sandbox">
            SANDBOX
          </Link>
        </li>}
        {userinfo.role!== 'MANAGER'&&  <li className="nav-item">
          <Link disabled className="nav-link" to="/gateway">
            API GW
          </Link>
        </li>}
        {userinfo.role!== 'MANAGER'&& <li className="nav-item">
          <Link className="nav-link" to="/updates_cloud">
            UPDATES CLOUD
          </Link>
        </li>}
        {userinfo.role!== 'MANAGER'&&  <li className="nav-item">
          <Link className="nav-link" to="/threatvault">
            Threat Vault
          </Link>
        </li>}
        {userinfo.role!== 'MANAGER'&& <li className="nav-item">
          <Link className="nav-link" to="/cloud_status">
            Cloud Status
          </Link>
        </li>}
        {userinfo.role!== 'MANAGER'&& <li className="nav-item">
          <Link className="nav-link" to="/pim">
            PIM
          </Link>
        </li>}
        {userinfo.role!== 'MANAGER'&& <hr style={{width: '60%', opacity: '1'}}/>}
        {userinfo.role!== 'MANAGER'&& <li className="nav-item">
          <Link className="nav-link" to="/service">
            Сервисы
          </Link>
        </li>}
        {userinfo.role!== 'MANAGER'&& <li className="nav-item">
          <Link className="nav-link" to="/documentation">
            Документация
          </Link>
        </li>}
      </ul>
    </Col>
  );
};
export default SideBar;
