import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import HypervisorService from "../../API/HypervisorService";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function HypervisorEditForm(props) {

    const [errors, setErrors] = useState([]);
    const [visorType, setVisorType] = useState(props?.visor?.hv_type);
    const [visorVersion, setVisorVersion] = useState(props?.visor?.hv_version);
    const [visorDescription, setVisorDescription] = useState(props?.visor?.description || "");
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                editVisor();
            })
            .catch(() => changeStatus())
    }
    const editVisor = async event => {
        if (event) event.preventDefault();
        const visor = {};
        visor.hv_type = visorType;
        visor.hv_version = visorVersion;
        visor.description = visorDescription;
        await HypervisorService.patchVisor(visor, props.visor?.id)
            .then(() => { setTimeout(() => { props.setVisible(false); props.setEditHyp(!props.hypEdit); setErrors(null) }, 500) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message);
                }
            })
    }

    return (
        <Form
            onSubmit={editVisor}
            className='was-validated'
        >
            {errors.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Form.Group className='mb-4'>
                <Form.Label>Тип гипервизора</Form.Label>
                <Form.Control type="text" required={true} value={visorType} onChange={(e) => setVisorType(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Версия гипервизора</Form.Label>
                <Form.Control type="text" required={true} value={visorVersion} onChange={(e) => setVisorVersion(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Описание (опционально)</Form.Label>
                <Form.Control type="text" value={visorDescription} onChange={(e) => setVisorDescription(e.target.value)} />
            </Form.Group>
            <Row>
                <Col className="mb-3 col-4">
                    <Button variant="primary" type="submit">
                        Изменить
                    </Button></Col>
                <Col className="mb-3 col-4">
                    <Button variant="outline-danger" type="button" onClick={() => props.setVisible(false)}>
                        Отменить
                    </Button></Col></Row>
        </Form>
    );
}
export default HypervisorEditForm;