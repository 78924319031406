import React from "react";
import Form from 'react-bootstrap/Form';

const MySelect = (props) => {


    function changeHandler(event) {

        if (props.platformtypes) {
            props.setPlatformTypeFilter(event.target.value);
            props.setCurPlatformType(event.target.value);
        }
        if (props.platforms) {
            props.setPlatformFilter(event.target.value);
            props.setCurPlatform(event.target.value);
        }
        if (props.categories) {
            props.setCategoryFilter(event.target.value);
            props.setCurCategory(event.target.value);
        }
        if (props.quotaStatuses) {
            props.setStatusFilter(event.target.value);
            props.setCurQuotaStatusFilter(event.target.value);
        }
        if (props.partnerorganizations) {
            props.setPartnerFilter(event.target.value);
            props.setCurPartnerFilter(event.target.value);
        }
        if (props.organizations) {
            props.setEndUserFilter(event.target.value);
            props.setCurEndUserFilter(event.target.value);
        }
    }

    return (
        <Form.Select size="sm" onChange={changeHandler} value={props.value} >
            {(props.platforms) &&
                <option value="">Все платформы</option>
            }
            {
                props.platforms?.map(platform =>
                    <option key={platform.name} value={platform.name}>{platform.name}</option>
            )}
            {(props.categories) &&
                <option value="">Все категории</option>
            }
            {
                props.categories?.map(category =>
                    <option key={category.name} value={category.name}>{category.name}</option>
            )}
            {(props.platformtypes) &&
                <option value="">Все типы</option>
            }
            {
                props.platformtypes?.map(platformtype =>
                    <option key={platformtype.name} value={platformtype.name}>{platformtype.name}</option>
            )}

            {(props.quotaStatuses) &&
                <option value="">Все статусы</option>
            }
            {   
                props.quotaStatuses?.map(status =>
                    <option key={status.value} value={status.value}>{status.name}</option>
            )}
            {(props.partnerorganizations) &&
                <option value="">Все партнеры</option>
            }
            {   
                props.partnerorganizations?.map(org =>
                    <option key={org.id} value={org.name}>{org.name}</option>
            )}
            {(props.organizations) &&
                <option value="">Все заказчики</option>
            }
            {   
                props.organizations?.map(org =>
                    <option key={org.id} value={org.name}>{org.name}</option>
            )}
        </Form.Select>
    );
}

export default MySelect;