import React, { useState } from "react"
import styles from "./MultiSelect.module.css"
import { Dropdown } from "react-bootstrap";

function MultiSelect({data, selectedScopes, setSelectedScopes, disabled})
{
    return (
        <>
            <Dropdown autoClose="outside" disabled={true}>
                <Dropdown.Toggle className={styles.input} style={{
                    display: "flex",
                    gap: 8,
                    backgroundColor: "white",
                    borderColor: disabled? 'grey' : selectedScopes?.length>0 ?"green" : 'red',
                    color: "black",
                    width: "100%",
                    alignItems: "center",
                    overflow: "hidden",
                    height: '31px',
                  
                }}
                disabled={disabled}>
                    <span style={{ padding: "0.3em 0" }}>{selectedScopes?.length>0? '': 'Выбрать'}</span>
                    {selectedScopes?.map(el => {return <span style={{
                        border: "1px solid #216efb",
                        borderRadius: "4px",
                        padding: "2px",
                        fontSize:'8px'
                    }} key={el + 'scope'}>{data?.find(v => v.id === el).name.slice(0,18)}</span>})}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {data?.map(el =>
                       {  return <Dropdown.Item
                            key={el.id}
                            style={{ display: "flex", alignItems: "center" }}
                            onClick={() => setSelectedScopes(v =>
                            {
                                const i = v.indexOf(el.id);
                                if (i >= 0)
                                {
                                    const r = [...v];
                                    r.splice(i, 1);
                                    return r;
                                }
                                return [...v, el.id];
                            })}
                        >
                            <span style={{
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "1em",
                                height: "1em",
                                marginRight: "0.2em",
                                border: "2px solid #216efb",
                                borderRadius: "0.2em",
                                backgroundColor: selectedScopes?.includes(el.id) ? "#216efb" : "white",
                            }}>
                                <div style={{
                                    width: "0.4em",
                                    height: "0.4em",
                                    backgroundColor: "white",
                                    borderRadius: "0.2em",
                                }}></div>
                            </span>
                            <span>{el.name}</span>
                        </Dropdown.Item>}
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

export default MultiSelect;