import React, { useEffect, useRef } from "react";

import { ArcElement, Chart as ChartJS, Legend, Title, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { numberWithSpaces } from "./utils";
import { Colors } from 'chart.js';
export default function PanBadCatDonut({data}) {
    ChartJS.register(ArcElement, Tooltip, Legend, Title, Colors);
let TOTAL = 0
    const chartDonut = {
        width: '100%',
        height: '330px',
        display: 'flex',
        /* justify-content: center; */
        position: 'relative',
        flexDirection: 'column',
        backgroundColor: '#fff',
        padding: '0px',
        borderRadius: '8px'
    }
    const dataCircle =  {
        position: 'absolute',
        top: '50%', 
        left:' 50%' ,
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '124px',
        height: '124px',
        borderRadius: '50%',
        background: '#fff',
        color: '#4b4f55',
        //font-variant-numeric: lining-nums proportional-nums;
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
      
        fill:'#FFF',
    filter: 'drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.10))'
    }

    const circleSubtitle = {
        color: '#989aa5',
        textAlign: 'center',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 500,
       
        letterSpacing: '0.2px',
        textTransform:'uppercase',
    }
    let ref = useRef(null);

    const COLORS = {
        "Malware": "#820014",
        "Grayware": "#ff7875",
        "Phishing": "#d3adf7",
        "Ransomware": "#ffbb96",
        'Command and Control': "#ffd591",
        "Scanning Activity": "#ff85c0",
    };
const getData=(obj)=> {
    let arr=  []
   if (obj) {for (let [k, v] of Object.entries(obj)) {
    if (k!=='total_cnt'){
        let index = 0
        if (k==='pot_bad_cnt') index = 2;
        else if (k==='other_cnt') index = 1
        arr.push({count: v, type_modification: index})
      }}}
     
      return arr
}
    const chartData = {
        labels: data?.map((v) => {TOTAL+=v.count; return v.cat_name}) || [],
        datasets: [
            {
                data: data?.map((v) => v.count) || [],
                backgroundColor: (context) => {
                    const index = context.dataIndex;
                    return COLORS[chartData.labels[index]];
                },
                borderWidth: 5,
                hoverBorderWidth: -5,
            },
        ],
    };
    const calculate = (type) => {
        let needTypeNumber = 0;
        if (type[0] === 'Остальные') needTypeNumber = 2;
        else if (type[0] === 'Вредоносные') needTypeNumber = 0;
        else if (type[0] === 'Потенциально опасные') needTypeNumber = 1;
        let dataUrl = getData(data);
    
        return calculateEventsPerModTypeValues(dataUrl, needTypeNumber);
    }
    
    function calculateEventsPerModTypeValues(eventsPerModType, type) {
        let result = {
            allEventsCount: 0,
            "Phishing": { percentage: 0, count: 0 },
            "Grayware": { percentage: 0, count: 0 },
            "Malware" : { percentage: 0, count: 0 },
            "Ransomware" : { percentage: 0, count: 0 },
            'Command and Control' : { percentage: 0, count: 0 },
            "Scanning Activity" : { percentage: 0, count: 0 },

        };
  
        if (eventsPerModType.length === 0) {
            return result;
        }
    
        let allEventsCount = eventsPerModType.reduce((total, item) => total + item.count, 0);
        let percentages = {};
        let fractions = {};
    
        eventsPerModType.forEach(item => {
            let percentage = (item.count / allEventsCount) * 100;
            percentages[item.cat_name] = Math.floor(percentage * 10) / 10;
            fractions[item.cat_name] = percentage - Math.floor(percentage * 10) / 10;
        });
    
        let percentagesSum = Object.values(percentages).reduce((sum, num) => sum + num, 0);
        let percentDifference = Math.round((100.0 - percentagesSum) * 10) / 10;
        let sortedFractions = Object.keys(fractions).sort((a, b) => fractions[b] - fractions[a]);
    
        for (let i = 0; i < percentDifference * 10; i++) {
            percentages[sortedFractions[i % sortedFractions.length]] += 0.1;
        }
    
        result.allEventsCount = allEventsCount;
        eventsPerModType.forEach(item => {
            result[item.cat_name] = {
                percentage: percentages[item.cat_name].toFixed(1),
                count: item.count
            };
        });

        return numberWithSpaces(result[`${type}`].count) + ' - ' + (result[`${type}`].percentage === '0.0'? '< 0.1': result[`${type}`].percentage) + '%';
    }
    

    const options = {
        responsive: true,
        cutout: 75,
        cutoutPercentage: 65,
  
        maintainAspectRatio: false,

        plugins: {
            datalabels: {
               display: false
              },
            legend: {
                display: false,
            },
            // colors: {
            //     enabled: true,
            //     forceOverride: true
            // },
            tooltip: {
                enabled: false,
                external: function (context) {
                    // Tooltip Element
                    let tooltipEl = document.getElementById("chartjs-tooltip");

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement("div");
                        tooltipEl.id = "chartjs-tooltip";
                        tooltipEl.innerHTML = "<table></table>";
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }
                    
                    // Set caret Position
                    tooltipEl.classList.remove(
                        "above",
                        "below",
                        "no-transform"
                    );
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add("no-transform");
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        // const titleLines = tooltipModel.title || [];
                        const bodyLines = tooltipModel.body.map(getBody);

                        let innerHtml = "<tbody>";

                        // titleLines.forEach(function (title) {
                        // 	innerHtml += '<tr><th>' + title + '</th></tr>';
                        // });
                        //
                        // innerHtml += '</thead><tbody>';

                        bodyLines.forEach((body) => {

                            body = body[0].split(/[\s,\t,\n]+/).join('');

                            const span =
                                '<div style="color: white; margin: 0 -17px; font-size: 16px;padding: 5px; background: rgba(0, 0, 0, 0.7); text-align: center; border-radius: 8px; backgroundColor: #FFFFFF">' +
                              // ((Math.round((((body / eventsSum) * 1000)))/1000)*100).toFixed(0) +
                           //  calculate(tooltipModel?.title, body) + 
                           tooltipModel?.title + ' ' + body + 
                             // body + 
                             // (Math.round(body / eventsSum)*100).toFixed(0) +
                                "</div>";
                            innerHtml += "<tr><td>" + span + "</td></tr>";
                        });

                        innerHtml += "</tbody>";

                        let tableRoot = tooltipEl.querySelector("table");
                        tableRoot.innerHTML = innerHtml;
                    }

                    const position =
                        context.chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = "absolute";
                    tooltipEl.style.left =
                        position.left +
                        window.pageXOffset +
                        tooltipModel.caretX +
                        "px";
                    tooltipEl.style.top =
                        position.top +
                        window.pageYOffset +
                        tooltipModel.caretY +
                        "px";
                    tooltipEl.style.padding =
                        tooltipModel.padding +
                        "px " +
                        tooltipModel.padding +
                        "px";
                    tooltipEl.style.pointerEvents = "none";
                },
            },
        },
    };

    const legend = () => {
        // return getData(data).map((d) => {
			return data.map((d) => {
        
            return (
                <div
				key={d.cat_name}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                        marginBottom: "24px",
                    }}
                >
                    <span
                        style={{
                            color: "#989AA5",
                            fontSize: 14,
                            fontWeight: 500,
                        }}
                    >
                        {d.cat_name}
                    </span>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                        }}
                    >
                        <span
                            style={{
                                borderRadius: "50%",
                                display: "inline-block",
                                width: 10,
                                height: 10,
                                backgroundColor:
                                COLORS[d.cat_name]
                                //margin: "0 20px 5px 0",
                            }}
                        />
                        <p
                            level={5}
                            key={d.cat_name}
                            style={{
                                color: "#4B4F55",
                                fontSize: 18,
                                margin: 0,
                            }}
                        >
                             {/* {d.count}  */}
                       
                           {calculateEventsPerModTypeValues(data, d.cat_name)}
                        </p>
                    </div>
                </div>
            );
        });
    };

    return (
        <div style={chartDonut}>
            <p
              
                style={{
                    color: "#4B4F55",
                    marginBottom: "20px",
                    fontSize: 20,
                    fontWeight: 600
                }}
            >
           Распределение прямых запросов по плохим категориям
            согласно PAN DB
            </p>
            <div style={{ height: "222px", display: "flex", gap: "25px" }}>
                <div style={{ width: "225px", position: 'relative' }}>
                    {Boolean(data) && <div style={dataCircle}>
                        <span>
                         
                            {/* {eventsSum? numberWithLetters(eventsSum) : ''} */}
                            {numberWithSpaces(TOTAL) || 0}
                        </span>
                        <span style={circleSubtitle}>
                            ВСЕГО 
                        </span>
                    </div>}
                    <Doughnut ref={ref} options={options} data={chartData} />
                </div>
                 {Boolean(data) && <div style={{display: "grid", gridTemplateColumns: '1fr 1fr', columnGap: '20px'}}>{legend()}</div>}  
            </div>
        </div>
    )
}
