import React from "react";
import Table from 'react-bootstrap/Table';
import { useNavigate } from "react-router-dom";
import copyAsset from '../../icons/copy.svg';
import { organisationRating } from "../../utils/constants";

function UsersFullList({ userList,setCurrentItem, setSwitchActive, setCloneUser }) {
    const basicStyles = {
        "fontSize": 'smaller',
        'cursor' : 'pointer'
    }
    const navigate = useNavigate();
    const disabledAdmin=(user)=> {
        if (!user.is_superuser) {
            setSwitchActive(
                true
            );
            setCurrentItem(
                user
            );
        }
    }
    return (
        <Table striped bordered hover>
            <thead style={basicStyles}>
                <tr>
                    <th>Фамилия</th>
                    <th>Имя</th>
                    <th>Email (логин)</th>
                    <th>Организация</th>
                    <th>Роль</th>
                    <th>Статус</th>
                    <th style={{ width: '10%' }}>Доступ к сторонним устройствам</th>
                    <th>Клонировать</th>

                </tr>

            </thead>
            <tbody style={basicStyles}>
                {userList?.map(user =>
                    <tr key={user.id} onClick={()=> navigate(`/users/${user.id}`)}>
                        <td>
                            {user.last_name}
                        </td>
                        <td>
                            {user.first_name}
                        </td>
                        <td>
                            {user.email}
                        </td>
                        <td onClick={(e) => { e.stopPropagation(); navigate(`/orglist/${user.organisation_info[0].id}`) }} > {organisationRating(user.organisation_info[0]?.rating, user.organisation_info[0]?.name)} 

            </td>
                        <td>
                            {user.role}
                        </td>
                         {<td>
                                                        <div
                                                        onClick={(
                                                            evt
                                                        ) => {evt.stopPropagation();
                                                            disabledAdmin(user)
                                                           
                                                        }}
                                                            className="form-check form-switch"
                                                            style={{
                                                                minHeight: 0,
                                                            }}
                                                        >
                                                            <input
                                                            disabled={user.is_superuser}
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="statusChecks"
                                                                readOnly={true}
                                                                checked={
                                                                    user.is_active ===
                                                                    !true
                                                                        ? false
                                                                        : true
                                                                }
                                                    
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="flexSwitchCheckChecked"
                                                            >{user?.is_active? 'Активен': 'Неактивен'}</label>
                                                        </div>
                                                    {/* </Col> */}
                                               </td> }
                        <td>
                            {user.num_external_orgs}
                        </td>
                    {/* <td><Button type="primary" 
                    onClick={(evt)=> {evt.stopPropagation(); setCurrentItem(user); setCloneUser(true)}}
                    >Клонировать</Button></td> */}
            <td>  <img width="30" src={copyAsset} alt="delete" onClick={
                (e) => { e.stopPropagation(); setCurrentItem(user); setCloneUser(true) }
            } /></td>
                    </tr>
                )}
            </tbody>

        </Table>)
}
export default UsersFullList;