import Api from '.';

export default class UrlService {
    static async checkUrl(userUrl) {

        var url = `api/v1/check-url/kfeed/`;

        const method = 'GET';
        const params = {};
        params.url = userUrl
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async checkUrlKfeed(mask, urlK) {

        var url = `api/v1/check-url/kfeed-only/`;

        const method = 'GET';
        const params = {};
        params.mask = mask;
        params.url = urlK
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async updateUrl(userUrl) {

        var url = `api/v1/check-url/`;

        const method = 'POST';
        const urlData = { url: userUrl }

        const data = [];
        data.push(urlData);

        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };

    static async checkDevice(sn) {
        var url = `api/v1/check-asset/?sn=${sn}`;

        const method = 'GET';
        const params = {};
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async checkStats(filters) {
        var url = `api/v1/urldb/fw/`;

        const method = 'GET';
        const params = {};
        if (filters) {
            if (filters.date_after) {
                params.date_after = filters.date_after;

            };
            if (filters.date_before) {
                params.date_before = filters.date_before;

            };
        }
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async checkPlatformStats() {
        var url = `api/v1/urldb/fw/platform-stats/`;

        const method = 'GET';
        const params = {};

        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async getCount() {
        var url = `api/v1/urldb/badge-stats/`;

        const method = 'GET';
        const params = {};

        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async addInstance(asset) {
        var url = `api/v1/urldb/fw/`;
        const method = 'POST';
        const data = asset;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async deleteItem(id) {
        const url = `api/v1/urldb/fw/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }
    static async switchActivate(id, active) {
        var url = `api/v1/urldb/fw/${id}/`;
        const method = 'PATCH';
        const data = {};
        data.is_active = active
        const api = new Api({ url: url});
        const response = await api.request(method, data);
        return response.data;
    };
    static async switchFallbackPermissions(id, access) {
        var url = `api/v1/assets/set-fallback-permissions/`;
        const method = 'PATCH';
        const data = {};
        data.asset = id;
        data.urlf_can_set_fallbacks = access;
        const api = new Api({ url: url});
        const response = await api.request(method, data);
        return response.data;
    };

    static async getKfeedUrlHitsFeeds(sn, page, filters) {
        var url = `api/v1/urldb/kfeed-url-stats/hits-per-url/`; 
        const params = {};
        if (sn) params.serial_nr = sn;
        if (filters) {
            if (filters.date_after) {
                params.date_after = filters.date_after;

            };
            if (filters.date_before) {
                params.date_before = filters.date_before;

            };
            if (filters.ordering) {
                params.ordering = filters.ordering;

            };
        }
        const method = 'GET';
       
        if (sn) params.serial_nr = sn;
        // params.limit = 5;
    
        if (page) params.page  = page
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async getKfeedDataGraph(filters) {
        var url = `api/v1/urldb/kfeed-url-stats/hits-per-day/`;

        const method = 'GET';
        const params = {};
        params.serial_nr = filters

        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async getExportExls(filters) {
        var url = 'api/v1/urldb/kfeed-url-stats/hits-per-url/export/'
        const method = 'GET';
        const params = {};
        if (filters) {
            if (filters.sn) {
                params.serial_nr = filters.sn

            };
            if (filters.date_after) {
                params.date_after = filters.date_after;
            };
            if (filters.date_before) {
                params.date_before = filters.date_before;
            };
            
        };
        const api = new Api({ url: url, params: params, responseType:'arraybuffer' });
        const response = await api.request(method);
        return response.data;
    };
    static async getExportExlsMeta(filters) {
        var url = 'api/v1/urldb/kfeed-url-stats/hits-per-url/export-metadata/'
        const method = 'GET';
        const params = {};
        if (filters) {
            if (filters.sn) {
                params.serial_nr = filters.sn

            };
            if (filters.date_after) {
                params.date_after = filters.date_after;
            };
            if (filters.date_before) {
                params.date_before = filters.date_before;
            };
            
        };
        const api = new Api({ url: url, params: params, responseType:'arraybuffer' });
        const response = await api.request(method);
        return response.data;
    };

    //KFEED РАЗДЕЛ

    //Суммарная статистика попаданий по PAN-DB URL категориям
    static async checkStatsSummaryPAN(filters) {
        var url = `api/v1/urldb/pa-cats-summary/`;
        const method = 'GET';
        const params = {};
        if (filters) {
            if (filters.date_after) {
                params.date_after = filters.date_after;

            };
            if (filters.date_before) {
                params.date_before = filters.date_before;

            };
            if (filters.serial_nr) {
                params.serial_nr = filters.serial_nr;

            };
            if (filters.organisation_id) {
                params.organisation_id = filters.organisation_id;

            };
        }
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    //PAN BAD CAT GRAPH
    static async getBadCatStatsPAN(filters) {
        var url = `api/v1/urldb/pa-bad-cats-pareto/`;
        const method = 'GET';
        const params = {};
        if (filters) {
            if (filters.date_after) {
                params.date_after = filters.date_after;

            };
            if (filters.date_before) {
                params.date_before = filters.date_before;

            };
            if (filters.serial_nr) {
                params.serial_nr = filters.serial_nr;

            };
            if (filters.organisation_id) {
                params.organisation_id = filters.organisation_id;

            };
        }
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
        //PAN DANGER CAT GRAPH
    static async getDangerCatStatsPAN(filters) {
        var url = `api/v1/urldb/pa-pot-bad-cats-pareto/`;
        const method = 'GET';
        const params = {};
        if (filters) {
            if (filters.date_after) {
                params.date_after = filters.date_after;

            };
            if (filters.date_before) {
                params.date_before = filters.date_before;

            };
            if (filters.serial_nr) {
                params.serial_nr = filters.serial_nr;

            };
            if (filters.organisation_id) {
                params.organisation_id = filters.organisation_id;

            };
        }
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    //KFEED BAD CAT GRAPH
    static async getBadCatKfeed(filters) {
        var url = `api/v1/urldb/kfeed-pa-bad-cats-ratio/`;
        const method = 'GET';
        const params = {};
        if (filters) {
            if (filters.date_after) {
                params.date_after = filters.date_after;

            };
            if (filters.date_before) {
                params.date_before = filters.date_before;

            };
            if (filters.serial_nr) {
                params.serial_nr = filters.serial_nr;

            };
            if (filters.organisation_id) {
                params.organisation_id = filters.organisation_id;

            };
        }
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    //KFEED SUMMARY STATS
    static async getKfeedSummaryStats(filters) {
        var url = `api/v1/urldb/kfeed-hits-summary/`;
        const method = 'GET';
        const params = {};
        if (filters) {
            if (filters.date_after) {
                params.date_after = filters.date_after;

            };
            if (filters.date_before) {
                params.date_before = filters.date_before;

            };
            if (filters.serial_nr) {
                params.serial_nr = filters.serial_nr;

            };
            if (filters.organisation_id) {
                params.organisation_id = filters.organisation_id;

            };
        }
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
}