import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import EdlService from "../../API/EdlService";
import Preloader from "../UI/Preloader/Preloader";
import Alert from 'react-bootstrap/Alert';
import Row from "react-bootstrap/esm/Row";
import CopyIcon from '../../icons/copy.svg';
function EdlItemCard({ data , trial}) {
    const [isPreloader, setIsPreloader] = useState(false);
    const [itemDetail, setItemDetail] = useState(null);
    const [errors, setErrors] = useState([]);
    const [copyText, setCopyText] = useState(false);
    const basicStylesTitles = {
        "fontSize": '10px',
        width: '40%', textAlign: 'right'
    }

    const basicStylesData = {
        "fontSize": '10px',
        textAlign: 'left'
    }

    useEffect(() => {
        (async () => {
            setIsPreloader(true);
            await EdlService.getItem(data.id).then((res) => { setItemDetail(res); setIsPreloader(false) }).catch((err) => { setIsPreloader(false); setErrors(err.message) })
        }
        )()
    }, [data])


    const dateEndStatus = (date, status) => {

        if (status?.includes('дн') && (Number(status.split(' ')[0]) === 0)) return <span style={{ marginRight: 10, fontSize: 'x-small' }} className="badge bg-danger">{date} ({status})</span>;
        else if (status?.includes('дн') && (Number(status.split(' ')[0]) <= 31)) return <span style={{ marginRight: 10, fontSize: 'x-small' }} className="badge bg-warning">{date} ({status})</span>;
        else return <span style={{ marginRight: 10, fontSize: 'x-small' }} className="badge bg-success">{date} ({status})</span>
    }

    const copyBufferText = (text, field) => {
        navigator.clipboard.writeText(text)
            .then((res) => {

                setCopyText({ text: 'Copy', field: field })
            })
            .catch(err => {
                setCopyText({ text: 'Error', field: field });
            });
    }

    return (
        <>
            <Row style={{ position: "relative", width: '100%' }}>
                <h5>Доступ</h5>
                {isPreloader && <Preloader />}
            </Row>
            <hr />
            <Row> {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }</Row>

            <Table bordered striped hover id='idEdlItem' >

                <tbody style={basicStylesData}>
                    <tr>
                        <th style={basicStylesTitles}>Организация</th><td>{itemDetail?.org_name}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>TLD</th><td>{itemDetail?.org_slug}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Статус</th><td>{itemDetail?.is_active ? <span style={{ color: "green" }}>Активен</span> : <span style={{ color: "red" }}>Не активен</span>}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Начало доступа</th><td>{itemDetail?.org_type!=='VENDOR'? itemDetail?.date_created: 'Не предусмотрено'} </td>
                    </tr>
                   {!trial &&  <tr >
                        <th style={basicStylesTitles}>Действителен до</th><td>{itemDetail?.org_type!=='VENDOR'? itemDetail && dateEndStatus(itemDetail?.date_end, itemDetail?.time_left_verbose):'Не предусмотрено' }</td>
                    </tr>}

                    <tr >
                        <th style={basicStylesTitles}>Списки<br />domain записи<br />IP записи<br />url записи<br /> hash записи<br /> всего записей:</th>

                        <td>{itemDetail?.org_type!=='VENDOR'?<>
                            активных: <span style={{ color: "green" }}>{itemDetail?.list_stats?.active_lists}</span>/<span style={{ color: "green" }}>{itemDetail?.list_stats?.lists_limits?.active}</span> неактивных: <span style={{ color: "red" }}>{itemDetail?.list_stats?.inactive_lists}</span>/<span style={{ color: "red" }}>{itemDetail?.list_stats?.lists_limits.inactive}</span><br />
                            {itemDetail?.list_stats?.domains} лимиты: <span style={{ color: "green" }}>{itemDetail?.list_stats?.domains_limits?.active}</span>/<span style={{ color: "red" }}>{itemDetail?.list_stats?.domains_limits?.inactive}</span> <br />
                            {itemDetail?.list_stats?.ip_addresses}  лимиты: <span style={{ color: "green" }}>{itemDetail?.list_stats?.ip_addresses_limits?.active}</span>/<span style={{ color: "red" }}>{itemDetail?.list_stats?.ip_addresses_limits?.inactive}</span><br />
                            {itemDetail?.list_stats?.urls}  лимиты: <span style={{ color: "green" }}>{itemDetail?.list_stats?.urls_limits?.active}</span>/<span style={{ color: "red" }}>{itemDetail?.list_stats?.urls_limits?.inactive}</span><br />
                            {itemDetail?.list_stats?.hashes}  лимиты: <span style={{ color: "green" }}>{itemDetail?.list_stats?.hashes_limits?.active}</span>/<span style={{ color: "red" }}>{itemDetail?.list_stats?.hashes_limits?.inactive}</span><br />
                            {itemDetail?.list_stats?.total_entries}</>: 'Не предусмотрено'}
                        </td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Подписка<br /> списки лимит <br /> domain лимит <br /> IP лимит <br /> url лимит<br /> hash лимит</th>
                        <td>{itemDetail?.org_type!=='VENDOR'?<>{itemDetail?.subscription?.name}<br />
                        <span style={{ color: "green" }}>{itemDetail?.subscription?.active_lists_max_limit}</span>/<span >{itemDetail?.subscription?.lists_max_limit}</span><br />
                          {itemDetail?.subscription?.domains_max_limit}<br />
                          {itemDetail?.subscription?.ip_addresses_max_limit}<br />
                        {itemDetail?.subscription?.urls_max_limit}<br/>
                        {itemDetail?.subscription?.hashes_max_limit}</>: 'Не предусмотрено'}
                        </td>
                    </tr>
                   {!trial &&  <tr >
                        <th style={basicStylesTitles}>Токен</th><td title={itemDetail?.gw_access_token}>{itemDetail?.gw_access_token ? <><img width="20" className="interactive_hover" alt={'copy token'} src={CopyIcon} onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    copyBufferText(itemDetail?.gw_access_token, 'access');
                                    

                                }
                            } /></> : ''} {copyText?.field==='access'? copyText?.text : ''}</td>
                    </tr>}
                    <tr >
                        <th style={basicStylesTitles}>Изменен</th><td>{itemDetail?.date_modified}</td>
                    </tr>


                </tbody>

            </Table></>
    )
}

export default EdlItemCard;