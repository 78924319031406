import React, { useEffect, useState, useContext, useRef } from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Table from 'react-bootstrap/Table';
import EdlService from "../../API/EdlService";
import ImgEdit from '../../icons/edit.png';
import MyModal from "../UI/MyModal/MyModal";
import AddScopeForm from "./AddScopeForm";
import ImgRemove from '../../icons/remove.png';
import ApiGatewayService from "../../API/ApiGatewayService";
import PatchScopeForm from "./PatchScopeForm";
import Preloader from "../UI/Preloader/Preloader";
import Alert from 'react-bootstrap/Alert';
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";
const ScopeList = () => {
    const [patchScope, setPatchScope] = useState(false);
    const [data, setData] = useState(null)
    const [errors, setErrors] = useState([]);
    const [addScope, setAddScope] = useState(false);
    const [successAdd, setSuccessAdd] = useState(false);
    const [successPatch, setSuccessPatch] = useState(false);
    const [currentScope, setCurrentScope] = useState(null);
    const [deleteItem, setDeleteItem] = useState(false);
    const [isPreloader, setIsPreloader] = useState(false)
    const [successDelete, setSuccessDelete] = useState(false);
    const [errorsDelete, setErrorsDelete] = useState([]);

    const basicStyles = {
        "fontSize": 'smaller',
    }
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    useEffect(() => {
        (async () => {
            setIsPreloader(true)
   
            await ApiGatewayService.getScopeList().then((res) => { setData(res)}).catch((err) => { setErrors(err.message) }).finally(()=> {setIsPreloader(false); })
        }
        )()
    }, [successAdd, successPatch, successDelete])
    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') deleteScope();

            })
            .catch(() => changeStatus())
    }

    const deleteScope = async (id) => {
        setIsPreloader(true);
        await ApiGatewayService.deleteScope(id)
            .then(() => { setIsPreloader(false); setDeleteItem(false); setSuccessDelete(!successDelete)})
            .catch((e) => {
                if (e.message[0].includes('token')) refreshToken('delete');
                else { setIsPreloader(false); setErrorsDelete(e.message); }
            });
    }

    return (
        <>
            {deleteItem && <MyModal visible={deleteItem} setVisible={setDeleteItem} >
                <Row className="mb-4" style={{ position: "relative" }}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4">Вы точно хотите удалить Сервис?</h3>
                </Row>
                <Row> {errorsDelete.length > 0 &&
                    errorsDelete.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); deleteScope(currentScope.id) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeleteItem(false); setErrorsDelete([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row>
            </MyModal>}
            <Row style={{position: "relative"}}>
                {!addScope && <Col className="mb-3 col-2">
                    <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                        onClick={() => { setAddScope(true) }}
                    >Добавить</Button>

                </Col>}
            </Row>
            <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
            {addScope && <AddScopeForm setAddScope={setAddScope} setSuccessAdd={setSuccessAdd} successAdd={successAdd} />}
            {patchScope && currentScope && <MyModal visible={patchScope} setVisible={setPatchScope}>
                <PatchScopeForm currentScope={currentScope} setPatchScope={setPatchScope} setSuccessPatch={setSuccessPatch} successPatch={successPatch} />
            </MyModal>}
            <Table striped bordered hover id='tableScopes' >
                <thead style={basicStyles}>
                    <tr>

                        <th>Название</th>
                        <th>Ресурс</th>
                        <th>Доступ</th>
                        <th>Сервис</th>
                        <th>Описание</th>
                   
                        {/* <th style={{ width: '5%' }}>Изменить</th> */}
                        <th style={{ width: '5%' }}>Удалить</th>

                    </tr>
                </thead>
                <tbody>

                    {data?.map((item, index) =>
                        <tr key={item.name + 'gwScope'}
                            style={basicStyles}

                        >
                            <td>{item.name}</td>
                            <td>{item.resource}</td>
                            <td>{item.access}</td>
                            <td>{item.audience?.name}</td>
                            <td>{item.description}</td>
                          
                            {/* <td><img width="20" className="interactive_hover" alt={'edit'} src={ImgEdit} onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    setCurrentScope(item)
                                    setPatchScope(true)
                                }
                            } />
                            </td> */}
                            <td><img width="20" className="interactive_hover" alt={'delete'} src={ImgRemove} onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    setCurrentScope(item)
                                    setDeleteItem(true)
                                }
                            } />
                            </td>
                        </tr>
                    )
                    }
                </tbody>
            </Table>
     
            </>
    )
}

export default ScopeList;