import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";
import ServiceService from "../../API/ServiceService";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function EditSubForm(props) {
    const [isPreloader, setIsPreloader] = useState(false);

    const [slugSub, setSlugSub] = useState(props?.currentSub?.slug || '');
    const [commentSub, setСommentSub] = useState(props?.currentSub?.comment || '');
    const [descriptionSub, setDescriptionSub] = useState(props?.currentSub?.description || '');
    const [errors, setErrors] = useState(null);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                patchingSub();
            })
            .catch(() => changeStatus())
    }
    const patchingSub = async event => {
        if (event) event.preventDefault();
        setIsPreloader(true);
        const item = {};
        item.slug = slugSub;
        item.comment = commentSub;
        item.description = descriptionSub;
        await ServiceService.patchSub(item, props.currentSub.id)
            .then(() => props.setEditSub(!props.editSub))
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })
    }

    return (
        <Form onSubmit={patchingSub} className='was-validated' style={{ position: 'relative' }}>
            {isPreloader && <Preloader />}
            <hr />
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Row >
                <h3 className="mb-3">Изменить подписку</h3>
                <Col className="mb-3 col-4">

                    <Form.Group controlId="SubSlug" >
                        <Form.Label>SLUG</Form.Label>
                        <Form.Control size="sm" type="text"
                            required={true}
                            value={slugSub}
                            placeholder="Введите.." onChange={(e) => setSlugSub(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group></Col>
                <Col className="mb-3 col-4">
                    <Form.Group controlId="SubAddr" >
                        <Form.Label>Комментарий (опционально ) </Form.Label>
                        <Form.Control size="sm" type="text" value={commentSub} placeholder="Введите.." onChange={(e) => setСommentSub(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group>
                </Col></Row>
            <Row>

                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Описание </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            required={true}
                            type="text"
                            placeholder="Описание "
                            value={descriptionSub}
                            size="sm"
                            onChange={(e) => setDescriptionSub(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }}>
                        Изменить
                    </Button>

                </Col>
                <Col sm={2}>
                    <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); props.setEditSub(false) }} style={{ width: '100%' }}>
                        Отменить
                    </Button>
                </Col> </Row>
            <hr />
        </Form>

    );
}
export default EditSubForm;