import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { organisationRating } from "../../utils/constants";
import copyQuota from '../../icons/copy.svg';
import { UserContext } from "../../context";
const QuotaItem = (props) => {
    const navigate = useNavigate();
    const {userinfo} = useContext(UserContext);
    const dateStyles = {
        "fontSize": 'x-small',
    }
    const linkStyle = {
        "cursor": 'pointer',
        "color": 'blue',
        'textDecoration': "underline",
    }
    const handleClickQuotaDetail = (e) => {
        e.preventDefault();
        props.setViewQuota(props.item.id);
    }
    return (
        <tr style={dateStyles} onClick={(e) => handleClickQuotaDetail(e)}>
            <td >{props.item.id}</td>
            <td >{props.item.is_main === true ? <span style={{ color: "red" }}>Да</span> : "Нет"}</td>
            <td >{props.item.date_modified}</td>
            <td >
                {userinfo.role!=='MANAGER'? <span onClick={(e) => { e.stopPropagation(); navigate(`/orglist/${props.item.organisation_info[0].id}`) }}>{organisationRating(props.item.organisation_info[0].rating, props.item.enduser_org)} </span>
                :   <span>{organisationRating(props.item.organisation_info[0].rating, props.item.enduser_org)} </span>  
            }
                
                </td>

            <td onClick={props.item.partner_org && userinfo?.role!=='MANAGER'? (e) => navigate(`/orglist/${props.item.organisation_info[1].id}`) : (e) => handleClickQuotaDetail(e)}>
                {props.item.partner_org ? organisationRating(props.item.organisation_info[1].rating, props.item.partner_org) : ''}

            </td>
            <td  >{props.item.description}</td>
            <td >{props.item.total_price_sale_rub}</td>

            <td >
                {
                    props.item.discount > 40
                        ?
                        <span style={{ color: "red" }}>{props.item.discount} %</span>
                        :
                        <span>{props.item.discount} %</span>
                }
            </td>
            <td >{props.item.currency_rate}</td>
            <td >{
                props.item.status === "EXPIRED"
                    ?
                    <span style={{ color: "red" }}>{props.item.status}</span>
                    :
                    props.item.status === "SOLD"
                        ?
                        <span style={{ color: "green" }}>{props.item.status}</span>
                        :
                        <span>{props.item.status}</span>
            }</td>

            <td >{props.item.sold_date}</td>
            <td>
                <img width="30" src={copyQuota} alt="copy" onClick={
                    (e) => { e.stopPropagation(); props.setIsCloneQuota(true); props.setCurrentQuota(props.item) }
                } />
            </td>
        </tr>
    );
}

export default QuotaItem;