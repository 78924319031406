import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";
import Preloader from "../UI/Preloader/Preloader";
import User from "../../API/User";
function UserCopyForm({user, successClone, setSuccessClone, setCloneUser}) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [isPreloader, setIsPreloader] = useState(false);

    const [userOrg, setUserOrg] = useState(user?.organisation);

    const [errors, setErrors] = useState([]);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                dublicateUser();
            })
            .catch(() => changeStatus())
    }
    const dublicateUser = async event => {
        const data={};
        data.email = email;
        data.first_name = firstName;
        data.last_name = lastName;
        setIsPreloader(true)
        if (event) event.preventDefault();
        await User.cloneUser(user.id, data).then(() => { setCloneUser(false); setSuccessClone(!successClone); setIsPreloader(false) })

            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })

    }
    return (
        <Form id="form" onSubmit={dublicateUser} className="was-validated" >
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}

            <Row>
                <h5 className="mb-3">Дублировать пользователя</h5>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Email</Form.Label>
                        <Form.Control size="sm" type="text" value={email.trim()} required={true} placeholder={'Введите email'}
                            onChange={(e) => setEmail(e.target.value.trim())}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Организация</Form.Label>
                        <Form.Control size="sm" type="text" value={userOrg} disabled

                        />

                   </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Имя</Form.Label>
                        <Form.Control size="sm" type="text" value={firstName}  onChange={(e) => setFirstName(e.target.value.trim())}
                        />
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Фамилия</Form.Label>
                        <Form.Control size="sm" type="text" value={lastName} onChange={(e) => setLastName(e.target.value.trim())}
                        />

                    </Form.Group>
                </Col>
            </Row>

            <Row >
                <Col sm={1} style={{ width: 100 }}>
                    <Form.Group className="mb-4">
                        <Button variant="outline-danger" size="sm" onClick={() => {
                            setCloneUser(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col >
                    <Form.Group className="mb-4">
                        <Button variant="outline-primary" size="sm" type="submit">
                            Дублировать
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>

            <hr />

        </Form >
    );
}
export default UserCopyForm;