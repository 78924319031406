import React from "react";
import Table from 'react-bootstrap/Table';
import EdlItem from "./EdlItem";


const EdlList = (props) => {


    const basicStyles = {
        "fontSize": '10px',
    }

    return (
        <>

            <Table striped bordered hover id='tableEdl' >
                <thead style={basicStyles}>
                    <tr>

                        <th>Организация</th>
                        <th>TLD</th>
                        <th>Начало доступа</th>
                        <th>Действителен до</th>
                        <th>Статус</th>
                        <th>Списки<br/> (актив/неактив)</th>
                        <th>Подписка</th>
                        <th>Всего записей</th>
                        <th>Токен</th>
                        <th style={{width: '5%'}}> Extra<br/>subscriptions</th>
                        <th style={{width: '5%'}}>Изменить</th>
                        <th style={{width: '5%'}}>Удалить</th>
                    </tr>
                </thead>
                <tbody>

                    {props.edlItems?.map((item, index) =>

                        <EdlItem
                            item={item}
                            key={item.id + "edlIt"}
                            setCurrentItem={props.setCurrentItem}
                            setPatchItem={props.setPatchItem}
                            setViewDetail={props.setViewDetail}
                            currentItem={props.currentItem}
                            setDeleteItem={props.setDeleteItem}
                            setSwitchSub={props.setSwitchSub}
                        />
                    )
                    }
                </tbody>
            </Table></>
    )
}

export default EdlList;

