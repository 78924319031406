import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";
import ServiceService from "../../API/ServiceService";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function AddCloudForm(props) {
    const [isPreloader, setIsPreloader] = useState(false);

    const [slugCloud, setSlugCloud] = useState('');
    const [addressCloud, setAddressCloud] = useState('');
    const [updateCloud, setUpdateCloud] = useState('');
    const [descriptionCloud, setDescriptionCloud] = useState('');
    const [errors, setErrors] = useState(null);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                addNewCloud();
            })
            .catch(() => changeStatus())
    }

    const addNewCloud = async event => {
        if (event) event.preventDefault();
        setIsPreloader(true);
        const item = {};
        item.slug = slugCloud;
        item.address = addressCloud;
        item.update_type = updateCloud;
        if (descriptionCloud !== '') item.description = descriptionCloud;
        await ServiceService.newCloud(item)
            .then(() => props.setAddCloud(!props.addCloud))
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })
    }

    return (
        <Form onSubmit={addNewCloud} className='was-validated' style={{ position: 'relative' }}>
            {isPreloader && <Preloader />}
            <hr />
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Row >
                <h3 className="mb-3">Добавить облако</h3>
                <Col className="mb-3 col-4">

                    <Form.Group controlId="cloudSlug" >
                        <Form.Label>SLUG</Form.Label>
                        <Form.Control size="sm" type="text"
                            required={true}
                            value={slugCloud}
                            placeholder="Введите.." onChange={(e) => setSlugCloud(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group></Col>
                <Col className="mb-3 col-4">
                    <Form.Group controlId="cloudAddr" >
                        <Form.Label>Адрес</Form.Label>
                        <Form.Control required={true} size="sm" type="text" value={addressCloud} placeholder="Введите.." onChange={(e) => setAddressCloud(e.target.value)} />
                        <Form.Text muted>
                            пример "updates.parus.su:12311"
                        </Form.Text>
                    </Form.Group>
                </Col></Row>
            <Row>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Тип обновления</Form.Label>
                        <Form.Control
                            type="text"
                            required={true}
                            placeholder="Описание "
                            value={updateCloud}
                            size="sm"
                            onChange={(e) => setUpdateCloud(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Описание (опционально)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={descriptionCloud}
                            size="sm"
                            onChange={(e) => setDescriptionCloud(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }}>
                        Добавить
                    </Button>

                </Col>
                <Col sm={2}>
                    <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); props.setAddCloud(false) }} style={{ width: '100%' }}>
                        Отменить
                    </Button>
                </Col> </Row>
            <hr />
        </Form>

    );
}
export default AddCloudForm;