import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import CopyIcon from '../../icons/copy.svg';

function SandboxDetailCard({ data, serial }) {
const [copyText, setCopyText] = useState(false);
const [currentItem, setCurrentItem]= useState(false);

// useEffect(()=> {
//     if (data && serial) {
//         let arr = testData?.filter((it)=> it.wf500_addr === serial)
//         console.log(arr)
//     }
// },[serial])
const copyBufferText = ({text, field}) => {
    navigator.clipboard.writeText(text)
        .then((res) => {

            setCopyText({text: 'Copy', field: field})
        })
        .catch(err => {
            setCopyText({text: 'Error', field: field});
        });
}

const changeDate = (date) => {
    if (date) {

        const formatDate = date?.split(" ");
        const reverseDate =
            formatDate[0].split("-").reverse().join(".") +
            " " +
            formatDate[1].split(".")[0];

        return reverseDate;
    } else return null;
};

const dateStyles = {
    fontSize: "x-small",
};

    return (
        <>

            <Table bordered striped hover id='idT' >
            <thead>
                                <tr style={dateStyles}>
                                    <th>sha256</th>
                                    <th>file type</th>
                                    <th>created at</th>
                                </tr>
                            </thead>
                            <tbody
                                className="table-group-divider"
                                id={"chDevExt"}
                            >
                                {data?.filter((it)=> it.wf500_addr === serial)[0]?.processing_files?.map((item, index) => (
                                    <tr
                                        key={item.sha256 + item.created_at}
                                        style={dateStyles}
                                    >
                                         <td title={item.sha256 || ''}>{item.sha256?  <span>...{item.sha256?.slice(-30)}{' '}<img width="16" className="interactive_hover" alt={'copy'} src={CopyIcon} disabled onClick={
                                            () => {
                                                setCurrentItem(item);
                                                copyBufferText({text: item.sha256});
                                                
                                           
                                            }
                                        } />{(item.sha256 + item.created_at) === (currentItem?.sha256 + currentItem?.created_at) ?
                                         <span style={copyText.text === 'Ошибка' ? { color: "red" } : { color: "green" }}>{copyText.text}</span> 
                                         : <span></span>}</span>: 'Нет данных'}</td>
                                        {/* <th>{item.sha256}</th> */}
                                        <th>{item.filetype}</th>
                                        <th>{changeDate(item.created_at)}</th>
                                       
                                        </tr>))}
                                        </tbody>

            </Table></>
    )
}

export default SandboxDetailCard;