import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OrgService from "../../API/OrgService";
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";

import ApiGatewayService from "../../API/ApiGatewayService";

import MultiSelect from "./MultiSelect/MultiSelect";


function PatchTokenForm({ setPatchToken, setSuccessPatch, successPatch, currentToken }) {

    const changeDateViewEnd = (date, typeDate) => {

        return typeDate==='date'?date.split('T')[0]: date.split('T')[1]
    }

    const [itemOrg, setItemOrg] = useState(currentToken?.organisation);
    const [issuedDate, setIssuedDate] = useState(changeDateViewEnd(currentToken?.issued_at,'date'));
    const [issuedTime, setIssuedTime] = useState(changeDateViewEnd(currentToken?.issued_at,'time'))
    const [endDate, setEndDate] = useState(changeDateViewEnd(currentToken?.date_end, 'date'));
    const [allAudience, setAllAudience] = useState([])
    const [allScope, setAllScope] = useState([])
    const [errors, setErrors] = useState([]);

    const [isPreloader, setIsPreloader] = useState(false);
    const [tokenDescription, setTokenDescription] = useState(currentToken?.description || '');
    // const [tokenDuration, setTokenDuration] = useState('');
    const [type, setType] = useState(currentToken?.type);
    const [name, setName] = useState(currentToken?.name);
    const [audience, setAudience] = useState(currentToken?.audience);
    const [reqPerMonth, setReqPerMonth] = useState(currentToken?.monthly_requests_limit);
    const [reqPerDay, setReqPerday] = useState(currentToken?.daily_requests_limit);
    const [active, setActive] = useState(currentToken?.is_active);
    const [selectedScopes, setSelectedScopes] = useState(currentToken?.scope.map((item)=> item.id));
  
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {

        (async () => {

            await ApiGatewayService.getAudienceList().then((res) => { setAllAudience(res) }).catch((err) => changeStatus())
            await ApiGatewayService.getScopeList().then((res) => { setAllScope(res) }).catch((err) => changeStatus())
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') patchToken();

            })
            .catch(() => changeStatus())
    }


    const patchToken = async event => {
        setIsPreloader(true);
        if (event) event.preventDefault();
        const token = {};
        token.organisation = itemOrg.id;
        token.name = name;
        if (type!==currentToken?.type) token.type = type;
        token.audience = audience.id;
        token.scope = selectedScopes;
        token.description = tokenDescription;
        if (type==='standard' ||type==='trial') {token.monthly_requests_limit = reqPerMonth; token.daily_requests_limit = reqPerDay;};
        
        token.is_active = active;
        await ApiGatewayService.patchToken(token, currentToken?.subject)
            .then(() => { setPatchToken(false); setIsPreloader(false); setSuccessPatch(!successPatch) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken('save');
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })


    }

    return (
        <Form id="form" onSubmit={patchToken} className="was-validated" style={{ position: "relative" }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key={"danger" + error} variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}
            <Row>
                <Col className="mb-1 col-2 ">
                    <div className="form-check" style={{ alignItems: "center", display: "flex", }}>
                        <input onChange={(evt) => setActive(evt.target.checked)} className="form-check-input" type="checkbox" value='' checked={active} id="isActive" style={{ height: '25px', width: '25px' }}></input>
                        <label className="form-check-label" htmlFor="isMain" style={{ fontSize: '14px', fontWeight: 600, marginLeft: '10px', color: "black" }}>
                            Active
                        </label>
                    </div></Col>
            </Row>
            <Row>
                <Col className=" col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Название</Form.Label>
                        <Form.Control size="sm" type='text' value={name} onChange={(e) => setName(e.target.value)} required={true}>

                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col className="col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Тип токена</Form.Label>
                        <Form.Select size="sm" id="tokenType" value={type}onChange={(e) => setType(e.target.value)} 
                        disabled={true}>
                            <option disabled value={'trial'}>Trial</option>
                            <option value={'standard'}>Standard</option>
                            <option value={'unlimited'}>Unlimited</option>
                            <option value={'unlimited-without-updating'}>Unlimited without updating</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

            </Row>
            <Row>
                <Col sm={4}>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Организация</Form.Label>
                        <Form.Control size="sm" type='text' value={itemOrg} onChange={(e) => setName(e.target.value)} required={true} disabled={true}>

                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col sm={2} >
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Дата активации </Form.Label>
                        <Form.Control
                            value={issuedDate || ""}
                            onChange={(e) => setIssuedDate(e.target.value)}
                            size="sm" type="date"
                            required={true}
                            disabled={true} />

                    </Form.Group>
                </Col>
              
                <Col sm={2}>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Время</Form.Label>
                        <Form.Control
                            value={issuedTime}
                            onChange={(e) => setIssuedTime(e.target.value)}
                            size="sm" type="time"
                            required={true} 
                            disabled={true}/>

                    </Form.Group>
                </Col> 
                <Col sm={2} >
                    <Form.Group className="mb-3" required={true} disabled={true} >
                        <Form.Label >Дата окончания </Form.Label>
                        <Form.Control
                            value={endDate}
                            onChange={(e) => setIssuedDate(e.target.value)}
                            size="sm" type="date"
                            required={true}
                            disabled={true} />

                    </Form.Group>
                </Col>
                <Col sm={2}>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Время</Form.Label>
                        <Form.Control
                            value={issuedTime}
                            onChange={(e) => setIssuedTime(e.target.value)}
                            size="sm" type="time"
                            required={true} 
                            disabled={true}/>

                    </Form.Group>
                </Col> 
                </Row>
                <Row>
               <Col sm={4}>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Сервис</Form.Label>

                        <Dropdown style={ { border: type==='unlimited-without-updating'?"1px solid grey": "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}  >
                            <Dropdown.Toggle as={MyToggle} id="dropdown-audience-patch" disabled={type==='unlimited-without-updating'}>
                                {audience?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu 
                                as={MyMenu}
                                type={'audiencePatch'}
                                filterQa={true}
                            >
                                {allAudience?.map(item =>
                                    <Dropdown.Item eventKey={"aupa" + item.id} key={"aupa" + item.id} onClick={() => { setAudience(item); setSelectedScopes([])}}>{item.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
              
              { audience && <Col sm={4}>
                   
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Ресурсы и доступы</Form.Label>

                        <MultiSelect data={audience?.scopes || currentToken?.scope} selectedScopes={selectedScopes} setSelectedScopes={setSelectedScopes} disabled={type==='unlimited-without-updating'}/>

                    </Form.Group>
                </Col>}
         

            </Row>
            <Row>
                {/* <Col className="col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Срок действия (дней)</Form.Label>
                        <Form.Select size="sm" id="tokenDate" onChange={(e) => setTokenDuration(e.target.value)} required={true} disabled={true}>
                            <option value='' disabled={tokenDuration ? true : false} >Выбрать</option>
                            {type === 'trial' ? trialDuration.map((item) => <option key={'option' + item.value} value={item.value}>{item.valueInDay}</option>) :
                                standartDuration.map((item) => <option key={'option' + item.value} value={item.value}>{item.valueInDay}</option>)}
                        </Form.Select>
                    </Form.Group>
                </Col> */}
                 {type === 'standard' && <Col className=" col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Макс. кол-во запросов в месяц</Form.Label>
                        <Form.Control size="sm" type='number' value={reqPerMonth} onChange={(e) => setReqPerMonth(e.target.value)} required={true}>

                        </Form.Control>
                    </Form.Group>
                </Col>}
                {type === 'standard' &&   <Col className=" col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Макс. кол-во запросов в сутки</Form.Label>
                        <Form.Control size="sm" type='number' value={reqPerDay} onChange={(e) => setReqPerday(e.target.value)} required={true}>

                        </Form.Control>
                    </Form.Group>
                </Col>}
            </Row>

            <Row>

                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Описание</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={tokenDescription || ""}
                            size="sm"
                            onChange={(e) => setTokenDescription(e.target.value)}
                        />
                    </Form.Group>
                </Col>

            </Row>
            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            setPatchToken(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Изменить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>


            <hr />
        </Form >
    );
}
export default PatchTokenForm;
