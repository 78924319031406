import React, { useEffect, useState, useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import CheckStats from "./CheckStats";
import CheckUrl from "./CheckUrl";
import bolt from '../../icons/bolt.svg';
import UrlService from "../../API/UrlService";
import Alert from 'react-bootstrap/Alert';
import CheckSn from "./CheckSn";
import { AuthContext } from "../../context";

function UrlFilterArea() {
    const [badgeData, setBadgeData] = useState(null);
    const [errors, setErrors] = useState([]);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const titleStyles = {
        'textAlign': "center",
        'margin': '0 0 10px',
        'padding': '0',
        'fontSize': '20px',
        'backgroundColor': 'rgb(13, 110, 253, .2)'
    }
    const appletStyle = {
        'border': '1px solid #0d6efd', 'padding': '0', 'borderRadius': '10px', 'position': "relative", 'boxSizing': "border-box",
        //'boxShadow': '10px 0 10px -10px rgba(0, 0, 0, .5)'
        'WebkitBoxSizing': 'border-box', minHeight: '300px',

    }
    useEffect(() => {

        (async () => {

            await UrlService.getCount().then((resp) => { setBadgeData(resp[0]); setErrors([]) }).catch((e) => { 
                if (e.message[0].includes('token')) changeStatus()
                else setErrors(e.message) });

        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (

        <Col >

         
            <Row>
                {errors?.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }
            </Row>

            <Row className="mb-3 justify-content-start" style={{ minHeight: '300px', margin: '0 auto' }}>
                <CheckStats badgeData={badgeData}/>
            </Row>

        </Col>

    )
}

export default UrlFilterArea;