import React, { useState, useEffect, useRef, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OrgService from "../../API/OrgService";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import WarehouseService from "../../API/WarehouseService";
import parseISO from 'date-fns/parseISO';
import ImgRemove from '../../icons/remove.png';
import ImgAdd from '../../icons/add.svg';
import './style.css';
import Preloader from "../UI/Preloader/Preloader";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
function EditWarehouseForm(props) {
    const refactDate = (date) => {
        if (date !== null) {
            const arr = date.split('.').reverse();

            return parseISO(arr.join('-') + 'T11:30:30')
        }
        else return null
    }

    const uploadPhoto = useRef();
    registerLocale('ru', ru)
    const [isPreloader, setIsPreloader] = useState(false);
    const [serialNumber, setSerialNumber] = useState(props.currentAsset.serial_nr);
    const [valid, setVaild] = useState(false);
    const [assetOrg, setAssetOrg] = useState(props.currentAsset.testing_organisation || '');
    const [assetPlatform, setAssetPlatform] = useState(props.currentAsset.platform);
    const [supplier, setSupplier] = useState(props.currentAsset.supplier || '');
    const [patchDate, setPatchDate] = useState(refactDate(props.currentAsset.testing_end_date));
    const [assetComment, setAssetComment] = useState(props.currentAsset.comment);
    const [isDocuments, setIsDocuments] = useState(props.currentAsset.is_documents);

    const [isNew, setIsNew] = useState(props.currentAsset.condition);
    const [isBox, setIsBox] = useState(props.currentAsset.is_in_box);
    const [isWarehouse, setIsWarehouse] = useState(props.currentAsset.equipment_location === 'tests' ? false : true);
    const [allOrganisations, setAllOrganisations] = useState(null);
    const [errors, setErrors] = useState([]);
    const [price, setPrice] = useState(props.currentAsset.purchase_price || null);
    const [buyDate, setBuyDate] = useState(refactDate(props.currentAsset.purchase_date) || '');
    const [files, setFiles] = useState(props.currentAsset.images);
    const [newFile, setNewFile] = useState(false);
    const [imagesString, setImagesString] = useState([])
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const getBase64Image = async res => {
        const blob = await res.blob();

        const reader = new FileReader();

        await new Promise((resolve, reject) => {
            reader.onload = resolve;
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
        return reader.result;
    };

    const getArrayImg=async()=> {
        let arr=[]
        await files.reduce(async (promise, item) => {
            await fetch(`${item.image}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
                .then(getBase64Image)
                .then(imgString => { arr.push({ image: imgString, id: item.id }) })
            await promise;
          }, Promise.resolve());
        // await files.forEach(async (item) => {
        //     await fetch(`${item.image}`, {
        //         headers: {
        //             Authorization: `Bearer ${localStorage.getItem('token')}`,
        //         },
        //     })
        //         .then(getBase64Image)
        //         .then(imgString => { arr.push({ image: imgString, id: item.id }) })
        // });
        //   files.map(async (item) => {
        //      await fetch(`${item.image}`, {
        //         headers: {
        //             Authorization: `Bearer ${localStorage.getItem('token')}`,
        //         },
        //     })
        //         .then(getBase64Image)
        //         .then(imgString => { arr.push({ image: imgString, id: item.id }) })
        // });
        return arr
    }
    useEffect(() => {

        (async () => {

        if (files) {   
            let arr = await getArrayImg()
        setImagesString(arr) }
        })()

    }, [files]);

    useEffect(() => {

        (async () => {

            await OrgService.getDropList().then((res) => setAllOrganisations(res)).catch(()=> changeStatus());

        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                saveAsset();
            })
            .catch(() => changeStatus())
    }

    const saveAsset = async event => {
        if (event) event.preventDefault();
        const asset = {};
        setIsPreloader(true)
        asset.serial_nr = serialNumber;
        asset.platform = assetPlatform;
        if (supplier !== null) asset.supplier = supplier;
        asset.is_documents = isDocuments;
        asset.condition = isNew;
        asset.is_in_box = isBox;
        asset.comment = assetComment;
        if (price !== null) asset.purchase_price = price;
        if (buyDate ) asset.purchase_date = buyDate.toISOString().split('T')[0];
        if (isWarehouse === false || isWarehouse === 'false') {
            asset.equipment_location = 'tests'; asset.testing_organisation = assetOrg;
            if (patchDate) asset.testing_end_date = patchDate.toISOString().split('T')[0];
        } else asset.equipment_location = 'warehouse';

        await WarehouseService.patchAsset(asset, props.currentAsset.id)
            .then(() => { setTimeout(() => { setIsPreloader(false); props.setCopyAsset(false) }, 1000) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })
    }

    const handleInputClick = () => {
        uploadPhoto.current.click();
    }
    const addPhoto = async (event) => {
        const formData = new FormData();
        setIsPreloader(true)
        formData.append('serial_nr', serialNumber);
        formData.append('platform', assetPlatform);
        formData.append('comment', assetComment);
        formData.append('documents', isDocuments);
        formData.append('condition', isNew);
        if (supplier) formData.append('supplier', supplier);
        if (price !== null) formData.append('purchase_price', price);
        if (buyDate !== null) formData.append('purchase_date', buyDate.toISOString().split('T')[0]);
        formData.append('is_in_box', isBox);
        if (isWarehouse === false || isWarehouse === 'false') {
            formData.append('equipment_location', 'tests');
            formData.append('testing_organisation', assetOrg);
            if (patchDate !== null)
                formData.append('testing_end_date', patchDate.toISOString().split('T')[0]);
        } else formData.append('equipment_location', 'warehouse');

        if (files !== null) {
            for (let i = 0; i < event.target.files.length; i = i + 1) {
                formData.append(`images[${i}]image`, event.target.files[i])
            }
        }
        await WarehouseService.patchAsset(formData, props.currentAsset.id).then((res) => { setTimeout(() => { setIsPreloader(false); setNewFile(true); setFiles(res.images) }, 1000) }).catch((e) => { setIsPreloader(false); setErrors(e.message) })

    }

    const deletePhotos = async () => {
        const asset = {};

        asset.serial_nr = serialNumber;
        asset.platform = assetPlatform;
        asset.supplier = supplier;
        asset.is_documents = isDocuments;
        asset.condition = isNew;
        asset.is_in_box = isBox;
        asset.comment = assetComment;
        if (price !== null) asset.purchase_price = price;
        if (buyDate !== null) asset.purchase_date = buyDate.toISOString().split('T')[0];
        if (isWarehouse === false || isWarehouse === 'false') {
            asset.equipment_location = 'tests'; asset.testing_organisation = assetOrg;
            if (patchDate !== null) asset.testing_end_date = patchDate.toISOString().split('T')[0];
        } else asset.equipment_location = 'warehouse';
        // if (assetDescription !== '') asset.description = assetDescription;
        asset.images = [];

        await WarehouseService.patchAsset(asset, props.currentAsset.id).catch((e) => setErrors(e.message))
    }
    const deletePhoto = async (id) => {

        await WarehouseService.deletePhoto(id, props.currentAsset.id).then(() => { const arr = files.filter((item, index) => item.id !== id); setFiles(arr) }).catch((e) => setErrors(e.message))
    }
    const calculateFileSize = (evt) => {
        const fileSizeBoolean = Array.from(evt.target.files).some((item) => item.size > 5000000);
        if (!fileSizeBoolean) { setErrors([]); addPhoto(evt) }
        else setErrors(['Размер файла не должен превышать 5 Мб'])
    }
    const dateCheckValid = (date) => {
        if (date !== null) date.setHours(15, 0, 0, 0);
        setPatchDate(date)
    }

    return (
        <Form id="form" onSubmit={saveAsset} className="was-validated" style={{ position: "relative" }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}
            <Row>
                <h3>Изменить устройство</h3>
            </Row>
            <Row >
                <Col className="mb-3 col-3" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            props.setCopyAsset(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-3" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Изменить устройство
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Серийный номер устройства</Form.Label>
                        <Form.Control size="sm" type="text" value={serialNumber} required={true} placeholder={'Введите серийный номер'}
                            onChange={(e) => setSerialNumber(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">

                        <Form.Label size="sm">Платформа</Form.Label>
                        <Form.Control size="sm" type="text" value={assetPlatform} disabled

                        />


                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group>
                        <Form.Label size="sm">Поставщик (опционально)</Form.Label>
                        <Form.Control size="sm" type="text" placeholder={''} value={supplier}
                            onChange={(e) => setSupplier(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>

            </Row>

            <Row>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Есть документы</Form.Label>
                        <Form.Select size="sm" id="isDocs" value={isDocuments} onChange={(e) => setIsDocuments(e.target.value)} >
                            <option value={true}>Да</option>
                            <option value={false}>Нет</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Состояние</Form.Label>
                        <Form.Select size="sm" id="isnew" value={isNew} onChange={(e) => setIsNew(e.target.value)} >
                            <option value={'new'}>New</option>
                            <option value={'mint'}>Mint</option>
                            <option value={'near-mint'}>Near-mint</option>
                            <option value={'used'}>Used</option>
                            <option value={'parts'}>Parts</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Устройство в коробке</Form.Label>
                        <Form.Select size="sm" id="isbox" value={isBox} onChange={(e) => setIsBox(e.target.value)} >
                            <option value={true}>Да</option>
                            <option value={false}>Нет</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row>


                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Где находится</Form.Label>
                        <Form.Select size="sm" id="istest" value={isWarehouse} onChange={(e) => setIsWarehouse(e.target.value)} >
                            <option value={true}>Склад</option>
                            <option value={false}>Тестируется</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

                {(isWarehouse === false || isWarehouse === 'false') && <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Организация</Form.Label>

                        <Dropdown style={!valid ? { border: "1px solid red", height: 31, borderRadius: 6, paddingLeft: '.5rem' } : { border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-orgs" >
                                Организация: {assetOrg || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assOrg'}
                                filterQa={true}
                            >
                                {allOrganisations?.map(org =>
                                    <Dropdown.Item eventKey={"e" + org.id} key={"e" + org.id} onClick={() => { setAssetOrg(org.name); setVaild(true) }}>{org.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>}
                {(isWarehouse === false || isWarehouse === 'false') && <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Выберите дату завершения</Form.Label>
                        <DatePicker
                            // id="datePicker_addAsset"
                            dateFormat="dd.MM.yyyy"
                            selected={patchDate}
                            onChange={(date) => { dateCheckValid(date) }}
                            locale="ru"
                        />
                    </Form.Group>
                </Col>}

            </Row>
            <Row>
                <Col className="mb-3 col-4">
                    <Form.Group>
                        <Form.Label size="sm">Стоимость закупки</Form.Label>
                        <Form.Control size="sm" type="text" value={price || ''} placeholder={'Стоимость закупки'}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-3">
                    <Form.Group className="mb-3">
                        <Form.Label size="sm">Выберите дату закупки</Form.Label>
                        <DatePicker

                            dateFormat="dd.MM.yyyy"
                            selected={buyDate}
                            onChange={(date) => { date.setHours(15, 0, 0, 0); setBuyDate(date) }}
                            locale="ru"
                        />
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4" >

                    <Form.Control
                        type="file"
                        size="sm"
                        ref={uploadPhoto}
                        multiple="multiple"
                        style={{ position: 'fixed', bottom: 0, left: 0, width: '2px', opacity: 0 }}
                        onChange={(e) => { calculateFileSize(e) }}
                        accept="/image*,.png,.jpg,.jpeg"
                    />

                    {imagesString?.length > 0 ? imagesString?.map((item, index) => <div className='warehouse__wrapper'>
                        <div className='warehouse__button_delete' onClick={() => deletePhoto(item.id)}></div>
                        <img width="60" height="60" alt="фото" src={item.image}
                        /></div>) :

                        <Form.Group className="mb-4">
                            <Form.Label size="sm">Фото (опционально)</Form.Label>
                            <Form.Control
                                type="file"
                                multiple="multiple"
                                size="sm"
                                onChange={(e) => { calculateFileSize(e) }}
                                accept="/image*,.png,.jpg,.jpeg"
                            />


                        </Form.Group>

                    }
                    {files?.length > 0 && <img width="50" style={{ marginLeft: '5px' }} alt="добавить" src={ImgAdd} disabled onClick={
                        () => {
                            handleInputClick();
                        }
                    } />}
                    {files?.length > 0 && <img width="50" style={{ marginLeft: '5px' }} alt="удалить" src={ImgRemove} disabled onClick={
                        () => {
                            deletePhotos();
                            setFiles([])

                        }
                    } />}

                </Col>

            </Row>
            <Row>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Комментарий (опционально)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={assetComment || ""}
                            size="sm"
                            onChange={(e) => setAssetComment(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <hr />
        </Form >
    );
}
export default EditWarehouseForm;
