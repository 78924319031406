import React, { useEffect, useState, useContext} from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Table from 'react-bootstrap/Table';
import ApiGatewayService from "../../API/ApiGatewayService";

import MyModal from "../UI/MyModal/MyModal";
import AddAudienceForm from "./AddAudienceForm";
import ImgRemove from '../../icons/remove.png';
import PatchAudienceForm from "./PatchAudienceForm";
import Preloader from "../UI/Preloader/Preloader";
import Alert from 'react-bootstrap/Alert';
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";
import { changeDate } from "./utils";

const AudienceList = () => {
    const [patchAudience, setPatchAudience] = useState(false);
    const [data, setData] = useState(null)
    const [errors, setErrors] = useState([]);
    const [errorsDelete, setErrorsDelete] = useState([]);
    const [addAudience, setAddAudience] = useState(false);
    const [successAdd, setSuccessAdd] = useState(false);
    const [successPatch, setSuccessPatch] = useState(false);
    const [currentAudience, setCurrentAudience] = useState(null);
    const [deleteItem, setDeleteItem] = useState(false);
    const [successDelete, setSuccessDelete] = useState(false)
    const [isPreloader, setIsPreloader] = useState(false);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const basicStyles = {
        "fontSize": 'smaller',
    }
    useEffect(() => {
        (async () => {
            setIsPreloader(true)
            await ApiGatewayService.getAudienceList().then((res) => { setData(res)}).catch((err) => { setErrors(err.message) }).finally(() => { setIsPreloader(false); })
        }
        )()
    }, [successAdd, successPatch, successDelete])
    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') deleteAudience();

            })
            .catch(() => changeStatus())
    }

    const deleteAudience = async (id) => {
        setIsPreloader(true);
        await ApiGatewayService.deleteAudience(id)
            .then(() => { setIsPreloader(false); setDeleteItem(false); setSuccessDelete(!successDelete) })
            .catch((e) => {
                if (e.message[0].includes('token')) refreshToken('delete');
                else { setIsPreloader(false); setErrorsDelete(e.message); }
            });
    }

    return (
        <>
            {deleteItem && <MyModal visible={deleteItem} setVisible={setDeleteItem} >
                <Row className="mb-4" style={{ position: "relative" }}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4">Вы точно хотите удалить Аудиенцию?</h3>
                </Row>
                <Row> {errorsDelete.length > 0 &&
                    errorsDelete.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); deleteAudience(currentAudience.id) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeleteItem(false); setErrorsDelete([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row>
            </MyModal>}
            <Row style={{ position: "relative" }}>
                {isPreloader && <Preloader />}
                {!addAudience && <Col className="mb-3 col-2">
                    <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                        onClick={() => { setAddAudience(true) }}
                    >Добавить</Button>

                </Col>}
            </Row>
            <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
            {addAudience && <AddAudienceForm setAddAudience={setAddAudience} setSuccessAdd={setSuccessAdd} successAdd={successAdd} />}
            {patchAudience && currentAudience && <MyModal visible={patchAudience} setVisible={setPatchAudience}>
                <PatchAudienceForm currentAudience={currentAudience} setPatchAudience={setPatchAudience} setSuccessPatch={setSuccessPatch} successPatch={successPatch} />
            </MyModal>}
            <Table striped bordered hover id='tableAudiences' >
                <thead style={basicStyles}>
                    <tr>

                        <th>Название сервиса</th>
                        <th>Домен</th>
                        <th>Описание</th>
                        <th>Создана</th>
                        {/* <th style={{ width: '5%' }}>Изменить</th> */}
                        <th style={{ width: '5%' }}>Удалить</th>

                    </tr>
                </thead>
                <tbody>

                    {data?.map((item, index) =>
                        <tr key={item.name + 'gwAud'}
                            style={basicStyles}

                        >
                            <td>{item.name}</td>
                            <td>{item.service_domain}</td>
                            <td>{item.description}</td>
                            <td>{changeDate(item.date_created)}</td>
                            {/* <td><img width="20" className="interactive_hover" alt={'edit'} src={ImgEdit} onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    setCurrentAudience(item)
                                    setPatchAudience(true)
                                }
                            } />
                            </td> */}
                            <td><img width="20" className="interactive_hover" alt={'delete'} src={ImgRemove} onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    setCurrentAudience(item)
                                    setDeleteItem(true)
                                }
                            } />
                            </td>
                        </tr>
                    )
                    }
                </tbody>
            </Table>
     
            </>
    )
}

export default AudienceList;