/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLocation } from 'react-router-dom';
import Preloader from "../UI/Preloader/Preloader";
import Button from 'react-bootstrap/Button';
import AssetList from "./AssetList";
import AssetService from "../../API/AssetService";
import AddAssetForm from "./AddAssetForm";
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import PlatformService from "../../API/PlatformService";
import HypervisorService from "../../API/HypervisorService";
import HypervisorList from "./HypervisorList";
import { AuthContext } from "../../context";
import Alert from 'react-bootstrap/Alert';
import { downloadExcel } from "react-export-table-to-excel";
import Auth from "../../API/Auth";
import MyConfirm from "../UI/MyConfirm/MyConfirm";

function AssetArea(props) {

    const [arrSlugFilter, setArrSlugFilter] = useState([]);
    const location = useLocation();
    const [isPreloader, setIsPreloader] = useState(false);
    const [errors, setErrors] = useState([]);
    const [successDelete, setSuccessDelete] = useState(false);
    const [deletingAsset, setDeletingAsset] = useState(false);
    const [currentAsset, setCurrentAsset] = useState({})
    //пагинация
    const [isAssetLoading, setIsAssetLoading] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [isAddingAsset, setIsAddingAsset] = useState(false);
    const [copyAsset, setCopyAsset] = useState(false)

    // перечень всех устройств
    const [assetItems, setAssetItems] = useState([]);
    const [totalAssetItems, setTotalAssetItems] = useState();

    // поиск и фильтрация
    const [searchText, setSearchText] = useState("");
    // const [search, setSearch] = useState("");
    // const [text, setText] = useState('')
    const [platformList, setPlatformList] = useState(null);
    const [platformFilter, setPlatformFilter] = useState(false);
    const [citiesList, setCitiesList] = useState(null);
    const [countriesList, setCountriesList] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [citySearchText, setCitySearchText] = useState("");

    // eslint-disable-next-line no-unused-vars
    const [countrySearchText, setCountryySearchText] = useState("");
    const [cityFilter, setCityFilter] = useState(false);
    const [countryFilter, setCountryFilter] = useState(false);
    const [slugFilter, setSlugFilter] = useState(false);
    const [slugList, setSlugList] = useState([]);
    const [rmaFilter, setRmaFilter] = useState(false);

    //гипервизоры
    const [viewHypervisorList, setViewHypervisorList] = useState(false);
    const [hypervisor, setHypervisor] = useState(null);
    const [hypervisorAdd, setHypervisorAdd] = useState(false)
    const [hypervisorEdit, setHypervisorEdit] = useState(false);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);


    const fetchData = async (add) => {
        let filters = false;
        if (platformFilter || searchText || cityFilter || countryFilter || arrSlugFilter.length > 0 || rmaFilter) {
            filters = {
                platform__id: platformFilter.id,
                search: searchText,
                city: cityFilter,
                country: countryFilter,
                slugFilter: arrSlugFilter,
                rma: rmaFilter
            };
        }

        setIsPreloader(true);
        setIsAssetLoading(true);

        const id = props.orgId ? props.orgId : false;

        await AssetService.getList(id, filters, add ? nextPage : 1)
            .then((res) => {
                if (add === 'add') {setNextPage(res.next ? nextPage + 1 : null);  res.results?.map(item => setAssetItems(arr => [...arr, item])); }
                else { setAssetItems(res.results); setNextPage(res.next ? 2 : null); }
                setTotalAssetItems(res.count);
                // setNextPage(res.next ? nextPage + 1 : null);
                setTimeout(()=>setIsAssetLoading(false), 500);
            })
            .catch(() => changeStatus()).finally(() => setIsPreloader(false))
    }

    useEffect(() => {

        setIsPreloader(true)
        setIsAssetLoading(true);
        const timerRefresh = setTimeout(fetchData, 1000);
        return () => {

            clearTimeout(timerRefresh)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddingAsset, platformFilter, searchText, cityFilter, countryFilter, rmaFilter, arrSlugFilter, copyAsset, successDelete]);

    //гипервизоры

    useEffect(() => {

        (async () => {

            await HypervisorService.getList().then((res) => setHypervisor(res.results)).catch(() => changeStatus());

        })();
    }, [hypervisorAdd, hypervisorEdit]);
    //Платформа
    useEffect(() => {
        (async () => {
            await PlatformService.getList().then((res) => setPlatformList(res)).catch(() => changeStatus());
            await AssetService.getSlug().then((res) => setSlugList(res));

        })();
    }, []);
    //Города
    useEffect(() => {
        (async () => {
            let filters = false;
            if (citySearchText) {
                filters.searchText = citySearchText;
            }
            await AssetService.getCities(filters).then((res) => setCitiesList(res.results));


        })();
    }, [citySearchText, isAddingAsset]);
    //Страны
    useEffect(() => {
        (async () => {
            let filters = false;
            if (countrySearchText) {
                filters.searchText = countrySearchText;
            }
            await AssetService.getCountries(filters).then((res) => setCountriesList(res.results));


        })();
    }, [countrySearchText, isAddingAsset]);

    useEffect(() => {

        ( () => {

            if (needToLoad && nextPage) {
               
                fetchData('add');
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    useEffect(() => {
        
        if (isAssetLoading) return;
        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage && assetItems?.length > 0) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

    }, [isAssetLoading]);

    const clearFilters = () => {
        setPlatformFilter(false);
        setCityFilter(false)
        setCountryFilter(false);
        setSearchText("");
        setSlugFilter(false);
        setRmaFilter(false);
        setArrSlugFilter([]);
        // setText('')
    }

    function changeHandler(currentSlug) {

        if (arrSlugFilter.some((item) => item === currentSlug) !== true) {
            setArrSlugFilter([...arrSlugFilter, currentSlug]);
            setSlugFilter(true)
        } else { const index = arrSlugFilter.findIndex((item) => currentSlug === item); setArrSlugFilter([...arrSlugFilter.slice(0, index), ...arrSlugFilter.slice(index + 1)]) }
    }
    const refreshToken = async (id) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                removeAsset(id);
            })
            .catch(() => changeStatus())
    }

    const removeAsset = async (assetId) => {
        setIsPreloader(true)
        await AssetService.deleteAsset(assetId).then(() => { setSuccessDelete(!successDelete); setDeletingAsset(false); setErrors([]); setIsPreloader(false) })

            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken(assetId);
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })

    }

    const downloadFile=async ()=> {
        let fileType = 'xlsx';
        let filters = false;
        if (platformFilter || searchText || cityFilter || countryFilter || arrSlugFilter.length > 0 || rmaFilter) {
            filters = {
                platform__id: platformFilter.id,
                search: searchText,
                city: cityFilter,
                country: countryFilter,
                slugFilter: arrSlugFilter,
                rma: rmaFilter
            };
        }
        setIsPreloader(true)
        await AssetService.getExportExls(filters).then((resp)=>{
            const date = (new Date()).toISOString().replace('.', '-').split('T')[0];

            const effectiveFileName = `assets-${date}.${fileType}`;
            const url = window.URL.createObjectURL(new Blob([resp]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', effectiveFileName);
            document.body.appendChild(link);
            link.click();
            link.remove();

        }).catch((err) => { setErrors(err.message)}).finally(()=> setIsPreloader(false));

    }

    return (
        <>
            <Col>
                {!location.pathname.includes("orglist") && isAddingAsset === false &&
                    <h3 className="mb-3">Устройства (отображено {totalAssetItems} устройств)</h3>}
                {location.pathname !== "/orglist" && isAddingAsset === true &&
                    <h3 className="mb-3">Добавить устройство</h3>}

                {!location.pathname.includes("orglist") && <Row className="mt-4 mb-3">
                    <Col className="mb-3 col-2 ">
                        <Button style={{ width: '100%' }} onClick={() => setViewHypervisorList(!viewHypervisorList)} variant="outline-dark" > {viewHypervisorList === false ? 'Гипервизоры' : 'Скрыть информацию'}</Button></Col>
                </Row>}
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                {deletingAsset &&
                    <MyConfirm visible={deletingAsset} setVisible={setDeletingAsset}>
                        <Row>

                            <h5 className="mb-3">Удалить устройство?</h5>
                        </Row>
                        <Row> {errors.length > 0 &&
                            errors.map(error =>
                                <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>
                            )
                        }</Row>
                        <Row>
                            <Col >
                                <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); removeAsset(currentAsset.id) }}>
                                    Да
                                </Button>

                            </Col>
                            <Col >
                                <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeletingAsset(false); setErrors([]) }} style={{ width: '100%' }}>
                                    нет
                                </Button>
                            </Col>
                        </Row>

                    </MyConfirm>}
                {/* {!location.pathname.includes("orglist") && <hr />} */}
                {viewHypervisorList === true && <Row>
                    <HypervisorList
                        hypervisor={hypervisor}
                        setViewHypervisorList={setViewHypervisorList}
                        setHypervisorAdd={setHypervisorAdd}
                        hypervisorAdd={hypervisorAdd}
                        setHypervisorEdit={setHypervisorEdit}
                        hypervisorEdit={hypervisorEdit}
                    />

                </Row>}
                {/* {assetsStats !== null && viewAllStats && assetsStats?.assets_per_platform_name.map((platform) => (<Row className="mb-4" key={'assPerPlet' + platform.platform__name}>
                    <b>Устройств на платформе {platform.platform__name} : {platform.count} шт.</b>
                </Row>))} */}

                <Row>
                    <Col className="mb-3 col-2">
                        {location.pathname === '/asset' && !isAddingAsset && <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                            onClick={() => { setIsAddingAsset(true) }}

                        >Добавить устройство</Button>}

                    </Col>

                    <Col className="mb-3 col-2">
                        {location.pathname === '/asset' && !isAddingAsset && <Button type="link" variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                            onClick={() => { downloadFile() }}

                        >Скачать в Excel</Button>}

                    </Col>

                </Row>  <hr />

                {/* ФИЛЬТРЫ */}

                {location.pathname === '/asset' && <Row className="justify-content-start" style={{ position: "relative" }}>
                    {isPreloader && <Preloader />}
                    {isAddingAsset && <AddAssetForm setIsAddingAsset={setIsAddingAsset} setHypervisorAdd={setHypervisorAdd}
                        hypervisorAdd={hypervisorAdd} />}
                    <Col className="mb-3 col-2" >
                        <Form.Label size="sm">Поиск</Form.Label>
                        <Form.Control onChange={(e) => { setSearchText(e.target.value) }}
                            placeholder="Начните вводить..."
                            size="sm"
                            name='search'
                            style={{ fontSize: '1rem', height: 38 }}
                            value={searchText}
                        // disabled={isAssetLoading? true: false}
                        />
                    </Col>

                    {/* < Col className="col-3" >

                        <label htmlFor="basic-url" className="form-label">Поиск</label>
                        <div className="input-group">

                            <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3 basic-addon4"
                                onChange={(e) => { setSearchText(e.target.value) }}
                                value={searchText} onKeyDown={(event) => {
                                    if (event.key === 'Enter')
                                        setSearch(!search)
                                }} />
                            <button disabled={!searchText || isAssetLoading ? true : false} className="input-group-text" id="basic-addon3" style={{ cursor: "pointer" }} onClick={() => setSearch(!search)}>Найти</button>
                        </div>

                    </Col> */}
                    <Col className="mb-3 col-2 ">
                        <Form.Label size="sm">Платформа</Form.Label>
                        <Dropdown style={{ width: "100%" }}>
                            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-platforms">
                                {platformFilter.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                setPlatformFilter={setPlatformFilter}
                                type='platform'
                            >
                                {platformList?.map(platform =>
                                    <Dropdown.Item eventKey={"apl" + platform.id} key={"apl" + platform.id} onClick={() => { setPlatformFilter(platform) }}>{platform.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className="mb-3 col-2 " >
                        <Form.Label size="sm">Город</Form.Label>
                        <Dropdown >
                            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-cities" >
                                {cityFilter || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                setCityFilter={setCityFilter}
                                type='city'
                            >
                                {citiesList?.map(city =>
                                    <Dropdown.Item eventKey={"ci" + city.city} key={"ci" + city.city} onClick={() => { setCityFilter(city.city) }}>{city.city}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className="mb-3 col-2 ">
                        <Form.Label size="sm">Страна</Form.Label>
                        <Dropdown style={{ width: "100%" }} >
                            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-countries" >
                                {countryFilter || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                data-bs-auto-close="outside"
                                as={MyMenu}
                                setCountryFilter={setCountryFilter}
                                type='country'
                            >
                                {countriesList?.map((country, index) => {
                                    return <Dropdown.Item eventKey={"co" + country.country} key={"co" + country.country} onClick={(e) => { setCountryFilter(country.country) }}>{country.country}</Dropdown.Item>
                                }
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className="mb-3 col-2 ">

                        <Form.Label size="sm">SLUG:
                        </Form.Label>
                        <Dropdown style={{ width: "100%" }} >
                            <Dropdown.Toggle as={MyToggleAsset} size={arrSlugFilter?.length > 1 ? 'min' : 'normal'}>
                                {(arrSlugFilter?.length && arrSlugFilter?.map((item, index) => item + ' ')) || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu

                                as={MyMenu}

                                type='multi'
                            >
                                {slugList?.map((slug, index) =>
                                    <Dropdown.Item className={arrSlugFilter.some((item) => item === slug) === true ? 'active' : ""} eventKey={"slug" + slug + index} key={"slug" + slug + index} onClick={(e) => { e.stopPropagation(); changeHandler(slug) }}>{slug}</Dropdown.Item>

                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className="mb-3 col-2" >
                        <Form.Label size="sm">RMA</Form.Label>
                        <Dropdown >
                            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-rma" >
                                {rmaFilter || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                filter={setRmaFilter}
                                type='rma'
                            >
                                <Dropdown.Item eventKey={"rm" + 1} key={"rm" + 1} onClick={() => { setRmaFilter('true') }}>{'Да'}</Dropdown.Item>
                                <Dropdown.Item eventKey={"rm" + 2} key={"rm" + 2} onClick={() => { setRmaFilter('false') }}>{'Нет'}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>


                </Row>}
                {
                    location.pathname === '/asset' && <Row>


                        {(platformFilter || cityFilter || countryFilter || platformFilter || searchText || slugFilter || rmaFilter) &&
                            <Col className="col-2">

                                <Button variant="outline-info" size="sm" style={{ width: "100%", height: '38px', margin: '10px 0' }} onClick={() => clearFilters()}>Сбросить фильтры</Button>
                            </Col>}
                    </Row>
                }

                <Row>
                    <Col >

                        <AssetList assetItems={assetItems} copyAsset={copyAsset} setCopyAsset={setCopyAsset} close={props.close} removeAsset={setDeletingAsset} currentAsset={currentAsset} setCurrentAsset={setCurrentAsset} />

                    </Col>
                </Row>
                <div ref={lastItem} style={{ height: 20 }} />
            </Col >
        </>
    );
}
export default AssetArea;