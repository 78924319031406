import React, { useState, useContext, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OrgService from "../../API/OrgService";
import Alert from 'react-bootstrap/Alert';
import Preloader from "../UI/Preloader/Preloader";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function OrgCreateForm(props) {

    const [isPreloader, setIsPreloader] = useState(false);
    const [orgName, setOrgName] = useState(props?.org?.name || "");
    const [vat, setVat] = useState(props?.org?.vat || "");
    const [website, setWebSite] = useState(props?.org?.website || "");
    const [slug, setSlug] = useState(props?.org?.slug || "");
    const [email, setEmail] = useState("");
    const [firstName, setFristName] = useState();
    const [lastName, setLastName] = useState();
    const [errors, setErrors] = useState([]);
    const [permisisons, setPermissions] = useState([]);
    const [permissionsObject, setPermissionsObject] = useState({});
    const [viewPermEdit, setViewPermEdit] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [orgType, setOrgType] = useState(props?.org?.type || props?.orgAddingType);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                createItem();
            })
            .catch(() => changeStatus())
    }
 const getValuePermissions=(read, full) => {
    if (full) return 2;
    else if (read && !full) return 1;
    else return 0
 }
    useEffect(()=> {
        if (!props.org) {
        (async () => {
            await OrgService.getPermissions()
            .then(res=> {setPermissions(res);
                const object = {}
                res.forEach((item)=> {object[item.slug] ={value: item.id===1? 0: 2, permission_section: item.id, slug: item.slug, name: item.name}})
                setPermissionsObject(object) })
          
            .catch((err)=> setErrors(err?.message))
        })()} 
        else {
            const object = {}
            Object.keys(props.org.permissions).forEach(function(key) {object[key] ={value: getValuePermissions(props.org.permissions[key].read_access, props.org.permissions[key].full_access), permission_section: props.org.permissions[key].permission_section_id, slug: key, name: props.org.permissions[key].name}})
            setPermissionsObject(object) 
        }
    },[props])

    const createItem = async (event) => {
        setIsPreloader(true)
        if (event) event.preventDefault();
        const org = {};
        org.type = props.orgAddingType;
        org.name = orgName;
        if (vat !== "") {
            org.vat = vat;
        };
        if (slug !== "") {
            org.slug = slug;
        };
        if (website !== "") org.website = website;
        org.permissions = []
        Object.keys(permissionsObject).forEach(function(key) {
            org.permissions.push({permission_section: permissionsObject[key].permission_section, read_access: permissionsObject[key].value === 1 ||  permissionsObject[key].value === 2, full_access: permissionsObject[key].value === 2})
        });
        if (lastName && firstName && email) {

            org.users = [{
                "email": email,
                "password": email,
                "first_name": firstName,
                "last_name": lastName
            }];
        };

        if (props?.org) {
            await OrgService.patchOrg(false, props.org.id, org).then(() => {
                setTimeout(() => {
                    props.setIsOrgAdding(false); setIsPreloader(false); setErrors([])
                }, 1500)
            })
                .catch((err) => {
                    if (err.message[0].includes('token')) refreshToken();
                    else {
                        setErrors(err.message); setIsPreloader(false);
                    }
                })
        } else {
            await OrgService.newOrg(false, false, org)
                .then(() => {
                    props.setOrgAdded(true);
                    props.setIsOrgAdding(false);
                    if (org.type === "ENDUSER") {
                        if (props.setCurEndUserFilter !== undefined) props.setCurEndUserFilter(org.name)
                        if (props?.setEndUserFilter !== undefined) props?.setEndUserFilter(org.name)
                    };
                    if (org.type === "PARTNER") {
                        if (props.setCurPartnerFilter !== undefined) props.setCurPartnerFilter(org.name)
                        if (props?.setPartnerFilter !== undefined) props?.setPartnerFilter(org.name)
                    };;
                    setErrors([]);
                })
                .catch((err) => {
                    if (err.message[0].includes('token')) refreshToken();
                    else {
                        setErrors(err.message); setIsPreloader(false);
                    }
                })
        }

    }
const formperm=(event)=> {
    const { name, value } = event.target;
    setPermissionsObject(prevState => ({
        ...prevState,
        [name]: {...prevState[name], value: Number(value)}
    }));

    
}

    return (
        <Row className="mb-3">
            {isPreloader && <Preloader />}
            <Col sm={3}>
                <Form onSubmit={createItem} className="was-validated">
                    <hr />
                    {errors?.length > 0 &&
                        errors?.map((error, index) => (
                            <Alert key={`'danger' + ${index}`} variant="danger">
                                {error}
                            </Alert>)
                        )
                    }
                    <h5>{props.title? props.title : 'Добавление новой организации'}</h5>
                    {
                        props.allTypes
                            ?
                            <Form.Group className="mb-4">
                                <Form.Label size="sm">Тип организации</Form.Label>
                                <Form.Select size="sm" onChange={(e) => props.setOrgAddingType(e.target.value)}>
                                    <option value="ENDUSER">Конечный пользователь</option>
                                    <option value="PARTNER">Партнер</option>
                                </Form.Select>
                            </Form.Group>
                            :
                            <Form.Group className="mb-4">
                                <Form.Label size="sm">Тип организации</Form.Label>
                                <Form.Control size="sm" value={orgType} disabled />
                            </Form.Group>

                    }

                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Наименование</Form.Label>
                        <Form.Control size="sm" type="text" value={orgName || ""} onChange={(e) => setOrgName(e.target.value)} required={true}/>
                        <Form.Text className="text-muted">
                            пример: ООО "Ромашка"
                        </Form.Text>
                    </Form.Group>
                                        
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">SLUG (опционально)</Form.Label>
                        <Form.Control size="sm" type="text" value={slug || ""} onChange={(e) => setSlug(e.target.value)} />
                        <Form.Text className="text-muted">
                            
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">ИНН (опционально)</Form.Label>
                        <Form.Control size="sm" type="text" value={vat || ""} onChange={(e) => setVat(e.target.value)} />
                        <Form.Text className="text-muted">
                            введите 9, 10 или 12 цифр без разделителей
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Веб-сайт (опционально)</Form.Label>
                        <Form.Control size="sm" type="text" value={website} onChange={(e) => setWebSite(e.target.value)} />
                        <Form.Text className="text-muted">
                            пример: https://www.test-org.ru
                        </Form.Text>
                    </Form.Group>
                    <Row>
                        <Col className="col-md-auto">
                            <Button variant="outline-danger"  style={{width: '100%', marginBottom: 20}} onClick={()=> setViewPermEdit(true)}>
                                Изменить доступы к разделам
                            </Button></Col>
                            </Row>
                    <Row>
                        <Col className="col-md-auto">
                            <Button variant="outline-success" type="submit" size="sm" >
                                Сохранить
                            </Button></Col>
                        <Col className="col-md-auto"><Button variant="outline-danger" onClick={(e) => props.setIsOrgAdding(false)} size="sm">
                            Отменить
                        </Button></Col>
                    </Row>
                </Form>
            </Col>
            {!props.org && (<Col sm={3}>
                <Form onSubmit={createItem}>
                    <hr />
                    <h5>Основной контакт (опционально)</h5>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Фамилия</Form.Label>
                        <Form.Control size="sm" type="text" value={lastName || ""} onChange={(e) => setLastName(e.target.value)} name='surname'/>
                    </Form.Group>
                    <Form.Group className="mb-5">
                        <Form.Label size="sm">Имя</Form.Label>
                        <Form.Control size="sm" type="text" value={firstName || ""} onChange={(e) => setFristName(e.target.value)} name='name'/>
                       <Form.Text>
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">email</Form.Label>
                        <Form.Control size="sm" type="text" value={email || ""} onChange={(e) => setEmail(e.target.value)} name='email'/>
                    </Form.Group>
                </Form >
            </Col>)}
           {viewPermEdit &&  <Col className="col-6">
            <hr />
            <Form onChange={formperm}>
            <h5 style={{marginBottom: 40}}>Доступ к разделам портала</h5>
            <div>
                {Object.keys(permissionsObject).map((item, index)=> {
             return    <div style={{display: "flex", flexDirection: "column", }} key={item + 'parent'}>
                
                <span style={{marginBottom: 5, fontWeight: 600, fontSize: 14}}>{permissionsObject[item]?.name}</span>
                <div className="btn-group">
  <input type="radio" className="btn-check" name={permissionsObject[item].slug} id={'pim' + permissionsObject[item].slug + index + '0'}  defaultChecked = {permissionsObject[item].value===0} value={0} />
  <label className="btn btn-default" htmlFor={'pim' + permissionsObject[item].slug + index + '0'} style={{width: 90}}>Без</label>
  <input type="radio" className="btn-check" name={permissionsObject[item].slug} id={'pim' + permissionsObject[item].slug + index + '1'}  defaultChecked = {permissionsObject[item].value===1} value={1}/>
  <label className="btn btn-default" htmlFor={'pim' + permissionsObject[item].slug + index + '1'} style={{width: 90}}>Чтение</label>
  <input type="radio" className="btn-check" name={permissionsObject[item].slug} id={'pim' + permissionsObject[item].slug + index + '2'}  defaultChecked = {permissionsObject[item].value===2} value={2}/>
  <label className="btn btn-default" htmlFor={'pim' + permissionsObject[item].slug + index + '2'} style={{width: 90}}>Полный</label>
</div>
<hr/>
</div>}
                )}
             {/* {permisisons?.map((item, index)=>  <div style={{display: "flex", flexDirection: "column", }} key={item.slug + 'parent'}>
                
                <span style={{marginBottom: 5, fontWeight: 600, fontSize: 14}}>{item?.name}</span>
                <div className="btn-group">
  <input type="radio" className="btn-check" name={item.slug} id={'pim' + item.slug + index + '0'}   value={0} />
  <label className="btn btn-default" htmlFor={'pim' + item.slug + index + '0'} style={{width: 90}}>Без</label>
  <input type="radio" className="btn-check" name={item.slug} id={'pim' + item.slug + index + '1'}   value={1}/>
  <label className="btn btn-default" htmlFor={'pim' + item.slug + index + '1'} style={{width: 90}}>Чтение</label>
  <input type="radio" className="btn-check" name={item.slug} id={'pim' + item.slug + index + '2'}  defaultChecked value={2}/>
  <label className="btn btn-default" htmlFor={'pim' + item.slug + index + '2'} style={{width: 90}}>Полный</label>
</div>
<hr/>
</div>)} */}
            </div>
            </Form>
           </Col>}
            <hr />
        </Row>
    );
}
export default OrgCreateForm;