/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";
import { AuthContext } from "../../context";
import MyModal from "../UI/MyModal/MyModal";
import Alert from 'react-bootstrap/Alert';
import AssetsList from "./AssetsList";
import UpdatesCloudService from "../../API/UpdatesCloudService";
import PlatformService from "../../API/PlatformService";
import Button from "react-bootstrap/esm/Button";
import Auth from "../../API/Auth";
import AddNewInstanceForm from "./AddNewInstanceForm";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ErrorList from "./ErrorList";
import OrgService from "../../API/OrgService";
function UpdatesCloudArea() {
    const [key, setKey] = useState('assets');
    const [errors, setErrors] = useState([]);
    const [page, setPage] = useState(0)
    const [assets, setAssets] = useState([]);
    const [filteredArray, setFilteredArray] = useState(null)
    const [withoutOrg, setWithoutOrg] = useState(false);
    const [isPreloader, setIsPreloader] = useState(false);
    const [platforms, setPlatforms] = useState([]);
    const [withoutRequest, setWithoutRequest] = useState(false);
    const [platformFilter, setPlatformFilter] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [switchActive, setSwitchActive] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(false);
    const [renewList, setRenewList] = useState(false)
    const [orderLastUpdate, setOrderLastUpdate] = useState(false);
    const [orderLastReq, setOrderLastReq] = useState(false);
    const [orderLastRN, setOrderLastRN] = useState(false);
    const [addInstance, setAddInstance] = useState(false);
    const [organisation, setOrganisation] = useState([]);
    const [clearItem, setClearItem] = useState(false);
    const [lastErrorFilter, setLastErrorFilter] = useState(false);
    const [withoutRenew, setWithoutRenew] = useState(false);
    const [activeFilter, setActiveFilter] = useState(false);
    const [typeFilter, setTypeFilter] = useState(false);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const appletStyleStats = {
        'border': '1px solid #0d6efd', 'padding': '0', 'borderRadius': '10px', 'position': "relative", 'boxSizing': "border-box",

        'WebkitBoxSizing': 'border-box', minHeight: '600px',

    }
    useEffect(() => {
        (async () => {
            if (switchActive === false && deleteItem === false && addInstance === false && clearItem === false) {
                setIsPreloader(true);
                resetFilter()
                await UpdatesCloudService.getAssetsList().then((resp) => { setAssets(resp.data); setErrors([]); setIsPreloader(false) }).catch((e) => { changeStatus(); setIsPreloader(false); setErrors(e.message); });
            }
        })()
    }, [switchActive, deleteItem, renewList, addInstance, clearItem]);

    useEffect(() => {
        (async () => {
            await PlatformService.getDropList().then((res) => { setPlatforms(res) }).catch((e) => { setIsPreloader(false); setErrors(e.message); });
            await OrgService.getDropList().then((res) => { setOrganisation(res) }).catch((e) => {  setIsPreloader(false); setErrors(e.message); })
        }
        )()
    }, [])

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'delete') deleteAsset(currentItem.serial)
                else if (type === 'clear ') clearLogs(currentItem.serial)
                else switchActivationAsset(currentItem)
            })
            .catch(() => changeStatus())
    }

    const calculateLastRequestDate = (date) => {
        const now = new Date();
        const lastReqDate = new Date(date);
        if (date === null) return true
        else if (now.getTime() - lastReqDate.getTime() > 86400000) return true
        else return false
    }
    const newTextFilter = (arr, filter, platform, withoutOrg, orderLastUpdate, orderLastReq, orderLastRN, withoutRequest, lastError, withoutRenew, activeFilter, typeFilter) => {
        setPage(0);
        let filtArrSn = arr?.filter((item) => item.serial.toLowerCase().includes(filter.toLowerCase()));
        let filtArrOrg = arr?.filter((item) => item.organisation__name !== null && item.organisation__name.toLowerCase().includes(filter.toLowerCase()));
        let filterIp = arr?.filter((item) => item.ip_list !== null ? item.ip_list.some(v => v.ip.indexOf(filter) >= 0) : false);
        let filtArrError = arr?.filter((item) => item.last_error !== null && item.last_error.toLowerCase().includes(filter.toLowerCase()));
        let allFilterArr = filtArrSn.concat(filtArrOrg).concat(filtArrError).concat(filterIp);
        let filterArr = allFilterArr.filter((i, index, c) => allFilterArr.findIndex(item => (item.serial === i.serial)) === index);
        let filterPlatform = []
        if (platform !== false) {
            if (platform === 'Без платформы') filterPlatform = filterArr.filter((item) => item.platform__name === null);
            else filterPlatform = filterArr.filter((item) => item.platform__name === platform);
        }
        else filterPlatform = filterArr.slice()

        let filterWithoutOrg = {};

        if (withoutOrg === true) filterWithoutOrg = filterPlatform.filter((item) => item.organisation__name === null);
        else filterWithoutOrg = filterPlatform.slice();

        let filterWithoutRequest = {};
        if (withoutRequest === true) filterWithoutRequest = filterWithoutOrg.filter((item) => item.active_flag === 1 && calculateLastRequestDate(item.datetime_last_request));
        else filterWithoutRequest = filterWithoutOrg.slice();
        let filterLasterror = {}
        if (lastError === true) filterLasterror = filterWithoutRequest.filter((item) => item.last_error !== null)
        else filterLasterror = filterWithoutRequest.slice();
        let filterWithoutRenew = {}
        if (withoutRenew === true) filterWithoutRenew = filterLasterror.filter((item) => item.datetime_last_get_update === null)
        else filterWithoutRenew = filterLasterror.slice();

        let filterActive = []
        if (activeFilter !== false) {
            if (activeFilter === 'Активен') filterActive = filterWithoutRenew.filter((item) => item.active_flag === 1);
            else filterActive = filterWithoutRenew.filter((item) => item.active_flag !== 1);
        }
        else filterActive = filterWithoutRenew.slice();

        let filterType = {};
        if (typeFilter) filterType = filterActive.filter((item) => item.device_type === typeFilter)
        else filterType = filterActive.slice();

        let filterOrderLastReq = [];
        if (orderLastReq === '↑') filterOrderLastReq = filterType.sort((a, b) => a.datetime_last_request !== null ? b.datetime_last_request !== null ? changeDate(a.datetime_last_request) - changeDate(b.datetime_last_request) : -1 : 1);
        else if (orderLastReq === '↓') { filterOrderLastReq = filterType.sort((a, b) => a.datetime_last_request !== null ? b.datetime_last_request !== null ? changeDate(b.datetime_last_request) - changeDate(a.datetime_last_request) : -1 : 1); }
        else if (orderLastReq === false) { filterOrderLastReq = filterType.sort((a, b) => a.date_modified !== null ? b.date_modified !== null ? changeDate(b.date_modified) - changeDate(a.date_modified) : 1 : 1); }
        let filterOrderLastUpdate = [];
        if (orderLastUpdate === '↑') filterOrderLastUpdate = filterOrderLastReq.sort((a, b) => a.datetime_last_get_update !== null ? b.datetime_last_get_update !== null ? changeDate(a.datetime_last_get_update) - changeDate(b.datetime_last_get_update) : -1 : 1);
        else if (orderLastUpdate === '↓') { filterOrderLastUpdate = filterOrderLastReq.sort((a, b) => a.datetime_last_get_update !== null ? b.datetime_last_request !== null ? changeDate(b.datetime_last_get_update) - changeDate(a.datetime_last_get_update) : -1 : 1); }
        else if (orderLastUpdate === false) { filterOrderLastUpdate = filterOrderLastReq.slice() }
        let filterOrderLastRN = [];
        if (orderLastRN === '↑') filterOrderLastRN = filterOrderLastUpdate.sort((a, b) => a.datetime_last_get_rn !== null ? b.datetime_last_get_rn !== null ? changeDate(a.datetime_last_get_rn) - changeDate(b.datetime_last_get_rn) : -1 : 1);
        else if (orderLastRN === '↓') { filterOrderLastRN = filterOrderLastUpdate.sort((a, b) => a.datetime_last_get_rn !== null ? b.datetime_last_get_rn !== null ? changeDate(b.datetime_last_get_rn) - changeDate(a.datetime_last_get_rn) : -1 : 1); }
        else if (orderLastRN === false) { filterOrderLastRN = filterOrderLastUpdate.slice() }


        setFilteredArray(filterOrderLastRN);
    }

    const changeDate = (date) => {

        if (date) {
            const formatDate = date?.split(' ');
            const reverseDate = formatDate[1].split('.').reverse().join('-') + " " + formatDate[0];
            const actualDate = new Date(reverseDate);

            return actualDate.getTime()
        }
        else return null
    }

    const handleClick = (index) => {

        if (index === 0) setPage(0)
        else setPage(index)

    }

    const newPaginationString = (count) => {
        let paginationArr = []
        let arrPages = [];
        let allPages = Math.floor(count / 20);
        if (count % 20 === 0) allPages = Math.floor(count / 20) - 1;
        for (let i = 0; i <= allPages; i++) { arrPages.push(i) };
        if (count) {
            if (allPages <= 10)
                return arrPages.map((item, index) => <li key={'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => handleClick(index)} >{index + 1}</button></li>)
            else if (page < 7 && allPages > 10) {
                paginationArr = arrPages.slice(0, 8); paginationArr.push('...', arrPages.length - 1); return paginationArr.map((item, index) => <li key={'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => item !== '...' && handleClick(item)} >{item !== "..." ? item + 1 : item}</button></li>)
            }
            else if (page > arrPages.length - 7 && allPages > 10) {
                paginationArr = arrPages.slice(arrPages.length - 8, arrPages.length); paginationArr.unshift(0, '...'); return paginationArr.map((item, index) => <li key={item === '...' ? item + index : 'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => item !== '...' && handleClick(item)} >{item !== "..." ? item + 1 : item}</button></li>)
            }
            else { paginationArr = arrPages.length > 0 && arrPages.slice(page - 3, page + 4); paginationArr.unshift(0, '...'); paginationArr.push('...', arrPages.length - 1); return paginationArr.map((item, index) => <li key={item === '...' ? item + index : 'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => item !== '...' && handleClick(item)} >{item !== "..." ? item + 1 : item}</button></li>) }
        }
    }

    const switchActivationAsset = async (item) => {
        let param;
        setIsPreloader(true);
        if (item.active_flag === 1) param = 0
        else param = 1
        await UpdatesCloudService.switchActivate(item.serial, param)
            .then(() => { setIsPreloader(false); setSwitchActive(false) })
            .catch((e) => {
                if (e.message[0].includes('token')) refreshToken('put');
                else { setIsPreloader(false); setErrors(e.message); }
            });
    }
    const deleteAsset = async (serial) => {
        setIsPreloader(true);
        await UpdatesCloudService.deleteSerial(serial)
            .then(() => { setIsPreloader(false); setDeleteItem(false) })
            .catch((e) => {
                if (e.message[0].includes('token')) refreshToken('delete');
                else { setIsPreloader(false); setErrors(e.message); }
            });
    }
    const clearLogs = async (serial) => {
        setIsPreloader(true);
        await UpdatesCloudService.clearAssetLogs(serial)
            .then(() => { setIsPreloader(false); setClearItem(false) })
            .catch((e) => {
                if (e.message[0].includes('token')) refreshToken('clear');
                else { setIsPreloader(false); setErrors(e.message); }
            });
    }
    const resetFilter = () => {
        setFilteredArray(null);
        setSearchText('');
        setPlatformFilter(false);
        setWithoutOrg(false);
        setOrderLastUpdate(false);
        setOrderLastReq(false);
        setOrderLastRN(false);
        setWithoutRequest(false);
        setWithoutRenew(false);
        setLastErrorFilter(false);
        setActiveFilter(false);
        setTypeFilter(false)
    }
    return (
        <Col >
            {switchActive && <MyModal visible={switchActive} setVisible={setSwitchActive} >
                <Row className="mb-4" style={{ position: "relative" }}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4">Вы точно хотите {currentItem.active_flag !== 1 ? 'предоставить' : 'отключить'} доступ {<br />}для устройства {currentItem.serial}?</h3>
                </Row>
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); switchActivationAsset(currentItem) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setSwitchActive(false); setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row>
            </MyModal>}
            {deleteItem && <MyModal visible={deleteItem} setVisible={setDeleteItem} >
                <Row className="mb-4" style={{ position: "relative" }}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4">Вы точно хотите удалить устройство {<br />}{currentItem.serial} с облака?</h3>
                </Row>
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); deleteAsset(currentItem.serial) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeleteItem(false); setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row>
            </MyModal>}
            {clearItem && <MyModal visible={clearItem} setVisible={setClearItem} >
                <Row className="mb-4" style={{ position: "relative" }}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4">Вы точно хотите удалить логи{<br />} устройства {currentItem.serial}?</h3>
                </Row>
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); clearLogs(currentItem.serial) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setClearItem(false); setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row>
            </MyModal>}
            <Row className='mt-3 mb-4'>

                <Col className='col-6'><h3>UPDATES CLOUD</h3></Col>
            </Row>
            <Row>
                <Col className="col-2 mt-2 mb-2">
                    {!addInstance && key === 'assets' && <Button variant="outline-success" size="sm" style={{ width: '100%' }} onClick={(e) => { setAddInstance(true) }}>Добавить запись</Button>}
                </Col>
            </Row>
            {addInstance && <AddNewInstanceForm setAddInstance={setAddInstance} />}
            <Row className='mt-3' style={{ minHeight: '300px', margin: '0 auto', padding: 0 }}>
                <Col className='col-12' style={appletStyleStats}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <Tabs
                        id="UC-tabs"
                        activeKey={key}
                        onSelect={(key) => setKey(key)}
                        className="mb-3"
                    >
                        <Tab eventKey="assets" title="Устройства" >
                            <AssetsList platforms={platforms} orderLastRN={orderLastRN} setOrderLastRN={setOrderLastRN}
                                typeFilter={typeFilter} setTypeFilter={setTypeFilter}
                                switchActive={switchActive} setSwitchActive={setSwitchActive} setDeleteItem={setDeleteItem}
                                setClearItem={setClearItem}
                                setLastErrorFilter={setLastErrorFilter} lastErrorFilter={lastErrorFilter}
                                renewList={renewList} setRenewList={setRenewList}
                                setCurrentItem={setCurrentItem} currentItem={currentItem}
                                setWithoutRequest={setWithoutRequest} withoutRequest={withoutRequest}
                                setActiveFilter={setActiveFilter} activeFilter={activeFilter}
                                setWithoutRenew={setWithoutRenew} withoutRenew={withoutRenew}
                                orderLastUpdate={orderLastUpdate} setOrderLastUpdate={setOrderLastUpdate} orderLastReq={orderLastReq} setOrderLastReq={setOrderLastReq}
                                filteredArray={filteredArray} setFilteredArray={setFilteredArray} textFilter={newTextFilter} withoutOrg={withoutOrg} setWithoutOrg={setWithoutOrg}
                                errors={errors} page={page} setPage={setPage} assets={assets} handleClick={handleClick} paginationString={newPaginationString}
                                platformFilter={platformFilter} setPlatformFilter={setPlatformFilter}
                                searchText={searchText} setSearchText={setSearchText} resetFilter={resetFilter}
                            />
                        </Tab>
                        <Tab eventKey="errors" title="Ошибки">
                            {key === 'errors' && <ErrorList platforms={platforms} organisation={organisation} />}
                        </Tab>
                    </Tabs>

                </Col>
            </Row>

        </Col>
    )
}
export default UpdatesCloudArea;