/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Preloader from "../UI/Preloader/Preloader";
import Button from "react-bootstrap/Button";
import EdlList from "./EdlList";
import AddEdlForm from "./AddEdlForm";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import MyModal from "../UI/MyModal/MyModal";
import { AuthContext } from "../../context";
import Alert from "react-bootstrap/Alert";
import EdlService from "../../API/EdlService";
import PatchEdlForm from "./PatchEdlForm";
import EdlItemCard from "./EdlItemCard";
import PlatformService from "../../API/PlatformService";
import EdlPlatformList from "./EdlPlatformList";
import EdlSubsList from "./EdlSubsList";
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import EditService from "../Assets/EditService";
import EdlItem from "./EdlItem";
import { Table } from "react-bootstrap";
import PatchEdlTrialForm from "./PatchEdlTrialForm";

function EdlTrialList({renew, setRenew}) {
    const [isPreloader, setIsPreloader] = useState(false);
    const [key, setKey] = useState("access");
    const [errors, setErrors] = useState([]);
    const [viewDetail, setViewDetail] = useState(false);
    const [currentItem, setCurrentItem] = useState({});

    //пагинация
    const [isListLoading, setIsListLoading] = useState(false);
    const [nextPage, setNextPage] = useState(null);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [isAddingInstance, setIsAddingInstance] = useState(false);
    const [patchItem, setPatchItem] = useState(false);
    const [deleteItemTrial, setDeleteItemTrial] = useState(false);
    const [successDelete, setSuccessDelete] = useState(false);
    const [edlItems, setEdlItems] = useState();

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const basicStyles = {
        fontSize: "10px",
    };

    const fetchData = async (add) => {
        let filters = false;

        filters = {
            is_trial: true,
            has_lists: true,
        };

        setIsPreloader(true);
        setIsListLoading(true);

        await EdlService.getList(filters, add ? nextPage : 1)
            .then((res) => {
                if (add === "add") {
                    res.results?.map((item) =>
                        setEdlItems((arr) => [...arr, item])
                    );
                    setNextPage(res.next ? nextPage + 1 : null);
                } else {
                    setEdlItems(res.results);
                    setNextPage(res.next ? 2 : null);
                }
                setIsListLoading(false);
            })
            .catch((err) => {
                changeStatus();
                setIsListLoading(false);
                setErrors(err.message);
            })
            .finally(() => setIsPreloader(false));
    };
    useEffect(() => {
        if (!isAddingInstance && !patchItem) {
            setIsPreloader(true);
            const timerRefresh = setTimeout(fetchData, 1000);
            return () => {
                clearTimeout(timerRefresh);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddingInstance, patchItem, successDelete]);

    // useEffect(() => {

    //     (async () => {
    //         setNextPage(null);
    //         let filters = false;

    //         if (searchText || statusFilter) {
    //             filters = {

    //                 searchText: searchText,
    //                 statusFilter: statusFilter
    //             };
    //         }

    //         setIsPreloader(true);
    //         setIsListLoading(true);

    //         await EdlService.getList(filters, false)
    //             .then((res) => { setEdlItems(res.results); setNextPage(res.next); setIsListLoading(false); })
    //             .catch((err) => { changeStatus(); setIsListLoading(false); setErrors(err.message) }).finally(() => setIsPreloader(false))

    //     })();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isAddingInstance, searchText, statusFilter, patchItem]);

    useEffect(() => {
        (async () => {
            if (needToLoad && nextPage) {
                await fetchData("add");
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    useEffect(() => {
        if (isListLoading) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);
    }, [isListLoading]);

    
    const deleteInstance = async (id) => {
        setIsPreloader(true);
        await EdlService.deleteItem(id)
            .then(() => {
                setIsPreloader(false);
                setDeleteItemTrial(false);
                setSuccessDelete(!successDelete);
            })
            .catch((e) => {
                setIsPreloader(false);
                setErrors(e.message);
            });
    };

    return (
        <>
        {deleteItemTrial && <MyConfirm visible={deleteItemTrial} setVisible={setDeleteItemTrial} >
                <Row className="mb-4" style={{ position: "relative" }}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4">Вы точно хотите удалить доступ {currentItem.org_name}?</h3>
                </Row>
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); deleteInstance(currentItem.id) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeleteItemTrial(false); setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row>
            </MyConfirm>}
            {viewDetail && (
                <MyModal
                    visible={viewDetail}
                    setVisible={setViewDetail}
                    align={true}
                >
                    <div
                        style={{
                            width: "50vw",
                            margin: "0 auto",
                            maxHeight: "80vh",
                            overflowY: "scroll",
                        }}
                    >
                        <EdlItemCard data={currentItem} trial={true}/>
                    </div>
                </MyModal>
            )}

            <Col>
                <Row>
                    {" "}
                    {errors.length > 0 &&
                        errors.map((error) => (
                            <Alert key="danger" variant="danger">
                                {error}
                            </Alert>
                        ))}
                </Row>
                {patchItem && (
                    <PatchEdlTrialForm
                        item={currentItem}
                        setPatchItem={setPatchItem}
                        renew={renew}
                        setRenew={setRenew}
                    />
                )}
                <Row>
                    <Col style={{ position: "relative" }}>
                        <Table striped bordered hover id="tableEdl">
                            <thead style={basicStyles}>
                                <tr>
                                    <th>Организация</th>
                                    <th>Начало доступа</th>
                                    <th>Статус</th>
                                    <th>
                                        Списки
                                        <br /> (актив/неактив)
                                    </th>
                                    <th>Всего записей</th>
                                    <th style={{ width: "auto" }}>Активировать</th>
                                    <th style={{ width: "5%" }}>Удалить</th>
                                </tr>
                            </thead>
                            <tbody>
                                {edlItems?.map((item, index) => (
                                    <EdlItem
                                        item={item}
                                        key={item.id + "edlIt"}
                                        setCurrentItem={setCurrentItem}
                                        setPatchItem={setPatchItem}
                                        setViewDetail={setViewDetail}
                                        currentItem={currentItem}
                                        setDeleteItem={setDeleteItemTrial}
                                        trial={true}
                                    />
                                ))}
                            </tbody>
                        </Table>
                        {isPreloader && <Preloader />}
                    </Col>
                </Row>
                <div ref={lastItem} style={{ height: 20 }} />
            </Col>
        </>
    );
}
export default EdlTrialList;
