import React, { useEffect, useState, useContext } from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import CloudStatusService from "../../API/CloudStatusService";
import CloudList from "./CloudList";
import Alert from 'react-bootstrap/Alert';
import CloudListService from "./CloudListService";
import CloudListStorage from "./CloudListStorage";
import Preloader from "../UI/Preloader/Preloader";
import { AuthContext } from "../../context";


function CloudStatusArea() {
    const [errors, setErrors] = useState([]);
    const [update, setUpdate] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [data, setData] = useState(null);
    //контекст
    const { setStatus, status } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {
        (async () => {
            setIsLoader(true)
            await CloudStatusService.checkCloudStatus()
            .then((res) => setData(res))
            .catch((err) => 
            {if (err.message.length> 0 && err.message[0].includes('token')) changeStatus();
            else setErrors(err.message);})
            .finally(() => setIsLoader(false))

        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update, status])

    const changeViewDate = (date) => {

        let row = date?.split(' ');

        return row[1] + ' ' + row[0].split('-').reverse().join('.')
    }
    return (
        <>
            <Col>
                {isLoader && <Preloader />}
                <h3>Cloud Status</h3>
                <hr />
                <Row className='mb-3'>
                    <Col sm={2} colSpan={1} className='mb-4'>

                        <Button
                            variant="outline-success"
                            size="sm"
                            style={{ width: '100%' }}
                            onClick={() => setUpdate(!update)}
                        >Обновить</Button>
                    </Col>
                </Row>
                <Row> {errors.length > 0 &&
                    errors.map((error, index )=>
                        <Alert key={'danger' + index} variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <b>Время обновления: </b> {data && changeViewDate(data?.generated.replace('T', ' '))}
                        <br />
                        <b>Занятое место: </b> {data?.used_space}
                        <br />
                        <b>SN в базе FW: </b>{data?.services[4].serials.fw_serials} <b>PANORAMA: </b> {data?.services[4].serials.panorama_serials}
                        <br />
                        <hr />

                    </Col>
                </Row>
                <Row>
                    <Col className="col-2">
                        <b>Состояние облака</b>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {data && <CloudList data={data?.services[5].cloud_status} />}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="col-2">
                        <b>Службы</b>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {data && <CloudListService data={data?.services} />}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="col-3">
                        <b>Состояние хранилища</b>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {data && <CloudListStorage data={data.services[0].updates_service.storage} />}
                    </Col>
                </Row>
            </Col>
        </>
    )
}

export default CloudStatusArea;