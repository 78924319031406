export default class CoreService {

    // static quotaSum(ar) {
    //   
    //     var sum = 0;
    //     for (var i = 0; i < ar.length; i++) {
    //         sum += parseFloat(ar[i].price * ar[i].amount);
    //     }
    //   
    //     return Number(sum.toFixed(2));
    // };

    static quotaSum(ar) {
   
        var sumWithoutDiscount = 0; var sumWithDiscount  = 0; var summary =[0, 0];
        for (var i = 0; i < ar.length; i++) {
           
            sumWithoutDiscount += parseFloat(ar[i].price * ar[i].amount);
            const disc = (100 - ar[i].discount) / 100;
            sumWithDiscount += parseFloat(ar[i].price * ar[i].amount * disc);
           
            summary[0] = Number(sumWithoutDiscount.toFixed(2));
            summary[1] = Number(sumWithDiscount.toFixed(2));
          
        }
    
        // return Number(sumWithoutDiscount.toFixed(2));
        return summary;
    };
    static makeDecription(data) {
 
        const uniquePlatforms = [...new Set(data.map(item => item.platform_name))];
        
        // const counts = {};
        // for (const num of data.map(item => item.platform_name)) {
        //     counts[num] = counts[num] ? counts[num] + 1 : 1;
        // };
      
        return uniquePlatforms;
    };

    static addMonths(date, months) {
        var d = date.getDate();
        date.setMonth(date.getMonth() + +months);
        if (date.getDate() !== d) {
          date.setDate(0);
        }
        return date;
    };

}