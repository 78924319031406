import React, { useState, useEffect, useMemo, useContext } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import PriceService from "../../API/PriceService";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PriceList from "../Price/PriceList";
import CoreService from "../../core/CoreService";
import QuotaItemsList from "./QuotaItemsList";
import QuotaService from "../../API/QuotaService";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import MySelect from "../UI/MySelect";
// import Validation from "../../core/Validation";
import Dropdown from "react-bootstrap/Dropdown";
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import OrgService from "../../API/OrgService";
import Preloader from "../UI/Preloader/Preloader";
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";

function EditQuotaForm(props) {
    registerLocale("ru", ru);
    const [partners, setPartners] = useState();
    const [priceitems, setPriceitems] = useState();
    const [curPlatform, setCurPlatform] = useState("");
    const [platformFilter, setPlatformFilter] = useState(false);
    const [curCategory, setCurCategory] = useState("");
    const [categoryFilter, setCategoryFilter] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [quotaItems, setQuotaItems] = useState([]);
    const [quotaDescription, setQuotaDescription] = useState("");
    const [quotaExpiringDate, setQuotaExpiringDate] = useState(
        CoreService.addMonths(new Date(), 1)
    );
    const [errors, setErrors] = useState([]);
    const [currencyRate, setCurrencyRate] = useState("");
    const [discount, setDiscount] = useState();
    const [quotaToEdit, setQuotaToEdit] = useState();
    const [isPreloader, setIsPreloader] = useState(false);
    const [isPartner, setIsPartner] = useState("");
    const [isMain, setIsMain] = useState(false);
    const quotaSum = useMemo(
        () => CoreService.quotaSum(quotaItems),
        [quotaItems]
    );
    const [curPriceVersion, setCurPriceVersion] = useState({});
    const [user, setUser] = useState("");
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const removeItem = (item, index) => {
        setQuotaItems([
            ...quotaItems.slice(0, index),
            ...quotaItems.slice(index + 1),
        ]);
    };

    const addItem = (item) => {
        const newItem = structuredClone(item);
        newItem.amount = 1;
        setQuotaItems((arr) => [...arr, newItem]);
    };

    const changeItemQty = (item, amount) => {
        let itemIndex = quotaItems.indexOf(item);
        if (itemIndex >= 0) {
            let newArr = [...quotaItems];
            newArr[itemIndex].amount = parseInt(amount);
            setQuotaItems(newArr);
        }
    };
    const changeDiscount = (item, discount) => {
        let itemIndex = quotaItems.indexOf(item);
        if (itemIndex >= 0) {
            let newArr = [...quotaItems];
            newArr[itemIndex].discount = discount;
            setQuotaItems(newArr);
        }
    };
    useEffect(() => setIsPartner(quotaToEdit?.partner_org), [quotaToEdit]);
    // useEffect(() => {

    //     (async () => {
    //         setIsPreloader(true);
    //         let filters = { 'type': 'PARTNER' };
    //         await OrgService.getList(filters, false, true).then((res) => {
    //             setPartners(res.results);
    //         }).finally(() => setIsPreloader(false));

    //     })();

    // }, []);

    useEffect(() => {
        (async () => {
            await QuotaService.getItem(props.quotaId).then((res) => {
                setQuotaToEdit(res);
                setCurrencyRate(res.currency_rate);
                setDiscount(res.discount);
                setQuotaItems(res.items);
                setIsMain(res.is_main);
                setUser(res.owner);
                var date = res.date_expires?.split(".");
                setQuotaExpiringDate(
                    date ? new Date(date[2], date[1] - 1, date[0]) : null
                );
                setQuotaDescription(res.description);
            });

            await PriceService.getPriceConfig(1).then((res) => {
                setCurPriceVersion({
                    dateCreated: res.active_priceversion_date,
                    description: res.active_priceversion_comment,
                });
            });
        })();
    }, [props.quotaId]);

    useEffect(() => {
        (async () => {
            let filters = false;
            if (platformFilter || categoryFilter || searchText) {
                filters = {
                    platformFilter: platformFilter,
                    categoryFilter: categoryFilter,
                    searchText: searchText,
                };
            }
            await PriceService.getList(filters, false).then((res) =>
                setPriceitems(res.results)
            );
        })();
    }, [platformFilter, categoryFilter, searchText]);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem("freshToken"))
            .then((res) => {
                localStorage.setItem("token", res.data.access);
                setErrors([]);
                saveQuota();
            })
            .catch(() => changeStatus());
    };

    const saveQuota = async (event) => {
        if (event) event.preventDefault();
        const quota = {};
        setIsPreloader(true);
        quota.items = quotaItems;
        if (isPartner === "Без партнера") quota.partner_org = null;
        else quota.partner_org = isPartner;
        quota.is_main = isMain;
        quota.description = quotaDescription;
        if (quotaDescription === "") {
            const description =
                CoreService.makeDecription(quotaItems).join(" ");
            quota.description = "Платформы: " + description;
        }
        quota.date_expires = quotaExpiringDate
            ? quotaExpiringDate.toISOString().split("T")[0]
            : null;
        // quota.date_expires = quotaExpiringDate.toISOString().split('T')[0];
        quota.currency_rate = currencyRate;
        quota.owner = user.id;
        quota.discount = discount || 0;

        try {
            await QuotaService.updateQuota(props.quotaId, quota);
            setTimeout(() => {
                setIsPreloader(false);
                props.clearFilters();
                props.setIsQuotaEdit(false);
                props.setQuotaToEditID();
                props.setQuotaAdded(true);
                setErrors([]);
            }, 1000);
        } catch (err) {
            if (err.message[0].includes("token")) refreshToken();
            else {
                setErrors(err.message);
                setIsPreloader(false);
            }

            setIsPreloader(false);
            setErrors(err.message);
        }

        // }
    };

    return (
        <Form onSubmit={saveQuota}>
            {isPreloader && <Preloader />}
     
                
                    <Row>
                        <Col className="col-2">
                            <Form.Group className="mb-4">
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    style={{ width: "100%", height: "38px" }}
                                    onClick={() => {
                                        props.clearFilters();
                                        props.setIsQuotaEdit(false);
                                        props.setQuotaToEditID();
                                    }}
                                >
                                    Отменить
                                </Button>
                            </Form.Group>
                        </Col>
                        <Col className="col-2">
                            <Form.Group className="mb-4">
                                <Button
                                    variant="outline-primary"
                                    style={{ width: "100%", height: "38px" }}
                                    size="sm"
                                    type="submit"
                                >
                                    Сохранить
                                </Button>
                                <br />
                            </Form.Group>
                        </Col>
                    </Row>
                    {errors.length > 0 &&
                        errors.map((error) => (
                            <Row>
                                <Col>
                                    <Alert key="danger" variant="danger">
                                        {error}
                                    </Alert>
                                </Col>
                            </Row>
                        ))}

                    <Row>
                        <Col className="col-2">
                            <div
                                className="form-check form-switch"
                                style={{
                                    minHeight: 0,
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Form.Label
                                    size="sm"
                                    style={{ marginLeft: "-40px" }}
                                >
                                    Main
                                </Form.Label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="statusChecks"
                                    checked={isMain}
                                    style={{ height: "25px", width: "50px" }}
                                    onChange={(evt) =>
                                        setIsMain(evt.target.checked)
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <Form.Group className="mb-2">
                                <Form.Label size="sm">Owner</Form.Label>

                                <Dropdown
                                    style={{
                                        border: "1px solid green",
                                        height: 31,
                                        borderRadius: 6,
                                        paddingLeft: ".5rem",
                                    }}
                                >
                                    <Dropdown.Toggle
                                        as={MyToggle}
                                        id="dropdown-users"
                                    >
                                        {user
                                            ? user.first_name +
                                              " " +
                                              user.last_name
                                            : "выбрать"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        as={MyMenu}
                                        type={"User"}
                                        filterQa={true}
                                    >
                                        {props?.users?.map((v) => (
                                            <Dropdown.Item
                                                eventKey={"u" + v.id}
                                                key={"u" + v.id}
                                                onClick={() => {
                                                    setUser(v);
                                                }}
                                            >{`${v.first_name} ${v.last_name}`}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-4">
                            <Form.Group className="mb-4">
                                <Form.Label size="sm">
                                    Конечный заказчик{" "}
                                </Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    id="Qend"
                                    disabled
                                    value={quotaToEdit?.enduser_org || ""}
                                />
                                <Form.Text className="text-muted"></Form.Text>
                            </Form.Group>
                        </Col>
                        <Col className="col-4">
                            <Form.Group className="mb-4">
                                <Form.Label size="sm">Партнер</Form.Label>

                                <Dropdown
                                    style={{
                                        border: "1px solid green",
                                        height: 31,
                                        borderRadius: 6,
                                        paddingLeft: ".5rem",
                                    }}
                                >
                                    <Dropdown.Toggle
                                        as={MyToggle}
                                        id="dropdownQpart"
                                    >
                                        {isPartner || "выбрать"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={MyMenu}>
                                        <Dropdown.Item
                                            eventKey={"ewp"}
                                            onClick={() => {
                                                setIsPartner("Без партнера");
                                            }}
                                        >
                                            Без партнера
                                        </Dropdown.Item>
                                        {props?.partnerorganizations?.map(
                                            (org) => (
                                                <Dropdown.Item
                                                    eventKey={"e" + org.id}
                                                    key={"e" + org.id}
                                                    onClick={() => {
                                                        setIsPartner(org.name);
                                                    }}
                                                >
                                                    {org.name}
                                                </Dropdown.Item>
                                            )
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>{" "}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={1}>
                            <Form.Group className="mb-4">
                                <Form.Label>Курс</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    value={currencyRate}
                                    onChange={(e) =>
                                        setCurrencyRate(e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={3}>
                            <div style={{ fontSize: 16, marginBottom: 10 }}>
                                <Form.Label>
                                    Прайс по которому считалось:
                                </Form.Label>
                                <br />
                                <Badge bg="info">
                                    {quotaToEdit?.active_priceversion_comment}
                                    <br /> загружен{" "}
                                    {quotaToEdit?.active_priceversion_date}
                                </Badge>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div style={{ fontSize: 16, marginBottom: 10 }}>
                                <Form.Label>Текущий прайс:</Form.Label>
                                <br />
                                <Badge bg="primary">
                                    {curPriceVersion?.description}
                                    <br /> загружен{" "}
                                    {curPriceVersion?.dateCreated}
                                </Badge>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {quotaItems && (
                                <QuotaItemsList
                                    quotaItems={quotaItems}
                                    quotaSum={quotaSum}
                                    // discount={discount}
                                    currencyRate={currencyRate}
                                    removeItem={removeItem}
                                    changeItemQty={changeItemQty}
                                    changeDiscount={changeDiscount}
                                />
                            )}
                        </Col>
                    </Row>
                    {quotaItems.length > 0 && (
                        <Row>
                            <Col sm={4}>
                                <Form.Group className="mb-4">
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        type="text"
                                        placeholder="Описание квоты..."
                                        value={quotaDescription}
                                        size="sm"
                                        onChange={(e) =>
                                            setQuotaDescription(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                Срок действия квоты
                                <br />
                                <DatePicker
                                    dateFormat="dd.MM.yyyy"
                                    selected={quotaExpiringDate}
                                    onChange={(date) =>
                                        setQuotaExpiringDate(date)
                                    }
                                    locale="ru"
                                />
                            </Col>
                            <hr />
                        </Row>
                    )}
                    <Row>
                        <Col sm={2}>
                            <Form.Control
                                onChange={(e) => setSearchText(e.target.value)}
                                placeholder="Начните вводить..."
                                size="sm"
                                value={searchText}
                            />
                        </Col>
                        <Col sm={2}>
                            <Form.Group className="mb-4">
                                <MySelect
                                    platforms={props.platforms}
                                    setPlatformFilter={setPlatformFilter}
                                    setCurPlatform={setCurPlatform}
                                    value={curPlatform}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group className="mb-4">
                                <MySelect
                                    categories={props.categories}
                                    setCategoryFilter={setCategoryFilter}
                                    setCurCategory={setCurCategory}
                                    value={curCategory}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            {(platformFilter ||
                                categoryFilter ||
                                searchText) && (
                                <Button
                                    variant="outline-info"
                                    size="sm"
                                    onClick={() => {
                                        setCurPlatform("");
                                        setPlatformFilter(false);
                                        setCurCategory("");
                                        setCategoryFilter(false);
                                        setSearchText("");
                                    }}
                                >
                                    Сбросить фильтр
                                </Button>
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <PriceList
                                priceitems={priceitems}
                                isQuotaAdding={true}
                                addItem={addItem}
                            />
                        </Col>
                    </Row>
   
          
        </Form>
    );
}
export default EditQuotaForm;
