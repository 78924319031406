import React, { useContext, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Routes, Route} from 'react-router-dom';
import { vendorRoutes, publicRoutes, otherPrivateRoutes, managerRoutes } from "../router";
import { AuthContext, UserContext } from "../context";
import Auth from "../API/Auth";

function AppRouter() {
  const {isAuth, logout, status} = useContext(AuthContext);
  const handleLogout =()=> logout();
  //const {isAuth} = useContext(AuthContext);
  const {userinfo} = useContext(UserContext);
  const token = localStorage.getItem('token');

  useEffect(() => {
   
    (async () => {
   
        const refreshToken = localStorage.getItem('freshToken');
        const reviewToken = localStorage.getItem('tokenReview');
        if (reviewToken === 'true' && refreshToken!==null) 
            Auth.refreshToken(refreshToken).then((res) => {localStorage.setItem('token', res.data.access); localStorage.removeItem('tokenReview'); window.location.reload()}).catch((er) => handleLogout())
    })()
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [status])


  return (<>
   
      {isAuth && token !==null
        ?
          userinfo!==null && userinfo['organizationType'] === "VENDOR" && userinfo.role!=='MANAGER'
          ?
            <Routes>
              {vendorRoutes.map(route => 
                <Route path={route.path} element={route.component} key={route.path} />
              )}
            </Routes>
          : userinfo!==null && userinfo.role==='MANAGER' ? <Routes>
          {managerRoutes.map(route => 
            <Route path={route.path} element={route.component} key={route.path} />
          )} 
        </Routes>
          : userinfo!==null &&<Routes>
          {otherPrivateRoutes.map(route => 
            <Route path={route.path} element={route.component} key={route.path} s/>
          )}
        </Routes>
            
        :
        <Routes>
          {publicRoutes.map(route => 
            <Route path={route.path} element={route.component} key={route.path} />
          )}
        </Routes>
      }
 
    </>
  );
}

export default AppRouter;
