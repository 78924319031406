import React, { useEffect, useState } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import DashboardService from "../../API/DashboardService";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Colors } from 'chart.js';

function DashboardContractDoug(props) {

    const [contractStats, setContractStats] = useState(null);

    ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels, Colors);
    useEffect(() => {
        (async () => {
            await DashboardService.getContractStats().then((res) => setContractStats(res))

        })()
    }, [])

    const data = {
        labels: ['EXPIRED', 'ACTIVE'],
        datasets: [
            {
                datalabels: {
                    color: 'black',
                    opacity: 0
                },

                data: contractStats !== null && [contractStats.expired_count, contractStats.active_count],
                backgroundColor: [
                    'rgba(255, 99, 132)',

                    'rgba(75, 192, 192)',

                ],
                borderColor: [
                    'rgba(255, 255, 255, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    const options = {
        colors: {

            forceOverride: true
        },
        plugins: {
            datalabels: {
                color: '#36A2EB'
            },
            legend: {
                position: 'top',
                labels: { boxWidth: 20 }

            },
            tooltip: {
                enabled: true
            },

        },
        rotation: (180 / 2) * -1,
        circumference: 180,
        cutout: "60%",
    }

    return (

        <div style={{ position: 'relative', width: '100%', height: '100%' }}>

            <p style={{ position: "absolute", top: "60%", left: "45%", fontSize: '30px', color: 'rgb(13, 110, 253, .8)' }}><b>{contractStats?.total_count}</b></p>
            <Doughnut data={data} options={options} />
        </div>
    );
}
export default DashboardContractDoug;