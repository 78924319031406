import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import EdlService from "../../API/EdlService";
import Preloader from "../UI/Preloader/Preloader";
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import ApiGatewayService from "../../API/ApiGatewayService";
import { AuthContext } from "../../context";
function PatchEdlForm(props) {
    const [isPreloader, setIsPreloader] = useState(false);
    const [subs, setSubs] = useState(null);
    const [itemSub, setItemSub] = useState(props.item.subscription);
    const [tokenList, setTokenList] = useState(null);
    const [token, setToken] = useState(null);
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const changeDate = (date) => {
        if (date) {
            const newDate = date.replace(/\./g, '-').split('-');

            newDate.reverse();

            return newDate.join('-');
        }
        else return ''
    }

    // eslint-disable-next-line no-unused-vars
    const [itemOrg, setItemOrg] = useState(props?.item.org_name);

    const [expiresDate, setExpiresDate] = useState(changeDate(props?.item.date_end));
    const [isStatus, setIsStatus] = useState(props?.item.is_active)
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (tokenList) {
            tokenList.forEach((item) => item.access_token === props.item.gw_access_token ? setToken(item) : '')
        }
    }, [tokenList])

    useEffect(() => {

        (async () => {

            await EdlService.getSubsListDropdown().then((res) => { setSubs(res) })
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const patchInstance = async event => {
        event.preventDefault();
        setIsPreloader(true)
        const instance = {};
        instance.date_end = expiresDate;
        instance.subscription = itemSub?.id;
        instance.is_active = isStatus;
        instance.gw_access_token = token?.access_token || token;
        await EdlService.patchItem(instance, props.item.id).then(() => { props.setPatchItem(false); setIsPreloader(false) }).catch((e) => { setIsPreloader(false); setErrors(e.message) })

    }
    useEffect(() => {
        (async () => {
            if (props?.item?.org_id) {
                if (props?.item?.org_type === 'VENDOR') {
                    let filters = {}
                    // filters.type='unlimited-without-updating';
                    filters.organisation = props.item.org_id;
                    filters.is_active = true
                    await ApiGatewayService.getServiceTokenList(filters).then((res) => setTokenList(res)).catch(() => changeStatus());
                }
                else {
                    let filters = {}
                    filters.organisation = props.item.org_id;
                    filters.type = 'unlimited-without-updating';
                    filters.is_active = true
                    await ApiGatewayService.getTokenList(filters).then((res) => setTokenList(res)).catch(() => changeStatus());
                }

            }
        })()
    }, [])

    return (
        <Form id="form" onSubmit={patchInstance} className="was-validated" style={{ position: "relative" }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}
            <h5 className="mt-2 mb-3">Изменить доступ</h5>

            <Row>

                <Col sm={4}>
                    <Form.Group className="mb-4">

                        <Form.Label >Организация</Form.Label>
                        <Form.Control
                            value={itemOrg}
                            disabled
                            size="sm" type="text"
                        />

                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Статус</Form.Label>
                        <Form.Select size="sm" id="isActive" onChange={(e) => setIsStatus(e.target.value)} value={isStatus} disabled={props?.item?.org_type === 'VENDOR' ? true : false}>
                            <option value={true}>Активен</option>
                            <option value={false}>Не активен</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Дата окончания</Form.Label>
                        <Form.Control
                            disabled={props?.item?.org_type === 'VENDOR' ? true : false}
                            value={expiresDate || ""}
                            onChange={(e) => setExpiresDate(e.target.value)}
                            size="sm" type="date"
                            required={true} />

                    </Form.Group>
                </Col>

            </Row>
            <Row>
                {props?.item?.org_type !== 'VENDOR' && <Col className="mb-4 col-4">
                    <Form.Group >
                        <Form.Label size="sm">Подписки</Form.Label>

                        <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-subs" >
                                {itemSub?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'subsEdl'}
                                filterQa={true}
                            >
                                {subs?.map(sub =>
                                    <Dropdown.Item eventKey={"es" + sub.id} key={"es" + sub.id} onClick={() => { setItemSub(sub); }}>{sub.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>}
                {itemOrg && <Col className="mb-4 col-4">
                    <Form.Group >
                        <Form.Label size="sm">Токен API GW</Form.Label>

                        <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-tokens" >
                                {token?.name || (token ? "API GW TOKEN" : "выбрать")}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'tokenEdl'}
                                filterQa={true}
                            >
                                <Dropdown.Item eventKey={"tok"} key={"tok"} onClick={() => { setToken(null); }}>Без токена</Dropdown.Item>
                                {tokenList?.map(token =>
                                    <Dropdown.Item eventKey={"tok" + token.subject} key={"es" + token.id} onClick={() => { setToken(token); }}>{token.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>}
            </Row>

            <hr />
            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            props.setPatchItem(false)

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Сохранить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>
        </Form >
    );
}
export default PatchEdlForm;
