import React, { useState, useEffect, useMemo, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PriceService from "../../API/PriceService";
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CoreService from "../../core/CoreService";
import QuotaItemsList from "./QuotaItemsList";
import QuotaService from "../../API/QuotaService";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
// import Validation from "../../core/Validation";
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import OrgService from "../../API/OrgService";
import Preloader from "../UI/Preloader/Preloader";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function CloneQuotaForm(props) {

    registerLocale('ru', ru)
    const [partners, setPartners] = useState('');
    const [quotaItems, setQuotaItems] = useState([]);
    const [quotaDescription, setQuotaDescription] = useState("");
    const [quotaExpiringDate, setQuotaExpiringDate] = useState(CoreService.addMonths(new Date(), 1));
    const [errors, setErrors] = useState([]);
    const [currencyRate, setCurrencyRate] = useState('');
    const [discount, setDiscount] = useState('');
    const [quotaToClone, setQuotaToClone] = useState();
    const [isPreloader, setIsPreloader] = useState(false);
    const [isPartner, setIsPartner] = useState('')
    const [isMain, setIsMain] = useState(false);
    const quotaSum = useMemo(() => CoreService.quotaSum(quotaItems), [quotaItems]);
    const [curPriceVersion, setCurPriceVersion] = useState({});
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const removeItem = (item, index) => {
        setQuotaItems([...quotaItems.slice(0, index), ...quotaItems.slice(index + 1)])
    };

    const changeItemQty = (item, amount) => {
        let itemIndex = quotaItems.indexOf(item);
        if (itemIndex >= 0) {
            let newArr = [...quotaItems];
            newArr[itemIndex].amount = parseInt(amount);
            setQuotaItems(newArr);
        }
    };
    const changeDiscount = (item, discount) => {
        let itemIndex = quotaItems.indexOf(item);
        if (itemIndex >= 0) {
            let newArr = [...quotaItems];
            newArr[itemIndex].discount = (discount);
            setQuotaItems(newArr);
        }

    }
    useEffect(() => setIsPartner(quotaToClone?.partner_org), [quotaToClone])

    // useEffect(() => {

    //     (async () => {
    //         setIsPreloader(true);
    //         let filters = { 'type': 'PARTNER' };
    //         await OrgService.getDropList(filters, false, true).then((res) => setPartners(res.results)).finally(() => setIsPreloader(false));

    //     })();

    // }, []);

    useEffect(() => {

        (async () => {
            await QuotaService.getItem(props.quota.id).then((res) => {
                setQuotaToClone(res);
                setCurrencyRate(res.currency_rate);
                setDiscount(res.discount);
                setQuotaItems(res.items);
                setIsMain(res.is_main);
                var date = res.date_expires?.split(".");
                setQuotaExpiringDate(date ? new Date(date[2], date[1] - 1, date[0]) : null);
                setQuotaDescription(res.description)
            })
            await PriceService.getPriceConfig(1).then((res) => {
                setCurPriceVersion(
                    {
                        dateCreated: res.active_priceversion_date,
                        description: res.active_priceversion_comment
                    }
                );
            });
        })();

    }, [props.quota]);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => { localStorage.setItem('token', res.data.access); setErrors([]); cloneQuota() })
            .catch(() => changeStatus())
    }

    const cloneQuota = async event => {
        if (event) event.preventDefault();
        setIsPreloader(true);
        const quota = {};
        quota.items = quotaItems;
        quota.enduser_org = quotaToClone?.enduser_org
        if (isPartner === 'Без партнера') quota.partner_org = null
        else quota.partner_org = isPartner;
        quota.is_main = isMain;
        quota.description = quotaDescription;
        if (quotaDescription === "") {
            const description = CoreService.makeDecription(quotaItems).join(" ");
            quota.description = "Платформы: " + description;
        };
        quota.date_expires = quotaExpiringDate ? quotaExpiringDate.toISOString().split('T')[0] : null;
        quota.currency_rate = currencyRate;
        quota.discount = (discount || 0);
        await QuotaService.newQuota(quota)
            .then((res) => { setTimeout(() => { setIsPreloader(false); props.setIsCloneQuota(false) }, 1000) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })

    }

    return (
        <Form onSubmit={cloneQuota} style={{ position: 'relative' }}>
            {isPreloader && <Preloader />}

                    {errors.length > 0 &&
                        errors.map(error =>
                            <Row>
                                <Col>
                                    <Alert key="danger" variant="danger">
                                        {error}
                                    </Alert>
                                </Col>
                            </Row>
                        )
                    }

                    <Row>
                        <Col className="mb-3 col-2 mt-4">
                            <div className="form-check" style={{ alignItems: "center", display: "flex" }}>
                                <input onChange={(evt) => setIsMain(evt.target.checked)} className="form-check-input" type="checkbox" value='' checked={isMain} id="isMain" style={{ height: '25px', width: '25px' }}></input>
                                <label className="form-check-label" htmlFor="isMain" style={{ fontSize: '18px', fontWeight: 600, marginLeft: '10px' }}>
                                    Main
                                </label>
                            </div></Col>
                        <Col className="col-4">
                            <Form.Group className="mb-4">
                                <Form.Label size="sm">Конечный заказчик  </Form.Label>
                                <Form.Control size="sm" type="text" id="Qend" disabled
                                    value={quotaToClone?.enduser_org || ''}
                                />
                                <Form.Text className="text-muted">

                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col className="col-4">
                            <Form.Group className="mb-4">
                                <Form.Label size="sm">Партнер</Form.Label>

                                <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                                    <Dropdown.Toggle as={MyToggle} id="dropdownQpart" >
                                        {(isPartner || '') || "выбрать"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        as={MyMenu}
                                    >
                                        <Dropdown.Item eventKey={"ewp"} onClick={() => { setIsPartner('Без партнера') }}>Без партнера</Dropdown.Item>
                                        {props?.partnerorganizations?.map(org =>
                                            <Dropdown.Item eventKey={"e" + org.id} key={"e" + org.id} onClick={() => { setIsPartner(org.name) }}>{org.name}</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>

                            </Form.Group> </Col>

                    </Row>
                    <Row>

                        <Col className="col-2">
                            <Form.Group className="mb-4">
                                <Form.Label>Курс</Form.Label>
                                <Form.Control size="sm" type="text" value={currencyRate} onChange={(e) => setCurrencyRate(e.target.value)} />
                            </Form.Group>
                        </Col>

                        <Col className="col-4">
                            <div style={{ fontSize: 16, marginBottom: 10 }}><Form.Label>Прайс по которому считалось:</Form.Label><br /><Badge bg="info">{quotaToClone?.active_priceversion_comment}<br /> загружен {quotaToClone?.active_priceversion_date}</Badge></div>
                        </Col>
                        <Col className="col-4">
                            <div style={{ fontSize: 16, marginBottom: 10 }}><Form.Label>Текущий прайс:</Form.Label><br /><Badge bg="primary">{curPriceVersion?.description}<br /> загружен {curPriceVersion?.dateCreated}</Badge></div>
                        </Col>

                    </Row>
                    {/* <Row>
                
            </Row> */}
                    <Row>
                        <Col>
                            {quotaItems
                                && <QuotaItemsList
                                    quotaItems={quotaItems}
                                    quotaSum={quotaSum}
                                    // discount={discount}
                                    currencyRate={currencyRate}
                                    removeItem={removeItem}
                                    changeItemQty={changeItemQty}
                                    changeDiscount={changeDiscount}
                                />
                            }
                        </Col>
                    </Row>
                    {quotaItems.length > 0
                        &&
                        <Row>
                            <Col className="col-4">
                                <Form.Group className="mb-4">
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        type="text"
                                        placeholder="Описание квоты..."
                                        value={quotaDescription || ''}
                                        size="sm"
                                        onChange={(e) => setQuotaDescription(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className="col-4">
                                Срок действия квоты<br />
                                <DatePicker
                                    dateFormat="dd.MM.yyyy"
                                    selected={quotaExpiringDate}
                                    onChange={(date) => setQuotaExpiringDate(date)}
                                    locale="ru"
                                />
                            </Col>
                            <hr />
                        </Row>
                    }
                    {/* <Row>
                        <Col sm={2}>
                            <Form.Control onChange={(e) => setSearchText(e.target.value)}
                                placeholder="Начните вводить..."
                                size="sm"
                                value={searchText}
                            />
                        </Col>
                        <Col sm={2}>
                            <Form.Group className="mb-4">
                                <MySelect
                                    platforms={props.platforms}
                                    setPlatformFilter={setPlatformFilter}
                                    setCurPlatform={setCurPlatform}
                                    value={curPlatform}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group className="mb-4">
                                <MySelect
                                    categories={props.categories}
                                    setCategoryFilter={setCategoryFilter}
                                    setCurCategory={setCurCategory}
                                    value={curCategory}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            {
                                (platformFilter || categoryFilter || searchText) &&
                                <Button
                                    variant="outline-info"
                                    size="sm"
                                    onClick={() => {
                                        setCurPlatform("");
                                        setPlatformFilter(false);
                                        setCurCategory("");
                                        setCategoryFilter(false);
                                        setSearchText("");
                                    }}>
                                    Сбросить фильтр
                                </Button>
                            }
                        </Col>
                    </Row> */}

                    <Row>
                        {/* <Col>
                            <PriceList
                                priceitems={priceitems}
                                isQuotaAdding={true}
                                addItem={addItem}
                            />
                        </Col> */}
                    </Row>
            <Row >
                <Col className="col-4">
                    <Form.Group className="mb-4">
                        <Button variant="outline-danger" style={{ width: '100%' }} size="sm" onClick={() => {
                            props.setIsCloneQuota(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="col-4" >
                    <Form.Group className="mb-4">
                        <Button variant="outline-primary" style={{ width: '100%' }} size="sm" type="submit">
                            Клонировать
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
}
export default CloneQuotaForm;