import React from "react";
import cl from './MyLoader.module.css';


function MyLoader({children}) {
    const rootClass = cl.loader
    
    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <div className={rootClass}></div>
        </div>
        
    );
}
export default MyLoader;