import React from "react";
import Row from 'react-bootstrap/Row';
import Header from "../components/UI/Header";
import SideBar from "../components/UI/Sidebar";
import ServiceArea from "../components/Service/ServiceArea";

function ServicePage() {

  return (
    <div>
      <Header />
      <Row>
          <SideBar />
          <ServiceArea />
      </Row>
    </div>
  );
}

export default ServicePage;