import Api from '.';

export default class UpdatesCloudService {

    static async getAssetsList() {
        var url = `api/v1/updates-cloud/manage/api/serials/`;
        const method = 'GET';
        const params = {};

        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async switchActivate(serial, active) {
        var url = `api/v1/updates-cloud/manage/api/serials/${serial}/set_status/`;
        const method = 'PUT';
        const data = {};
        data.active_flag = active
        const api = new Api({ url: url});
        const response = await api.request(method, data);
        return response.data;
    };

    static async deleteSerial(serial) {
        var url = `api/v1/updates-cloud/manage/api/serials/${serial}/`;
        const method = 'DELETE';
        const api = new Api({ url: url});
        const response = await api.request(method);
        return response.data;
    };
    static async clearAssetLogs(serial) {
        var url = `api/v1/updates-cloud/manage/api/serials/${serial}/clear-logs/`;
        const method = 'DELETE';
        const api = new Api({ url: url});
        const response = await api.request(method);
        return response.data;
    };
    static async checkAsets(serial) {
        var url = `api/v1/assets/?serial_nr=${serial}`;
        const method = 'GET';
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };
    static async addInstance(asset) {
        var url = `api/v1/updates-cloud/manage/api/serials/`;
        const method = 'POST';
        const data = asset;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async getErrorsList(skip, filters) {
        let count = 50 * skip;
        const params ={}
        if (filters) {
            if (filters.platformFilter) {
                params.platform__id = filters.platformFilter;

            };
            if (filters.orgFilter) {
                params.organisation__id = filters.orgFilter;

            };
            if (filters.searchTextError) {
                params.search = filters.searchTextError;
            };
            if (filters.searchTextSerial) {
                params.serial = filters.searchTextSerial
            };
            if (filters.serial === true) {
                params.serial = 'null'
            };

        };
        var url = `api/v1/updates-cloud/manage/api/errors/?limit=50&skip=${count}`;
        const method = 'GET';
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
}