import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";
import DocumentationService from "../../API/DocumentationService";
import ImgRemove from '../../icons/remove.png';
import PimService from "../../API/PimService";
function AddPimDocForm({ open, setSuccessAdd, successAdd }) {

    const [errors, setErrors] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [releaseVersion, setReleaseVersion] = useState('');
    const [files, setFiles] = useState([]);
    const [fileName, setFileName] = useState('')
    const [issuedDate, setIssuedDate] = useState(null);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') saveInstance();

            })
            .catch(() => changeStatus())
    }


    const saveInstance = async event => {
        setIsPreloader(true);
        if (event) event.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('doc_file', files);
  
        await PimService.newPimDocs(formData)
            .then(() => { open(false); setIsPreloader(false); setSuccessAdd(!successAdd) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken('save');
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })


    }

    // const validateFiles = (value, index) => {
    //     let arr = [];
    //     arr = files?.filter((item) => item.field !== index);
    //     setFiles([...arr, { file: value, name: nameFile(index), field: index }])
    // }

    return (
        <Form id="form" onSubmit={saveInstance} className="was-validated" style={{ position: "relative" }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key={"danger" + error} variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}


            <Row>
            <Col className="mb-3 col-4">
                    <Form.Group>
                        <Form.Label size="sm">Название</Form.Label>
                        <Form.Control size="sm" type="text" id={'mVersion'} required={true} placeholder={'Введите название'}

                        onChange={(e)=> setName(e.target.value)}
                        />
                        {/* <Form.Text className="text-muted">
                       
                        </Form.Text> */}
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-6">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Файл</Form.Label>
                        <Form.Control
                            type="file"
                            size="sm"
                            required={true}
                            style={{ width: '100%' }}
                            onChange={(e) => {setFiles(e.target.files[0])}}
                        // onChange={(e) => { validateFiles(e.target.files[0], index) }}
                        // setFiles([...files, { file: e.target.files, name: nameFile(index,), field: index }]) 
                        accept="/*,.doc,.docx,.xls,.xslx,.txt,.zip,.pdf"
                        />

                    <Form.Text className="text-muted">
                    допускаются расширения: ['doc', 'docx', 'xls', 'xlsx', 'txt', 'zip', 'pdf']
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <hr />
            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            open(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Добавить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>


            <hr />
        </Form >
    );
}
export default AddPimDocForm;
