import Api from '.';

export default class ContractService {

    static async getList(id, filters, nextpage, enduser) {
        var url = id === false ? 'api/v1/contracts/' : enduser !== true ? `api/v1/contracts/?page=all&contract_partner_org__id=${id}` : `api/v1/contracts/?page=all&contract_enduser_org__id=${id}`

        const method = 'GET';
        const params = {};
      
        if (filters) {
            if (filters.paymentFilter) {
                params.payment_method = filters.paymentFilter;
            };
            if (filters.searchText) {
                params.search = filters.searchText;
            };
            if (filters.statusFilter) {
                params.status = filters.statusFilter;
            };
            if (filters.techStatusFilter) {
                params.tech_status = filters.techStatusFilter;
            };
            if (filters.finStatusFilter) {
                params.fin_status = filters.finStatusFilter;
            };

        };

        if (nextpage) {
           params.page = nextpage;
        }
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async newContract(contract) {

        const url = 'api/v1/contracts/';
        const method = 'POST';
        const data = contract;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };

    static async getItem(id) {

        const url = `api/v1/contracts/${id}/`;
        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }
    
    static async patchContract(contract) {
        const url  = `api/v1/contracts/${contract.id}/`;
        const data = contract;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
       
        return response.data;
    }
    static async deleteContract(id ) {
        const url  = `api/v1/contracts/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);
       
        return response.data;
    }
    static async getExportContractsExls(filters) {
        var url = 'api/v1/contracts/export/'
        const method = 'GET';
        const params = {};
        if (filters) {
            if (filters.date_created_after) {
                params.date_created_after = filters.date_created_after;
            };
            if (filters.date_created_before) {
                params.date_created_before = filters.date_created_before;
            };
            
        };
        const api = new Api({ url: url, params: params, responseType:'arraybuffer' });
        const response = await api.request(method);
        return response.data;
    };
}