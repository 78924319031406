import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";
import ServiceService from "../../API/ServiceService";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function AddServiceForm(props) {
    const [isPreloader, setIsPreloader] = useState(false);

    const [slugService, setSlugService] = useState('');
    const [serviceType, setServiceType] = useState('full');
    const [сommentService, setCommentService] = useState('');
    const [descriptionService, setDescriptionService] = useState('');
    const [descriptionSlaService, setDescriptionSlaService] = useState('');
    const [contactService, setContactService] = useState('')
    const [rmaService, setRmaService] = useState('Да')
    const [errors, setErrors] = useState(null);
    const [file, setFile] = useState(null);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                addNewService();
            })
            .catch(() => changeStatus())
    }
    const addNewService = async event => {
        if (event) event.preventDefault();
        setIsPreloader(true);

        const formData = new FormData();
        formData.append('slug', slugService);
        if (file !== null) formData.append('sla_document', file);
        formData.append('service_type', serviceType);
        formData.append('description', descriptionService);
        if (contactService !== '') formData.append('service_center_contact', contactService);
        if (descriptionSlaService !== "") formData.append('sla_description', descriptionSlaService);
        if (сommentService !== '') formData.append('comment', сommentService);
        const item = {};
        item.slug = slugService;
        item.service_type = serviceType;
        item.sla_document = file;
        item.description = descriptionService;
        switch (rmaService) { case "Да": formData.append('rma', true); break; case 'Нет': formData.append('rma', false); break; default: item.rma = null; }
        await ServiceService.newService(formData)
            .then(() => props.setAddService(!props.addService))
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })
    }

    return (
        <Form onSubmit={addNewService} className='was-validated' style={{ position: 'relative' }}>
            {isPreloader && <Preloader />}
            <hr />
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Row >
                <h3 className="mb-3">Добавить сервис</h3>
                <Col className="mb-3 col-4">

                    <Form.Group controlId="ServiceSlug" >
                        <Form.Label>SLUG</Form.Label>
                        <Form.Control size="sm" type="text"
                            required={true}
                            value={slugService}
                            placeholder="Введите.." onChange={(e) => setSlugService(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group></Col>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Тип сервиса</Form.Label>
                        <Form.Select size="sm" id="serType" onChange={(e) => setServiceType(e.target.value)} >
                            <option value="full">full</option>
                            <option value="light">light</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group controlId="ServiceContact" >
                        <Form.Label>Контакты сервисного центра</Form.Label>
                        <Form.Control size="sm" type="text" value={contactService} placeholder="Введите.." onChange={(e) => setContactService(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Описание</Form.Label>
                        <Form.Control
                            as="textarea"
                            required={true}
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={descriptionService}
                            size="sm"
                            onChange={(e) => setDescriptionService(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Комментарий (опционально)</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Комментарий "
                            rows={2}
                            type="text"
                            value={сommentService}
                            size="sm"
                            onChange={(e) => setCommentService(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">SLA описание (опционально)</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Описание "
                            rows={2}
                            type="text"
                            value={descriptionSlaService}
                            size="sm"
                            onChange={(e) => setDescriptionSlaService(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">RMA</Form.Label>
                        <Form.Select size="sm" id="selRma" onChange={(e) => setRmaService(e.target.value)} >
                            <option value="Да">Да</option>
                            <option value="Нет">Нет</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Файл (опционально)</Form.Label>
                        <Form.Control
                            type="file"

                            size="sm"
                            onChange={(e) => setFile(e.target.files[0])}
                            accept='application/pdf, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .docx'
                        />
                        <Form.Text muted>поддерживаемые типы файлов: ['pdf', 'xlsx', 'docx']
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }}>
                        Добавить
                    </Button>

                </Col>
                <Col sm={2}>
                    <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); props.setAddService(false) }} style={{ width: '100%' }}>
                        Отменить
                    </Button>
                </Col> </Row>
            <hr />
        </Form>

    );
}
export default AddServiceForm;