import { SmartCaptcha } from '@yandex/smart-captcha';

export const Captcha = (props) => {
  const key = process.env.REACT_APP_CAPTCHA_CLIENT_KEY;

  const handleChallengeHidden = () => setTimeout(()=>props.setToken(), 2000) ;

  return <SmartCaptcha sitekey={key} onSuccess={handleChallengeHidden} />;
};

