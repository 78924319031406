import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PriceService from "../../API/PriceService";
import Alert from 'react-bootstrap/Alert';
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function CategoryAddForm(props) {

    const [categoryName, setCategoryName] = useState("");
    const [categoryDiscount, setCategoryDiscount] = useState(0);
    const [errors, setErrors] = useState(null);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                addCategory();
            })
            .catch(() => changeStatus())
    }

    const addCategory = async event => {
        if (event) event.preventDefault();
        const item = {
            'name': categoryName,
            'discount': categoryDiscount
        };
        await PriceService.newCat(item)
            .then(() => { props.setCategoryAdding(false); props.setCategoryAdded(true); setErrors(null) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); 
                }
            })

    }

    return (
        <Form onSubmit={addCategory} className='was-validated'>
            {errors !== null && <Alert key="danger" variant="danger">{errors}</Alert>}
            <Form.Group controlId="catNameField" className="mb-4">
                <Form.Label>Название новой категории</Form.Label>
                <Form.Control size="sm" type="text"
                    required={true}
                    value={categoryName}
                    placeholder="Введите.." onChange={(e) => setCategoryName(e.target.value)} />
                <Form.Text muted>
                    Например Subscription / Service-light
                </Form.Text>
            </Form.Group>
            <Form.Group controlId="catDiscountField" className="mb-4">
                <Form.Label>Укажите скидку</Form.Label>
                <Form.Control size="sm" type="text" value={categoryDiscount} placeholder="Введите.." onChange={(e) => setCategoryDiscount(e.target.value)} />
                <Form.Text muted>

                </Form.Text>
            </Form.Group>
            <Button variant="outline-primary" size="sm" type="submit">
                Добавить
            </Button>
            <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); props.setCategoryAdding(false) }}>
                Отменить
            </Button>
        </Form>
    );
}
export default CategoryAddForm;