class MyValidation {


    static quotaValidate(enduser, partner, currency,  items) {
        var validationStatus = true;
    
        var errors = [];

        if (enduser === "") {
            errors.push("Необходимо указать конечного заказчика");
        };

        if (enduser === partner) {
            errors.push("Не указывайте партнера, если он является конечным заказчиком");
        }

        if (!currency) {
            errors.push("Не указан курс валюты");
        } else {
            if (parseFloat(currency) < 1 || parseFloat(currency) > 1000 || isNaN(currency)) {
                errors.push("Укажите курс валюты от 1 до 1000");
            };
        };
        items.forEach((item)=> {if (!isFinite(item.discount) || item.discount==="") errors.push("Значение скидки не может быть пустым");})
        if (items.length === 0) {
            errors.push("Невозможно создать квоту без позиций из прайс-листа");
        }
         if (errors.length > 0) {
            validationStatus = false;
        };
        return {"status": validationStatus, "errors":errors};
    };

    static priceFileValidate(file) {
        var validationStatus = true;
        var errors = [];

        if (file.size > 5242880) {
            errors.push("Размер файла не должен превышеть 5 МБайт");
        };

        if (!(file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type ==='application/vnd.ms-excel')) 
       {
            errors.push("Неверный тип файла, ожидается лист MS Excel");
        };

        
        if (errors.length > 0) {
            validationStatus = false;
        };
        return {"status": validationStatus, "errors":errors};
    };
};

export default MyValidation;