import React from "react";
import Row from 'react-bootstrap/Row';
import DashboardList from "../components/Dashboard/DashboardList";
import Header from "../components/UI/Header";
import SideBar from "../components/UI/Sidebar";

function Dashboard() {

  return (
    <div>
      {/* <AuthLayout status={status} setStatus={setStatus}> */}
      <Header />
      <Row>
          <SideBar />
          <DashboardList/>
      </Row>
      {/* </AuthLayout> */}
    </div>
  );
}

export default Dashboard;