import React, { useState } from "react";
import { Col } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import CopyIcon from '../../icons/copy.svg';
import { changeDate } from "./utils";

function TokenCard({ data }) {
const [copyText, setCopyText] = useState(false)
    const basicStylesTitles = {
        "fontSize": 'smaller',
        width: '15%', textAlign: 'right' 
    }
    const copyBufferText = (text, field) => {
        navigator.clipboard.writeText(text)
            .then((res) => {

                setCopyText({ text: 'Copy', field: field })
            })
            .catch(err => {
                setCopyText({ text: 'Error', field: field });
            });
    }
    const viewLimits = (item, typeReq) => {
        if (item.is_active) {
            if (item.type === "trial" || item.type === 'standard') {
                return typeReq === 'days' ? item.last_day_requests + '/' + item.daily_requests_limit : item.last_month_requests + '/' + item.monthly_requests_limit
            } else {
                return typeReq === 'days' ? <span >{item.last_day_requests}/&infin;</span> : <span >{item.last_month_requests}/&infin;</span>
            }
        } else return <span style={{ color: "red" }}>N/A</span>
    }
    return (
        <>

            <Table bordered striped hover id='idT' >

                <tbody>
                    <tr>
                        <th style={basicStylesTitles}>Имя:</th><td>{data?.name}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Тип</th><td>{data?.type}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Активен</th><td>{data?.is_active ? <span style={{ color: "green" }}>Да</span> : <span style={{ color: "red" }}>Нет</span>}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Организация</th><td>{data?.organisation}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Сервис</th><td>{data?.audience.name}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Ресурсы и доступы</th><td>{data?.scope?.map((item)=> <Col key={item.id}>{item.name}</Col>)}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Дата начала</th><td>{changeDate(data?.issued_at)}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Дата окончания</th><td>{changeDate(data?.date_end)}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Создан</th><td>{changeDate(data?.date_created)}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Изменен</th><td>{changeDate(data?.date_modified)}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Запросы за сутки</th><td>{viewLimits(data, 'days')}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Запросы за 30д.</th><td>{viewLimits(data, 'month')}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Запросов всего</th><td>{data?.total_requests}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Описание</th><td>{data?.description}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Refresh</th><td title={data?.refresh_token}><img width="20" className="interactive_hover" alt={'copy token'} src={CopyIcon} onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    copyBufferText(data?.refresh_token, 'refresh');
                                    // setCurrentToken(item)

                                }
                            } /> {copyText?.field==='refresh'? copyText?.text : ''}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Refresh expires</th><td>{data?.refresh_timeleft_verbose}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Access</th><td title={data?.access_token}>{data?.access_token ? <><img width="20" className="interactive_hover" alt={'copy token'} src={CopyIcon} onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    copyBufferText(data?.access_token, 'access');
                                    

                                }
                            } /></> : ''} {copyText?.field==='access'? copyText?.text : ''}</td>
                    </tr>
                    <tr >
                        <th style={basicStylesTitles}>Access expires</th><td>{data?.access_token? data?.access_timeleft_verbose: ''} </td>
                    </tr>

                </tbody>

            </Table></>
    )
}

export default TokenCard;