import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/esm/Button";
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import ImgRemove from '../../icons/remove.png';
import CopyIcon from '../../icons/copy.svg';

function AssetsList({ setTypeFilter, typeFilter, setActiveFilter, activeFilter, setWithoutRenew, withoutRenew, platformFilter, setClearItem, lastErrorFilter, setLastErrorFilter, setPlatformFilter, resetFilter, searchText, setSearchText, setRenewList, renewList, setCurrentItem, currentItem, setOrderLastRN, orderLastRN, platforms, assets, errors, paginationString, orderLastUpdate, setOrderLastUpdate, orderLastReq, setOrderLastReq, page, setPage, filteredArray, setFilteredArray, textFilter, withoutOrg, setWithoutOrg, setSwitchActive, setDeleteItem, setWithoutRequest, withoutRequest }) {


    const assetsArr = filteredArray === null ? assets : filteredArray;
    const [copytext, setCopyText] = useState(false)
    const dateStyles = {
        "fontSize": 'x-small',

    }
    const tdStyle = {
        padding: '4px'
    }
    // const copyBufferText = (text) => {
    //     navigator.clipboard.writeText(text)
    //         .then((res) => {

    //             setCopyText('Cкопировано')
    //         })
    //         .catch(err => {
    //             setCopyText('Ошибка');
    //         });
    // }

    const getActiveFlag = (device) => {
        if (device.active_flag !== 1) return <span style={{ color: "red" }}>{device.serial}</span>
        else return <span style={{ color: "green" }}>{device.serial}</span>
    }


    const handleUpdateOrder = (filterOrderReq) => {
        setOrderLastReq(false);
        setOrderLastRN(false)
        setOrderLastUpdate(filterOrderReq);

        textFilter(assets, searchText, platformFilter, withoutOrg, filterOrderReq, false, false, withoutRequest, lastErrorFilter, withoutRenew, activeFilter, typeFilter)
    }

    const handleOrderLastReq = (filterOrderLastReq) => {
        setOrderLastUpdate(false);
        setOrderLastRN(false)
        setOrderLastReq(filterOrderLastReq)

        textFilter(assets, searchText, platformFilter, withoutOrg, false, filterOrderLastReq, false, withoutRequest, lastErrorFilter, withoutRenew, activeFilter, typeFilter)
    }
    // const handleOrderLastRN = (filterOrderLastRN) => {
    //     setOrderLastUpdate(false);
    //     setOrderLastRN(filterOrderLastRN)
    //     setOrderLastReq(false)

    //     textFilter(assets, searchText, platformFilter, withoutOrg, false, false, filterOrderLastRN, withoutRequest, lastErrorFilter, withoutRenew, activeFilter, typeFilter)
    // }

    const drawLastRequest = (item) => {
        const now = new Date();
        const lastReqDate = new Date(item.datetime_last_request);
        if (item.active_flag !== 1 && item.datetime_last_request === null) return 'NO LOGS'
        else if (item.active_flag !== 1 && item.datetime_last_request !== null) return <span style={{ color: "black" }}>{item.datetime_last_request.replace('T', ' ')}</span>
        else if (item.active_flag === 1 && item.datetime_last_request === null) return <span style={{ color: "red" }}>NO LOGS</span>
        else if (item.active_flag === 1 && now.getTime() - lastReqDate.getTime() > 86400000) return <span style={{ color: "red" }}>{item.datetime_last_request.replace('T', ' ')}</span>
        else return <span>{item.datetime_last_request.replace('T', ' ')}</span>
    }
    return (
        <>
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Row style={{ margin: ' 0 0 3px', padding: '0 0 0 5px' }} className='justify-content-between'>
                <Col className="col-3 mt-2 mb-2">
                    <b>Всего устройств: {assetsArr.length}шт.</b>
                </Col>
                <Col className="col-2 mt-2 mb-2">
                    <Button variant="success" size="sm" style={{ width: '100%' }} onClick={(e) => { setRenewList(!renewList) }}>Обновить</Button>
                </Col>
            </Row>

            <hr style={{ margin: '5px 0' }} />
            <Row style={{ margin: ' 0 0 3px', padding: '0 0 0 5px' }}>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm" >Поиск</Form.Label>
                    <Form.Control onChange={(e) => { setSearchText(e.target.value); textFilter(assets, e.target.value, platformFilter, withoutOrg, orderLastUpdate, orderLastReq, orderLastRN, withoutRequest, lastErrorFilter, withoutRenew, activeFilter, typeFilter) }}
                        placeholder="Начните вводить..."
                        size="sm"
                        style={{ fontSize: '1rem', height: 38 }}
                        value={searchText}
                    />
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Платформа</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-platform" >
                            {platformFilter || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filterAssets={setPlatformFilter}
                            type='platformfilter'
                            filterActiveAssets={setFilteredArray}
                            filterRequest={setWithoutOrg}
                        >
                            <Dropdown.Item eventKey={"af" + 1} key={"af" + 1} onClick={() => {
                                setPlatformFilter('Без платформы');
                                textFilter(assets, searchText, 'Без платформы', withoutOrg, orderLastUpdate, orderLastReq, orderLastRN, withoutRequest, lastErrorFilter, withoutRenew, activeFilter, typeFilter)

                            }}>{'Без платформы'}</Dropdown.Item>
                            {platforms && platforms.map((item) => <Dropdown.Item eventKey={item.id + 'plat'} key={item.id + 'plat'} onClick={() => {
                                setPlatformFilter(item.name);
                                textFilter(assets, searchText, item.name, withoutOrg, orderLastUpdate, orderLastReq, orderLastRN, withoutRequest, lastErrorFilter, withoutRenew, activeFilter, typeFilter)

                            }}>{item.name}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Активен/Неактивен</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-active" >
                            {activeFilter || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            // filter={setActiveFilter}
                            type='activeFilter'
                            // filterActiveAssets={setFilteredArray}
                            // filterRequest={setWithoutOrg}
                        >
                            <Dropdown.Item eventKey={"aс" + 1} key={"aс" + 1} onClick={() => {
                                setActiveFilter('Активен');
                                textFilter(assets, searchText, platformFilter, withoutOrg, orderLastUpdate, orderLastReq, orderLastRN, withoutRequest, lastErrorFilter, withoutRenew, 'Активен', typeFilter)

                            }}>{'Активен'}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"aсс" + 1} key={"aсс" + 1} onClick={() => {
                                setActiveFilter('Неактивен');
                                textFilter(assets, searchText, platformFilter, withoutOrg, orderLastUpdate, orderLastReq, orderLastRN, withoutRequest, lastErrorFilter, withoutRenew, 'Неактивен', typeFilter)

                            }}>{'Неактивен'}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Тип устройства</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-type" >
                            {typeFilter || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            // filterQa={setTypeFilter}
                            type='typeFilter'
                            // filterActiveAssets={setFilteredArray}
                            // filterRequest={setWithoutOrg}
                        >
                            <Dropdown.Item eventKey={"tf" + 0} key={"tf" + 0} onClick={() => {
                                setTypeFilter(false);
                                textFilter(assets, searchText, platformFilter, withoutOrg, orderLastUpdate, orderLastReq, orderLastRN, withoutRequest, lastErrorFilter, withoutRenew, activeFilter, false)

                            }}>{'Показать все'}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"tf" + 1} key={"tf" + 1} onClick={() => {
                                setTypeFilter('panorama');
                                textFilter(assets, searchText, platformFilter, withoutOrg, orderLastUpdate, orderLastReq, orderLastRN, withoutRequest, lastErrorFilter, withoutRenew, activeFilter, 'panorama')

                            }}>{'panorama'}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"tf" + 2} key={"tf" + 2} onClick={() => {
                                setTypeFilter('hw_fw');
                                textFilter(assets, searchText, platformFilter, withoutOrg, orderLastUpdate, orderLastReq, orderLastRN, withoutRequest, lastErrorFilter, withoutRenew, activeFilter, 'hw_fw')

                            }}>{'hw_fw'}</Dropdown.Item>
                            <Dropdown.Item eventKey={"tf" + 3} key={"tf" + 3} onClick={() => {
                                setTypeFilter('vm_fw');
                                textFilter(assets, searchText, platformFilter, withoutOrg, orderLastUpdate, orderLastReq, orderLastRN, withoutRequest, lastErrorFilter, withoutRenew, activeFilter, 'vm_fw')

                            }}>{'vm_fw'}</Dropdown.Item>
                            <Dropdown.Item eventKey={"tf" + 4} key={"tf" + 4} onClick={() => {
                                setTypeFilter('only_apps_fw');
                                textFilter(assets, searchText, platformFilter, withoutOrg, orderLastUpdate, orderLastReq, orderLastRN, withoutRequest, lastErrorFilter, withoutRenew, activeFilter, 'only_apps_fw')

                            }}>{'only_apps_fw'}</Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                </Col>

            </Row>
            <Row style={{ margin: ' 0 0 3px', padding: '0 0 0 5px' }}>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Без организации</Form.Label>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={withoutOrg}
                            onChange={(e) => {
                                setWithoutOrg(e.target.checked);
                                textFilter(assets, searchText, platformFilter, e.target.checked, orderLastUpdate, orderLastReq, orderLastRN, withoutRequest, lastErrorFilter, withoutRenew, activeFilter)
                            }} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>

                    </div>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Без запросов за 24ч</Form.Label>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={withoutRequest}
                            onChange={(e) => {
                                setWithoutRequest(e.target.checked);
                                textFilter(assets, searchText, platformFilter, withoutOrg, orderLastUpdate, orderLastReq, orderLastRN, e.target.checked, lastErrorFilter, withoutRenew, activeFilter)
                            }} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>

                    </div>
                </Col>

                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Без обновлений</Form.Label>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={withoutRenew}
                            onChange={(e) => {
                                setWithoutRenew(e.target.checked);
                                textFilter(assets, searchText, platformFilter, withoutOrg, orderLastUpdate, orderLastReq, orderLastRN, withoutRequest, lastErrorFilter, e.target.checked, activeFilter)
                            }} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>

                    </div>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">last error</Form.Label>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={lastErrorFilter}
                            onChange={(e) => {
                                setLastErrorFilter(e.target.checked);
                                textFilter(assets, searchText, platformFilter, withoutOrg, orderLastUpdate, orderLastReq, orderLastRN, withoutRequest, e.target.checked, withoutRenew, activeFilter)
                            }} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>

                    </div>
                </Col>
                {/* <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Активные</Form.Label>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={withoutOrg}
                            onChange={(e) => {
                                setWithoutOrg(e.target.checked);
                                textFilter(assets, searchText, platformFilter, e.target.checked, orderLastUpdate, orderLastReq, orderLastRN, withoutRequest, lastErrorFilter, withoutRenew, activeFilter)
                            }} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>

                    </div>
                </Col> */}
                {(searchText || platformFilter || orderLastRN || withoutOrg || orderLastUpdate || orderLastReq || withoutRequest || withoutRenew || lastErrorFilter || activeFilter || typeFilter) && <Col className="col-2">

                    <Button variant="outline-info" size="sm" style={{ width: "100%", height: '38px', margin: '30px 0 0' }} onClick={() => resetFilter()}>Сбросить</Button>
                </Col>}



            </Row>

            {

                <div style={{
                    position: "relative", minHeight: '375px', display: 'flex',
                    flexDirection: 'column'
                }}>

                    <div className="table-responsive" >
                        <table className="table align-middle table-striped table-bordered" id='checkUrlResp'>
                            <thead>
                                <tr style={dateStyles}>
                                    <th style={tdStyle}>SN</th>
                                    <th style={tdStyle}>Орг-ция</th>
                                    <th style={tdStyle}>Платформа</th>
                                    <th style={tdStyle}>Тип</th>
                                    <th style={tdStyle}>Подписки</th>
                                    <th style={tdStyle}>Последнее обращение {<span style={{ color: orderLastReq === '↑' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => orderLastReq !== '↑' ? handleOrderLastReq('↑') : handleOrderLastReq(false)}>↑</span>}{<span style={{ color: orderLastReq === '↓' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => orderLastReq !== '↓' ? handleOrderLastReq('↓') : handleOrderLastReq(false)}>↓</span>}</th>
                                    <th style={tdStyle}>Последнее обновление {<span style={{ color: orderLastUpdate === '↑' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => orderLastUpdate !== '↑' ? handleUpdateOrder('↑') : handleUpdateOrder(false)}>↑</span>}{<span style={{ color: orderLastUpdate === '↓' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => orderLastUpdate !== '↓' ? handleUpdateOrder('↓') : handleUpdateOrder(false)}>↓</span>}</th>
                                    {/* <th style={tdStyle}>Release notes  {<span style={{ color: orderLastRN === '↑' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => orderLastRN !== '↑' ? handleOrderLastRN('↑') : handleOrderLastRN(false)}>↑</span>}{<span style={{ color: orderLastRN === '↓' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => orderLastRN !== '↓' ? handleOrderLastRN('↓') : handleOrderLastRN(false)}>↓</span>}</th> */}
                                    <th style={tdStyle}>Last error</th>
                                    <th style={tdStyle}>ip-адреса</th>
                                    <th style={tdStyle}>Коммент</th>
                                    <th style={tdStyle}>Доступ</th>
                                    <th style={tdStyle}>Удалить<br />лог</th>
                                    <th style={tdStyle}>Удалить</th>
                                </tr>
                            </thead>


                            <tbody className="table-group-divider" id={'chDevExt'}>
                                {assetsArr?.slice(page * 20, page * 20 + 20).map((item, index) =>
                                    <tr key={item.serial + 'assetsUC'} style={dateStyles}>
                                        <td style={tdStyle}>{getActiveFlag(item)}</td>

                                        <td style={tdStyle}>{item.organisation__name}</td>
                                        <td style={tdStyle}>{item.platform__name}</td>
                                        <td style={tdStyle}>{item.device_type}</td>
                                        <td style={tdStyle}>{item.subscriptions?.length > 0 && item.subscriptions.map((dev, index) => <Col key={dev.id + 'sub' + dev.slug}>{dev.slug} {dev.time_left_verbose}</Col>)}</td>
                                        <td style={tdStyle}>{drawLastRequest(item)}
                                            {/* <br />{'HASH '}<img width="10" className="interactive_hover" alt={'copy'} src={CopyIcon}
                                            onClick={
                                                (evt) => {
                                                    evt.stopPropagation();
                                                    copyBufferText(item.hash);
                                                    setCurrentItem(item);

                                                }
                                            } />  {item?.serial === currentItem?.serial && copytext ? <span style={copytext === 'Ошибка' ? { color: "red" } : { color: "green" }}>{copytext}</span> : <span></span>} */}
                                        </td>
                                        <td style={tdStyle}>{item.datetime_last_get_update?.replace('T', ' ') || 'NO LOGS'}<br />{item.last_update_name}</td>
                                        {/* <td style={tdStyle}>{item.last_rn_name || 'NO LOGS'}<br />{item.datetime_last_get_rn?.replace('T', ' ')}</td> */}
                                        <td style={tdStyle}>{item.last_error || 'NO LOGS'}</td>
                                        <td style={tdStyle}>{item.ip_list?.length > 0 ? item.ip_list.map((ip, index) => <Col key={ip.ip + 'ip'}> {ip.ip}</Col>) : 'NO LOGS'}</td>
                                        <td style={tdStyle}>{item.comment}</td>
                                        <td style={tdStyle}>{
                                            <Col className="col-2" >

                                                <div className="form-check form-switch" style={{ minHeight: 0 }}>
                                                    <input className="form-check-input" type="checkbox" id="statusChecks" checked={item.active_flag !== 1 ? false : true}
                                                        onChange={(e) => {
                                                            setSwitchActive(true);
                                                            setCurrentItem(item);
                                                        }} />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>

                                                </div>
                                            </Col>
                                            // <span
                                            //     onClick={() => { setCurrentItem(item); setSwitchActive(true) }}
                                            //     style={{ marginRight: 10, fontSize: 'x-small', cursor: "pointer" }}
                                            //     className={item.active_flag !== 1 ? "badge bg-success" : "badge bg-danger"}>
                                            //     {item.active_flag !== 1 ? 'activate' : 'deactivate'}</span>
                                        } </td>
                                        <td style={{ cursor: 'pointer', padding: '4px' }}>
                                            <span
                                                onClick={() => { setCurrentItem(item); setClearItem(true) }}
                                                style={{ marginRight: 10, fontSize: 'x-small', cursor: "pointer" }}
                                                className={"badge bg-danger"}>
                                                {'clear'}</span>

                                        </td>
                                        <td style={{ cursor: 'pointer', padding: '4px' }}>
                                            <img width="20" src={ImgRemove} alt="delete" onClick={
                                                (e) => { e.stopPropagation(); setDeleteItem(true); setCurrentItem(item) }
                                            } />
                                        </td>
                                    </tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                    <nav aria-label="pagination" style={{ marginTop: 'auto' }} >
                        <ul className="pagination justify-content-center" id='pagination-menu' style={{ margin: "0, auto" }}>
                            {assetsArr && <li className="page-item">
                                <button disabled={page === 0 ? true : false} className="page-link" aria-label="Previous" onClick={() => setPage(page - 1)}>
                                    <span aria-hidden="true">&laquo;</span>

                                </button> </li>}
                            {paginationString(assetsArr?.length)}
                            {assetsArr && <li className="page-item">
                                <button disabled={page + 1 > assetsArr.length / 20 ? true : false} className="page-link" aria-label="Next" onClick={() => setPage(page + 1)}>
                                    <span aria-hidden="true">&raquo;</span>

                                </button> </li>}
                        </ul>
                    </nav>

                </div>}
        </>
    )
}

export default AssetsList;