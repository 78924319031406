import React, { useEffect, useState, useContext } from "react";
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Preloader from "../UI/Preloader/Preloader";
import Row from "react-bootstrap/esm/Row";
import { AuthContext } from "../../context";
import DashboardSub from "./DashboardSub";
import DashboardContract from "./DashboardContract";
import DashboardPlatform from "./DashboardPlatform";
import AssetService from "../../API/AssetService";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DashboardQuota from "./DashboardQuota";
import DashboardServ from "./DashboardServ";
import DashboardContractDoug from "./DashboardContractDoug";
import DashboardOrgDoug from "./DashboardOrgDoug";
import DashboardCloudDoug from "./DashboardCloudDoug";
import DashboardSubDoug from "./DashboardSubDoug";
import DashboardServDoug from "./DashboardServDoug";
import DashboardContractGraph from "./DashboardContractGraph";
import DashboardSubEnded from "./DashboardSubEnded";
import WarehouseService from "../../API/WarehouseService";
import DashboardWarehouse from "./DashboardWarehouse";
import DashboardRentAssets from "./DashboardRentAssets";

function DashboardList() {

    const [isPreloader, setIsPreloader] = useState(false);
    const [errors, setErrors] = useState([]);
    const [firstKey, setFirstKey] = useState('subs');
    const [secondKey, setSecondKey] = useState('platHW');

    //устройства - платформы
    const [assetsStats, setAssetsStats] = useState([])
    const [warehouseStats, setWarehouseStats] = useState([])
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const titleStyles = {
        'textAlign': "center",
        'margin': '0 0 10px',
        'padding': '0',
        'fontSize': '20px',
        'backgroundColor': 'rgb(13, 110, 253, .2)'
    }
    const appletStyle = {
        'border': '1px solid #0d6efd', 'padding': '0', 'borderRadius': '10px', 'position': "relative", 'boxSizing': "border-box",
        'WebkitBoxSizing': 'border-box'

    }

    useEffect(() => {
        setErrors([]);

        (async () => {

            setIsPreloader(true);
            await AssetService.getStats().then((res) => setAssetsStats(res)).catch(() => changeStatus()).finally(() => setIsPreloader(false));
            await WarehouseService.getStats().then((res) => setWarehouseStats(res)).catch(() => changeStatus()).finally(() => setIsPreloader(false));

        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (

        <Col >
            {isPreloader && <Preloader />}
            <Row className="mb-4">
                <div><h3>Дашборд</h3></div>
            </Row>
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }

            <hr />
            <Row className="mb-3 justify-content-md-center" style={{ minHeight: '600px', margin: '0 auto' }}>
                <Col className="col-6" style={appletStyle}>
                    <h4 style={titleStyles}>Ближайшие продления</h4>
                    <Tabs
                        style={{ fontSize: 20, borderColor: "black" }}
                        id="subsId"
                        activeKey={firstKey}
                        onSelect={(key) => setFirstKey(key)}
                        className="mb-3"
                    >
                        <Tab eventKey="subs" title="Подписки" >
                            {firstKey === 'subs' && <DashboardSub />}
                        </Tab>
                        {/* <Tab eventKey="service" title="Сервисы" >
                            {firstKey === 'service' && <DashboardServ />}
                        </Tab> */}
                        <Tab eventKey="contracts" title="Договора" style={{ borderColor: "black" }} >
                            {firstKey === 'contracts' && <DashboardContract />}
                        </Tab>
                        <Tab eventKey="ended" title="Истекшие" style={{ borderColor: "black" }}  >
                            {firstKey === 'ended' && <DashboardSubEnded />}
                        </Tab>
                        <Tab eventKey="rent" title="Аренда" style={{ borderColor: "black" }}  >
                            {firstKey === 'rent' && <DashboardRentAssets />}
                        </Tab>
                    </Tabs>
                </Col>

                <Col className="col-6" style={appletStyle}>
                    <h4 style={titleStyles}>Платформы</h4>
                    <Tabs
                        style={{ fontSize: 20, borderColor: "black" }}
                        id="PlatsId"
                        activeKey={secondKey}
                        onSelect={(key) => setSecondKey(key)}
                        className="mb-3"
                    >
                        <Tab eventKey="platHW" title="Платформы тип HW"  >
                            {secondKey === 'platHW' && <DashboardPlatform assetsStats={assetsStats.hw_platform_assets} platType='HW' />}
                        </Tab>
                        <Tab eventKey="platVM" title="Платформы тип VM" >
                            {secondKey === 'platVM' && <DashboardPlatform assetsStats={assetsStats.vm_platform_assets} platType={'VM'} />}
                        </Tab>
                        <Tab eventKey="platWH" title="Склад" >
                            {secondKey === 'platWH' && <DashboardWarehouse assetsStats={warehouseStats.hw_platform_assets} platType={'Склад'} />}
                        </Tab>
                    </Tabs>

                </Col>

            </Row>
            <Row>
                <Col style={appletStyle} className="col-12 mb-3">
                    <DashboardContractGraph />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col className="col-3" style={appletStyle}>
                    <h4 style={titleStyles}>Квоты</h4>
                    <DashboardQuota />
                </Col>
                <Col className="col-3" style={appletStyle}>
                    <h4 style={titleStyles}>Контракты</h4>
                    <DashboardContractDoug />
                </Col>
                <Col className="col-3" style={appletStyle}>
                    <h4 style={titleStyles}>Организации</h4>
                    <DashboardOrgDoug />
                </Col></Row>
            <Row>
                <Col className="col-3" style={appletStyle}>
                    <h4 style={titleStyles}>Сервисы</h4>
                    <DashboardServDoug />
                </Col>
                <Col className="col-3" style={appletStyle}>
                    <h4 style={titleStyles}>Облака</h4>
                    <DashboardCloudDoug />
                </Col>
                <Col className="col-3" style={appletStyle}>
                    <h4 style={titleStyles}>Подписки</h4>
                    <DashboardSubDoug />
                </Col>

            </Row>
        </Col>
    );
}
export default DashboardList;