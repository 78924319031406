/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import UrlService from "../../API/UrlService";
import Preloader from "../UI/Preloader/Preloader";
import { AuthContext } from "../../context";
import CheckPlatform from "./CheckPlatform";
import DeviceStats from "./DeviceStats";
import Auth from "../../API/Auth";
import CheckSn from "./CheckSn";
import CheckUrl from "./CheckUrl";
import bolt from '../../icons/bolt.svg';
import ApiGatewayService from "../../API/ApiGatewayService";
import { Form } from "react-bootstrap";
function CheckStats({ badgeData }) {
    const [errors, setErrors] = useState([]);
    const [page, setPage] = useState(0)
    const [statistic, setStatistic] = useState({});
    const [filteredArray, setFilteredArray] = useState(null)
    const [withoutRequest, setWithoutRequest] = useState(false);
    const [withoutOrg, setWithoutOrg] = useState(false);
    const [withRequest, setWithRequest] = useState(false);
    const [isPreloader, setIsPreloader] = useState(false);
    const [isPreloaderPlatform, setIsPreloaderPlatform] = useState(false);
    const [platformStats, setPlatformStats] = useState({})
    const [orderReq, setOrderReq] = useState(false)
    const [orderReqPlatform, setOrderReqPlatform] = useState(false);
    const [countRequestApi, setCountRequestApi] = useState(0);
    const [deleteItem, setDeleteItem] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [successDelete, setSuccessDelete] = useState(false);
    const [successAdd, setSuccessAdd] = useState(false);
    const [successActive, setSuccessActive] = useState(false)
    const [searchText, setSearchText] = useState("");
    const [orderFeed, setOrderFeed] = useState(false);
    const [orderPercent, setOrderPercent] = useState(false);
    const [dateAfter, setDateAfter] = useState(new Date().toISOString().replace('.', '-').split('T')[0]);
    const [dateBefore, setDateBefore] = useState(null);
    const [withKfeed, setWithKfeed] = useState(false);
    const [kfeedMode, setKfeedMode] = useState(false);
    const [errorsDelete, setErrorsDelete] = useState([]);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const appletStyleStats = {
        'border': '1px solid #0d6efd', 'padding': '0', 'borderRadius': '10px', 'position': "relative", 'boxSizing': "border-box",

        'WebkitBoxSizing': 'border-box', minHeight: '600px',

    }
    const titleStyles = {
        'textAlign': "center",
        'margin': '0 0 10px',
        'padding': '0',
        'fontSize': '20px',
        'backgroundColor': 'rgb(13, 110, 253, .2)'
    }
    const appletStyle = {
        'border': '1px solid #0d6efd', 'padding': '0', 'borderRadius': '10px', 'position': "relative", 'boxSizing': "border-box",

        'WebkitBoxSizing': 'border-box', minHeight: '300px',

    }
    useEffect(()=> {
        (async () => {
            setIsPreloaderPlatform(true)
            await UrlService.checkPlatformStats().then((resp) => { 
      
                setErrors([]); setIsPreloaderPlatform(false); setPlatformStats(resp) }).catch((e) => { changeStatus(); setIsPreloaderPlatform(false); setErrors(e.message); });
        
        })()
    },[])

    useEffect(() => {
        (async () => {
            setIsPreloader(true);
            let filters=false;
            if (dateAfter || dateBefore) {
                filters = {
                    date_after: dateAfter?.split('-').reverse().join('.'),
                    date_before: dateBefore?.split('-').reverse().join('.'),
                };
            }
            await UrlService.checkStats(filters).then((resp) => { 
                    if (filteredArray) {
                        newTextFilter(resp.assets,searchText, activeFilter === "Активные" ? true : activeFilter === "Неактивные" ? false : null, withoutRequest, orderReq, orderReqPlatform, withRequest, withoutOrg, true , orderFeed, kfeedMode, withKfeed, orderPercent)
                    }
                setStatistic(resp); setErrors([]); setIsPreloader(false);  }).catch((e) => { changeStatus(); setIsPreloader(false); setErrors(e.message); });
        
        })()
    }, [successDelete, successAdd, successActive, dateAfter, dateBefore])
    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                renewDataPlatforms();
            })
            .catch(() => changeStatus())
    }

    const renewDataPlatforms = async () => {
        setIsPreloaderPlatform(true);
        await UrlService.checkPlatformStats().then((resp) => { setErrors([]); setIsPreloaderPlatform(false); setPlatformStats(resp) })
            .catch((e) => {
                if (e.message[0].includes('token')) refreshToken();
                else { setIsPreloaderPlatform(false); setErrors(e.message); }
            });
    }


    const activeFilter = (arr, filter, searchText) => {
        setPage(0)
        let filtArr = arr?.filter((item) => item.is_active === filter)
        requestNullFilter(filtArr, searchText);
        // setFilteredArray(filtArr);

    }

    const newTextFilter = (arr, filter, status, check, orderReq, orderPlatform, withRequestItem, withoutOrg, changePage, orderFeed, kfeedMode, withKfeed, orderPercent) => {
        if (!changePage) setPage(0);
        let filtArrSn = arr?.filter((item) => item.sn.toLowerCase().includes(filter.toLowerCase()));
        let filtArrComment = arr?.filter((item) => item.comment !== null && item.comment.toLowerCase().includes(filter.toLowerCase()));
        let filtArrPlatform = arr?.filter((item) => item.platform__name!== null && item.platform__name?.toLowerCase().includes(filter.toLowerCase()));
        let filtArrOrg = arr?.filter((item) => item.organisation__name !== null && item.organisation__name.toLowerCase().includes(filter.toLowerCase()));
        let allFilterArr = filtArrSn.concat(filtArrComment).concat(filtArrPlatform).concat(filtArrOrg);

        let filterArr = allFilterArr.filter((i, index, c) => allFilterArr.findIndex(item => (item.sn === i.sn)) === index);
        let filterStatus = []
        if (status === null) filterStatus = filterArr.filter((item) => item.is_active !== status);
        else filterStatus = filterArr.filter((item) => item.is_active === status);

        let filterRequest = [];
        if (status === false) setWithoutRequest(false)
        if (check === true && status !== false) { filterRequest = filterStatus.filter((item) => item.is_active === true && item.curr_day_req_cnt === 0); }
        else { filterRequest = filterStatus.slice() }
        let filterWithRequest = []
        if (status === false) setWithRequest(false)
        if (withRequestItem === true && status !== false) { filterWithRequest = filterRequest.filter((item) => { return item.curr_day_req_cnt > 0 }); }
        else { filterWithRequest = filterRequest.slice() }
        let filterOrderReq = [];
        if (orderReq === '↑') filterOrderReq = filterWithRequest.sort((a, b) => a.curr_day_req_cnt - b.curr_day_req_cnt);
        else if (orderReq === '↓') { filterOrderReq = filterWithRequest.sort((a, b) => b.curr_day_req_cnt - a.curr_day_req_cnt); }
        else if (orderReq === false) { filterOrderReq = filterWithRequest.sort((a, b) => changeDate(b.updated_at) - changeDate(a.updated_at)); }
        let filterOrederPlatform = [];
        if (orderPlatform === '↑') filterOrederPlatform = filterOrderReq.sort((a, b) => a.platform__name !== null ? b.platform__name!== null ? a.platform__name.localeCompare(b.platform__name) : -1 : -1);
        else if (orderPlatform === '↓') { filterOrederPlatform = filterOrderReq.sort((a, b) => a.platform__name !== null ? b.platform__name!== null ? a.platform__name.localeCompare(b.platform__name) : -1 : -1).reverse(); }
        else if (orderPlatform === false) { filterOrederPlatform = filterOrderReq.sort((a, b) => changeDate(b.updated_at) - changeDate(a.updated_at)); }
        let filterWithutOrg = [];
        if (withoutOrg=== true ) { filterWithutOrg = filterOrederPlatform.filter((item) => { return item.platform__name ===null }); }
        else { filterWithutOrg  = filterOrederPlatform.slice() }

        let filterWithKfeed = [];
        if (withKfeed=== true ) { filterWithKfeed= filterWithutOrg.filter((item) => {return item.urlf_can_set_fallbacks == true }); }
        else { filterWithKfeed  = filterWithutOrg.slice() }

        let filterKfeedMode = [];
        if (kfeedMode === 'replacing') filterKfeedMode= filterWithKfeed.filter((item) => { return item.fallbacks?.length> 0 });
        else if (kfeedMode === 'monitoring') { filterKfeedMode= filterWithKfeed.filter((item) => { return item.fallbacks?.length === 0 }); }
        else if (kfeedMode === false) { filterKfeedMode = filterWithKfeed.slice() }

        let filterOrderFeed = [];
        if (orderFeed === '↑') filterOrderFeed= filterKfeedMode.sort((a, b) => a.kfeed_hits_total - b.kfeed_hits_total);
        else if (orderFeed === '↓') { filterOrderFeed= filterKfeedMode.sort((a, b) => b.kfeed_hits_total - a.kfeed_hits_total); }
        else if (orderFeed === false) { filterOrderFeed = filterKfeedMode.sort((a, b) => changeDate(b.updated_at) - changeDate(a.updated_at)); }
        let filterOrderPercent = [];
        
        if (orderPercent === '↑') filterOrderPercent= filterOrderFeed.sort((a, b) => (a.curr_day_req_cnt ? (a.kfeed_hits_total / a.curr_day_req_cnt)*100:0.00) .toFixed(2) -(b.curr_day_req_cnt?(b.kfeed_hits_total / b.curr_day_req_cnt)*100: 0.00).toFixed(2));
        else if (orderPercent === '↓') { filterOrderPercent= filterOrderFeed.sort((a, b) => (b.curr_day_req_cnt ? (b.kfeed_hits_total / b.curr_day_req_cnt)*100:0.00) .toFixed(2) -(a.curr_day_req_cnt?(a.kfeed_hits_total / a.curr_day_req_cnt)*100: 0.00).toFixed(2)); }
        else if (orderPercent === false) { filterOrderPercent = filterOrderFeed.sort((a, b) => changeDate(b.updated_at) - changeDate(a.updated_at)); }
        setFilteredArray(filterOrderPercent);
    }

    const changeDate = (date) => {
        const dateArr = date.split(' ');
        const dateReverse = dateArr[0].split('.').reverse().join('-');
        const fullDate = dateReverse + ' ' + date[1] + ' ' + date[2] + date[3];
        const formatDate = new Date(fullDate)
        return formatDate.getTime()
    }
    const requestNullFilter = (arr, searchText, check) => {

        if (check === true) {

            setPage(0)
            let filtArr = arr?.filter((item) => item.is_active === true && item.curr_day_req_cnt === 0)
            newTextFilter(filtArr, searchText)

        } else {
            newTextFilter(arr, searchText)
        }
    }

    const handleClick = (index) => {

        if (index === 0) setPage(0)
        else setPage(index)

    }

    const newPaginationString = (count) => {
        let paginationArr = []
        let arrPages = [];
        let allPages = Math.floor(count / 20);
        if (count % 20 === 0) allPages = Math.floor(count / 20) - 1;
        for (let i = 0; i <= allPages; i++) { arrPages.push(i) };
        if (count) {
            if (allPages <= 10)
                return arrPages.map((item, index) => <li key={'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => handleClick(index)} >{index + 1}</button></li>)
            else if (page < 7 && allPages > 10) {
                paginationArr = arrPages.slice(0, 8); paginationArr.push('...', arrPages.length - 1); return paginationArr.map((item, index) => <li key={'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => item !== '...' && handleClick(item)} >{item !== "..." ? item + 1 : item}</button></li>)
            }
            else if (page > arrPages.length - 7 && allPages > 10) {
                paginationArr = arrPages.slice(arrPages.length - 8, arrPages.length); paginationArr.unshift(0, '...'); return paginationArr.map((item, index) => <li key={item === '...' ? item + index : 'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => item !== '...' && handleClick(item)} >{item !== "..." ? item + 1 : item}</button></li>)
            }
            else { paginationArr = arrPages.length > 0 && arrPages.slice(page - 3, page + 4); paginationArr.unshift(0, '...'); paginationArr.push('...', arrPages.length - 1); return paginationArr.map((item, index) => <li key={item === '...' ? item + index : 'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => item !== '...' && handleClick(item)} >{item !== "..." ? item + 1 : item}</button></li>) }
        }
    }
    const deleteInstance = async (id) => {
        setIsPreloader(true);
        await UrlService.deleteItem(id)
            .then(() => { setIsPreloader(false); setDeleteItem(false); setSuccessDelete(!successDelete); setErrorsDelete([])})
            .catch((e) => {
                
                 setIsPreloader(false); setErrorsDelete(e.message) }
            );
    }
    return (
        <>
            <Row className='justify-content-between mt-3 mb-4'>

                <Col className='col-md-auto'><h3>URL FILTERING</h3></Col>

                <Col className="col-md-auto">
                    <div style={{ display: "flex", gap: '10px', justifyContent: "flex-end" }}>
                        <div style={{ height: 70, padding: '10px 10px 3px 0 ', fontSize: '20px', display: 'flex', flexDirection: 'row', marginRight: '20px' }} className="badge bg-light text-dark">
                            <img style={{ width: 50, height: 50 }} src={bolt} alt='badge'></img>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', }}><span>{ badgeData?.urldb_cnt|| ''}</span>
                                <span style={{ fontSize: '10px' }}>Записей в базе</span></div>
                        </div>
                        <div style={{ height: 70, padding: '10px 10px 3px', fontSize: '20px', display: 'flex', flexDirection: 'row', gap: '10px' }} className="badge bg-light text-dark">
                            {/* <img style={{ width: 50, height: 50 }} src={bolt} alt='badge'></img> */}
                            <span >NGFW</span>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}><span>{badgeData?.hits_total || ''}</span>
                                <span style={{ fontSize: '10px' }}>Кол-во запросов за сегодня</span></div>
                        </div>

                        <div style={{ height: 70, padding: '10px 10px 3px', fontSize: '20px', display: 'flex', flexDirection: 'row', gap: '10px' }} className="badge bg-light text-dark">
                            {/* <img style={{ width: 50, height: 50 }} src={bolt} alt='badge'></img> */}
                            <span >API</span>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}><span>{badgeData?.api_hits_total}</span>
                                <span style={{ fontSize: '10px' }}>Кол-во запросов за сегодня</span></div>
                        </div>
                        <div style={{ height: 70, padding: '10px 10px 3px', fontSize: '20px', display: 'flex', flexDirection: 'row', gap: '10px' }} className="badge bg-light text-dark">
                            {/* <img style={{ width: 50, height: 50 }} src={bolt} alt='badge'></img> */}
                            <span >KFEED</span>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}><span>{badgeData?.kfeed_hits_primary + badgeData?.kfeed_hits_addition} / {badgeData?.kfeed_hits_secondary}</span>
                                <span style={{ fontSize: '10px' }}>кол-во запросов за сегодня</span></div>
                        </div>
                    </div>
                </Col>
              
            </Row>
            <hr />
            <Row className="mb-3 mt-2 justify-content-start" style={{ minHeight: '300px', margin: '0 auto', padding: 0 }}>
                <Col className="col-6" style={appletStyle}>
                    <h4 style={titleStyles}>CHECK URL</h4>
                    <hr />
                    <CheckUrl />
                </Col>
                <Col className="col-6" style={appletStyle}>
                    <h4 style={titleStyles}>CHECK SN</h4>
                    <hr />
                    <CheckSn />
                </Col> </Row>
            <Row className='mt-3' style={{ minHeight: '300px', margin: '0 auto', padding: 0 }}>
                <Col className='col-12' style={appletStyleStats}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    
                    <DeviceStats
                        orderReq={orderReq} setOrderReq={setOrderReq} orderReqPlatform={orderReqPlatform} setOrderReqPlatform={setOrderReqPlatform}
                        handleClickActiveFilter={activeFilter} withRequest={withRequest} setWithRequest={setWithRequest}
                        orderFeed={orderFeed} setOrderFeed={setOrderFeed}
                        withoutOrg={withoutOrg} setWithoutOrg={setWithoutOrg} searchText={searchText} setSearchText={setSearchText}
                        filteredArray={filteredArray} setFilteredArray={setFilteredArray} textFilter={newTextFilter} withoutRequest={withoutRequest} setWithoutRequest={setWithoutRequest} requestNullFilter={requestNullFilter}
                        errors={errors} page={page} setPage={setPage} assetStats={statistic.asset_stats} assets={statistic.assets} handleClick={handleClick} paginationString={newPaginationString} 
                        setCurrentItem={setCurrentItem} currentItem={currentItem} setDeleteItem={setDeleteItem} deleteItem={deleteItem} deleteInstance={deleteInstance} successAdd={successAdd} setSuccessAdd={setSuccessAdd}
                       setSuccessActive={setSuccessActive} successActive={successActive}
                       setDateAfter={setDateAfter} setDateBefore={setDateBefore} dateAfter={dateAfter} dateBefore={dateBefore}
                       kfeedMode={kfeedMode} setKfeedMode={setKfeedMode} withKfeed={withKfeed} setWithKfeed={setWithKfeed}
                       orderPercent={orderPercent} setOrderPercent={setOrderPercent}
                       errorsDelete={errorsDelete} setErrorsDelete={setErrorsDelete}
                       />
                </Col>
            </Row>
            <Row className='mt-3' style={{ minHeight: '300px', margin: '0 auto', padding: 0, maxHeight: '700px', overflowY: "scroll" }}>
                <Col className='col-12' style={appletStyleStats}>

                    {isPreloaderPlatform && <Preloader leftCoord={true} />}
                    <CheckPlatform errors={errors} platform={platformStats} renewFunction={renewDataPlatforms} />
                </Col>
            </Row>
        </>
    )
}

export default CheckStats;