import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import MySelect from "../UI/MySelect";
import PlatformService from "../../API/PlatformService";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function PlatformAddForm(props) {

    const [platformName, setPlatformName] = useState("");
    const [errors, setErrors] = useState([]);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                add();
            })
            .catch(() => changeStatus())
    }

    const add = async event => {
        if (event) event.preventDefault();
        const item = {
            'name': platformName,
            'type': props.curPlatformType,
        };

        try {
            await PlatformService.newItem(item);
            props.setPlatformAdding(false)
            props.setPlatformAdded(true);
            setErrors([]);
        } catch (e) {

            if (e.message[0].includes('token')) refreshToken();
            else {
                setErrors(e.message);
            }

        };
    }


    return (
        <Form onSubmit={add} >
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Form.Group controlId="formFile" className="mb-4">
                <Form.Label>Тип новой платформы</Form.Label>
                <MySelect
                    size="sm"
                    platformtypes={props.platformtypes}
                    setCurPlatformType={props.setCurPlatformType}
                    setPlatformTypeFilter={props.setPlatformTypeFilter}
                    value={props.curPlatformType}
                />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-4">
                <Form.Label>Название новой платформы</Form.Label>
                <Form.Control size="sm" type="text" value={platformName} placeholder="Введите.." onChange={(e) => setPlatformName(e.target.value)} />
                <Form.Text muted>
                    Например 5220 / 220 / VM2
                </Form.Text>
            </Form.Group>
            <Button variant="outline-primary" size="sm" type="submit">
                Добавить
            </Button>
            <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); props.setPlatformAdding(false) }}>
                Отменить
            </Button>
        </Form>
    );
}
export default PlatformAddForm;