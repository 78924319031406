import React, { useState, useContext } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext, UserContext } from "../../context";
import MyModal from "./MyModal/MyModal";
import ChangePasswordForm from "../User/ChangePasswordForm";
import MyProfileForm from "../User/MyProfileForm";
import Button from 'react-bootstrap/Button';
import SettingsPopup from "./SettingsPopup/SettingsPopup";

// сделать передачу user-info в props-ах попробовать!

const Header = function () {
    const {logout} = useContext(AuthContext);
    const handleLogout =()=> logout();
    const { userinfo, setUserinfo } = useContext(UserContext);
    const [firstName, setFirstName] = useState(userinfo.first_name);
    const [lastName, setLastName] = useState(userinfo['last_name']);
    const [jobTitle, setJobTitle] = useState(userinfo['job_title']);
    const [phone, setPhone] = useState(userinfo['phone']);

    const [isOpen, setIsOpen] = useState(false);

    const tooglePopupState = () => setIsOpen(!isOpen);

    const [passChangeModal, setPassChangeModal] = useState(false);
    const [profileChangeModal, setProfileChangeModal] = useState(false);

    const toglePassChangeModal = () => setPassChangeModal(!passChangeModal);
    const togleProfileChangeModal = () => setProfileChangeModal(!profileChangeModal);

    return (
        <Row>
            <Col>
                <MyModal visible={passChangeModal} setVisible={setPassChangeModal}>
                    <ChangePasswordForm
                        setVisible={setPassChangeModal}
                    />
                </MyModal>
                <MyModal visible={profileChangeModal} setVisible={setProfileChangeModal}>
                    <MyProfileForm
                        setVisible={togleProfileChangeModal}
                        firstName={firstName}
                        jobTitle={jobTitle}
                        phone={phone}
                        lastName={lastName}
                        setFirstName={setFirstName}
                        setLastName={setLastName}
                        setJobTitle={setJobTitle}
                        setPhone={setPhone}
                        userinfo={userinfo}
                        setUserinfo={setUserinfo}
                    />
                </MyModal>
                <nav className="navbar navbar-expand-lg bg-light">
                    <div className="container-fluid">
                        <ul className="navbar-nav">


                            <li className="nav-item">
                                <a href="#none" className="nav-link active" disabled>Компания {userinfo.organization}</a>
                            </li>
                        </ul>
                        <div className="settings__block">
                            <a href="#none" className="nav-link" disabled>Здравствуйте, {userinfo.first_name} ({userinfo.email})</a>
                            {/* <ul className="navbar-nav position_relative">
                        <li className="nav-item">
                            <a href="#none" className="nav-link" disabled>Здравствуйте, {firstName} ({userinfo['email']})</a>
                        </li> */}
                            {/* <li className="nav-item justify-content-end"> */}
                            {/* <Button variant="link" onClick={(e) => {e.preventDefault(); setProfileChangeModal(true);}}>Профиль</Button> */}
                            {/* <a className="nav-link active" href="" onClick={(e) => {e.preventDefault(); setPassChangeModal(true);}}>Сменить пароль</a> */}
                            {/* </li> */}
                            {/* <li className="nav-item justify-content-end"> */}
                            {/* <Button variant="link" onClick={(e) => {e.preventDefault(); setPassChangeModal(true);}}>Сменить пароль</Button> */}
                            {/* <a className="nav-link active" href="" onClick={(e) => {e.preventDefault(); setPassChangeModal(true);}}>Сменить пароль</a> */}
                            {/* </li> */}
                            {/* <li className="nav-item justify-content-end"> */}
                            <Button variant="link" className='header__menu_button' onClick={tooglePopupState}>{!isOpen ? 'Настройки' : 'Закрыть'}</Button>
                            {/* <Link className="nav-link active" to="/login" onClick={logout}>Выйти</Link> */}
                            {/* </li> */}
                            <SettingsPopup
                                isOpen={isOpen}
                                logout={handleLogout}
                                changePass={toglePassChangeModal}
                                changeProfile={togleProfileChangeModal}
                            />
                        </div>

                    </div>
                </nav>
            </Col>
        </Row>
    );
}
export default Header;
