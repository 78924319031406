/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import PanSummaryDoughnut from "./PanSummaryDoughnut";
import Preloader from "../UI/Preloader/Preloader";
function KfeedSummaryPAN({ data, isLoading }) {
    const appletStyle = {
        border: "1px solid #0d6efd",
        borderRadius: "10px",
        position: "relative",
        boxSizing: "border-box",

        WebkitBoxSizing: "border-box",
        minHeight: "300px",
    };

    return (
        <>
            <Col style={appletStyle} className="col-5">
                {isLoading &&  <Preloader/>}
                <div style={{ display: "flex", opacity: isLoading? 0.1: 1 }}>
                   
                    {data && <PanSummaryDoughnut data={data}/>}
                </div>
            </Col>
        </>
    );
}
export default KfeedSummaryPAN;
