import React, { useState } from "react";
import Table from 'react-bootstrap/Table';
import ImgEdit from '../../icons/edit.png';
import MyModal from "../UI/MyModal/MyModal";
import PatchPlatformLimit from "./PatchPlatformLimit";

const EdlPlatformList = (props) => {
    const [patchPlatform, setPatchPlatform] = useState(false);
    const [currentPlatform, setCurrentPlatform] = useState(null);

    const basicStyles = {
        "fontSize": '12px',
    }

    return (
        <>
            {patchPlatform && currentPlatform && <MyModal visible={patchPlatform} setVisible={setPatchPlatform}>
            <PatchPlatformLimit currentPlatform={currentPlatform} setPatchPlatform={setPatchPlatform} platformPatch={props.platformPatch} setPlatformPatch={props.setPlatformPatch}/>
            </MyModal>}
            <Table striped bordered hover id='tableEdl' >
                <thead style={basicStyles}>
                    <tr>

                        <th>Платформа</th>
                        <th>Тип</th>
                        <th>IP limit</th>
                        <th>URL limit</th>
                        <th>DOMAIN limit</th>
                        {/* <th>HASH limit</th> */}
                        <th style={{ width: '5%' }}>Изменить</th>

                    </tr>
                </thead>
                <tbody>

                    {props.data?.map((item, index) =>
                        <tr key={item.id + 'edlPlat'}
                            style={basicStyles}
                          
                        >
                            <td>{item.name}</td>
                            <td>{item.type}</td>
                            <td>{item.edl_ip_limit}</td>
                            <td>{item.edl_url_limit}</td>
                            <td>{item.edl_domain_limit}</td>
                            {/* <td>{item.edl_domain_limit}</td> */}
                            <td><img width="20" className="interactive_hover" alt={'edit'} src={ImgEdit} disabled onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    setCurrentPlatform(item)
                                    setPatchPlatform(true)
                                }
                            } />
                            </td>

                        </tr>
                    )
                    }
                </tbody>
            </Table></>
    )
}

export default EdlPlatformList;

