import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context";


const WindowFocusHandler = () => {
    let timer = null;
        //контекст
        const { setStatus, logout } = useContext(AuthContext);
        const changeStatus = () => setStatus(true);

    const onFocus = () => {
        if (timer) clearTimeout(timer);
    };

    const onBlur = () => {
        const token = localStorage.getItem('accessTokenFM');
        timer = setTimeout(() => {logout() }, [3600000]);
    };

    useEffect(() => {

        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        onFocus();
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        };
    }, []);

    return <></>;
};

export default WindowFocusHandler;