import React, { useState } from "react";
import Row from 'react-bootstrap/Row';
import Header from "../components/UI/Header";
import SideBar from "../components/UI/Sidebar";
import OrgList from "../components/Organizations/OrgList";

function AllOrgsPage() {
  const [status, setStatus] = useState(false);

  return (
    <div>
      {/* <AuthLayout status={status} setStatus={setStatus}> */}
      <Header />
      <Row>
          <SideBar />
         {status===false &&  <OrgList setStatus={setStatus}/>}
      </Row>
      {/* </AuthLayout> */}
    </div>
  );
}

export default AllOrgsPage;
