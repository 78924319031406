import React from "react";
import Col from "react-bootstrap/esm/Col";
import Table from 'react-bootstrap/Table';


const CloudListService = ({ data }) => {

    const dateStyles = {
        "fontSize": 'xx-small',
    }

    const calculateUpdateDate = (date, sec) => {

        let updFirstDate = new Date(date);
        let upDate = new Date(updFirstDate.getTime() + Number(sec) * 1000 + 10800000);
        let row = upDate.toISOString().replace('T', ' ').slice(0, -5).split(' ')
        return row[1] + ' ' + row[0].split('-').reverse().join('.')
    }

    const getObjectKeys = (object) => {

        return Object.entries(object).map(
            ([key, value]) => { return <Col key={key + 'ind'}>{key}: {value}</Col> }
        );

    }
    const calculateDateDiff = (date, sec) => {

        const now = new Date();
        let timeDate = new Date(date);
        let difference = now.getTime() - timeDate.getTime() - Number(sec) * 1000;
        let styleArr = {}
        if (difference > 0) styleArr.color = 'red'
        else styleArr.color = 'black'
        return styleArr
    }

    const changeViewDate = (date) => {

        let row = date.split(' ');

        return row[1] + ' ' + row[0].split('-').reverse().join('.')
    }

    return (
        <Table striped bordered hover id='clserv' >
            <thead style={dateStyles}>
                <tr>
                    <th></th>
                    <th >Наполнение хранилища</th>
                    <th>Индексация хранилища</th>
                    <th>Хеширование серийных номеров</th>
                    <th>Скачивание файлов Release Notes</th>

                </tr>
            </thead>
            <tbody>
                <tr style={dateStyles}>
                    <th>Последнее выполнение</th>
                    <th><Col style={calculateDateDiff(data[0].updates_service.last_execution, data[0].updates_service.frequency)}>{changeViewDate(data[0].updates_service.last_execution.replace('T', ' '))}</Col>
                    </th>
                    <th style={calculateDateDiff(data[1].index_service.last_execution, data[1].index_service.frequency)}><Col>{changeViewDate(data[1].index_service.last_execution.replace('T', ' '))}</Col>

                    </th>
                    <th style={calculateDateDiff(data[2].hash_service.last_execution, data[2].hash_service.frequency)}><Col>{changeViewDate(data[2].hash_service.last_execution.replace('T', ' '))}</Col>

                    </th>
                    <th style={calculateDateDiff(data[3].parser_service.last_execution, data[3].parser_service.frequency)}><Col>{changeViewDate(data[3].parser_service.last_execution.replace('T', ' '))}</Col>


                    </th>

                </tr>
                <tr style={dateStyles}>
                    <th>Частота (сек)</th>
                    <th><Col>{data[0].updates_service.frequency}</Col></th>
                    <th><Col>{data[1].index_service.frequency}</Col>
                    </th>
                    <th><Col>{data[2].hash_service.frequency}</Col>
                    </th>
                    <th><Col>{data[3].parser_service.frequency}</Col>
                    </th>
                </tr>
                <tr style={dateStyles}>
                    <th>Следующее выполнение</th>
                    <th><Col>{calculateUpdateDate(data[0].updates_service.last_execution, data[0].updates_service.frequency)}</Col></th>
                    <th><Col>{calculateUpdateDate(data[1].index_service.last_execution, data[1].index_service.frequency)}</Col></th>
                    <th><Col>{calculateUpdateDate(data[2].hash_service.last_execution, data[2].hash_service.frequency)}</Col></th>
                    <th><Col>{calculateUpdateDate(data[3].parser_service.last_execution, data[3].parser_service.frequency)}</Col></th>

                </tr>
                <tr style={dateStyles}>
                    <th>Результаты последнего выполнения</th>
                    <th> {data[0].updates_service.storage.map((item, index) => { return <Col key={Object.keys(item)[0] + 'indexes'}><b>{Object.keys(item)[0]}</b> <b>last:</b> {item.last}, <b>files_count:</b> {item.files_count}</Col> })}</th>
                    <th><Col><b>Всего: </b>{data[1].index_service.total_index_files} <b>из них:</b></Col>{getObjectKeys(data[1].index_service.index)}</th>
                    <th><b>Захешировано: </b>{data[2].hash_service.total_hashed_sn} из: {data[4].serials.fw_serials + data[4].serials.panorama_serials}</th>
                    <th><Col><b>Найдено новых: </b>{data[3].parser_service.found.map((item, index) => { return <Col key={Object.keys(item)[0] + 'ps'}><b>{Object.keys(item)[0]}</b>: {item[Object.keys(item)[0]]}</Col> })}</Col>
                        <Col><b>Последнее скачивание:</b></Col>
                        <Col>{data[3].parser_service.last_parsed_pdfs.length > 0 ? data[3].parser_service.last_parsed_pdfs.map((item) => <Col key={item + 'pserv'}>{item}</Col>) : 'Нет данных'}</Col>
                        <Col><b>Было: </b>{data[3].parser_service.stats.records_before}</Col>
                        <Col><b>Стало: </b>{data[3].parser_service.stats.total_records}</Col>
                    </th>
                </tr>
            </tbody>
        </Table>
    )
}

export default CloudListService;

