import React from "react";
import Row from 'react-bootstrap/Row';
import Header from "../components/UI/Header";
import SideBar from "../components/UI/Sidebar";
import ContractArea from "../components/Contracts/ContractArea";



function ContractPage() {

  return (
    <div>
      <Header />
      <Row>
          <SideBar />
          <ContractArea/>
      </Row>
    </div>
  );
}

export default ContractPage;