/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DocumentationList from "./DocumentationList";

function DocumentationArea() {

    return (
        <>
            <Col>
                <h3 className="mb-3">Документация</h3>
                <Row>
                    <Col style={{ position: "relative" }}>
                        <DocumentationList />
                    </Col>
                </Row>
            </Col>
        </>
    );
}
export default DocumentationArea;