import React from "react";
import Row from 'react-bootstrap/Row';
import Header from "../components/UI/Header";
import SideBar from "../components/UI/Sidebar";
import UpdatesCloudArea from "../components/UpdatesCloud/UpdatesCloudArea";

function UpdatesCloudPage() {

  return (
    <div>
      <Header />
      <Row>
          <SideBar />
          <UpdatesCloudArea />
      </Row>
    </div>
  );
}

export default UpdatesCloudPage;
