import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";
import ServiceService from "../../API/ServiceService";
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import AssetCloud from "../../API/AssetService";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function AddCloud(props) {
    const [isPreloader, setIsPreloader] = useState(false);
    const [errors, setErrors] = useState(null);
    const [cloudList, setCloudList] = useState([]);
    const [currentCloud, setCurrentCloud] = useState(null);
    const [validCloud, setValidCloud] = useState(false);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {
        (async () => {
            setIsPreloader(true);
            let filters = false;
            if (filters) {
                filters.searchText = false;
            }
            await ServiceService.getCloudList(false, true).then((res) => setCloudList(res.results)).catch(() => changeStatus());

            setIsPreloader(false)
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                addCloud();
            })
            .catch(() => changeStatus())
    }

    const addCloud = async event => {
        if (event) event.preventDefault();
        setIsPreloader(true);
        const item = {};
        item.cloud = currentCloud.id;
        await AssetCloud.postCloudAsset(props.id, item)
            .then(() => setTimeout(() => { props.setAddingCloud(false); setIsPreloader(false); }, 1500))
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })
    }

    return (
        <Form onSubmit={addCloud} className='was-validated' style={{ position: 'relative' }}>
            {isPreloader && <Preloader />}
            <hr />
            {errors !== null && <Alert key="danger" variant="danger">{errors}</Alert>}
            <Row >
                <h5 className="mb-3">Добавить облако</h5>

                <Col >
                    <Form.Group className="mb-4">

                        <Dropdown style={!validCloud ? { border: "1px solid red", height: 31, borderRadius: 6, paddingLeft: '.5rem' } : { border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-assetSub" >
                                {currentCloud?.slug || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assetCloud'}
                                filterQa={true}
                            >
                                {cloudList?.map(cloud =>
                                    <Dropdown.Item eventKey={"clo" + cloud.id} key={"clo" + cloud.id} onClick={() => { setCurrentCloud(cloud); setValidCloud(true) }}>{cloud.slug}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
            </Row>
            <Row>

            </Row>
            <Row>
                <Col >
                    <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }}>
                        Добавить
                    </Button>

                </Col>
                <Col >
                    <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); props.setAddingCloud(false) }} style={{ width: '100%' }}>
                        Отменить
                    </Button>
                </Col> </Row>
            <hr />
        </Form>

    );
}
export default AddCloud;