import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OrgService from "../../API/OrgService";
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";
import ApiGatewayService from "../../API/ApiGatewayService";
import { trialDuration, standartDuration } from "../../utils/constants";
import MultiSelect from "./MultiSelect/MultiSelect";
function AddtokenForm({ addToken, setAddToken, setSuccessAdd, successAdd }) {

    const [valid, setVaild] = useState(false);
    const [itemOrg, setItemOrg] = useState(null);
    const [issuedDate, setIssuedDate] = useState(null);
    const [issuedTime, setIssuedTime] = useState('06:00');
    const [allOrganisations, setAllOrganisations] = useState(null);
    const [allAudience, setAllAudience] = useState([])
    const [allScope, setAllScope] = useState([])
    const [errors, setErrors] = useState([]);

    const [isPreloader, setIsPreloader] = useState(false);
    const [tokenDescription, setTokenDescription] = useState('');
    const [tokenDuration, setTokenDuration] = useState('');
    const [type, setType] = useState('trial');
    const [name, setName] = useState('');
    const [audience, setAudience] = useState(null);
    const [reqPerMonth, setReqPerMonth] = useState('');
    const [reqPerDay, setReqPerday] = useState('');
    const [selectedScopes, setSelectedScopes] = useState([])

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {

        (async () => {

            await OrgService.getDropList().then((res) => setAllOrganisations(res)).catch(() => changeStatus());
            await ApiGatewayService.getAudienceList().then((res) => { setAllAudience(res) }).catch((err) => changeStatus())
            await ApiGatewayService.getScopeList().then((res) => { setAllScope(res) }).catch((err) => changeStatus())
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') saveToken();

            })
            .catch(() => changeStatus())
    }


    const saveToken = async event => {
        setIsPreloader(true);
        if (event) event.preventDefault();
        const token = {};
        token.organisation = itemOrg.id;
        token.name = name;
        token.type = type;
        token.issued_at = issuedDate + 'T' + issuedTime;
        token.refresh_expires_in = tokenDuration;
        token.audience = audience.id;
        token.scope = selectedScopes;
        token.description = tokenDescription;
        if (reqPerMonth) token.monthly_requests_limit = reqPerMonth;
        if (reqPerDay) token.daily_requests_limit = reqPerDay;

        await ApiGatewayService.newToken(token)
            .then(() => { setAddToken(false); setIsPreloader(false); setSuccessAdd(!successAdd) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken('save');
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })


    }
    const getExpDate = () => {
        const startDate = new Date(issuedDate);
        const dateWithToken = startDate.getTime() + Number(tokenDuration) * 1000;
        const expDate = new Date(dateWithToken)
        return expDate.toISOString().split('T')[0]
    }
    return (
        <Form id="form" onSubmit={saveToken} className="was-validated" style={{ position: "relative" }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key={"danger" + error} variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}
            {/* <Row>
                <Col className="mb-1 col-2 ">
                    <div className="form-check" style={{ alignItems: "center", display: "flex", }}>
                        <input onChange={(evt) => setActive(evt.target.checked)} className="form-check-input" type="checkbox" value='' checked={active} id="isActive" style={{ height: '25px', width: '25px' }}></input>
                        <label className="form-check-label" htmlFor="isMain" style={{ fontSize: '14px', fontWeight: 600, marginLeft: '10px', color: "black" }}>
                            Active
                        </label>
                    </div></Col>
            </Row> */}
         
            <Row>
                <Col className=" col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Название</Form.Label>
                        <Form.Control size="sm" type='text' value={name} onChange={(e) => setName(e.target.value)} required={true}>

                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col className="col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Тип токена</Form.Label>
                        <Form.Select size="sm" id="tokenType" onChange={(e) => { setTokenDuration(''); setType(e.target.value) }} >
                            <option value={'trial'}>Trial</option>
                            <option value={'standard'}>Standard</option>
                            <option value={'unlimited'}>Unlimited</option>
                            <option value={'unlimited-without-updating'}>Unlimited without updating</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Организация</Form.Label>

                        <Dropdown style={!valid ? { border: "1px solid red", height: 31, borderRadius: 6, paddingLeft: '.5rem' } : { border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-orgs" >
                                {itemOrg?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'Org'}
                                filterQa={true}
                            >
                                {allOrganisations?.map(org =>
                                    <Dropdown.Item eventKey={"e" + org.id} key={"e" + org.id} onClick={() => { setItemOrg(org); setVaild(true); }}>{org.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
               
            </Row>
            <Row>
                <Col sm={2}>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Дата активации </Form.Label>
                        <Form.Control
                            value={issuedDate || ""}
                            onChange={(e) => setIssuedDate(e.target.value)}
                            size="sm" type="date"
                            required={true} />

                    </Form.Group>
                </Col>
                <Col sm={2}>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >время активации</Form.Label>
                        <Form.Control
                            value={issuedTime}
                            onChange={(e) => setIssuedTime(e.target.value)}
                            size="sm" type="time"
                            required={true} />

                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Сервис</Form.Label>

                        <Dropdown style={!audience ? { border: "1px solid red", height: 31, borderRadius: 6, paddingLeft: '.5rem' } : { border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-audience" >
                                {audience?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'audience'}
                                // filterQa={true}
                            >
                                {allAudience?.map(item =>
                                    <Dropdown.Item eventKey={"au" + item.id} key={"au" + item.name} onClick={() => { setAudience(item);setSelectedScopes([]) }}>{item.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Ресурсы и доступы</Form.Label>

                       {  <MultiSelect data={audience?.scopes || []} selectedScopes={selectedScopes} setSelectedScopes={setSelectedScopes} disabled={audience? false: true}/>}

                    </Form.Group>
                </Col>
               
            </Row>
            <Row>
                <Col className="col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Срок действия (дней)</Form.Label>
                        <Form.Select size="sm" id="tokenDate" onChange={(e) => setTokenDuration(e.target.value)} required={true}>
                            <option value='' disabled={tokenDuration ? true : false} >Выбрать</option>
                            {type === 'trial' ? trialDuration.map((item) => <option key={'option' + item.value} value={item.value}>{item.valueInDay}</option>) :
                                standartDuration.map((item) => <option key={'option' + item.value} value={item.value}>{item.valueInDay}</option>)}
                        </Form.Select>
                    </Form.Group>
                </Col>
            {(type === 'trial' || type === 'standard') && <Col className=" col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Максимальное кол-во запросов в месяц</Form.Label>
                        <Form.Control size="sm" type='number' value={reqPerMonth} onChange={(e) => setReqPerMonth(e.target.value)} required={true}>

                        </Form.Control>
                    </Form.Group>
                </Col>}
                {(type === 'trial' || type === 'standard') && <Col className=" col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Максимальное кол-во запросов в сутки</Form.Label>
                        <Form.Control size="sm" type='number' value={reqPerDay} onChange={(e) => setReqPerday(e.target.value)} required={true}>

                        </Form.Control>
                    </Form.Group>
                </Col>}
            </Row>

            <Row>

                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Описание</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={tokenDescription || ""}
                            size="sm"
                            onChange={(e) => setTokenDescription(e.target.value)}
                        />
                    </Form.Group>
                </Col>

                {issuedDate && tokenDuration && <Col sm={2}>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Дата окончания</Form.Label>
                        <Form.Control
                  
                            value={getExpDate()}
                            // onChange={(e) => setExpiresDate(e.target.value)}
                            disabled={true}
                            size="sm" type="date"
                        />

                    </Form.Group>
                </Col>}
                {issuedDate && tokenDuration &&<Col sm={2}>
                <Form.Group className="mb-3" required={true} >
                    <Form.Label style={{ color: "white" }}>Time</Form.Label>
                    <Form.Control
                        value={issuedTime}
                                disabled={true}
                        size="sm" type="time"
                        required={true} />

                </Form.Group>
            </Col>}
            </Row>
            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            setAddToken(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Добавить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>


            <hr />
        </Form >
    );
}
export default AddtokenForm;
