import React, { useState } from "react";
import Table from 'react-bootstrap/Table';
import AssetItem from "./AssetItem";
import MyModal from "../UI/MyModal/MyModal";
import AssetCopyForm from "./AssetCopyForm";

const AssetList = (props) => {
const [isCopyAsset, setIsCopyAsset] = useState(false);

    const basicStyles = {
        "fontSize": 'smaller',
    }

    return (
        <>
               { isCopyAsset && <MyModal visible={isCopyAsset} setVisible={setIsCopyAsset}>
            <AssetCopyForm asset={props.currentAsset} setIsCopyAsset={setIsCopyAsset}  copyAsset={props.copyAsset} setCopyAsset={props.setCopyAsset}/>
    </MyModal>}
        <Table striped bordered hover id='tableExcell' >
            <thead style={basicStyles}>
                <tr>
                <th ></th>
                    <th >Организация</th>
                    <th>Платформа</th>
                    <th>Серийный номер</th>
                    <th>Версия ОС</th>
                    <th>Комментарий</th>
                    <th>Город</th>
                    <th>Страна</th>
                    <th>Активные подписки</th>
                    <th>Дата окончания</th>
                    <th>Сервис</th>
                    <th>Copy</th>
                    <th>Удалить</th>
                </tr>
            </thead>
            <tbody>
               
               { props.assetItems?.map((item, index) =>
              
                    <AssetItem
                        item={item}
                        key={item.id + "assIt" + index}
                        viewOrg={props.viewOrg}
                        setViewOrg={props.setViewOrg}
                        close={props.close}
                        setIsCopyAsset={setIsCopyAsset}
                        setCurrentAsset={props.setCurrentAsset}
                        removeAsset={props.removeAsset}
                    />
                )
                }
            </tbody>
        </Table></>
    )
}

export default AssetList;

