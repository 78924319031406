import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";

import PimService from "../../API/PimService";
function PatchAgentSoftForm({ open, setSuccessAdd, successAdd, close }) {

    const [errors, setErrors] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [description, setDescription] = useState('');
    const [majorVersion, setMajorVersion] = useState('');
    const [releaseVersion, setReleaseVersion] = useState('');
    const [knownIssues, setKnownIssues] = useState('')
    const [issuedDate, setIssuedDate] = useState(null);
    const [visible, setVisible] = useState(true)

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') saveInstance();

            })
            .catch(() => changeStatus())
    }
const changeDate=(date)=> {
    const arr = date.split('.');
    return arr.reverse().join('-')
}
const changeName=(file)=> {
    const arr = file.split('/')
  
    return arr[arr.length - 1]
}
useEffect(()=> {
   if (open){ (async () => {
        setIsPreloader(true)
        await PimService.getPimDetail(open?.id, open?.type)
        .then((res) => { setKnownIssues(res.known_issues?.replaceAll('*', '\n')); setDescription(res.release_notes.replaceAll('\*', '\n')); setVisible(res.is_visible); setMajorVersion(res.major_version);  setIssuedDate(changeDate(res.release_date)); }).catch((err) => { setErrors(err.message) }).finally(() => { setIsPreloader(false); })
    }
    )()}
},[open])
    const saveInstance = async event => {
        setIsPreloader(true);
        if (event) event.preventDefault();
        const formData = new FormData();
        const arrDescription = description?.split('\n');
        const finalArr = arrDescription.map((item)=> item? item + '\*' : '');
        const stringDescription = finalArr.join('')
        formData.append('release_notes', stringDescription);
        formData.append('major_version', majorVersion);
        formData.append('release_date', issuedDate);
        formData.append('is_visible', visible);
        if (knownIssues) {
            let arr = knownIssues?.split('\n')
            const arrIssues = arr?.map((item)=> item + '\*')
            formData.append('known_issues', arrIssues.join(''))
            } else  formData.append('known_issues', '')
        await PimService.patchInstanceAgent(formData, open?.id)
            .then(() => { close(); setIsPreloader(false); setSuccessAdd(!successAdd) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken('save');
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })


    }
    // const nameFile = (index) => {
    //     const item = document.getElementById('nameFile' + index);

    //     return item.value;
    // }

    // const validateFiles = (value, index) => {
    //     let arr = [];
    //     arr = files?.filter((item) => item.field !== index);
    //     setFiles([...arr, { file: value, name: nameFile(index), field: index }])
    // }
    return (
        <>
        <h5>Изменить релиз</h5>
        <Form id="form" onSubmit={saveInstance} className="was-validated" style={{ position: "relative" }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key={"danger" + error} variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}


            <Row>
            <Col className="mb-3 col-4">
                    <Form.Group>
                        <Form.Label size="sm">Версия релиза</Form.Label>
                        <Form.Control size="sm" type="text" id={'mVersion'} required={true} placeholder={'Введите версию'} pattern="^\d+\.\d+\.\d+$"
                        value={majorVersion}
                        disabled={true}
                        onChange={(e)=> setMajorVersion(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        Версия релиза в формате 'X.Y.Z'
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2">
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Дата релиза </Form.Label>
                        <Form.Control
                            value={issuedDate || ""}
                            onChange={(e) => setIssuedDate(e.target.value)}
                            type="date"
                            size="sm"
                            required={true} />

                    </Form.Group>
                </Col>
                <Col className="col-2 mb-3">
                    <div
                        className="form-check form-switch"
                        style={{
                            minHeight: 0, display: "flex", flexDirection: "column"
                        }}
                    >
                           <Form.Label size="sm" style={{marginLeft: '-40px'}}>Visible</Form.Label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="statusChecks"
                            checked={visible}
                            style={{ height: "25px", width: "50px"}}
                            onChange={(evt) => setVisible(evt.target.checked)}
                        />
                    </div>
                </Col>
            </Row>
            <Row >

                {/* <Col className="mb-3 col-2">
                    <Form.Group>
                        <Form.Label size="sm">Версия релиза</Form.Label>
                        <Form.Control size="sm" type="text" id={'rVersion'} required={true} placeholder={'Введите версию'}
                        value={releaseVersion}
                        onChange={(e)=> setReleaseVersion(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        Версия релиза в формате 'X.Y.Z'
                        </Form.Text>
                        
                    </Form.Group>
                </Col> */}
               
                {/* <Col className="col-3">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">ОС</Form.Label>
                        <Form.Select size="sm" id="tokenType" onChange={(e) => {  setOsType(e.target.value) }} value={osType} >
                            <option value={'linux'}>Linux</option>
                            <option value={'windows'}>Windows</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-5">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Файл: {fileName}</Form.Label>
                        <Form.Control
                            type="file"
                            size="sm"
                            
                            style={{ width: '100%' }}
                            
                            onChange={(e) => {setFiles(e.target.files[0]); setFileName(e.target.files[0]?.name)}}
                        // onChange={(e) => { validateFiles(e.target.files[0], index) }}
                        // setFiles([...files, { file: e.target.files, name: nameFile(index,), field: index }]) 
                        accept="/*,.deb,.rpm,.exe"
                        />

                    <Form.Text className="text-muted">
                    Пекет установки ПО (.deb или .rpm)
                        </Form.Text>
                    </Form.Group>
                </Col> */}
                <Row>
                
                <Col className="mb-3 col-4">
                        <Form.Group className="mb-4">
                            <Form.Label size="sm">Описание релиза</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                type="text"
                                placeholder="Описание "
                                value={description || ""}
                                size="sm"
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                    </Col> 
                    <Col className="mb-3 col-4">
                        <Form.Group className="mb-4">
                            <Form.Label size="sm">Известные проблемы</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                type="text"
                                placeholder="Известные проблемы"
                                value={knownIssues || ""}
                                size="sm"
                                onChange={(e) => setKnownIssues(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                {/* <Col>
                    <img width="20" className="interactive_hover" alt={'delete'} src={ImgRemove} style={{ marginTop: '33px' }}
                        onClick={() => { setFilesCount(filesCount.filter((it) => it.count !== item.count)); setFiles(files.filter((it) => it.field + 1 !== item.count)) }} /></Col> */}
            </Row>
            {/* <Row>
                <Col className="mb-3 col-4">
                    <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                        onClick={() => { setFilesCount([...filesCount, { count: filesCount.length + 1 }]) }}
                    >Добавить документ</Button></Col></Row> */}
            <hr />
            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            close();

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Изменить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>


            <hr />
        </Form >
        </>
    );
}
export default PatchAgentSoftForm
