/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import Table from 'react-bootstrap/Table';
import Preloader from "../../UI/Preloader/Preloader";
import MyModal from "../../UI/MyModal/MyModal";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";
import Alert from 'react-bootstrap/Alert';
import { AuthContext } from "../../../context";
import UrlService from "../../../API/UrlService";
import { Form } from "react-bootstrap";
import touchIcon from '../../../icons/touch.svg';
import MyModalFeed from "../../UI/MyModalFeed/MyModalFeed";
import DetailFeed from "../DetailFeed";
const StatsList = ({sn, asset}) => {

    const basicStyles = {
        "fontSize": '10px',
    }
    const [statistic, setStatistic] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [statsCount, setStatsCount] = useState(0);
    const [errors, setErrors] = useState([]);
    const [dateAfter, setDateAfter] = useState(null);
    const [dateBefore, setDateBefore] = useState(null);
    const [openDetailFeed, setOpenDetailFeed] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [ordering, setOrdering] = useState(false)

    //пагинация 
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [isStatsLoading, setIsStatsLoading] = useState(false);
    const [nextPage, setNextPage] = useState(0);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

const fetchData=async(add)=> {
    if (sn)  {setNextPage(null)
        setIsStatsLoading(true)
        setIsPreloader(true);
        let filters=false;
        if (dateAfter || dateBefore || ordering) {
            filters = {
                date_after: dateAfter?.split('-').reverse().join('.'),
                date_before: dateBefore?.split('-').reverse().join('.'),
                ordering: ordering
            };
        }
        await UrlService.getKfeedUrlHitsFeeds(sn, add ? nextPage : 1, filters).then((res) => {
            if (add === 'add') {setNextPage(res.next ? nextPage + 1 : null); res.results?.map(item => setStatistic(arr => [...arr, item]));}
            else { setStatistic(res.results); setNextPage(res.next ? 2 : null); }
            setStatsCount(res.count)
           setIsStatsLoading(false)
         }).catch(() => changeStatus()).finally(()=> setIsPreloader(false))
}}

    useEffect(() => {
  fetchData()
    }, [sn, dateAfter, dateBefore, ordering]);


    useEffect(() => {

        if (isStatsLoading) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStatsLoading]);
    useEffect(() => {

        ( () => {

            if (needToLoad && nextPage) {
               
                fetchData('add');
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);


    const clearFilters=()=> {
        setDateAfter(null);
        setDateBefore(null);
        setOrdering(false);
    }
    const downloadFile=async ()=> {
        let fileType = 'xlsx';
        let filters = {sn: sn}
        if (dateAfter|| dateBefore) {
            filters.date_after = dateAfter;
            filters.date_before = dateBefore;
 
        }
        setIsPreloader(true)
        await UrlService.getExportExls(filters).then((resp)=>{
            const date = (new Date()).toISOString().replace('.', '-').split('T')[0];

            const effectiveFileName = `${sn}-${date}.${fileType}`;
            const url = window.URL.createObjectURL(new Blob([resp]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', effectiveFileName);
            document.body.appendChild(link);
            link.click();
            link.remove();

        }).catch((err) => { setErrors(err.message)}).finally(()=> setIsPreloader(false));

    }
    const formatLongUrl=(url, arr)=> {
        if (url.length>60) {
          let str = url.slice(0,59);
          arr.push(str);
     
          formatLongUrl(url.slice(59),  arr)
        } else  {arr.push(url);}
      return arr

    }
    return (
        <>
        {openDetailFeed&& <MyModalFeed visible={openDetailFeed} setVisible={setOpenDetailFeed} top={true}>
            <DetailFeed setVisible={setOpenDetailFeed} mask={currentItem.mask || currentItem.url} url={currentItem?.url}/>
            </MyModalFeed>}
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Row className="mb-3" style={{ position: 'relative' }}>
            {isPreloader && <Preloader />}
            <Col>
            <span style={{fontSize: '14px'}}> Кол-во попаданий урлов по фиду касперского за текущий день</span>
            <span style={{fontSize: '14px'}}>  (всего {statsCount})</span></Col>
           {asset &&  <Col className="col-3"><Button type="link" variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                            onClick={() => { downloadFile() }}

                        >Скачать в Excel</Button></Col>}
            </Row>
            <Row style={{ padding: '0 3px'}}>
                <Col className="mb-3 col-3">
                    <Form.Group  >
                        <Form.Label style={{fontSize: '12px'}}>После</Form.Label>
                        <Form.Control
                            disabled={false}
                           value={dateAfter || ""}
                           onChange={(e) => setDateAfter(e.target.value)}
                            size="sm" type="date"
                            />

                    </Form.Group>
                </Col>
                <Col className="mb-3 col-3">
                    <Form.Group  >
                        <Form.Label style={{fontSize: '12px'}}>До</Form.Label>
                        <Form.Control
                            disabled={false}
                           value={dateBefore || ""}
                           onChange={(e) => setDateBefore(e.target.value)}
                            size="sm" type="date"
                             />

                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" style={{display: "flex", alignItems: 'flex-end'}}>
                   { (dateAfter || dateBefore) && <Button variant="outline-info" size="sm" style={{ width: "100%",}} onClick={() => clearFilters()}>Сбросить</Button>}
                </Col>
           </Row>
            <Table striped bordered hover style={{ overflowY: "scroll" }}>

                <thead style={basicStyles}>
                    <tr>
                        <th></th>
                        <th >URL</th>
                        <th >Всего попаданий {
                        <span
                                                style={{
                                                    color:
                                                        ordering === "kfeed_hits"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    ordering !== "kfeed_hits"
                                                        ? setOrdering("kfeed_hits")
                                                        : setOrdering(false)
                                                }
                                            >
                                                ↑
                                            </span>
                                        }
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        ordering=== "-kfeed_hits"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    ordering !== "-kfeed_hits"
                                                        ? setOrdering("-kfeed_hits")
                                                        : setOrdering(false)
                                                }
                                            >
                                                ↓
                                            </span>
                                        }</th>
                        <th >Прямые попадания {
                        <span
                                                style={{
                                                    color:
                                                        ordering === "kfeed_direct_hits"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    ordering !== "kfeed_direct_hits"
                                                        ? setOrdering("kfeed_direct_hits")
                                                        : setOrdering(false)
                                                }
                                            >
                                                ↑
                                            </span>
                                        }
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        ordering=== "-kfeed_direct_hits"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    ordering !== "-kfeed_direct_hits"
                                                        ? setOrdering("-kfeed_direct_hits")
                                                        : setOrdering(false)
                                                }
                                            >
                                                ↓
                                            </span>
                                        }</th>
                        <th >Дополнительные попадания {
                        <span
                                                style={{
                                                    color:
                                                        ordering === "kfeed_secondary_hits"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    ordering !== "kfeed_secondary_hits"
                                                        ? setOrdering("kfeed_secondary_hits")
                                                        : setOrdering(false)
                                                }
                                            >
                                                ↑
                                            </span>
                                        }
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        ordering=== "-kfeed_secondary_hits"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    ordering !== "-kfeed_secondary_hits"
                                                        ? setOrdering("-kfeed_secondary_hits")
                                                        : setOrdering(false)
                                                }
                                            >
                                                ↓
                                            </span>
                                        }</th>
                        {!asset &&<th >Кол-во устройств</th>}
                        <th >Популярность
                            {
                        <span
                                                style={{
                                                    color:
                                                        ordering === "popularity"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    ordering !== "popularity"
                                                        ? setOrdering("popularity")
                                                        : setOrdering(false)
                                                }
                                            >
                                                ↑
                                            </span>
                                        }
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        ordering=== "-popularity"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    ordering !== "-popularity"
                                                        ? setOrdering("-popularity")
                                                        : setOrdering(false)
                                                }
                                            >
                                                ↓
                                            </span>
                                        }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {statistic?.map(item => (
                        <tr style={basicStyles} key={'kfeedData' + item.url}>
                             <td>
                                                <img
                                                    width="20"
                                                    className="img__asset"
                                                    src={touchIcon}
                                                    alt="linkToAsset"
                                                    onClick={() =>{
                                                        setCurrentItem(item);
                                                       setOpenDetailFeed(true)}
                                                    }
                                                />
                                            </td>
                                            {/* <td>{formatLongUrl(item.url, []).map(item=> <span key={item}>{item}<br/></span>)}</td> */}
                                            <td ><div style={{wordWrap: "break-word", width: '480px'}}>{item.url}</div></td>
                            <td>{item.kfeed_hits}</td>
                            <td>{item.kfeed_direct_hits}</td>
                            <td>{item.kfeed_secondary_hits}</td>
                           {!asset &&  <td>{item.sn_count}</td>}
                           <td>{item.popularity}</td>
                        </tr>
                    ))}
                    <tr ref={lastItem} style={{ height: 20 }} ></tr>
                </tbody>
            </Table >
            
        </>
    )
}

export default StatsList;