import React, { useEffect, useState, useRef, useContext } from "react";
import Col from 'react-bootstrap/Col';
import OrgService from "../../API/OrgService";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OrgCreateForm from "../Organizations/OrgCreateForm";
import Alert from 'react-bootstrap/Alert';
import Preloader from "../UI/Preloader/Preloader";
import Row from "react-bootstrap/esm/Row";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context";
import { organisationRating } from "../../utils/constants";
import Auth from "../../API/Auth";
import MyConfirm from "../UI/MyConfirm/MyConfirm";

function OrgList() {
    const navigate = useNavigate();

    const [isPreloader, setIsPreloader] = useState(false);
    const [isOrgListLoading, setIsOrgListLoading] = useState(false);
    const [nextPage, setNextPage] = useState(null);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();

    const basicStyles = {
        "position": 'relative',
        "fontSize": 'smaller',
    }
    const [orgList, setOrgList] = useState();
    const [rateFilter, setRateFilter] = useState(false)
    const [searchText, setSearchText] = useState("");
    const [isOrgAdding, setIsOrgAdding] = useState(false);
    const [orgAddingType, setOrgAddingType] = useState("ENDUSER");
    const [orgAdded, setOrgAdded] = useState(false);
    const [errors, setErrors] = useState([]);
    const [orgsStats, setOrgsStats] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(false)

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);


    const fetchData = async (add) => {
        let filters = false;

        if (searchText || rateFilter) {
            filters = {

                searchText: searchText,
                rateFilter: rateFilter,
            };
        }
        // const filters = { 'searchText': searchText };
        setIsOrgListLoading(true)
        await OrgService.getList(filters, add ? nextPage : 1).then((res) => {
            if (add === 'add') { res.results?.map(item => setOrgList(arr => [...arr, item])); setNextPage(res.next ? nextPage + 1 : null); }
            else { setOrgList(res.results); setNextPage(res.next ? 2 : null); }
            setIsOrgListLoading(false)
        }).catch(() => changeStatus()).finally(() => setIsPreloader(false));

        await OrgService.getStats().then((res) => setOrgsStats(res));

    }
    useEffect(() => {
        setErrors([]);
        if (orgAdded) {
            setOrgAdded(false);
        };

        setIsPreloader(true)
        const timerRefresh = setTimeout(fetchData, 1000);
        return () => {

            clearTimeout(timerRefresh)
        }
        // (async () => {

        //     setIsPreloader(true);
        //     let filters = false;

        //     if (searchText || rateFilter) {
        //         filters = {

        //             searchText: searchText,
        //             rateFilter: rateFilter,
        //         };
        //     }
        //     // const filters = { 'searchText': searchText };
        //     setIsOrgListLoading(true)
        //     await OrgService.getList(filters, false, false).then((res) => {
        //         setNextPage(res.next);
        //         setOrgList(res.results);
        //         setIsOrgListLoading(false)
        //     }).catch(() => changeStatus()).finally(() => setIsPreloader(false));

        //     await OrgService.getStats().then((res) => setOrgsStats(res));


        // })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, orgAdded, rateFilter]);

    const refreshToken = async (id) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                removeOrg(id);
            })
            .catch(() => changeStatus())
    }
    async function removeOrg(id) {
        try {
            await OrgService.delete(id);
            setOrgAdded(true);
            setErrors([]);
            setDeleteItem(false)
        } catch (err) {

            if (err.message[0].includes('token')) refreshToken(id);
            else {
                setErrors(err.message); setIsPreloader(false);
            }
        };
    }

    useEffect(() => {

        (async () => {

            if (needToLoad && nextPage) {
                fetchData('add')
                // setIsPreloader(true);
                // setIsOrgListLoading(true);
                // await OrgService.getList(false, nextPage, false).then((res) => {
                //     res.results?.map(item => setOrgList(arr => [...arr, item]));
                //     setNextPage(res.next);
                // }).finally(() => setIsPreloader(false));

                // setIsOrgListLoading(false);
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    useEffect(() => {

        if (isOrgListLoading) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOrgListLoading]);

    const clearFilters = () => {
        setRateFilter(false);
        setSearchText("");
    }

    return (

        <Col >
 {deleteItem && <MyConfirm visible={deleteItem} setVisible={setDeleteItem} >
                <Row className="mb-4" style={{ position: "relative" }}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4" style={{maxWidth:  '400px'}}>Вы точно хотите удалить организацию {currentItem.name}?</h3>
                </Row>
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); removeOrg(currentItem.id) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeleteItem(false); setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row>
            </MyConfirm>}
            <Row className="mb-4">
                <div><h3>Список всех организаций</h3></div>
            </Row>
            <Row className="mb-4">
                <b>Организаций "PARTNER" : {orgsStats?.partner_orgs}</b>
            </Row>
            <Row className="mb-4">
                <b>Организаций "ENDUSER": {orgsStats?.enduser_orgs}</b>
            </Row>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }

            <Button variant="outline-success" size="sm" onClick={() => setIsOrgAdding(true)} >Добавить новую организацию</Button>
            {
                isOrgAdding
                &&
                <OrgCreateForm
                    setIsOrgAdding={setIsOrgAdding}
                    orgAddingType={orgAddingType}
                    setOrgAdded={setOrgAdded}
                    allTypes={true}
                    setOrgAddingType={setOrgAddingType}
                />
            }
            <hr />
            <Row style={{ position: "relative" }}>
                {isPreloader && <Preloader />}
                <Col className="mb-3 col-4" >
                    <Form.Label size="sm">Поиск</Form.Label>
                    <Form.Control onChange={(e) => { setSearchText(e.target.value) }}
                        placeholder="Начните вводить..."
                        size="sm"
                        style={{ fontSize: '1rem', height: 38 }}
                        value={searchText}

                    />
                    
                </Col>
                {(searchText || rateFilter) && 
                    <Col className="col-3" style={{ padding: ' 0 ', marginTop: '22px'}}>

                        <Button variant="outline-info" size="sm" style={{ width: "100%", height: '38px', margin: '10px 0', padding: 0 }} onClick={() => clearFilters()}>Сбросить</Button>
                    </Col>
                }
            </Row>
            <div className="mb-4 mt-3" style={{display: "flex", justifyContent: "space-between"}}>
                <div onClick={() => setRateFilter('1')} style={{ backgroundColor: rateFilter === '1' ? "rgba(0, 0, 0, .1)" : 'transparent', cursor: "pointer", border: '1px solid red', borderRadius: '4px', height: '40px', "fontSize": 'smaller', padding: '0 0 0 3px', width: '18%' }}><span style={{ marginRight: 5, padding: '3px 5px', fontSize: '10px', boxSizing: 'border-box' }} className={`badge bg-danger`}>1</span> - свыше 10 млн</div>
                <div onClick={() => setRateFilter('2')} style={{ backgroundColor: rateFilter === '2' ? "rgba(0, 0, 0, .1)" : 'transparent', cursor: "pointer", border: '1px solid blue', borderRadius: '4px', height: '40px', "fontSize": 'smaller', padding: '0 0 0 3px', width: '18%' }}><span style={{ marginRight: 5, padding: '2px 3px', fontSize: '10px', boxSizing: 'border-box' }} className={`badge bg-primary`}>2</span> - от 5 до 10 млн</div>
                <div onClick={() => setRateFilter('3')} style={{ backgroundColor: rateFilter === '3' ? "rgba(0, 0, 0, .1)" : 'transparent', cursor: "pointer", border: '1px solid green', borderRadius: '4px', height: '40px', "fontSize": 'smaller', padding: '0 0 0 3px', width: '18%' }}><span style={{ marginRight: 5, padding: '3px 5px', fontSize: '10px', boxSizing: 'border-box' }} className={`badge bg-success`}>3</span> - от 1 до 5 млн</div>
                <div onClick={() => setRateFilter('4')} style={{ backgroundColor: rateFilter === '4' ? "rgba(0, 0, 0, .1)" : 'transparent', cursor: "pointer", border: '1px solid #0dcaf0', borderRadius: '4px', height: '40px', "fontSize": 'smaller', padding: '0 0 0 3px', width: '18%'}}><span style={{ marginRight: 5, padding: '3px 5px', fontSize: '10px', boxSizing: 'border-box' }} className={`badge bg-info`}>4</span> - до 1 млн</div>
                <div onClick={() => setRateFilter('5')} style={{ backgroundColor: rateFilter === '5' ? "rgba(0, 0, 0, .1)" : 'transparent', cursor: "pointer", border: '1px solid black', borderRadius: '4px', height: '40px', "fontSize": 'smaller', padding: '0 0 0 3px', width: '18%' }}><span style={{ marginRight: 5, padding: '3px 5px', fontSize: '10px', boxSizing: 'border-box' }} className={`badge bg-dark`}>5</span> - без активных договоров</div>

            </div>
            <Table striped bordered hover >

                <thead style={basicStyles}>
                    <tr >
                        <th>Тип</th>
                        <th>Название</th>
                        <th>ИНН</th>
                        <th>Веб-сайт</th>
                        <th >Квоты</th>
                        <th >Контракты</th>
                        <th >Устройства</th>
                        <th >Slug</th>
                        <th>Пользователей</th>
             
                        <th></th>
                    </tr>

                </thead>
                <tbody style={basicStyles}>
                    {orgList?.map(org =>
                        <tr key={org.id} onClick={(e) => { e.preventDefault(); navigate(`/orglist/${org.id}`) }}>
                            <td>
                                {org.type}
                            </td>
                            <td>
                                {organisationRating(org.rating, org.name)}
                            </td>
                            <td>
                                {org.vat}
                            </td>
                            <td>
                                {org.website}
                            </td>
                            <td>
                                {org.quotes} шт
                            </td>
                            <td>
                                {org.contracts} шт
                            </td>
                            <td>
                                {org.devices} шт
                            </td>
                            <td>
                                {org.slug}
                            </td>
                            {org.users_amount === 0 ? <td>{0}</td> :
                                <td>
                                    активных: {org.active_users}<br />
                                    не активных: {org.passive_users}
                                </td>}

                            <td onClick={(e) => { e.stopPropagation() }}>
                                {
                                    org.type !== "VENDOR" &&
                                    <Button variant="outline-danger" size="sm" onClick={ () => {setCurrentItem(org); setDeleteItem(true)}} >Удалить</Button>
                                }
                            </td>
                        </tr>
                    )}
                    <tr ref={lastItem} style={{ height: 20 }} ></tr>
                </tbody>

            </Table>

        </Col>
    );
}
export default OrgList;