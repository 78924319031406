import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Table from 'react-bootstrap/Table';


const CloudListStorage = ({ data }) => {
    const [appsAll, setAppsAll] = useState(false);
    const [appthreatAll, setAppthreatAll] = useState(false);
    const [avAll, setAvAll] = useState(false);
    const [wf2All, setWf2All] = useState(false);
    const [wf3All, setWf3All] = useState(false);
    const dateStyles = {
        "fontSize": 'xx-small',

    }
    const buttonStyles = {
        textDecoration: 'underline',
        cursor: 'pointer',
        color: 'blue'

    }
    const showApps = (arr, type) => {
        let newArr = []

        if (!type) { newArr = arr.filter((item, index) => index < 30 ? item : '') }
        else newArr = arr.filter((item) => item)
        return newArr.map((item, index) => <Col key={item + `type`}>{item}</Col>)
    }
    return (
        <Table striped bordered hover id='clstor' >
            <thead style={dateStyles}>
                <tr>

                    <th >Apps ({data[0].files_count})</th>
                    <th>Appthreat ({data[1].files_count})</th>
                    <th>Av ({data[2].files_count})</th>
                    <th>wf2 ({data[3].files_count})</th>
                    <th>Wf3 ({data[4].files_count})</th>

                </tr>
            </thead>
            <tbody>
                <tr style={dateStyles}>
                    <th>{showApps(data[0].apps, appsAll)} {!appsAll && data[0].apps.length > 30 && <Col style={buttonStyles} onClick={() => setAppsAll(true)}>Показать все</Col>}</th>
                    <th>{showApps(data[1].appthreat, appthreatAll)}{!appthreatAll && data[1].appthreat.length > 30 && <Col style={buttonStyles} onClick={() => setAppthreatAll(true)}>Показать все</Col>}</th>
                    <th>{showApps(data[2].av, avAll)} {!avAll && data[2].av.length > 30 && <Col style={buttonStyles} onClick={() => setAvAll(true)}>Показать все</Col>}</th>
                    <th>{showApps(data[3].wf2, wf2All)}  {!wf2All && data[3].wf2.length > 30 && <Col style={buttonStyles} onClick={() => setWf2All(true)}>Показать все</Col>}</th>
                    <th>{showApps(data[4].wf3, wf3All)}  {!wf3All && data[4].wf3.length > 30 && <Col style={buttonStyles} onClick={() => setWf3All(true)}>Показать все</Col>}</th>
                </tr>
            </tbody>
        </Table>
    )
}

export default CloudListStorage;

