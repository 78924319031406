/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLocation } from 'react-router-dom';
import Preloader from "../UI/Preloader/Preloader";
import ContractService from "../../API/ContractService";
import ContractsList from "./ContractList";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import Form from 'react-bootstrap/Form';
import { paymentMethod, techStatus } from "../../utils/constants";
import QuotaDescription from "../Quotator/QuotaDescription";
import { AuthContext, UserContext } from "../../context";
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import ExportContractForm from "./ExportContractForm";

function ContractArea(props) {

    //пагинация 
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [isContractLoading, setIsContractLoading] = useState(false);

    const location = useLocation();
    const [isPreloader, setIsPreloader] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isAddingContract, setIsAddingContract] = useState(false);

    // перечень всех контрактов
    const [contractItems, setContractItems] = useState();
    const [totalContractItems, setTotalContractItems] = useState();
    const [nextPage, setNextPage] = useState(0);

    //Фильтры
    const [paymentFilter, setPaymentFilter] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [statusFilter, setStatusFilter] = useState(false);
    const [techStatusFilter, setTechStatusFilter] = useState(false);

    //квота деталь
    const [viewQuota, setViewQuota] = useState(false);
    const [currentQuota, setCurrentQuota] = useState(null);
    const [viewOrg, setViewOrg] = useState(false);
    const [exportContracts, setExportContracts] = useState(false);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const {userinfo} = useContext(UserContext);
    const changeStatus = () => setStatus(true);

    const fetchData = async (add) => {
        let filters = false;
        if (paymentFilter || searchText || statusFilter || techStatusFilter) {
            filters = {
                paymentFilter: paymentFilter.type,
                searchText: searchText,
                statusFilter: statusFilter,
                techStatusFilter: techStatusFilter.type
            };
        }
        setIsPreloader(true)
        setIsContractLoading(true);
        const id = props.orgId ? props.orgId : false;
        // const id = location.pathname === '/orglist' ? props.orgId : false;

        await ContractService.getList(id, filters, add ? nextPage : 1, props.enduser)
            .then((res) => {
                if (add === 'add') { res.results?.map(item => setContractItems(arr => [...arr, item])); setNextPage(res.next ? nextPage + 1 : null); }
                else { setContractItems(res.results); setNextPage(res.next ? 2 : null); }
                setTotalContractItems(res.count);
                // setNextPage(res.next ? 2 : null);
                setIsContractLoading(false);
            })
            .catch(() => changeStatus()).finally(() => setIsPreloader(false))
    }

    useEffect(() => {
        setIsPreloader(true)
        const timerRefresh = setTimeout(fetchData, 1000);
        return () => {

            clearTimeout(timerRefresh)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddingContract, paymentFilter, searchText, statusFilter, techStatusFilter]);

    useEffect(() => {

        (async () => {

            if (needToLoad && nextPage) {
                await fetchData('add');
                setNeedToLoad(false);
            }
        })();
    }, [needToLoad]);

    useEffect(() => {

        if (isContractLoading) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage && contractItems?.length>0) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

    }, [isContractLoading]);

    const clearFilters = () => {
        setPaymentFilter(false);
        setStatusFilter(false)
        setSearchText("");
        setTechStatusFilter(false);
    }

    return (<>
 {exportContracts && <MyConfirm visible={exportContracts} setVisible={setExportContracts} >
               <ExportContractForm close={()=> setExportContracts(false)} />
            </MyConfirm>}
        {viewOrg === false && <Col >
            {location.pathname !== "/orglist" && isAddingContract === false &&
                <div className="mb-4"><h3>Договора (отображено {totalContractItems} договоров)</h3></div>}
            {location.pathname !== "/orglist" && isAddingContract === true &&
                <div><h3>Создать договор</h3></div>}
{   userinfo.role!=='MANAGER' &&         <Row className="col-2">
            <Col >
                            <Button variant="outline-success" style={{ width: "100%" }} size="sm" onClick={() => { setExportContracts(true) }}>Экспорт в xslx</Button>
                        </Col >
              
            </Row>}
              <hr />
            {viewQuota === true && <QuotaDescription id={currentQuota} setViewQuota={setViewQuota} />}
            <Row className="justify-content-start" style={{position: "relative"}}>
            {isPreloader && <Preloader />}
                <Col className="mb-3 col-2">
                    <Form.Label size="sm">Тех. статус</Form.Label>
                    <Dropdown style={{ width: "100%" }}>
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-ts" >
                            {techStatusFilter.title || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filter={setTechStatusFilter}
                        >
                            {techStatus?.map(method =>
                                <Dropdown.Item eventKey={"ts" + method.title} key={"ts" + method.title} onClick={() => { setTechStatusFilter(method) }}>{method.title}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Статус</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-status" >
                            {statusFilter || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filter={setStatusFilter}
                            type='statusF'
                        >
                            <Dropdown.Item eventKey={"sf" + 1} key={"sf" + 1} onClick={() => { setStatusFilter('ACTIVE') }}>{'ACTIVE'}</Dropdown.Item>
                            <Dropdown.Item eventKey={"sf" + 2} key={"sf" + 2} onClick={() => { setStatusFilter('EXPIRED') }}>{'EXPIRED'}</Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2">
                    <Form.Label size="sm">Тип оплаты</Form.Label>
                    <Dropdown style={{ width: "100%" }}>
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-payment" >
                            {paymentFilter.title || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            setPaymentFilter={setPaymentFilter}
                            type='typePayment'
                        >
                            {paymentMethod?.map(method =>
                                <Dropdown.Item eventKey={"mpay" + method.title} key={"mpay" + method.title} onClick={() => { setPaymentFilter(method) }}>{method.title}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>

                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Поиск</Form.Label>
                    <Form.Control onChange={(e) => { setSearchText(e.target.value) }}
                        placeholder="Начните вводить..."
                        size="sm"
                        style={{ fontSize: '1rem', height: 38 }}
                        value={searchText}
                        // disabled={isContractLoading}
                        // autoFocus
                    />
                </Col>
                {(paymentFilter || searchText || statusFilter || techStatusFilter) &&
                    <Col className="col-2">

                        <Button variant="outline-info" size="sm" style={{ width: "100%", height: '38px', marginTop: 29 }} onClick={() => clearFilters()}>Сбросить фильтры</Button>
                    </Col>}

            </Row>
            <Row className="justify-content-start">
                <ContractsList
                    contractItems={contractItems} setViewQuota={setViewQuota}
                    viewQuota={viewQuota} setCurrentQuota={setCurrentQuota}
                    viewOrg={viewOrg} setViewOrg={setViewOrg} />
              


            </Row>
            <div ref={lastItem} style={{ height: 20 }} />
        </Col>}
    </>
    );
}
export default ContractArea;