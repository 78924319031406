import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Table from 'react-bootstrap/Table';
import ImgDelete from '../../icons/delete.svg';
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import Preloader from "../UI/Preloader/Preloader";
import ImgNotes from '../../icons/notes.svg';
import IconEdit from '../../icons/edit.svg';
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";
import Alert from 'react-bootstrap/Alert';
import AddServerSoftForm from "./AddServerSoftForm";
import PimService from "../../API/PimService";
import AgentSoftwareList from "./AgentSoftwareList";
import AddAgentSoftForm from "./AddAgentSoftForm";
import PatchServerSoftForm from "./PatchServerSoftForm";
import PatchAgentSoftForm from "./PatchAgentSoftForm";

const ServerSoftwareList = () => {
    const [patchDocument, setPatchDocument] = useState(false);
    const [patchDocumentAgent, setPatchDocumentAgent] = useState(false);
    const [data, setData] = useState(null)
    const [errors, setErrors] = useState([]);
    const [addDocument, setAddDocument] = useState(false);
    const [addDocumentAgent, setAddDocumentAgent] = useState(false);
    const [successAdd, setSuccessAdd] = useState(false);
    const [successAddAgent, setSuccessAddAgent] = useState(false)
    const [successPatch, setSuccessPatch] = useState(false);
    const [isPreloader, setIsPreloader] = useState(false);
    const [deleteItem, setDeleteItem] = useState(false);
    const [successDelete, setSuccessDelete] = useState(false);
    const [viewDetail, setViewDetail] = useState(false);
    const [detailData, setDetailData] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const basicStyles = {
        "fontSize": '14px',
        cursor: 'pointer'
    }
    const viewExtension=(file)=> {
        if (file) {
            let arr = file.split('/');
            let string = arr[arr.length -1];
    return string
        } else return ''
    }
    
    const fetchData=async (add)=> {
        setIsPreloader(true)
        await PimService.getPimServersSoftList(add ? nextPage : 1).then((res) => { 
            if (add === 'add') {setNextPage(res.next ? nextPage + 1 : null);
                res.results?.map(item => setData(arr => [...arr, item]));}
                else {
                    setData(res.results);
                    setNextPage(res.next ? 2 : null);
                }
             }).catch((err) => { setErrors(err.message) }).finally(() => { setIsPreloader(false); })
    }
    useEffect(() => {
       fetchData()
    }, [successAdd, successDelete, successPatch])

    useEffect(() => {

        if (isPreloader) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPreloader]);
    useEffect(() => {

        ( () => {

            if (needToLoad && nextPage) {
               
                fetchData('add');
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') deleteInstance();

            })
            .catch(() => changeStatus())
    }
useEffect(()=> {
  if (viewDetail) { (async () => {
        setIsPreloader(true)
        await PimService.getPimDetail(viewDetail?.id, viewDetail?.type).then((res) => { setDetailData(res); }).catch((err) => { setErrors(err.message) }).finally(() => { setIsPreloader(false); })
    }
    )()}
},[viewDetail])
    const deleteInstance = async (id, type) => {
        setIsPreloader(true);
        await PimService.deleteServerInstance(id, type)
            .then(() => { setIsPreloader(false); setDeleteItem(false); setSuccessDelete(!successDelete) })
            .catch((e) => {
                if (e.message[0].includes('token')) refreshToken('delete');
                else { setIsPreloader(false); setErrors(e.message); }
            });
    }
    return (
        <>
         {viewDetail?.open && <MyConfirm visible={viewDetail} setVisible={setViewDetail} align={'top'}>
        <div style={{ width: '50vw', margin: '0 auto', maxHeight: '80vh', overflowY: 'scroll' , position: "relative" }}>

           
        <div style={{position: 'sticky', top: '0px', background: 'white', zIndex: 10}}>
  <div style={{display: "flex", justifyContent: "space-between", padding: '0 5px'}}>

    <h5>v {detailData?.release_version || detailData?.major_version}{' - '}{detailData?.release_date}</h5>
    <div style={{display: 'flex', justifyContent: 'flex-end', gap: 15, marginBottom: 20}}><a href='#notes'>Описание релиза</a>{detailData?.known_issues && <a href='#issues'>Известные проблемы</a>}</div>
  </div>

</div>
<div  id='notes'style={{paddingTop: '40px', marginTop: '-40px'}}></div>
<h5 >{'Описание релиза'}</h5>         
    {detailData?.release_notes ? detailData?.release_notes?.split('\*')?.map((item, index)=> item? <div key={item + 'notes' + index}><p  style={{margin: 0}}>{item}</p> <hr style={{marginTop: 0}}/></div>: ''): 'Нет данных'}
{  detailData?.known_issues &&  <><h5 id='issues' style={{marginTop : '20px'}}>{'Известные проблемы'} </h5>         
    {detailData?.known_issues? detailData?.known_issues?.split('\*')?.map((item, index)=> item? <div key={item + 'issues' + index}><p  style={{margin: 0}}>{item}</p> <hr style={{marginTop: 0}}/></div>: ''): 'Нет данных'}</> }
        </div>
    </MyConfirm>}
            {deleteItem?.open && <MyConfirm visible={deleteItem?.open} setVisible={setDeleteItem}  >
                <div style={{width: 700}}>
                <Row className="mb-4" style={{ position: "relative",}}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4" >Вы точно хотите удалить {deleteItem?.type==='major'? 
                    <span>мажорную&nbsp;версию&nbsp;{deleteItem?.item.major_version}? Это так-же удалит все релизные версии относящиеся к ней.</span>
                    : <span>релизную&nbsp;версию&nbsp;{deleteItem?.item.release_version}?</span>}</h3>
                </Row>
                <Row > {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row >
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); deleteInstance(deleteItem.id,deleteItem?.type  ) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeleteItem(false); setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row></div>
            </MyConfirm>}
            <Row style={{ position: "relative" }}>
                {isPreloader && <Preloader />}
               
            </Row>
            {addDocument && <AddServerSoftForm open={setAddDocument} setSuccessAdd={setSuccessAdd} successAdd={successAdd} />}
            {patchDocument && <PatchServerSoftForm open={patchDocument} setSuccessAdd={setSuccessPatch} successAdd={successPatch}  close={()=>setPatchDocument(false)}/>}
            {patchDocumentAgent && <PatchAgentSoftForm open={patchDocumentAgent} setSuccessAdd={setSuccessAddAgent} successAdd={successAddAgent}  close={()=>setPatchDocumentAgent(false)}/>}
            {addDocumentAgent && <AddAgentSoftForm open={setAddDocumentAgent} setSuccessAdd={setSuccessAddAgent} successAdd={successAddAgent} />}
            <hr />
            <Row className="justify-content-between">
                <Col className="col-6" style={{boxSizing: "border-box"}}>
               <div style={{display: "flex", justifyContent: "space-between", marginBottom: 5}}> <span >Серверное ПО</span>
               <Button variant="outline-success" size="sm" style={{ fontSize: '1rem',  }}
                        onClick={() => { setAddDocument(true) }}
                    >Добавить</Button>
               </div>
               <div style={{maxHeight: 400, height: 400, overflowY: 'scroll'}}>

            <Table striped bordered hover id='tableTokens'  >
                <thead style={basicStyles}>
                    <tr>
                        <th style={{width: '20%'}}>Мажорная версия</th>
                        <th>Версия релиза</th>
 
                    </tr>
                </thead>
                <tbody>

                    {data?.map((item, index) =>
                        <tr key={item.major_version_id + 'serverSoft'}
                            style={basicStyles}
                        >
                            <td ><div style={{display: "flex", justifyContent: "space-between"}}><span>{item.major_version}</span>
                           <img width="14" className="interactive_hover" alt={'download'} src={ImgDelete} onClick={
                                (evt) => {

                                    evt.stopPropagation();
                                    setDeleteItem({open: true, type: 'major', item: item, id: item.major_version_id})

                                }
                            } />
                            </div></td>
                            <td>{item.releases?.map((release, ind)=> {
                           return <div key={release?.release_version + 'version'}><div  style={{marginBottom: 5, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                           <div style={{display: "flex", flexDirection: "column"}}><span style={{display: 'flex', gap: 15}}><span style={{fontWeight: 600}}>{release.release_version}</span>{' '}
                           <div style={{ background: '#e6f7ff', textAlign: "center", borderRadius: '4px', padding: '0 2px'}}>{viewExtension(release.release_package)}</div></span>
                           <span style={{display: "flex", gap: 10}}>{release.release_date}<div
                                                                style={{
                                                                    background:
                                                                      release?.is_full ?   "#1890ff" : '#52c41a',
                                                                    textAlign:
                                                                        "center",
                                                                    borderRadius:
                                                                        "4px",
                                                                    color: "white",
                                                                    padding:
                                                                        "0 4px",
                                                                        height: 20
                                                                }}
                                                            >
                                                            {release?.is_full? 'Full': 'Update'}
                                                            </div>{((release.release_size)/(1024*1024)).toFixed(2)}{' '}Мб.
                                                            <div
                                                                style={{
                                                                    background:
                                                                      release?.is_visible ?   "#adc6ff" : '#ffbb96',
                                                                    textAlign:
                                                                        "center",
                                                                    borderRadius:
                                                                        "4px",
                                                                    color: "white",
                                                                    padding:
                                                                        "0 4px",
                                                                        height: 20
                                                                }}
                                                            >
                                                            {release?.is_visible? 'Visible': 'Hidden'}
                                                            </div>                                          
                         
                           
                          </span>
                           </div> 
                           
                           <div key={release?.release_version + 'actions'} >
                            <img width="14"  style={{marginRight: 5,}}className="interactive_hover" alt={'release notes'} src={ImgNotes} onClick={
                                (evt) => {

                                    evt.stopPropagation();
                                   setViewDetail({open: true, id: release.release_id, type: 'server'})
                                 
                                }
                            } />
                            <img width="14" style={{marginRight: 5,}}className="interactive_hover" alt={'edit'} src={IconEdit} onClick={
                                (evt) => {

                                    evt.stopPropagation();
                                      setPatchDocument({open: true, id: release.release_id, type: 'server'})

                                }
                            } /><img width="14" className="interactive_hover" alt={'download'} src={ImgDelete} onClick={
                                (evt) => {

                                    evt.stopPropagation();
                                    setDeleteItem({open: true, type: 'release', item: release, id: release.release_id})

                                }
                            } /></div>
                           </div>{ind<item.releases.length -1 && <hr style={{margin: 2}}/>}</div> })}</td>
                            
                           
                          
                        </tr>
                    )
                    }
                </tbody>
            </Table>
            <div ref={lastItem} style={{ height: 20 }} ></div>
            </div></Col>
            <Col className="col-6" style={{boxSizing: "border-box"}}>
            <AgentSoftwareList addDocumentAgent={addDocumentAgent} setAddDocumentAgent={setAddDocumentAgent} 
            successAdd={successAddAgent} setSuccessAdd={setSuccessAddAgent} setViewDetail={setViewDetail}
            patchDocument={patchDocumentAgent} setPatchDocument={setPatchDocumentAgent}
            />
            </Col>
            </Row>
        </>
    )
}

export default ServerSoftwareList;
