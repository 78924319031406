import React from "react";
import Row from 'react-bootstrap/Row';
import Header from "../components/UI/Header";
import SideBar from "../components/UI/Sidebar";
import AssetDetail from "../components/Assets/AssetDetail";

function AssetDetailPage() {

  return (
    <div>
      <Header />
      <Row>
          <SideBar />
         <AssetDetail />
      </Row>
    </div>
  );
}

export default AssetDetailPage;