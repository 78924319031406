import axios from 'axios';
import Api from '.';

export default class DocumentationService {
    static async getDocumentationList(filters) {
        var url = 'api/v1/docs/'
        const method = 'GET';
        const params = {};
        if (filters) {

            if (filters.search) {
                params.search = filters.search;
            }
        };
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async newInstance(formData) {
        const url = 'api/v1/docs/';
        const method = 'POST';
        const data = formData;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };

    static async deleteInstance(id) {
        const url = `api/v1/docs/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }

    static async deleteDoc(idList, idFile) {
        const url = `api/v1/docs/${idList}/delete-file/${idFile}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }

    static async deleteAllDocs(idList) {
        const url = `api/v1/docs/${idList}/delete-all-files/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }



    static async patchInstance(item, id) {
        const url = `api/v1/docs/${id}/`;
        const data = item;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }
    static async downloadDocs(file) {
    
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            responseType: 'arraybuffer',
        };

        const response = await axios.get(file.doc_file, config);
        return response;
    }



}