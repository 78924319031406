import React, { useContext, useState } from "react";
import { UserContext } from "../../context";
import Col from 'react-bootstrap/Col';
import UserList from "../User/UserList";


function MyOrgArea() {
    
    const {userinfo} = useContext(UserContext);
    // eslint-disable-next-line no-unused-vars
    const [organization, setOrganization] = useState(userinfo.organization);
   
    return (
            <Col>
                <div><h3>Моя организация {organization}</h3></div>
                <UserList type={userinfo.organizationType}/>
            </Col>
    );
}
export default MyOrgArea;