import React, { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AssetService from "../../API/AssetService";
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";
import Preloader from "../UI/Preloader/Preloader";
function AssetCopyForm(props) {

    // const [patchDate,] = useState(refactDate(props.asset.patched_date))
    const [assetDescription,] = useState(props.asset.description);
    const [serialNumber, setSerialNumber] = useState(props.asset.serial_nr);
    const [city,] = useState({ city_ru: props.asset.city });
    const [country,] = useState({ country_ru: props.asset.country });
    const [isPreloader, setIsPreloader] = useState(false);
    ///
    // eslint-disable-next-line no-unused-vars
    const [assetOrg, setAssetOrg] = useState(props.asset?.organisation_info[0].name);
    const [softVersion,] = useState(props.asset.sw_version || null)
    const [errors, setErrors] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [assetPlatform, setAssetPlatform] = useState(props.asset.platform_name || null);
    // eslint-disable-next-line no-unused-vars
    const [assetPlatformType, setAssetPlatformType] = useState(props.asset.platform_type || null);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                dublicateAsset();
            })
            .catch(() => changeStatus())
    }
    const dublicateAsset = async event => {
        setIsPreloader(true)
        if (event) event.preventDefault();
        await AssetService.dublicateAsset(props.asset.id, serialNumber).then(() => { props.setIsCopyAsset(false); props.setCopyAsset(!props.copyAsset); setIsPreloader(false) })

            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })

    }
    return (
        <Form id="form" onSubmit={dublicateAsset} className="was-validated" >
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}

            <Row>
                <h5 className="mb-3">Дублировать устройство</h5>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Серийный номер устройства</Form.Label>
                        <Form.Control size="sm" type="text" value={serialNumber.trim()} required={true} placeholder={'Введите серийный номер'}
                            onChange={(e) => setSerialNumber(e.target.value.trim())}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Организация</Form.Label>
                        <Form.Control size="sm" type="text" value={assetOrg} disabled

                        />
                        {/* <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                                <Dropdown.Toggle as={MyToggle} id="dropdown-orgs" >
                                    Организация: {assetOrg || "выбрать"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    as={MyMenu}
                                    type={'assOrg'}
                                    filterQa={true}
                                >
                                    {allOrganisations?.map(org =>
                                        <Dropdown.Item eventKey={"e" + org.id} key={"e" + org.id} onClick={() => { setAssetOrg(org.name) }}>{org.name}</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown> */}

                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">

                        <Form.Label size="sm">Платформа</Form.Label>
                        <Form.Control size="sm" type="text" value={assetPlatform} disabled

                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Страна</Form.Label>
                        <Form.Control size="sm" type="text" value={country?.country_ru} disabled
                        />
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Город</Form.Label>
                        <Form.Control size="sm" type="text" value={city?.city_ru} disabled
                        />

                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Версия ПО </Form.Label>
                        <Form.Control size="sm" type="text" value={softVersion || ""}
                            disabled
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>

            <Row>

                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Комментарий</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={assetDescription || ""}
                            size="sm"
                            disabled
                        />
                    </Form.Group>
                </Col>
                {/* <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Дата патча</Form.Label>
                        <Form.Control
                            value={patchDate || ""}
                            size="sm" type="date"
                            disabled
                        />
             
                    </Form.Group>
                </Col> */}
                {/* {assetPlatformType === "VM" && <Col sm={4}>
                    <Form.Group className="mb-4" >
                        <Form.Label size="sm">Гипервизор</Form.Label>
                        <Form.Control
                            value={currentHypervisor?.hv_type || ""}
                            size="sm" 
                            disabled
                        />
                    </Form.Group>
                </Col>} */}
            </Row>

            <Row >
                <Col sm={1} style={{ width: 100 }}>
                    <Form.Group className="mb-4">
                        <Button variant="outline-danger" size="sm" onClick={() => {
                            props.setIsCopyAsset(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col >
                    <Form.Group className="mb-4">
                        <Button variant="outline-primary" size="sm" type="submit">
                            Дублировать
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>

            <hr />

        </Form >
    );
}
export default AssetCopyForm;