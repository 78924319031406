import React, { useState } from "react";
import Form from 'react-bootstrap/Form';


const QuotaPriceItem = (props) => {

    // eslint-disable-next-line no-unused-vars
    const [currentDiscount, setCurrentDiscount] = useState(props.item.discount);
    function changeAmount(item, value) {
        if (value !== "") {
            props.changeItemQty(item, value, props.index)
        } else {
            props.changeItemQty(item, 0)
        }
    }
    const changeDiscount = (item, value) => {
        props.item.discount = value;
        setCurrentDiscount(value)
        props.changeDiscount(item, value)
    }

    return (
        <tr >
            <td>
                {props.item.part_number}
            </td>
            <td>
                {props.item.name}
            </td>
            <td>
                {props.item.price}
            </td>
            <td sm={1} >
                <Form.Group className="mb-1">
                    <Form.Control
                        type="text"
                        value={props.item.discount}
                        size="sm"
                        
                        onChange={(e) => changeDiscount(props.item, e.target.value)}
                    />
                </Form.Group>
            </td>
            <td sm={1} >
                <Form.Group className="mb-1">
                    <Form.Control
                        type="text"
                        value={props.item.amount}
                        size="sm"
                        onChange={(e) => changeAmount(props.item, e.target.value)}
                    />
                </Form.Group>
            </td>
            <td>
                {parseFloat(props.item.price * (props.item.amount) * Number(100 - props.item.discount) / 100).toFixed(2)}
            </td>
            <td sm={2}>
                <a href="#none" onClick={() => props.removeItem(props.item, props.index)}>
                    Удалить
                </a>
            </td>
        </tr>
    )
}

export default QuotaPriceItem;