import React, { useState } from "react";
import Table from 'react-bootstrap/Table';
import ImgEdit from '../../icons/edit.png';
import MyModal from "../UI/MyModal/MyModal";
import HyperTypeAddForm from "./HyperTypeAddForm";
import HypervisorEditForm from "./HypervisorEditForm";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function HypervisorList(props) {

    const [hypervisorEditing, setHypervisorEditing] = useState(false);
    const [hypervisorAdding, setHypervisorAdding] = useState(false);
    const [currentHypervisor, setCurrentHypervisor] = useState(null);
    const basicStyles = {
        "fontSize": 'smaller',
    }

    return (

        <>
            {hypervisorEditing &&
                <MyModal visible={hypervisorEditing} setVisible={setHypervisorEditing}>

                    <HypervisorEditForm
                        visor={currentHypervisor}
                        setVisible={setHypervisorEditing}
                        setEditHyp={props.setHypervisorEdit}
                        hypEdit = {props.hypervisorEdit}
                    />

                </MyModal>}
            {hypervisorAdding && <MyModal visible={hypervisorAdding} setVisible={setHypervisorAdding}>


                <HyperTypeAddForm setAddHypervisor={setHypervisorAdding} setHypervisorAdd={props.setHypervisorAdd} hypervisorAdd={props.hypervisorAdd} />


            </MyModal>}
            <Row>
                <Col className="mb-3 col-2 ">
                    <Button style={{ width: '100%' }} onClick={() => { setHypervisorAdding(true) }} variant="outline-dark" > Добавить гипервизор</Button>
                </Col>
            </Row>
            <Table className="mb-5">
                <thead style={basicStyles}>
                    <tr>
                        <th sm={1}>ID</th>
                        <th sm={5}>Тип</th>
                        <th sm={4}>Версия</th>
                        <th sm={1}>Описание</th>
                        <th sm={1}>Изменить</th>

                    </tr>
                </thead>
                <tbody style={basicStyles}>
                    {
                        props.hypervisor?.map(visor =>
                            <tr key={visor.id}>
                                <td>
                                    {visor.id}
                                </td>
                                <td>
                                    {visor.hv_type}
                                </td>
                                <td>
                                    {visor.hv_version}
                                </td>
                                <td>
                                    {visor.description}
                                </td>
                                <td>
                                    <img width="20" className="interactive_hover" src={ImgEdit} disabled alt={"edit"} onClick={
                                        () => {
                                            setHypervisorEditing(true);
                                            // props.setCategoryName(category.name);
                                            // props.setCategoryDiscount(category.discount);
                                            // props.setCategoryDescription(category.description);
                                            setCurrentHypervisor(visor)
                                        }
                                    } />
                                </td>
                            </tr>
                        )

                    }

                </tbody>

            </Table>

        </>
    );
}
export default HypervisorList;