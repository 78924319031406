import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate, useParams } from 'react-router-dom';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Alert from 'react-bootstrap/Alert';
import { AuthContext } from "../../context";
import User from "../../API/User";
import UserPatchForm from "./UserPatchForm";
import OrgService from "../../API/OrgService";
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import Preloader from "../UI/Preloader/Preloader";

const UserDetail = (props) => {
    const navigate = useNavigate();
    const { idUser } = useParams();
    const [userDetails, setUserDetails] = useState();
    const [errors, setErrors] = useState([]);
    const [patchUser, setPatchUser] = useState(false);
    const [allOrganisation, setAllOrganisation] = useState([]);
    const [externalAccess, setExternalAccess] = useState([]);
    const [orgFilter, setOrgFilter] = useState('');
    const [deleteUser, setDeleteUser] = useState(false);
    const [isPreloader, setIsPreloader] = useState(false);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {

    }, [])
    useEffect(() => {

        (async () => {
            if (!patchUser) await User.getUser(idUser).then((res) => { setUserDetails(res); setExternalAccess(res.external_orgs_access) }).catch(() => changeStatus());

        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idUser, patchUser]);
const fetchOrgData=async()=> {
    let filters = false;
    if (orgFilter) {
        filters = { searchText: orgFilter }
    }
    await OrgService.getList(filters, false, true).then((res) => setAllOrganisation(res)).catch(() => changeStatus());
}
    useEffect(() => {
        const timerRefresh = setTimeout(fetchOrgData, 500);
        return () => {
          clearTimeout(timerRefresh);
        };
    }, [orgFilter]);

    useEffect(() => {
        if (!patchUser) setExternalAccess(userDetails?.external_orgs_access)
    }, [patchUser]);

    const checkDeleteUser = (orgType, status, role) => {
        if (status !== true) {
            if (orgType === 'VENDOR' && role !== 'PRIMARY_USER') return true
            else if (orgType === 'VENDOR' && role === 'PRIMARY_USER') return false
            else return true
        } else return false
    }

    const handleDeleteUser=async ()=> {
        setIsPreloader(true);
        await User.deleteUser(userDetails?.id).then(()=>{ setDeleteUser(false); navigate('/users')}).catch((err)=> setErrors(err.message)).finally(()=> setIsPreloader(false))
    }
    return (
        <Col className="col-9 mb-5">
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {deleteUser &&
                <MyConfirm visible={deleteUser} setVisible={setDeleteUser}>
                    <Row>

                        <h5 className="mb-3">Удалить пользователя?</h5>
                    </Row>
                    <Row> {errors.length > 0 &&
                        errors.map(error =>
                            <Alert key="danger" variant="danger">
                                {error}
                            </Alert>
                        )
                    }</Row>
                    <Row>
                        <Col >
                            <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); handleDeleteUser()}}>
                                Да
                            </Button>

                        </Col>
                        <Col >
                            <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeleteUser(false); setErrors([]) }} style={{ width: '100%' }}>
                                нет
                            </Button>
                        </Col>
                    </Row>

                </MyConfirm>}
            <Row>
                <Col sm={2} colSpan={1}>
                    <Form.Group className="mb-4">
                        <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => { navigate(-1) }}
                        >Вернуться</Button>

                    </Form.Group>
                </Col>
            </Row>

            <Row style={{position: "relative"}}>
                {isPreloader && <Preloader/>}
                <Col className="col-md-auto">
                    <b>Фамилия:</b> <span> {userDetails?.last_name || 'Не указано'} </span> <b>Имя: </b>{userDetails?.first_name || 'Не указано'}
                    <br />
                    <b>Email: </b>{userDetails?.email}
                    <br />
                    <b>Роль: </b>{userDetails?.role}
                    <br />
                    <b>Организация: </b>{userDetails?.organisation_info[0].name}
                    <br />
                    <b>Активен: </b> {userDetails?.is_active === true ? <span style={{ color: "green" }}>Да</span> : <span style={{ color: "red" }}>Нет</span>}
                    <br />
                    <b>Должность: </b>{userDetails?.job_title || 'Не указано'}
                    <br />
                    <b>Телефон: </b>{userDetails?.phone || 'Не указано'}
                    <br />
                    <b>Кол-во доступов к сторонним устройствам: </b> {externalAccess?.length}
                    <br />
                    <br />
                    {externalAccess?.length > 0 &&
                        externalAccess?.map((item) => <span key={item.id + 'access'} style={{ marginRight: 10, width: 'fit-content ', fontSize: '18px' }} className="badge bg-info">{item.name}</span>)}

                </Col>
            </Row>

            {!patchUser && <Row>
                <Col className="col-2 mt-3"  >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" onClick={() => setPatchUser(true)}>Редактировать</Button>
                    </Form.Group>
                </Col>
                {checkDeleteUser(userDetails?.organisation_info[0].type, userDetails?.is_active, userDetails?.role) && <Col className="col-2 mt-3"  >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => setDeleteUser(true)}>Удалить</Button>
                    </Form.Group>
                </Col>}

            </Row>}
            {patchUser && <Row>
                <UserPatchForm orgFilter={orgFilter} setOrgFilter={setOrgFilter} user={userDetails} setPatchUser={setPatchUser} allOrganisation={allOrganisation} externalAccess={externalAccess} setExternalAccess={setExternalAccess} />

            </Row>}
            {/* {userDetails?.num_external_orgs > 0 && !patchUser && <span><b>Доступы к сторонним устройствам : </b></span>} */}
            {/* {userDetails?.num_external_orgs > 0 && !patchUser && <Row className="mt-3">
                {userDetails?.external_orgs_access?.map((item) => <span key={item.id + 'access'} style={{ marginRight: 10, width: 'fit-content ', fontSize: '18px' }} className="badge bg-info">{item.name}</span>)}
            </Row>} */}

        </Col >
    );
}

export default UserDetail;