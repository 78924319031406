import Api from '.';

export default class HypervisorService {

    static async getList() {

        const url = 'api/v1/asset-hypervisors/';
        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }

    static async newItem(item) {

        const url = 'api/v1/asset-hypervisors/';
        const method = 'POST';
        const data = item;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    }

    static async patchVisor(visor, id) {
        const url = `api/v1/asset-hypervisors/${id}/`;
        const data = visor;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.status;
    }
}