import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";
import PimService from "../../API/PimService";
import { Dropdown } from "react-bootstrap";
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
function AddPimCompatibleForm({ open, setSuccessAdd, successAdd }) {

    const [errors, setErrors] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [description, setDescription] = useState('');
    const [majorVersion, setMajorVersion] = useState('');
    const [releaseVersion, setReleaseVersion] = useState('');
    const [serversReleaseList, setServersReleaseList] = useState([]);
    const [agentsReleaseList, setAgentsReleaseList] = useState([])
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') saveInstance();

            })
            .catch(() => changeStatus())
    }
    const saveInstance = async event => {
        setIsPreloader(true);
        if (event) event.preventDefault();
        const formData = {}
        formData.server_version = majorVersion;
        formData.client_compatible_since = releaseVersion;
        const arrDescription = description?.split('\n');
        const finalArr = arrDescription.map((item)=> item? item + '*' : '');
        const stringDescription = finalArr.join('')
        formData.description = stringDescription;
        await PimService.newPimCompatible(formData)
            .then(() => { open(false); setIsPreloader(false); setSuccessAdd(!successAdd) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken('save');
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })


    }
    useEffect(() => {
        (async () => {
            await PimService.getPimServerReleaseDropdown().then((res) =>
                setServersReleaseList(res)
            );
        })();
    }, []);
    useEffect(() => {
        (async () => {
            await PimService.getPimAgentsReleaseDropdown(false).then((res) =>
                setAgentsReleaseList(res)
            );
        })();
    }, []);
    // const validateFiles = (value, index) => {
    //     let arr = [];
    //     arr = files?.filter((item) => item.field !== index);
    //     setFiles([...arr, { file: value, name: nameFile(index), field: index }])
    // }

    return (
        <Form id="form" onSubmit={saveInstance} className="was-validated" style={{ position: "relative" }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key={"danger" + error} variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}


            <Row>
            <Col className="mb-3 col-3">
                        <Form.Label size="sm">Серверная релизная версия</Form.Label>
                        <Dropdown>
                            <Dropdown.Toggle
                                as={MyToggleAsset}
                                id="dropdown-release"
                            >
                              {majorVersion || 'Выберите версию'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                //filterAssets={setNewRelease}
                                type="releaseFilter"
                            >
                                
                                {serversReleaseList?.map((release) => (
                                    <Dropdown.Item
                                        eventKey={"apl" + release?.version}
                                        key={"apl" + release?.version}
                                        onClick={() => {
                                          
                                            setMajorVersion(release?.version)
                                        }}
                                    >
                                        {release?.version} 
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className="mb-3 col-3">
                        <Form.Label size="sm">Совместимость клиента</Form.Label>
                        <Form.Control
                                as="textarea"
                                rows={1}
                                required={true}
                                type="text"
                                placeholder="Совместимость"
                                value={releaseVersion || ""}
                               
                                onChange={(e) => setReleaseVersion(e.target.value)}
                            />
                        {/* <Dropdown>
                            <Dropdown.Toggle
                                as={MyToggleAsset}
                                id="dropdown-releaseAgwnts"
                            >
                              {releaseVersion || 'Выберите версию'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                //filterAssets={setNewRelease}
                                type="releaseFilterAg"
                            >
                                
                                {agentsReleaseList?.map((release) => (
                                    <Dropdown.Item
                                        eventKey={"apl" + release?.version}
                                        key={"apl" + release?.version}
                                        onClick={() => {
                                          
                                            setReleaseVersion(release?.version)
                                        }}
                                    >
                                        {release?.version} 
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown> */}
                    </Col>
            </Row>
            <Row>
            <Col className="mb-3 col-4">
                        <Form.Group className="mb-4">
                            <Form.Label size="sm">Описание релиза</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                type="text"
                                placeholder="Описание "
                                value={description || ""}
                                size="sm"
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                    </Col> 
            </Row>
            <hr />
            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            open(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Добавить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>


            <hr />
        </Form >
    );
}
export default AddPimCompatibleForm
