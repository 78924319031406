import React from "react";
import Table from 'react-bootstrap/Table';
import ContractItem from "./ContractItem";

const ContractList = (props) => {

    const basicStyles = {
        "fontSize": 'smaller',
    }

    return (
        <Table striped bordered hover >
            <thead style={basicStyles}>
                <tr>
                    <th>Тех.<br/> статус</th>
                    <th style={{ width: "7%" }}>Дата<br/> договора</th>
                    <th style={{ width: "7%" }}>Дата <br/>окончания</th>
                    <th style={{ width: "12%" }}>Номер</th>
                    <th style={{ width: "7%" }}>Тип <br/>оплаты</th>
                    <th >Покупатель</th>
                    <th>Конечник</th>
                    <th style={{ width: "4%" }}>Квота</th>
                    <th style={{ width: "7%" }}>Сумма <br/>контракта</th>
                    <th>Комментарий</th>
                </tr>
            </thead>
            <tbody>
                {props.contractItems?.map(item =>

                    <ContractItem
                        item={item}
                        key={item.id + 'contracts'}
                        setViewQuota={props.setViewQuota}
                        setCurrentQuota={props.setCurrentQuota}
                        viewOrg={props.viewOrg}
                        setViewOrg={props.setViewOrg}
                        viewQuota={props.viewQuota}
                        currentContract={props.currentContract}
                        setCurrentContract={props.setCurrentContract}
                        removeContract={props.removeContract}
                    />
                )}

                <tr ref={props.lastItem} style={{ height: 20 }} />
            </tbody>
        </Table>

    )
}

export default ContractList;