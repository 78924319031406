import React from "react";
import PasswordRecover from "../components/UI/PasswordRecover";

function PassRecoverPage() {

  return (
    <PasswordRecover />
  );
}

export default PassRecoverPage;
